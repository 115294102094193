import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { useGetDistributeByIdQuery } from 'services/payroll/information/information';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { changeTimeZone, checkIsNumberDecimal, floatNumberFormatter } from 'utils/helpers';

import { DistributeCards, IDistributeValues, TFields } from '../../constants/types';

import { getDistributeDefaultValues, getDistributeMainAmount, getDistributeMainPercent } from './constants/helper';
import { distributeValidation } from './constants/validation';

export const useDistribute = () => {
  const { searchParams } = useDetectedParams();
  const { card, id, open, mode } = searchParams;

  const distributePermissions = usePermittedActions('payroll');

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const [activeRow, setActiveRow] = useState<TFields | null>(null);
  const [activeInput, setActiveInput] = useState('');

  const [remainingSum, setRemainingSum] = useState(0);
  const [remainingPercent, setRemainingPercent] = useState(0);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data, isFetching } = useGetDistributeByIdQuery(
    { id },
    { skip: mode !== 'distribute' || !id || (card !== DistributeCards.CHARGES && card !== DistributeCards.CREDITS) }
  );

  const hasDistributePermission = useMemo(() => {
    if (card === DistributeCards.CREDITS) {
      if (data?.edited) {
        return !distributePermissions?.payrollCredit?.editDistribute;
      } else {
        return !distributePermissions?.payrollCredit?.createDistribute;
      }
    } else {
      if (data?.edited) {
        return !distributePermissions?.payrollCharge?.editDistribute;
      } else {
        return !distributePermissions?.payrollCharge?.createDistribute;
      }
    }
  }, [card, data?.edited, open]);

  const startCurrentData = changeTimeZone(new Date(dayjs().startOf('month').valueOf()), 'America/Los_Angeles');
  const endCurrentData = changeTimeZone(new Date(dayjs().endOf('month').valueOf()), 'America/Los_Angeles');

  const distributeBtnCheck = !(startCurrentData <= Number(data?.acceptBillingTimeAt) <= endCurrentData);

  const defaultFormValues = useMemo(() => {
    return getDistributeDefaultValues(roleLevel! as number);
  }, [roleLevel]);

  const mainAmount = useMemo(() => {
    return Math.abs(Math.round(getDistributeMainAmount(roleLevel! as number, data!) as number));
  }, [roleLevel, isFetching]);
  const mainPercent = useMemo(() => {
    return getDistributeMainPercent(roleLevel! as number, data!);
  }, [roleLevel, isFetching]);

  const validationCheck = useMemo(() => {
    return mainAmount! - remainingSum <= 0.01 && mainAmount! >= remainingSum;
  }, [mainAmount, remainingSum]);

  const {
    control,
    formState: { errors, isDirty, isValid, touchedFields },
    watch,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<IDistributeValues>({
    defaultValues: defaultFormValues,
    mode: 'all',
    resolver: yupResolver(distributeValidation(validationCheck, Object.keys(defaultFormValues))),
  });

  const [isDistributeOpen, setIsDistributeOpen] = useState(false);

  const percent = useMemo(() => {
    return (Number(watch(`${activeRow!}.sum`))! / mainAmount!) * mainPercent!;
  }, [activeInput, remainingSum, remainingPercent, activeRow]);

  const sum = useMemo(() => {
    return (Number(watch(`${activeRow!}.percent`))! * mainAmount!) / mainPercent!;
  }, [activeInput, remainingSum, remainingPercent, activeRow]);

  const handleResetAllDistributes = () => {
    reset(defaultFormValues, { keepErrors: true, keepTouched: false });

    setActiveRow(null);
    setActiveInput('');

    setRemainingSum(0);
    setRemainingPercent(0);
  };

  const handleOpenDistributeSection = (value: boolean) => {
    setIsDistributeOpen(value);
    handleResetAllDistributes();
  };

  const handleDistributeChange = (key: any, value: string) => {
    setValue(key, floatNumberFormatter(value), { shouldDirty: true, shouldValidate: true });

    const focusedRowKey = key.split('.')[0];
    const activeInput = key.split('.')[1];

    setActiveRow(focusedRowKey);
    setActiveInput(activeInput);

    setIsSubmitted(false);
  };

  useEffect(() => {
    if (!isFetching) {
      const sum = Object.values(watch())
        ?.map(el => +el.sum)
        ?.reduce((acc, curr) => acc + curr);
      setRemainingSum(sum);

      const percent = Object.values(watch())
        ?.map(el => +el.percent)
        ?.reduce((acc, curr) => acc + curr);
      setRemainingPercent(percent);
    }
  }, [watch(activeRow!).percent, watch(activeRow!).sum, watch(), activeInput, activeRow, card, isFetching]);

  useEffect(() => {
    if (activeInput && activeInput === 'sum') {
      setValue(`${activeRow!}.percent`, checkIsNumberDecimal(percent) ? checkIsNumberDecimal(percent) : '', {
        shouldValidate: true,
      });
    } else if (activeInput === 'percent') {
      setValue(`${activeRow!}.sum`, checkIsNumberDecimal(sum) ? checkIsNumberDecimal(sum) : '', {
        shouldValidate: true,
      });
    }

    if (validationCheck) {
      clearErrors();
    }
  }, [activeInput, remainingSum, sum, percent, remainingPercent, activeRow, card, mainAmount]);

  useEffect(() => {
    if (!isDistributeOpen) {
      clearErrors();
      setIsSubmitted(false);
    }
  }, [isDistributeOpen, open, id]);

  return {
    data,
    errors,
    control,
    isDirty,
    isTouched: !!Object.keys(touchedFields).length && isDirty,
    isOpen: isDistributeOpen,
    isValid,
    activeRow,
    isFetching,
    mainAmount,
    activeInput,
    isSubmitted,
    remainingSum,
    validationCheck,
    remainingPercent,
    distributeBtnCheck,
    hasDistributePermission,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    setIsSubmitted,
    handleOpen: handleOpenDistributeSection,
    handleReset: handleResetAllDistributes,
    setIsDistributeOpen,
    handleDistributeChange,
    handleOpenDistributeSection,
  };
};
