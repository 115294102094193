import CarrierOTP from 'pages/authorization/carrier-otp/CarrierOTP';
import SignIn from 'pages/authorization/sign-in/SignIn';
import Contact from 'pages/contact/Contact';
import CreditCheck from 'pages/credit-check/CreditCheck';
import Factoring from 'pages/factoring/Factoring';
import HomePage from 'pages/home/Home';
import Loads from 'pages/loads/Loads';
import Payroll from 'pages/payroll/Payroll';
import Permission from 'pages/permission/Permission';
import Profile from 'pages/profile/Profile';
import SearchResult from 'pages/search-result/SearchResult';
import ThankYouPage from 'pages/thankyou/ThankYouPage';
import TruckBoard from 'pages/truck-board/TruckBoard';
import UserSettings from 'pages/user-settings/UserSettings';

import CarrierSignUpPage from '../../../pages/authorization/carrier-sign-up/CarrierSignUpPage';
import NotFound from '../../not-found/NotFound';
import { ROUTES } from '../routes';

import { IRole, IRoute } from './types';

export const allPossibleRoutes = [
  {
    Component: HomePage,
    path: ROUTES.HOME,
    isStatic: true,
  },
  {
    Component: Profile,
    path: ROUTES.PROFILE,
  },
  {
    Component: Loads,
    path: ROUTES.LOADS,
  },
  {
    Component: Payroll,
    path: ROUTES.PAYROLL,
  },
  {
    Component: CreditCheck,
    path: ROUTES.CREDIT_CHECK,
  },
  {
    Component: Contact,
    path: ROUTES.CONTACT,
  },
  {
    Component: Factoring,
    path: ROUTES.FACTORING,
  },
  {
    Component: TruckBoard,
    path: ROUTES.TRUCK_BOARD,
  },
  {
    Component: Permission,
    path: ROUTES.PERMISSION,
  },
  {
    Component: UserSettings,
    path: ROUTES.USER_SETTINGS,
    isStatic: true,
  },
  {
    Component: NotFound,
    path: ROUTES.NOT_FOUND,
    isStatic: true,
  },
  {
    Component: SearchResult,
    path: ROUTES.SEARCH_RESULT,
    isStatic: true,
  },
];
export const allPossibleRoutesAgent = [
  {
    Component: HomePage,
    path: ROUTES.HOME,
    isStatic: true,
  },
  {
    Component: Loads,
    path: ROUTES.LOADS,
  },
  {
    Component: Payroll,
    path: ROUTES.PAYROLL,
  },
  {
    Component: Contact,
    path: ROUTES.CONTACT,
  },

  {
    Component: TruckBoard,
    path: ROUTES.TRUCK_BOARD,
  },
  {
    Component: UserSettings,
    path: ROUTES.USER_SETTINGS,
    isStatic: true,
  },
  {
    Component: NotFound,
    path: ROUTES.NOT_FOUND,
    isStatic: true,
  },
  {
    Component: SearchResult,
    path: ROUTES.SEARCH_RESULT,
    isStatic: true,
  },
];
export const allPossibleRoutesOwner = [
  {
    Component: HomePage,
    path: ROUTES.HOME,
    isStatic: true,
  },
  {
    Component: Profile,
    path: ROUTES.PROFILE,
  },
  {
    Component: Loads,
    path: ROUTES.LOADS,
  },
  {
    Component: Payroll,
    path: ROUTES.PAYROLL,
  },
  {
    Component: CreditCheck,
    path: ROUTES.CREDIT_CHECK,
  },
  {
    Component: Contact,
    path: ROUTES.CONTACT,
  },
  {
    Component: Factoring,
    path: ROUTES.FACTORING,
  },
  {
    Component: TruckBoard,
    path: ROUTES.TRUCK_BOARD,
  },
  {
    Component: Permission,
    path: ROUTES.PERMISSION,
  },
  {
    Component: UserSettings,
    path: ROUTES.USER_SETTINGS,
    isStatic: true,
  },
  {
    Component: NotFound,
    path: ROUTES.NOT_FOUND,
    isStatic: true,
  },
  {
    Component: SearchResult,
    path: ROUTES.SEARCH_RESULT,
    isStatic: true,
  },
];
export const allPossibleRoutesManager = [
  {
    Component: HomePage,
    path: ROUTES.HOME,
    isStatic: true,
  },
  {
    Component: Profile,
    path: ROUTES.PROFILE,
  },
  {
    Component: Loads,
    path: ROUTES.LOADS,
  },
  {
    Component: Payroll,
    path: ROUTES.PAYROLL,
  },
  {
    Component: CreditCheck,
    path: ROUTES.CREDIT_CHECK,
  },
  {
    Component: Contact,
    path: ROUTES.CONTACT,
  },
  {
    Component: TruckBoard,
    path: ROUTES.TRUCK_BOARD,
  },
  {
    Component: Permission,
    path: ROUTES.PERMISSION,
  },
  {
    Component: UserSettings,
    path: ROUTES.USER_SETTINGS,
    isStatic: true,
  },
  {
    Component: NotFound,
    path: ROUTES.NOT_FOUND,
    isStatic: true,
  },
  {
    Component: SearchResult,
    path: ROUTES.SEARCH_RESULT,
    isStatic: true,
  },
];
export const staticUserRoutes: IRole = {
  0: allPossibleRoutes,
  1: allPossibleRoutesOwner,
  2: allPossibleRoutesManager,
  3: allPossibleRoutesAgent,
};

export const getRoutesByPermission = (routes: any) => {
  return allPossibleRoutes.filter((route: any) => route.isStatic || routes.includes(route.path.split('/')[1]));
};

export const getPublicRoutes = (): IRoute[] => [
  {
    Component: CarrierSignUpPage,
    path: ROUTES.CARRIER_SIGN_UP,
  },
  {
    Component: CarrierOTP,
    path: ROUTES.CARRIER_OTP,
  },
  {
    Component: SignIn,
    path: ROUTES.SIGN_IN,
  },
  {
    Component: NotFound,
    path: ROUTES.NOT_FOUND,
  },
  {
    Component: ThankYouPage,
    path: ROUTES.BROKER_THANK_YOU,
  },
  {
    Component: ThankYouPage,
    path: ROUTES.SHIPPER_THANK_YOU,
  },
  {
    Component: ThankYouPage,
    path: ROUTES.DISPATCHER_THANK_YOU,
  },
  {
    Component: ThankYouPage,
    path: ROUTES.FACTORING_COMPANY_THANK_YOU,
  },
];
