import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { transactionsForDataGrind } from 'pages/loads/components/edit-load/constants/helpers';
import { LoadCancelStatus } from 'pages/loads/components/edit-load/constants/types';
import AutoRateConfirmation from 'pages/loads/tabs/loads-tab/components/auto-rate-confirmation/AutoRateConfirmation';
import TonuBanner from 'pages/loads/tabs/loads-tab/components/loads-comment/components/tonu-banner/TonuBanner';
import RedConfimrationViewFiles from 'pages/loads/tabs/loads-tab/components/read-confimariton-view-files/RedConfimrationViewFiles';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { LoadGlobalStatus } from 'ui/load-status/constants/types';
import { MainLoader } from 'ui/main-loader/MainLoader';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import {
  formatNumberWithThousandsSeparatorAndDecimal,
  multiplyDownloadWithDelay,
  multiplyViewWithDelay,
} from 'utils/helpers';

import { filesFormatter, filesNoaFormatter } from 'components/billing-block/constants/helpers';
import { PaymentTermType, PaymentTermTypeText } from 'components/billing-block/constants/types';
import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import CustomComment from 'components/custom-comment/CustomComment';
import CopyIcon from 'components/svgs/CopyIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT, TONU_SECTIONS_VIEW } from '../constants/constants';

import useTonuLoadView from './useTonuView';

import styles from './TonuView.module.scss';

const TonuLoadView = ({
  data,
  files,
  transactions,
  isFetching,
  hasEditPermission,
  loadsPagePermissions,
  refetchLoadById,
  tonuControl,
  comments,
  getMoreComments,
  isShowMoreVisible,
  rateList,
  handleCarrierRate,
  getCarrierRatings,
  handleDeleteRate,
  isLoadsCommentsLoading,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  handleSendRateConfirmation,
}: any) => {
  const {
    handleChangeMode,
    handleViewFile,
    handleDownloadFile,
    handleSwitchMarkAsClaim,
    handleCancelLoad,
    breakdowns,
    breakdownParams,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    loadHistory,
    isShowMoreVisibleNotes,
    getMoreNotes,
    commentControl,
    commentWatch,
    commentSetValue,
    isDefaultHistory,
    setIsDefaultHistory,
    handleScrollToHistory,
    drawerRef,
    isFetchingHistory,
    activeSection,
    width,
  } = useTonuLoadView({
    refetchLoadById,
    data,
  });
  const { permissionsInfo, employeeLevel, userType } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const loadPagePermissions = usePermittedActions('loads.loads');

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadPagePermissions.view);

  const { copiedText, handleCopy } = useHandleCopyActive();

  const mailablePOD = files?.result?.POD?.filter((item: any) => item.mailable);

  const carrierLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.carrierAmountPaid && data?.finance?.carrierAmountPaid) ||
      (data?.finance?.carrierBalance && data?.finance?.carrierBalance) ||
      (data?.finance?.carrierFinalPayable && data?.finance?.carrierFinalPayable) ||
      (data?.finance?.carrierInitialRate && data?.finance?.carrierInitialRate) ||
      (data?.finance?.carrierPaymentTermGeneratedAmount && data?.finance?.carrierPaymentTermGeneratedAmount)
    );
  }, [
    data?.finance?.carrierAmountPaid,
    data?.finance?.carrierBalance,
    data?.finance?.carrierFinalPayable,
    data?.finance?.carrierInitialRate,
    data?.finance?.carrierPaymentTermGeneratedAmount,
  ]);

  const customerLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.customerAmountPaid && data?.finance?.customerAmountPaid) ||
      (data?.finance?.customerBalance && data?.finance?.customerBalance) ||
      (data?.finance?.customerFinalReceivable && data?.finance?.customerFinalReceivable) ||
      (data?.finance?.customerInitialRate && data?.finance?.customerInitialRate)
    );
  }, [
    data?.finance?.customerAmountPaid,
    data?.finance?.customerBalance,
    data?.finance?.customerFinalReceivable,
    data?.finance?.customerInitialRate,
  ]);

  return (
    <div
      className={clsx({
        ['scrollBarWrapper']: width === 'full',
      })}
    >
      {width === 'full' && <ScrollBar sections={TONU_SECTIONS_VIEW} />}
      <div
        className={clsx('scrollBarContent', {
          ['openedDrawer']: width === 'full',
        })}
      >
        <div className={styles.tonViewWrapper}>
          <MainLoader isFetching={isFetching} />
          <TonuBanner
            activeSection={activeSection}
            data={data!}
            handleSwitchChange={handleSwitchMarkAsClaim}
            tonuWatch={data?.load?.claimed}
            control={tonuControl}
            inView
            hasEditPermission={hasEditPermission}
            handleChangeMode={handleChangeMode}
            handleCancelLoad={handleCancelLoad}
            withActions={data?.load?.status !== LoadCancelStatus.Canceled}
            hasClaimPermission={loadsPagePermissions.claim}
            hasDeClaimPermission={loadsPagePermissions.resolve}
            hasCancelPermission={loadsPagePermissions.cancel}
            rateList={rateList}
            handleCarrierRate={handleCarrierRate}
            getCarrierRatings={getCarrierRatings}
            handleDeleteRate={handleDeleteRate}
            hasAccessToViewHistory={hasAccessToViewHistory}
            handleCommentClick={() => handleScrollToHistory('comment')}
            handleScrollToHistory={() => handleScrollToHistory('history')}
            loadHistory={loadHistory}
          />
          <ViewItemWrapper
            title={SUBJECT.LOAD_FINANCE}
            id={SUBJECT.LOAD_FINANCE_ID}
            className={activeSection === SUBJECT.LOAD_FINANCE_ID ? 'active' : ''}
          >
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row gutter={[50, 0]}>
                  {customerLoadFinanceCheck && (
                    <Col span={8}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER} className={styles.financeTitle} />
                    </Col>
                  )}
                  <Col span={8}>
                    <Typography variant="heading" children={SUBJECT.AGENT} className={styles.financeTitle} />
                  </Col>
                  {carrierLoadFinanceCheck && (
                    <Col span={8}>
                      <Typography variant="heading" children={SUBJECT.CARRIER} className={styles.financeTitle} />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={50}>
                  {customerLoadFinanceCheck && (
                    <Col className={styles.borderedColumn} span={8}>
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.CUSTOMER_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.INITIAL_RATE_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerInitialRate,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {data?.load?.paymentTermType !== PaymentTermType.BILLING_TYPES_FACTORING && (
                          <Col span={24}>
                            <Row justify="space-between" align="middle">
                              <Col span={12}>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoHeading}
                                  children={
                                    PaymentTermTypeText[data?.load?.paymentTermType as keyof typeof PaymentTermTypeText]
                                  }
                                />
                              </Col>
                              <Col>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoParagraph}
                                  children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                    data?.finance?.customerPaymentTermGeneratedAmount,
                                    true
                                  )} `}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_RECEIVABLE_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerFinalReceivable,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AMOUNT_PAID_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerAmountPaid,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col className={styles.borderedColumn} span={8}>
                    <Row gutter={[0, 24]}>
                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.AGENT_BALANCE}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.agentBalance,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24}>
                        <Row justify="space-between" align="middle">
                          <Col span={12}>
                            <Typography
                              variant="paragraph"
                              className={styles.infoHeading}
                              children={SUBJECT.FINAL_PROFIT}
                            />
                          </Col>
                          <Col>
                            <Typography
                              variant="paragraph"
                              className={styles.infoParagraph}
                              children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                data?.finance?.agentFinalProfit,
                                true
                              )} `}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {carrierLoadFinanceCheck && (
                    <Col className={styles.borderedColumn} span={8}>
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.CARRIER_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.INITIAL_RATE_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierFinalPayable,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {!!Number(data?.finance?.carrierPaymentTermGeneratedAmount) && (
                          <Col span={24}>
                            <Row justify="space-between" align="middle">
                              <Col span={12}>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoHeading}
                                  children={SUBJECT.QUICK_PAY}
                                />
                              </Col>
                              <Col>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoParagraph}
                                  children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                    data?.finance?.carrierPaymentTermGeneratedAmount,
                                    true
                                  )} `}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_PAYABLE_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierFinalPayable,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={12}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AMOUNT_PAID_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierAmountPaid,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </ViewItemWrapper>
          {transactions?.count ? (
            <ViewItemWrapper
              title={SUBJECT.LOAD_TRANSACTION}
              id={SUBJECT.LOAD_TRANSACTION_ID}
              className={activeSection === SUBJECT.LOAD_TRANSACTION_ID ? 'active' : ''}
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable data={transactionsForDataGrind(transactions)} isCustomer isCarrier />
                </Col>
              </Row>
            </ViewItemWrapper>
          ) : null}
          {!!data?.load?.customer?.id && (
            <ViewItemWrapper
              title={SUBJECT.CUSTOMER}
              id={SUBJECT.VIEW_CUSTOMER_ID}
              className={activeSection === SUBJECT.VIEW_CUSTOMER_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.branch?.name}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REFERENCE_NUMBER}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'referenceNumber',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.referenceNumber}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.REFERENCE_NUMBER}`}
                        />

                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.referenceNumber), 'referenceNumber')}
                        >
                          <CopyIcon active={copiedText === 'referenceNumber'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_AMOUNT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.CUSTOMER_CONTACT}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.customerContact}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_CONTACT}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'customer',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.customer?.id}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_ID}`}
                        />

                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.customer?.id), 'customer')}
                        >
                          <CopyIcon active={copiedText === 'customer'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.TAKEN_AS_MC} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={
                          data?.load?.takenAsAuthority?.id &&
                          `${data?.load?.takenAsAuthority?.name} / MC ${data?.load?.takenAsAuthority?.mc} / ID ${data?.load?.takenAsAuthority?.id}`
                        }
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.TAKEN_AS_MC}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
          {files?.result?.RED_CONFIRMATION ? (
            <div className={styles.documentationWrapper}>
              <ViewItemWrapper
                title="Customer Rate Confirmation"
                id={SUBJECT.RATE_CONFIRMATION_ID}
                className={activeSection === SUBJECT.RATE_CONFIRMATION_ID ? 'active' : ''}
              >
                {files?.result?.RED_CONFIRMATION?.map((fileItem: any) => (
                  <RedConfimrationViewFiles
                    key={files?.result?.RED_CONFIRMATION?.fileId}
                    fileItem={fileItem}
                    handleViewFile={handleViewFile}
                    handleDownloadFileClick={handleDownloadFile}
                  />
                ))}
              </ViewItemWrapper>
            </div>
          ) : null}

          {!!data?.load?.carrier?.id && (
            <ViewItemWrapper
              title={SUBJECT.CARRIER}
              id={SUBJECT.VIEW_CARRIER_ID}
              className={activeSection === SUBJECT.VIEW_CARRIER_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.carrier?.name}
                        subject={`${SUBJECT.CARRIER}${SUBJECT.CARRIER_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_AMOUNT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
                {data?.load?.truck?.truckNumber && (
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.TRUCK} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.truck?.truckNumber}
                          subject={`${SUBJECT.CARRIER}${SUBJECT.TRUCK}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'carrier',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.carrier?.id}
                          subject={`${SUBJECT.CARRIER}${SUBJECT.CARRIER_ID}`}
                        />

                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.carrier?.id), 'carrier')}
                        >
                          <CopyIcon active={copiedText === 'carrier'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {data?.load?.soldAsAuthority?.id && (
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.SOLD_US_AUTHORITY}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={`${data?.load?.soldAsAuthority?.name} / MC ${data?.load?.soldAsAuthority?.mc} / ID ${data?.load?.soldAsAuthority?.id}`}
                          subject={`${SUBJECT.CARRIER}${SUBJECT.SOLD_US_AUTHORITY}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </ViewItemWrapper>
          )}
          {rateConfirmations?.result?.length ? (
            <div className={styles.documentationWrapper}>
              <ViewItemWrapper
                title="Carrier Rate Confirmation"
                id={SUBJECT.CARRIER_RATE_CONFIRMATION_ID}
                className={activeSection === SUBJECT.CARRIER_RATE_CONFIRMATION_ID ? 'active' : ''}
              >
                {rateConfirmations?.result?.map((fileItem: any, index: number) => (
                  <AutoRateConfirmation
                    fileItem={fileItem}
                    handleViewFile={handleViewFile}
                    handleDownloadFileClick={handleDownloadFile}
                    handleSendRateConfirmation={handleSendRateConfirmation}
                    rateConfirmations={rateConfirmations}
                    index={index}
                  />
                ))}
                {!!createRateConfCheck && (
                  <Typography
                    onClick={handleCreateRateConfirmation}
                    variant="paragraph"
                    children={SUBJECT.CREATE_RATE}
                    className={styles.newCarrierRateBtn}
                  />
                )}
              </ViewItemWrapper>
            </div>
          ) : null}
          {data?.load?.carrier?.id && !!breakdowns?.result?.length && (
            <ViewItemWrapper
              title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
              id={SUBJECT.VIEW_BREAKDOWN_ID}
              className={activeSection === SUBJECT.VIEW_BREAKDOWN_ID ? 'active' : ''}
            >
              <CarrierPaymentBreakdown
                data={{
                  result: breakdowns?.result?.map((item: any) => {
                    return {
                      download: item?.file?.download,
                      id: item?.id,
                      name: item?.file?.name,
                      createdAt: formatDate(item?.createdAt),
                      preview: item?.file?.preview,
                    };
                  }),
                  count: breakdowns?.count,
                }}
                skip={breakdownParams.skip}
                limit={breakdownParams.limit}
                isLoading={isBreakdownLoading}
                onPaginationClick={handleCarrierBreakdownPaginationClick}
                onSort={handleBreakdownSortClick}
                withPagination={true}
              />
            </ViewItemWrapper>
          )}

          {roleLevel === 1 &&
            data?.load?.status !== LoadGlobalStatus.LOAD_STATUS_NOT_PROCESSING &&
            data?.load?.customer?.id && (
              <ViewItemWrapper
                title="Billing"
                id={SUBJECT.BILLING_ID}
                className={activeSection === SUBJECT.BILLING_ID ? 'active' : ''}
              >
                <Row justify="space-between" align="top" gutter={[10, 30]}>
                  <Col span={10}>
                    <Row gutter={10} align="middle">
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.FACTORED_YES_NO}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <Typography
                          variant="paragraph"
                          className={styles.infoParagraph}
                          children={
                            data?.load?.paymentTermType === PaymentTermType.BILLING_TYPES_FACTORING
                              ? SUBJECT.CUSTOMER_IS_FACTORED
                              : SUBJECT.CUSTOMER_IS_NOT_FACTORED
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  {data?.load?.noa?.length &&
                    data?.load?.noa?.map((item: any) => {
                      return (
                        <Col span={10} key={item.id}>
                          <Row gutter={10} align="middle">
                            <Col span={11}>
                              <Typography variant="heading" children="NOA" className={styles.infoHeading} />
                            </Col>
                            <Col role="button" onClick={() => multiplyViewWithDelay(filesNoaFormatter([item]))}>
                              <EyeIconSvg outlined={true} />
                            </Col>
                            <Col role="button">
                              <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesNoaFormatter([item]))} />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  {mailablePOD?.length &&
                    mailablePOD?.map((item: any) => {
                      return (
                        <Col span={10}>
                          <Row gutter={10} align="middle">
                            <Col span={11}>
                              <Typography variant="heading" children="POD" className={styles.infoHeading} />
                            </Col>
                            <Col role="button" onClick={() => multiplyViewWithDelay(filesFormatter([item]))}>
                              <EyeIconSvg outlined={true} />
                            </Col>
                            <Col role="button">
                              <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesFormatter([item]))} />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  {files?.result?.INVOICE?.length &&
                    files?.result?.INVOICE?.map((item: any) => {
                      return (
                        <Col span={10} key={item.id}>
                          <Row gutter={10} align="middle">
                            <Col span={11}>
                              <Typography variant="heading" children="Invoice" className={styles.infoHeading} />
                            </Col>
                            <Col role="button" onClick={() => multiplyViewWithDelay(filesFormatter([item]))}>
                              <EyeIconSvg outlined={true} />
                            </Col>
                            <Col role="button">
                              <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesFormatter([item]))} />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  {files?.result?.RED_CONFIRMATION && (
                    <Col span={10}>
                      <Row gutter={10} align="middle">
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children="Customer Rate Confirmation"
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col
                          role="button"
                          onClick={() => multiplyViewWithDelay(filesFormatter(files?.result?.RED_CONFIRMATION))}
                        >
                          <EyeIconSvg outlined={true} />
                        </Col>
                        <Col role="button">
                          <NoaDownloadIcon
                            onClick={() => multiplyDownloadWithDelay(filesFormatter(files?.result?.RED_CONFIRMATION))}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </ViewItemWrapper>
            )}
          {!!(comments?.count || (loadHistory?.result?.length && hasAccessToViewHistory)) && (
            <div ref={drawerRef}>
              <CustomComment
                activeSection={activeSection}
                id="comment"
                watch={commentWatch}
                control={commentControl}
                setValue={commentSetValue}
                comments={comments}
                getMoreComments={getMoreComments}
                isShowMoreVisible={isShowMoreVisible}
                name="comment"
                inView
                canCreate={false}
                setIsDefaultHistory={setIsDefaultHistory}
                canDelete={false}
                loadHistory={loadHistory}
                withNotes={loadHistory?.result?.length}
                drawerRef={drawerRef}
                isShowMoreVisibleNotes={isShowMoreVisibleNotes}
                getMoreNotes={getMoreNotes}
                hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
                isDefaultHistory={isDefaultHistory}
                isFetchingHistory={isFetchingHistory}
                isLoadsCommentsLoading={isLoadsCommentsLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TonuLoadView;
