import React, { useMemo } from 'react';
import { Col, Row, Tabs } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import CustomSwitch from 'ui/inputs/switch/Switch';
import Typography from 'ui/typography/Typography';
import { AgentTypes, UserTypes } from 'utils/constants';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';
import PayrollCards from 'components/payroll-cards/PayrollCards';
import ResetIcon from 'components/svgs/ResetIcon';
import Table from 'components/table/Table';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import { AGENT_COLUMNS, AGENT_KEYS, CARDS_HAS_MINE, SUBJECT, tabCards } from './constants/constants';
import { FilterSelect, MonthPicker, SearchableDropdown } from './hoc-instance/instance';
import useInformation from './useInformation';

import styles from './Information.module.scss';

const Information = ({ setExcelParams }: any) => {
  const {
    data,
    card,
    offices,
    columns,
    finalPay,
    overallPay,
    branches,
    roleLevel,
    cardsData,
    isFetching,
    turnOnCharge,
    turnOffCharge,
    filterControl,
    selectedBranches,
    selectedCardTitle,
    finalAgentOptions,
    informationFilters,
    selectedAgentOption,
    canEditChargeOrAdvance,
    canDeleteChargeOrAdvance,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
    filterWatch,
    resetFilter,
    isChangedDate,
    handleRowEdit,
    handleRowClick,
    setFilterValue,
    dirtyFields,
    setAgentsFilter,
    setBranchFilter,
    handleSortClick,
    handleRowDelete,
    handleStopResize,
    setOfficesFilter,
    handleSelectCard,
    handleSwitchClick,
    handleColumnSearch,
    handlePaginationClick,
    handleDistributeClick,
    handlePaymentPlanClick,
    handlePayClick,
    handleMineFilter,
    mine,
    canEditAdvanceOrCharge,
    setSelectedAgentOption,
    isSummariesFetching,
    handleMoveToNextMonthClick,
    handleMoveToCollectionClick,
    moveToCollectionAmount,
    collectionPayDetails,
    isCollectionPayDetailsLoading,
    handlePaymentDetailsClick,
    hasMoveToNextMonthPermission,
    hasMoveToCollectionPermission,
    hasPayInCollectionPermission,
    setSearchParams,
    searchParams,
    feeType,
    agentType,
    userType,
  } = useInformation(setExcelParams);

  const handleTabChange = (activePage: string) => {
    if (activePage === 'loads') {
      setSearchParams({
        ...searchParams,
        feeType: 'software',
      });
    } else {
      setSearchParams({
        ...searchParams,
        feeType: 'softwareWithoutLoad',
      });
    }
  };
  const carrierTabs: any[] = useMemo(
    () => [
      {
        label: 'Loads',
        key: 'loads',
        children: (
          <Table
            data={data}
            skip={informationFilters.skip}
            limit={informationFilters.limit}
            columns={columns}
            isLoading={isFetching}
            withMenu={
              canEditAdvanceOrCharge
                ? (Number(card) === 7 || Number(card) === 9) && (canEditChargeOrAdvance || canDeleteChargeOrAdvance)
                : false
            }
            onPaginationClick={handlePaginationClick}
            onSort={handleSortClick}
            onDistributeClick={handleDistributeClick}
            onMoveToNextMonthClick={handleMoveToNextMonthClick}
            onMoveToCollectionClick={handleMoveToCollectionClick}
            onColumnSearch={handleColumnSearch}
            onRowClick={handleRowClick}
            onResizeStop={handleStopResize}
            handleRowEdit={handleRowEdit}
            handleRowDelete={handleRowDelete}
            hasEditPermission={canEditChargeOrAdvance}
            hasDeletePermission={canDeleteChargeOrAdvance}
            onPaymentPlanClick={handlePaymentPlanClick}
            onSwitchClick={handleSwitchClick}
            onPayClick={handlePayClick}
            hasTerminateOnPermission={canEditAdvanceOrCharge ? turnOnCharge : false}
            hasTerminateOffPermission={canEditAdvanceOrCharge ? turnOffCharge : false}
            collectionPayDetails={collectionPayDetails}
            isCollectionPayDetailsLoading={isCollectionPayDetailsLoading}
            handlePaymentDetailsClick={handlePaymentDetailsClick}
            hasMoveToNextMonthPermission={hasMoveToNextMonthPermission}
            hasMoveToCollectionPermission={hasMoveToCollectionPermission}
            hasPayInCollectionPermission={hasPayInCollectionPermission}
          />
        ),
      },
      ...(userType === UserTypes.ADMIN || roleLevel === IRoleLevel.corpLevel
        ? [
            {
              label: 'Agency',
              key: 'agency',
              children: (
                <Table
                  data={data}
                  skip={informationFilters.skip}
                  limit={informationFilters.limit}
                  columns={[
                    {
                      id: 'agency',
                      title: 'Agency',
                      dataIndex: 'agency',
                      displayType: 'text',
                      isVisible: true,
                      sortable: false,
                      isSearchable: true,
                      width: 200,
                    },
                    {
                      id: 'softwareFeeAmount',
                      title: 'Software Fee Amount',
                      dataIndex: 'softwareFeeAmount',
                      displayType: 'amount',
                      isVisible: true,
                      sortable: false,
                      isSearchable: false,
                      width: 200,
                    },
                  ]}
                  isLoading={isFetching}
                  withMenu={
                    canEditAdvanceOrCharge
                      ? (Number(card) === 7 || Number(card) === 9) &&
                        (canEditChargeOrAdvance || canDeleteChargeOrAdvance)
                      : false
                  }
                  onPaginationClick={handlePaginationClick}
                  onSort={handleSortClick}
                  onDistributeClick={handleDistributeClick}
                  onMoveToNextMonthClick={handleMoveToNextMonthClick}
                  onMoveToCollectionClick={handleMoveToCollectionClick}
                  onColumnSearch={handleColumnSearch}
                  onRowClick={handleRowClick}
                  onResizeStop={handleStopResize}
                  handleRowEdit={handleRowEdit}
                  handleRowDelete={handleRowDelete}
                  hasEditPermission={canEditChargeOrAdvance}
                  hasDeletePermission={canDeleteChargeOrAdvance}
                  onPaymentPlanClick={handlePaymentPlanClick}
                  onSwitchClick={handleSwitchClick}
                  onPayClick={handlePayClick}
                  hasTerminateOnPermission={canEditAdvanceOrCharge ? turnOnCharge : false}
                  hasTerminateOffPermission={canEditAdvanceOrCharge ? turnOffCharge : false}
                  collectionPayDetails={collectionPayDetails}
                  isCollectionPayDetailsLoading={isCollectionPayDetailsLoading}
                  handlePaymentDetailsClick={handlePaymentDetailsClick}
                  hasMoveToNextMonthPermission={hasMoveToNextMonthPermission}
                  hasMoveToCollectionPermission={hasMoveToCollectionPermission}
                  hasPayInCollectionPermission={hasPayInCollectionPermission}
                />
              ),
            },
          ]
        : []),
    ],
    [columns, data, isFetching, userType, roleLevel]
  );

  return (
    <div className="page-content">
      <>
        <div className="filtersWrapper">
          <div className={styles.informationFilter}>
            <Row justify="space-between" align="middle">
              <Col xl={21} xxl={21}>
                <Row gutter={12} align="middle">
                  <Col span={5}>
                    <MonthPicker control={filterControl} name="monthPickerPayroll" isFutureYearDisabled={false} />
                  </Col>
                  {roleLevel === IRoleLevel.corpLevel && (
                    <Col span={5}>
                      <FilterSelect
                        isSearchable
                        isMultiSelect
                        withCheckbox
                        resetFunc={() =>
                          setFilterValue('officePayroll', '', {
                            shouldDirty: false,
                            shouldTouch: false,
                            shouldValidate: false,
                          })
                        }
                        options={offices?.result}
                        label={SUBJECT.OFFICE}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="officePayroll"
                        control={filterControl}
                        offset={offices?.count}
                        setOffset={setOfficesFilter}
                        setValue={setFilterValue}
                        withMarking
                        isAutocomplete
                      />
                    </Col>
                  )}
                  {roleLevel <= IRoleLevel.officeLevel && (
                    <Col span={5}>
                      <FilterSelect
                        isSearchable
                        isMultiSelect
                        isAutocomplete
                        withCheckbox
                        options={branches?.result}
                        label={SUBJECT.BRANCH}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="branchPayroll"
                        control={filterControl}
                        offset={branches?.count}
                        setOffset={setBranchFilter}
                        setValue={setFilterValue}
                        withMarking
                        isDisabled={filterWatch('officePayroll')!.length !== 1 && roleLevel === IRoleLevel.corpLevel}
                      />
                    </Col>
                  )}
                  {roleLevel <= IRoleLevel.branchLevel && (
                    <Col span={5}>
                      <SearchableDropdown
                        options={finalAgentOptions?.result}
                        columns={AGENT_COLUMNS}
                        keys={AGENT_KEYS}
                        label={SUBJECT.AGENT}
                        placeholder={SUBJECT.NOT_SELECTED}
                        name="agentPayroll"
                        control={filterControl}
                        disabled={
                          (selectedBranches.length !== 1 && roleLevel < IRoleLevel.branchLevel) ||
                          (selectedBranches.length === 1 && selectedBranches?.[0]?.default)
                        }
                        resetFunc={() => {
                          setSelectedAgentOption([]);
                        }}
                        setValue={setFilterValue}
                        values={selectedAgentOption}
                        offset={finalAgentOptions.count}
                        setOffset={setAgentsFilter}
                        showKey="firstName"
                        width={420}
                      />
                    </Col>
                  )}
                  {(agentType === AgentTypes.MANAGER || agentType === AgentTypes.OWNER) &&
                    CARDS_HAS_MINE.includes(tabCards[card]) && (
                      <Col span={5}>
                        <div className={styles.markasClaim}>
                          <Typography variant="paragraph" className={styles.textItem}>
                            {SUBJECT.ISSUED_ADVANCES}
                          </Typography>
                          <span>
                            <CustomSwitch name="mine" onChange={handleMineFilter} checked={mine === 'true'} />
                          </span>
                        </div>
                      </Col>
                    )}
                  {(Object.keys(dirtyFields).length || isChangedDate) && (
                    <Col>
                      <Button
                        icon={<ResetIcon />}
                        variant="ghost"
                        onClick={() => resetFilter(false)}
                        children={SUBJECT.RESET_ALL}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
              {typeof finalPay === 'number' && (
                <Col>
                  <div className={styles.finalPayBlock}>
                    <div className={styles.finalPay}>
                      <Typography variant="paragraph">Net: </Typography>
                      <Typography
                        variant="paragraph"
                        className={clsx({ [styles.danger]: finalPay < 0, [styles.zeroPrice]: !+finalPay })}
                      >
                        {formatNumberWithThousandsSeparatorAndDecimal(finalPay, true)}
                      </Typography>
                    </div>
                    {typeof overallPay === 'number' && (
                      <div className={styles.finalPay}>
                        <Typography variant="paragraph">{SUBJECT.FINAL_PAY}: </Typography>
                        <Typography
                          variant="paragraph"
                          className={clsx({ [styles.danger]: overallPay < 0, [styles.zeroPrice]: !+overallPay })}
                        >
                          {formatNumberWithThousandsSeparatorAndDecimal(overallPay, true)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <PayrollCards cards={cardsData} handleSelectCard={handleSelectCard} isSummariesFetching={isSummariesFetching} />
        {Number(card) === 5 ? (
          <div className={styles.titleNameAndCard}>
            <Typography variant="heading" className={styles.tableTitle}>
              {selectedCardTitle?.name || ''}
            </Typography>
            <div className={styles.movedToCollection}>
              <Typography variant="paragraph" className={styles.title}>
                Moved to collection
              </Typography>
              <Typography className={styles.amount} variant="paragraph">
                {formatNumberWithThousandsSeparatorAndDecimal(moveToCollectionAmount, true)}
              </Typography>
            </div>
          </div>
        ) : (
          <Typography variant="heading" className={styles.tableTitle}>
            {selectedCardTitle?.name || ''}
          </Typography>
        )}
        {!!card && (
          <div className={styles.tableWrapper}>
            {Number(card) === 11 ? (
              <Tabs
                defaultActiveKey={feeType === 'software' ? 'loads' : 'agency'}
                onChange={activeTab => handleTabChange(activeTab)}
                items={carrierTabs}
                className={styles.carriersHeaderTabs}
              />
            ) : (
              <Table
                data={data}
                skip={informationFilters.skip}
                limit={informationFilters.limit}
                columns={columns}
                isLoading={isFetching}
                withMenu={
                  canEditAdvanceOrCharge
                    ? (Number(card) === 7 || Number(card) === 9) && (canEditChargeOrAdvance || canDeleteChargeOrAdvance)
                    : false
                }
                onPaginationClick={handlePaginationClick}
                onSort={handleSortClick}
                onDistributeClick={handleDistributeClick}
                onMoveToNextMonthClick={handleMoveToNextMonthClick}
                onMoveToCollectionClick={handleMoveToCollectionClick}
                onColumnSearch={handleColumnSearch}
                onRowClick={handleRowClick}
                onResizeStop={handleStopResize}
                handleRowEdit={handleRowEdit}
                handleRowDelete={handleRowDelete}
                hasEditPermission={canEditChargeOrAdvance}
                hasDeletePermission={canDeleteChargeOrAdvance}
                onPaymentPlanClick={handlePaymentPlanClick}
                onSwitchClick={handleSwitchClick}
                onPayClick={handlePayClick}
                hasTerminateOnPermission={canEditAdvanceOrCharge ? turnOnCharge : false}
                hasTerminateOffPermission={canEditAdvanceOrCharge ? turnOffCharge : false}
                collectionPayDetails={collectionPayDetails}
                isCollectionPayDetailsLoading={isCollectionPayDetailsLoading}
                handlePaymentDetailsClick={handlePaymentDetailsClick}
                hasMoveToNextMonthPermission={hasMoveToNextMonthPermission}
                hasMoveToCollectionPermission={hasMoveToCollectionPermission}
                hasPayInCollectionPermission={hasPayInCollectionPermission}
              />
            )}

            <MainDrawer
              Children={component}
              buttonText={buttonText}
              withFooter={withFooter}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default Information;
