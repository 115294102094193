import { ICarrierType } from 'services/profile/carriers/interfaces';
import { emailPattern, onlyCharacters, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  COMPANY_REQUIRED,
  PRIMARY_PHONE_REQUIRED,
  CONTACT_REQUIRED,
  TYPE_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  PAID_AS_REQUIRED,
  STATE_REQUIRED,
  ZIP_REQUIRED,
  MIN_OPTION,
  PHONE,
  CONTACT,
  INVALID_EMAIL,
  EMAIL_IS_REQUIRED,
  DOT_REQUIRED,
  TRUCK_NUMBER_REQUIRED,
  LENGTH_REQUIRED,
  FUEL_CARD_MIN,
  FUEL_CARD_MAX,
  MAX_LENGTH,
  CARRIER_PACKET_REQUIRED,
  SIGN_AGREEMENT_REQUIRED,
  PAID_W9_REQUIRED,
  INSURANCE_REQUIRED,
  VOIDED_CHECK_REQUIRED,
  LIABILITY_REQUIRED,
  CARGO_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MC_NUMBER_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  TRUCKS_REQUIRED,
  NOT_ZERO,
  INCORRECT_FAX,
  LESS_THAN_BILLION,
  NUMBER_OF_TRUCKS_REQUIRED,
  NUMBER_OF_TRAILERS_REQUIRED,
  PAYMENT_TERMS_REQUIRED_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = (mode: string, activePage: string, panelShowed: boolean) => {
  return yup.object().shape({
    // GENERAL INFORMATION
    company: yup.string().required(COMPANY_REQUIRED),
    primaryPhone: yup.string().max(12, MAX_LENGTH).required(PRIMARY_PHONE_REQUIRED).matches(phoneRegExp, PHONE),
    secondaryPhone: yup
      .string()
      .nullable()
      .test('isCorrectPhone', 'isCorrectPhone', function checkIsPhoneCorrect(value) {
        if ((value && value.length === 12) || !value) {
          return true;
        } else if (!value?.match(phoneRegExp)) {
          return this.createError({
            message: PHONE,
            path: 'secondaryPhone',
          });
        } else {
          return this.createError({
            message: MAX_LENGTH,
            path: 'secondaryPhone',
          });
        }
      }),
    fax: yup
      .string()
      .nullable()
      .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
        if ((value && value.length === 12) || !value) {
          return true;
        } else if (!value?.match(phoneRegExp)) {
          return this.createError({
            message: INCORRECT_FAX,
            path: 'fax',
          });
        } else {
          return this.createError({
            message: MAX_LENGTH,
            path: 'fax',
          });
        }
      }),
    contact: yup.string().required(CONTACT_REQUIRED).matches(onlyCharacters, CONTACT),
    email: yup.string().email(INVALID_EMAIL).required(EMAIL_IS_REQUIRED).matches(emailPattern, INVALID_EMAIL),
    type: yup.string().min(1, MIN_OPTION).required(TYPE_REQUIRED),
    MC: yup.string().required(MC_NUMBER_REQUIRED),
    dot: yup.string().required(DOT_REQUIRED),
    address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    city: yup.string().required(CITY_REQUIRED),
    iAgreePref: yup.boolean().when('preferedFeature', {
      is: (value: boolean) => !!value,
      then: () => yup.boolean().isTrue(''),
      otherwise: schema => schema.notRequired(),
    }),

    iAgreeForm: yup.boolean().isTrue(''),

    state: yup.string().required(STATE_REQUIRED),

    zip: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),

    physicalAddress: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
      otherwise: schema => schema.notRequired(),
    }),
    physicalCity: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(CITY_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    physicalState: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(STATE_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),

    physicalZip: yup.string().when('physical', {
      is: (value: string) => !!value,
      then: () => yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
      otherwise: schema => schema.notRequired(),
    }),

    // ADD TRUCK
    trucks: yup.array().test('isRequired', TRUCKS_REQUIRED, function checkLength(value) {
      if (this.parent.type === String(ICarrierType['Preferred Carrier'])) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    truckNumber: yup.string().test('isRequired', TRUCK_NUMBER_REQUIRED, function checkLength(value) {
      if (this.parent.type === String(ICarrierType['Preferred Carrier']) && !this.parent.trucks?.length) {
        return !!value?.length;
      } else {
        return true;
      }
    }),
    numberOfTrucks: yup.string().test('isRequired', NUMBER_OF_TRUCKS_REQUIRED, function checkLength(value) {
      return !!value?.length;
    }),
    numberOfTrailers: yup.string().test('isRequired', NUMBER_OF_TRAILERS_REQUIRED, function checkLength(value) {
      return !!value?.length;
    }),

    paymentTerms: yup.array().when('preferedFeature', {
      is: (value: boolean) => !value,
      then: () =>
        yup.array().test('isRequired', PAYMENT_TERMS_REQUIRED_REQUIRED, function checkLength(value) {
          if (panelShowed) {
            return !!value?.length;
          } else {
            return value?.some(el => el.type === 2);
          }
        }),
      otherwise: schema => schema.notRequired(),
    }),

    yearAndMake: yup.string().notRequired(),
    boxTruck: yup.number().notRequired(),
    length: yup.string().test('isRequired', LENGTH_REQUIRED, function checkLength(value) {
      if (this.parent.boxTruck === 1) {
        if (value) {
          if (String(value).length > 7) {
            return this.createError({
              message: `Length couldn't be more than 7 digits`,
              path: 'length',
            });
          } else if (String(parseFloat(value)).replace('.', '')! == '0') {
            return this.createError({
              message: NOT_ZERO,
              path: 'length',
            });
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

    fuelCard: yup.string().test('fuelCardLength', function (value: any) {
      if (value?.length) {
        if (value.length < 16 && value.length < 24) {
          return this.createError({
            message: FUEL_CARD_MIN,
            path: 'fuelCard',
          });
        } else if (value.length > 24) {
          return this.createError({
            message: FUEL_CARD_MAX,
            path: 'fuelCard',
          });
        }
      }
      return true;
    }),
    carrierPacketFile: yup.array().test('length', CARRIER_PACKET_REQUIRED, value => {
      return value && value.length > 0;
    }),

    // DOCUMENTATION
    // carrierPacketFile:
    //   mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
    //     ? yup.array().notRequired()
    //     : yup.array().test('length', CARRIER_PACKET_REQUIRED, value => {
    //         return value && value.length > 0;
    //       }),
    // signAgreementFile:
    //   mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
    //     ? yup.array().notRequired()
    //     : yup.array().test('length', SIGN_AGREEMENT_REQUIRED, value => {
    //         return value && value.length > 0;
    //       }),
    // paidAsW9File:
    //   mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
    //     ? yup.array().notRequired()
    //     : yup.array().test('length', PAID_W9_REQUIRED, value => {
    //         return value && value.length > 0;
    //       }),
    // insuranceFile:
    //   mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
    //     ? yup.array().notRequired()
    //     : yup.array().test('length', INSURANCE_REQUIRED, value => {
    //         return value && value.length > 0;
    //       }),
    // voidedCheckBankLatterFile:
    //   mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard'
    //     ? yup.array().notRequired()
    //     : yup.array().test('length', VOIDED_CHECK_REQUIRED, value => {
    //         return value && value.length > 0;
    //       }),
    cargoCoverageValue: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'cargoCoverageValue',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
    nonOwnedCoverage: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'nonOwnedCoverage',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
    trailerInterchange: yup
      .string()
      .notRequired()
      .nullable()
      .test('not-zero', NOT_ZERO, function (value) {
        if (Number(value) >= 1000000000) {
          return this.createError({
            message: LESS_THAN_BILLION,
            path: 'trailerInterchange',
          });
        }
        return String(value).replace('.', '')! !== '0';
      }),
  });
};
