import { BillingPermission, PayablePermission, ReceivablesPermission } from 'services/profile/offices/interfaces';

export const billingPermissionSerializerForView = (billingPermission: BillingPermission): string => {
  if (!billingPermission.view && !billingPermission.actions.acceptToBilling && !billingPermission.actions.reject) {
    return 'No access for agency';
  } else if (
    !billingPermission.actions.acceptToBilling &&
    !billingPermission.actions.reject &&
    billingPermission.view
  ) {
    return 'Only view for agency';
  } else if (billingPermission.actions.acceptToBilling || billingPermission.actions.reject) {
    if (billingPermission.bothActions) {
      return 'Access for both';
    } else {
      return 'Actions for agency';
    }
  }
  return '';
};

export const payablesPermissionSerializerForView = (payablesPermission: PayablePermission): string => {
  if (
    !payablesPermission.view &&
    !payablesPermission.actions.claimLoadPay &&
    !payablesPermission.actions.pay &&
    !payablesPermission.actions.receiveInvoice
  ) {
    return 'No access for agency';
  } else if (
    !payablesPermission.actions.claimLoadPay &&
    !payablesPermission.actions.pay &&
    !payablesPermission.actions.receiveInvoice &&
    payablesPermission.view
  ) {
    return 'Only view for agency';
  } else if (
    payablesPermission.actions.claimLoadPay ||
    payablesPermission.actions.pay ||
    payablesPermission.actions.receiveInvoice
  ) {
    if (payablesPermission.bothActions) {
      return 'Access for both';
    } else {
      return 'Actions for agency';
    }
  }
  return '';
};

export const receivablesPermissionSerializerForView = (receivablesPermission: ReceivablesPermission): string => {
  if (
    !receivablesPermission.view &&
    !receivablesPermission.actions.claimLoadReceive &&
    !receivablesPermission.actions.receive
  ) {
    return 'No access for agency';
  } else if (
    !receivablesPermission.actions.claimLoadReceive &&
    !receivablesPermission.actions.receive &&
    receivablesPermission.view
  ) {
    return 'Only view for agency';
  } else if (receivablesPermission.actions.claimLoadReceive || receivablesPermission.actions.receive) {
    if (receivablesPermission.bothActions) {
      return 'Access for both';
    } else {
      return 'Actions for agency';
    }
  }
  return '';
};
