const SoftwareFeeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.0499 13.0032C29.6349 13.5139 29.6951 14.4023 29.1844 14.9873L14.4522 31.8624C14.1852 32.1683 13.7989 32.3438 13.3929 32.3438C12.9868 32.3438 12.6006 32.1683 12.3335 31.8624L6.44067 25.1124C5.9299 24.5273 5.99012 23.6389 6.5752 23.1281C7.16025 22.6174 8.04861 22.6776 8.55938 23.2628L13.3929 28.7993L27.0656 13.1377C27.5764 12.5526 28.4648 12.4924 29.0499 13.0032Z"
        fill="#252733"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.4705 13.1614C39.0328 13.697 39.0545 14.5871 38.5188 15.1495L22.4469 32.0245C22.1628 32.3228 21.7623 32.4815 21.3512 32.4588C20.94 32.4361 20.5593 32.2343 20.3098 31.9066L19.5067 30.8519C19.0363 30.2341 19.1557 29.3517 19.7735 28.8813C20.3043 28.477 21.0303 28.5081 21.5227 28.9167L36.4822 13.2098C37.0179 12.6475 37.908 12.6257 38.4705 13.1614Z"
        fill="#252733"
      />
    </svg>
  );
};

export default SoftwareFeeIcon;
