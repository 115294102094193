import { AuthorityTypes } from 'pages/profile/tabs/authorities/constants/constants';
import { formatNumberWithThousandsSeparatorAndDecimal, handleDeFloatedBrackeds } from 'utils/helpers';

export const getBranchesSerializer = (body: any): any => {
  const result = body?.result?.map((branch: any) => {
    return {
      ...branch,
      office: branch?.officeName,
      manager: branch?.managers,
      state: branch?.state?.name,
      locationTypeName: branch?.location ? branch?.locationTypeName : '',
      branchConsignmentAmount:
        branch?.branchConsignmentAmount > 0
          ? `${formatNumberWithThousandsSeparatorAndDecimal(branch?.branchConsignmentAmount)}$`
          : null,
      branchConsignmentPercent: branch?.branchConsignmentPercent > 0 ? `${branch?.branchConsignmentPercent}%` : null,
    };
  });
  return {
    result,
    count: body?.count,
  };
};
export const getBranchByIdSerializer = (body: any): any => {
  const branchConsignmentAmount = body.result.branchConsignmentAmount;
  const branchConsignmentPercent = body.result.branchConsignmentPercent;
  const newResult = {
    ...body.result,
    brackets: handleDeFloatedBrackeds(body.result.brackets),
    branchConsignmentAmount: branchConsignmentAmount ? Math.round(branchConsignmentAmount) : 0,
    branchConsignmentPercent: branchConsignmentPercent ? Math.round(branchConsignmentPercent) : 0,
  };
  return {
    result: newResult,
  };
};

export const getAuthoritiesByBranchId = (body: any): any => {
  const result = body?.result?.map((authority: any) => {
    return {
      ...authority,
      type: AuthorityTypes[authority?.type],
    };
  });
  return {
    result,
    count: body?.count,
  };
};
