import { LoadTypes } from '../../pages/loads/components/edit-load/constants/types';
import { changeTimeZone } from '../../utils/helpers';

import {
  ICreateConnectingLoadBody,
  ICreateInternalLoadBody,
  ICreateLoadPoint,
  ICreateRegularLoadBody,
} from './interface';

export const createTonuLoad = (body: any) => {
  return {
    ...(body.carrierSwitch
      ? body.carrier[0]?.type === 'Preferred Carrier'
        ? { truckId: Number(body.truck[0]?.value) }
        : {}
      : {}),
    ...(body.carrierSwitch ? { carrierId: Number(body.carrier[0]?.value) } : {}),
    ...(body.carrierSwitch ? { carrierAmount: Number(body.carrierAmount) } : {}),
    ...(body.customerSwitch ? { customerId: Number(body.customerName[0]?.value) } : {}),
    ...(body.customerSwitch ? { referenceNumber: body.referenceNumber } : {}),
    ...(body.customerSwitch ? { customerAmount: Number(body.customerAmount) } : {}),
    ...(body.customerSwitch ? { customerContact: body.customerContact } : {}),
    ...(body.customerSwitch ? { customerContactName: body.customerContactName } : {}),
    ...(body.customerSwitch ? { takenAuthorityId: Number(body.takenUsAuthority[0]?.id) } : {}),
    ...(body.soldUsSwitch ? { soldAuthorityId: Number(body.allAuthorities[0]?.id) } : {}),
    fileIds: body.file?.map((el: any) => Number(el)),
  };
};

export const createInternalLoad = (body: ICreateInternalLoadBody) => {
  return {
    referenceNumber: body.referenceNumber,
    customerAmount: Number(body.customerAmount),
    loadId: Number(body.id),
    fileIds: body.documentation?.map(el => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        appointmentDate,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createConnectingLoad = (body: ICreateConnectingLoadBody) => {
  return {
    referenceNumber: body.referenceNumber,
    agentAmount: Number(body.agentAmount),
    customerAmount: Number(body.customerAmount),
    agentId: Number(body.agent?.[0]?.id),
    loadId: Number(body.id),
    customerContact: body.customerContact,
    customerContactName: body.customerContactName,
    fileIds: body.documentation?.map(el => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        phone: el.phoneNumber,
        appointmentDate,
        facility: el.facility,
        address: el.address,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createRegularLoad = (body: ICreateRegularLoadBody) => {
  return {
    referenceNumber: body.referenceNumber,
    customerAmount: body.customerAmount,
    loadId: Number(body.id),
    carrierId: Number(body.carrier),
    carrierAmount: Number(body.carrierAmount),
    truckId: Number(body.truck),
    customerContact: body.customerContact,
    customerContactName: body.customerContactName,
    ...(body.authorities.length && body?.soldUsSwitch
      ? {
          soldAuthorityId: Number(body?.authorities?.[0]?.value),
        }
      : {}),
    fileIds: body.documentation?.map(el => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        ...(el.phoneNumber && { phone: el.phoneNumber }),
        appointmentDate,
        facility: el.facility,
        address: el.address,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createInternalLoadOutside = (body: any) => {
  return {
    referenceNumber: body.referenceNumber,
    customerAmount: Number(body.customerAmount),
    customerId: Number(body.customerId),
    takenAuthorityId: body.takenUsAuthority?.[0]?.id,
    fileIds: body.documentation?.map((el: any) => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        appointmentDate,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createConnectingLoadOutside = (body: any) => {
  return {
    referenceNumber: body.referenceNumber,
    agentAmount: Number(body.agentAmount),
    customerAmount: Number(body.customerAmount),
    customerId: Number(body.customerId),
    agentId: Number(body.agent?.[0]?.id),
    customerContact: body.customerContact,
    customerContactName: body.customerContactName,
    takenAuthorityId: body.takenUsAuthority?.[0]?.id,
    fileIds: body.documentation?.map((el: any) => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        phone: el.phoneNumber,
        appointmentDate,
        facility: el.facility,
        address: el.address,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createRegularLoadOutside = (body: any) => {
  return {
    referenceNumber: body.referenceNumber,
    customerAmount: body.customerAmount,
    carrierId: Number(body.carrier),
    customerId: Number(body.customerId),
    carrierAmount: Number(body.carrierAmount),
    truckId: Number(body.truck),
    customerContact: body.customerContact,
    customerContactName: body.customerContactName,
    takenAuthorityId: body.takenUsAuthority?.[0]?.id,
    ...(body.authorities.length && body?.soldUsSwitch
      ? {
          soldAuthorityId: Number(body?.authorities?.[0]?.value),
        }
      : {}),
    fileIds: body.documentation?.map((el: any) => Number(el)),
    loadInformation: {
      weight: Number(body.weight),
      takenType: Number(body.takenAs),
      givenType: Number(body.givenAs),
      transportCapability: Number(body.transportType),
      bookedType: Number(body.bookedAs),
      soldType: Number(body.soldAs),
      ...(body.transportType === '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
      commodity: body.commodity,
      ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
    },
    loadPoints: [...body.pickups, ...body.dropOffs].map((el: ICreateLoadPoint) => {
      const appointmentDate = changeTimeZone(
        typeof el.appointmentDate === 'number' ? new Date(el.appointmentDate) : el.appointmentDate,
        'America/Los_Angeles'
      );
      return {
        type: Number(el.dropType),
        ...(el.phoneNumber && { phone: el.phoneNumber }),
        appointmentDate,
        facility: el.facility,
        address: el.address,
        city: el.city,
        stateId: Number(el.state),
        zip: el.zip,
        comment: el.comment,
      };
    }),
  };
};

export const createLoadPoint = (body: ICreateLoadPoint) => {
  const [hour, minute] = body.appointmentTime.split(':');

  const appointmentDate = changeTimeZone(
    new Date(
      body.appointmentDate.getTime() +
        ((Number(hour) === 12 ? 0 : Number(hour)) * 3600 + Number(minute) * 60) * 1000 +
        (body.timePeriod === 'PM' ? 12 * 3600 * 1000 : 0)
    ),
    'America/Los_Angeles'
  );

  return {
    type: Number(body.dropType),
    appointmentDate,
    ...(body.address && { address: body.address }),
    ...(body.facility && { facility: body.facility }),
    city: body.city,
    stateId: Number(body.state),
    zip: body.zip,
    comment: body.comment,
    ...(body.phoneNumber && { phone: body.phoneNumber }),
  };
};

export const editLoadPoint = (body: ICreateLoadPoint) => {
  const [hour, minute] = body.appointmentTime.split(':');

  const appointmentDate = changeTimeZone(
    new Date(
      body.appointmentDate.getTime() +
        ((Number(hour) === 12 ? 0 : Number(hour)) * 3600 + Number(minute) * 60) * 1000 +
        (body.timePeriod === 'PM' ? 12 * 3600 * 1000 : 0)
    ),
    'America/Los_Angeles'
  );
  return {
    type: Number(body.dropType),
    appointmentDate,
    ...(body.address && { address: body.address }),
    ...(body.facility && { facility: body.facility }),
    city: body.city,
    stateId: Number(body.state),
    zip: body.zip,
    comment: body.comment,
    id: Number(body.id),
    ...(body.phoneNumber && { phone: body.phoneNumber }),
  };
};

export const editLoad = (body: any) => {
  return {
    ...(body.referenceNumber ? { referenceNumber: body.referenceNumber } : {}),
    ...(body.soldAuthority?.length
      ? { soldAuthorityId: Number(body.soldAuthority?.[0]?.id) }
      : {
          ...(body.loadType === LoadTypes['Internal'] ? {} : { soldAuthorityId: 0 }),
        }),
    ...(body.carrier ? { carrierId: Number(body.carrier?.[0]?.id) } : {}),
    ...(body.carrierAmount ? { carrierAmount: Number(body.carrierAmount) } : {}),
    ...(body.truck ? { truckId: Number(body?.truck?.[0]?.id) } : {}),
  };
};
export const editInternalInfoLoad = (body: any) => ({
  weight: Number(body.weight),
  takenType: Number(body.takenAs),
  givenType: Number(body.givenAs),
  transportCapability: Number(body.transportType),
  bookedType: Number(body.bookedAs),
  soldType: Number(body.soldAs),
  commodity: body.commodity,
  ...(body.transportType == '2' ? { refrigeratorCelsius: String(body.celsius) } : {}),
  ...(body.soldAs == 2 && !!body.feetPartial ? { feetOfPartial: Number(body.feetPartial) } : {}),
});
