import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Collapse, Divider, Row } from 'antd';
import clsx from 'clsx';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { EquipmentTypes } from 'services/carriersOnBoard/interface';
import { ICarrierType, IFileTypes } from 'services/profile/carriers/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { selectIsLoading } from 'store/sidebarSlice/selector';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Checkbox from 'ui/inputs/checkbox/Checkbox';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import {
  emailFormatter,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  nonAlphanumericFormatter,
  onlyNumbersFormatter,
  unicodeFormatter,
} from 'utils/helpers';
import { v4 as uuid } from 'uuid';

import DollarIcon from 'components/svgs/DollarIcon';
import FilledArrowIcon from 'components/svgs/FilledArrowIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { CARRIER_OPTIONS, SUBJECT } from '../../constants/constants';
import { DatePicker, Input, Switch } from '../../hoc-instance/instance';
import PaymentTerms from '../../paymentTerms/PaymentTerms';
import { useCreateCarrier } from '../../useCreateCarrier';
import { FileTypes } from '../edit-carrier/constants/types';

import { ICreateCarrierProps } from './constants/types';

import styles from './CreateCarrier.module.scss';

const { Panel } = Collapse;

const CreateCarrier = ({
  handleSubmit,
  onSubmit,
  control,
  getValues,
  setValue,
  watch,
  errors,
  mode,
  statesData,
  activeSection,
  beforeUpload,
  allFiles,
  handleDeleteCarrierFile,
  handleDownloadCarrieryFile,
  handleViewCarrieryFileEdit,
  carrierInfo,
  getFilesByType,
  trigger,
  resetField,
  truckSectionRef,
  handleDownloadFileCreate,
  handleViewFileCreate,
  isEditLoading,
  isCreateLoading,
  trucksByCarrierId,
  handleTruckPaginationClick,
  truckState,
  setTruckState,
  refetchTrucksById,
  setPaymentTermDirty,
  isSubmitted,
  dirtyFields,
  clearErrors,
  carrierRequestCarrier,
}: ICreateCarrierProps) => {
  const {
    addedTrucks,
    isAddTruckBtnDisabled,
    selectedRow,
    isEditedRow,
    handleAddTruck,
    handleRowEdit,
    handleRowDelete,
    isAproveMode,
    editingRow,
    setEditingRow,
    handleResetTruck,
    isFuelCardValid,
    handleAddFuelCard,
    fuelCards,
    handleFuelCardEdit,
    handleDeleteFuelCard,
    selectedCard,
    isVisibleTrucksForm,
    handleCloseTruckForm,
    handleOpenTruckForm,
    isVisibleCardsForm,
    handleCloseCardForm,
    isCreateCardLoading,
    handleOpenCardForm,
    handleSwitchMainFullCard,
    setIsVisibleTrucksForm,
  } = useCreateCarrier({
    watch,
    setValue,
    trigger,
    truckSectionRef,
    resetField,
    errors,
    carrierInfo,
    setTruckState,
    truckState,
    trucksByCarrierId,
    refetchTrucksById,
  });

  const { file }: any = window.global;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const onCheckBoxCheck = (ctx: number) => () => {
    const newValue = getValues('equipmentType');
    if (newValue.includes(ctx)) {
      setValue(
        'equipmentType',
        newValue.filter((id: number) => id !== ctx)
      );
    } else {
      setValue('equipmentType', [...newValue, ctx]);
    }
  };

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isEditLoading && !isCreateLoading }));
  }, [isEditLoading, isCreateLoading]);
  const carrierTruckFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisibleTrucksForm && carrierTruckFormRef?.current && addedTrucks?.length) {
      carrierTruckFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isVisibleTrucksForm, carrierTruckFormRef?.current]);

  useEffect(() => {
    if (watch('Cargo')) {
      trigger('Cargo');
    }
  }, [watch('Cargo')]);

  useEffect(() => {
    if (watch('Liability')) {
      trigger('Liability');
    }
  }, [watch('Liability')]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.carriers, {
        [styles.editMode]: mode === 'edit',
      })}
    >
      <ViewItemWrapper
        title={mode === 'add' ? SUBJECT.CREATE_CARRIER : SUBJECT.EDIT_CARRIER}
        className={activeSection === SUBJECT.GENERAL_INFO_ID ? 'active' : ''}
        id={SUBJECT.GENERAL_INFO_ID}
      >
        <div>
          <Typography variant="heading" level={3} children={SUBJECT.GENERAL_INFO} className={styles.generalInfo} />
          <Row gutter={[18, 40]}>
            <Col span={24}>
              <Input
                name="company"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.COMPANY_NAME}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="paymentPaidAs"
                control={control}
                placeholder={SUBJECT.PAID_AS}
                getValues={getValues}
                errors={errors}
                rules={{ required: true }}
                required
                onChange={(e: any) =>
                  setValue('paymentPaidAs', e.target.value.trimStart(), {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }
                onBlur={() => trigger('paymentPaidAs')}
              />
            </Col>
            <Col span={8}>
              <Input
                name="primaryPhone"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.PRIMARY_PHONE_NUMBER}
                getValues={getValues}
                errors={errors}
                onChange={(e: any) =>
                  setValue('primaryPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="secondaryPhone"
                control={control}
                placeholder={SUBJECT.SECONDARY_PHONE_NUMBER}
                onChange={(e: any) =>
                  setValue('secondaryPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                getValues={getValues}
              />
            </Col>
            <Col span={8}>
              <Input
                name="fax"
                onChange={(e: any) =>
                  setValue('fax', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                control={control}
                placeholder={SUBJECT.FAX}
                getValues={getValues}
              />
            </Col>
            <Col span={16}>
              <Input
                name="contact"
                control={control}
                rules={{ required: true }}
                onChange={({ target: { value } }) => {
                  setValue('contact', unicodeFormatter(value.trimStart()), { shouldDirty: true, shouldValidate: true });
                }}
                placeholder={SUBJECT.CONTACT_NAME}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                type="string"
                control={control}
                required
                onChange={(e: any) =>
                  setValue('email', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.EMAIL}
                getValues={getValues}
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      name={field.name}
                      suffixIcon={<SelectArrowIcon />}
                      options={CARRIER_OPTIONS}
                      placeholder={SUBJECT.CARRIERS_TYPE}
                      onBlur={() => trigger('type')}
                      onChange={(value: string | string[]) => {
                        value === String(ICarrierType['Preferred Carrier']) && setIsVisibleTrucksForm(true);
                        field.onChange(value);
                      }}
                      errors={errors}
                      value={field.value}
                      required
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.type} />
            </Col>
            <Col span={8}>
              <Input
                name="MC"
                type="number"
                control={control}
                placeholder={SUBJECT.MC}
                getValues={getValues}
                errors={errors}
                rules={{ required: true }}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="dot"
                control={control}
                rules={{ required: true }}
                onChange={(e: any) =>
                  setValue('dot', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.DOT}
                getValues={getValues}
                errors={errors}
                required
              />
            </Col>
            <Col span={8}>
              <Input
                name="ff"
                control={control}
                placeholder={SUBJECT.FF_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('ff', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="stateNumber"
                type="number"
                control={control}
                placeholder={SUBJECT.STATE_NUMBER}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => {
                  setValue('stateNumber', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[18, 40]}>
                <Col span={12}>
                  <Input
                    name="address"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('address', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="secondAddress"
                    control={control}
                    placeholder={SUBJECT.SECOND_ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    onChange={(e: any) =>
                      setValue('secondAddress', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={24}>
                  <Row gutter={18}>
                    <Col span={8}>
                      <Input
                        name="city"
                        control={control}
                        rules={{ required: true }}
                        placeholder={SUBJECT.CITY}
                        getValues={getValues}
                        errors={errors}
                        required
                        onChange={(e: any) =>
                          setValue('city', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                        }
                      />
                    </Col>
                    <Col span={8}>
                      <Controller
                        name="state"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StateSelect
                              allowClear
                              options={statesData}
                              placeholder={SUBJECT.STATE}
                              name={field.name}
                              errors={errors}
                              onBlur={() => trigger('state')}
                              onChange={(value: string) =>
                                setValue('state', value, {
                                  shouldDirty: true,
                                  shouldValidate: true,
                                })
                              }
                              value={field.value}
                              required
                            />
                          );
                        }}
                      />
                      <ErrorMessage fieldError={errors?.state} />
                    </Col>
                    <Col span={8}>
                      <Input
                        name="zip"
                        control={control}
                        rules={{ required: true }}
                        placeholder={SUBJECT.ZIP_CODE}
                        getValues={getValues}
                        onChange={(e: any) =>
                          setValue('zip', onlyNumbersFormatter(e.target.value), {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                        errors={errors}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="middle" className={styles.switchSection}>
                <Col span={5}>
                  <Typography
                    variant="heading"
                    children={SUBJECT.ADD_PHYSICAL_ADDRESS}
                    className={styles.switchHeading}
                  />
                </Col>
                <Col>
                  <Switch
                    name="physical"
                    control={control}
                    checked={watch('physical')}
                    onChange={() =>
                      setValue('physical', !watch('physical'), { shouldDirty: true, shouldValidate: true })
                    }
                  />
                </Col>
              </Row>
            </Col>

            {!!watch('physical') && (
              <>
                <Col span={12}>
                  <Input
                    name="physicalAddress"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('physicalAddress', e.target.value.trimStart(), {
                        shouldValidate: true,
                        shouldDirty: true,
                      })
                    }
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="physicalSecondAddress"
                    control={control}
                    placeholder={SUBJECT.SECOND_ADDRESS}
                    getValues={getValues}
                    errors={errors}
                    onChange={(e: any) => setValue('physicalSecondAddress', e.target.value.trimStart())}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="physicalCity"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.CITY}
                    getValues={getValues}
                    errors={errors}
                    required
                    onChange={(e: any) =>
                      setValue('physicalCity', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
                    }
                  />
                </Col>
                <Col span={8}>
                  <Controller
                    name="physicalState"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <StateSelect
                          allowClear
                          options={statesData}
                          placeholder={SUBJECT.STATE}
                          name={field.name}
                          errors={errors}
                          onBlur={() => trigger('physicalState')}
                          onChange={(value: string) =>
                            setValue('physicalState', value, {
                              shouldDirty: true,
                              shouldValidate: true,
                            })
                          }
                          value={field.value}
                          required
                        />
                      );
                    }}
                  />
                  <ErrorMessage fieldError={errors?.physicalState} />
                </Col>
                <Col span={8}>
                  <Input
                    name="physicalZip"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.ZIP_CODE}
                    getValues={getValues}
                    onChange={(e: any) =>
                      setValue('physicalZip', onlyNumbersFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    errors={errors}
                    required
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </ViewItemWrapper>

      <div ref={truckSectionRef}>
        <ViewItemWrapper
          title={SUBJECT.ADD_TRUCK}
          Component={
            (!!watch('truckNumber') ||
              !!watch('yearAndMake') ||
              watch('boxTruck') !== 2 ||
              !!watch('length') ||
              !!watch('numberOfTrucks') ||
              !!watch('numberOfTrailers') ||
              !!watch('equipmentType')?.length) && (
              <Button icon={<ResetIcon />} variant="ghost" children={SUBJECT.RESET} onClick={handleResetTruck} />
            )
          }
          className={activeSection === SUBJECT.TRUCK_ID ? 'active' : ''}
          id={SUBJECT.TRUCK_ID}
        >
          {String(watch('type')) === String(ICarrierType['Preferred Carrier']) && (
            <>
              {isVisibleTrucksForm && (
                <div ref={carrierTruckFormRef}>
                  <Row>
                    <Col span={16}>
                      <Row gutter={[17, 38]} align="top">
                        <Col span={12}>
                          <Input
                            name="truckNumber"
                            control={control}
                            placeholder={SUBJECT.TRUCK_NUMBER}
                            onChange={(e: any) => {
                              setValue('truckNumber', nonAlphanumericFormatter(e.target.value), {
                                ...(mode == 'edit' ? { shouldDirty: false } : { shouldDirty: true }),
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                            }}
                            getValues={getValues}
                            errors={errors}
                            rules={{ required: true }}
                            required
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            name="yearAndMake"
                            control={control}
                            placeholder={SUBJECT.YEAR_AND_MAKE}
                            onChange={(e: any) => {
                              setValue('yearAndMake', e.target.value, {
                                ...(mode == 'edit' ? { shouldDirty: false } : { shouldDirty: true }),
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                            }}
                            getValues={getValues}
                            errors={errors}
                          />
                        </Col>
                        <Col span={12}>
                          <Row gutter={16}>
                            <Col>
                              <Typography
                                variant="paragraph"
                                children={SUBJECT.BOX_TRUCK}
                                className={styles.switchHeading}
                              />
                            </Col>
                            <Col>
                              <Switch
                                name="boxTruck"
                                checked={!!(watch('boxTruck') === 1)}
                                control={control}
                                onChange={value => {
                                  setValue('boxTruck', watch('boxTruck') === 1 ? 2 : 1, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  });
                                  if (!value) {
                                    setValue('length', '');
                                    trigger('length');
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {watch('boxTruck') === 1 && (
                          <Col span={12}>
                            <Input
                              name="length"
                              control={control}
                              placeholder={SUBJECT.LENGTH}
                              getValues={getValues}
                              value={
                                watch('length') ? formatNumberWithThousandsSeparatorAndDecimal(watch('length')) : ''
                              }
                              onChange={(e: any) =>
                                setValue('length', floatNumberFormatter(e.target.value), {
                                  ...(mode == 'edit' ? { shouldDirty: false } : { shouldDirty: true }),
                                  shouldValidate: true,
                                  shouldDirty: true,
                                })
                              }
                              errors={errors}
                              rules={{ required: watch('boxTruck') === 1 }}
                              required={watch('boxTruck') === 1}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={20} justify="end" className={styles.addTruckBtn}>
                    {!!addedTrucks?.length && (
                      <Col>
                        <Button children={SUBJECT.CANCEL} variant="outlined" onClick={handleCloseTruckForm} />
                      </Col>
                    )}
                    <Col>
                      <Button
                        children={isEditedRow ? SUBJECT.SAVE : SUBJECT.TRUCK_ADD_BTN}
                        variant="comment"
                        disabled={isAddTruckBtnDisabled}
                        onClick={() => {
                          if (!isLoading) {
                            handleAddTruck({
                              truckNumber: getValues('truckNumber'),
                              boxTruck: getValues('boxTruck'),
                              length: getValues('length'),
                              truckId: uuid(),
                              ...(getValues('truckId') ? { id: getValues('truckId') } : {}),
                              ...(getValues('yearAndMake') ? { yearAndMake: getValues('yearAndMake') } : {}),
                            });
                            setEditingRow(null);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  {(dirtyFields.truckNumber || dirtyFields.yearAndMake || dirtyFields.boxTruck) &&
                    isSubmitted &&
                    !errors?.trucks &&
                    mode === 'add' && (
                      <Col span={12}>
                        <ErrorMessage
                          fieldError={{
                            message: 'You need to add or cancel your changes , before submitting the form',
                          }}
                        />
                      </Col>
                    )}
                  {!!errors?.trucks && (
                    <Col span={12}>
                      <ErrorMessage
                        fieldError={{
                          message: 'At least one equipment is required',
                        }}
                      />
                    </Col>
                  )}
                </div>
              )}

              {addedTrucks?.length && String(watch('type')) === String(ICarrierType['Preferred Carrier']) ? (
                <>
                  <DataGrid
                    data={addedTrucks}
                    type="addedTrucks"
                    handleRowEdit={handleRowEdit}
                    handleRowDelete={handleRowDelete}
                    selectedRow={selectedRow}
                    isDeleteDisabled={mode === 'edit' && truckState.skip === 0 && addedTrucks.length === 1}
                    editingRow={editingRow}
                    setEditingRow={setEditingRow}
                    {...(mode === 'edit' ? { count: trucksByCarrierId?.count } : {})}
                    {...(mode === 'edit' ? { handlePaginationClick: handleTruckPaginationClick } : {})}
                  />
                  {!isVisibleTrucksForm && (
                    <Row justify="end" className={styles.addTruckBtn}>
                      <Col>
                        <Button children={SUBJECT.TRUCK_ADD_BTN} variant="comment" onClick={handleOpenTruckForm} />
                      </Col>
                    </Row>
                  )}
                </>
              ) : null}
              {Object.values(watch(['truckNumber', 'length']))?.filter(el => !!el).length === 2 && (
                <ErrorMessage fieldError={errors.trucks} />
              )}
              <Divider className={styles.divider} />
            </>
          )}
          <>
            <Row gutter={[17, 38]}>
              <Col span={8}>
                <Input
                  name="numberOfTrucks"
                  control={control}
                  placeholder={SUBJECT.NUMBER_OF_TRUCK}
                  onChange={(e: any) => {
                    setValue('numberOfTrucks', onlyNumbersFormatter(e.target.value), {
                      ...(mode == 'edit' ? { shouldDirty: false } : { shouldDirty: true }),
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  getValues={getValues}
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="numberOfTrailers"
                  control={control}
                  placeholder={SUBJECT.NUMBER_OF_TRAILER}
                  onChange={(e: any) => {
                    setValue('numberOfTrailers', onlyNumbersFormatter(e.target.value), {
                      ...(mode == 'edit' ? { shouldDirty: false } : { shouldDirty: true }),
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }}
                  getValues={getValues}
                  errors={errors}
                />
              </Col>
            </Row>
            <div className={styles.truckTypes}>
              <label className={styles.truckType}>
                Dry Van
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.DRY_VAN)}
                  onChange={onCheckBoxCheck(EquipmentTypes.DRY_VAN)}
                />
              </label>
              <label className={styles.truckType}>
                Reefer
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.REEFER)}
                  onChange={onCheckBoxCheck(EquipmentTypes.REEFER)}
                />
              </label>
              <label className={styles.truckType}>
                Flat bed
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.FLAT_BED)}
                  onChange={onCheckBoxCheck(EquipmentTypes.FLAT_BED)}
                />
              </label>
              <label className={styles.truckType}>
                Box truck
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.BOX_TRUCK)}
                  onChange={onCheckBoxCheck(EquipmentTypes.BOX_TRUCK)}
                />
              </label>
              <label className={styles.truckType}>
                Conestoga
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.CONESTOGA)}
                  onChange={onCheckBoxCheck(EquipmentTypes.CONESTOGA)}
                />
              </label>
              <label className={styles.truckType}>
                Step deck
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.STEP_DECK)}
                  onChange={onCheckBoxCheck(EquipmentTypes.STEP_DECK)}
                />
              </label>
              <label className={styles.truckType}>
                Sprinter van
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.SPRINTER_VAN)}
                  onChange={onCheckBoxCheck(EquipmentTypes.SPRINTER_VAN)}
                />
              </label>
              <label className={styles.truckType}>
                Curtain Van
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.CURTAIN_VAN)}
                  onChange={onCheckBoxCheck(EquipmentTypes.CURTAIN_VAN)}
                />
              </label>
              <label className={styles.truckType}>
                Low boy
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.LOW_BOY)}
                  onChange={onCheckBoxCheck(EquipmentTypes.LOW_BOY)}
                />
              </label>
              <label className={styles.truckType}>
                Hot shot
                <Checkbox
                  size="default"
                  checked={getValues('equipmentType').includes(EquipmentTypes.HOT_SHOT)}
                  onChange={onCheckBoxCheck(EquipmentTypes.HOT_SHOT)}
                />
              </label>
            </div>
          </>
        </ViewItemWrapper>
      </div>

      <PaymentTerms
        statesData={statesData}
        activeSection={activeSection}
        setPaymentTermDirty={setPaymentTermDirty}
        isSubmitted={isSubmitted}
        setValue={setValue}
        errorsFromCreateForm={errors}
        clearCarrierError={clearErrors}
        carrierRequestCarrier={carrierRequestCarrier}
      />

      <ViewItemWrapper
        className={activeSection === SUBJECT.FUEL_CARD_ID ? 'active' : ''}
        id={SUBJECT.FUEL_CARD_ID}
        title={SUBJECT.ADD_FUEL_CARD}
      >
        {isVisibleCardsForm && !isCreateCardLoading && (
          <>
            <Row gutter={[17, 0]} align="middle" justify="space-between">
              <Col span={10} className={styles.fuelCard}>
                <Input
                  name="fuelCard"
                  control={control}
                  placeholder={SUBJECT.FUEL_CARD}
                  getValues={getValues}
                  onChange={(e: any) =>
                    setValue('fuelCard', onlyNumbersFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  errors={errors}
                />
              </Col>
              <Col span={24}>
                <Switch
                  name="isMainFuelCard"
                  checked={watch('isMainFuelCard')}
                  onChange={handleSwitchMainFullCard}
                  control={control}
                />
              </Col>
            </Row>
            <Row justify="end" gutter={20} className={styles.addTruckBtn}>
              {!!fuelCards.length && (
                <Col>
                  <Button children={SUBJECT.CANCEL} variant="outlined" onClick={handleCloseCardForm} />
                </Col>
              )}
              <Col>
                <Button
                  variant="comment"
                  children={selectedCard?.card ? SUBJECT.SAVE : SUBJECT.ADD_FUEL_CARD}
                  disabled={!isFuelCardValid}
                  onClick={handleAddFuelCard}
                />
              </Col>
            </Row>
          </>
        )}
        {!!fuelCards?.length && (
          <>
            <DataGrid
              data={fuelCards}
              type="fuelCards"
              handleRowEdit={handleFuelCardEdit}
              handleRowDelete={handleDeleteFuelCard}
              selectedCard={selectedCard}
            />
            {!isVisibleCardsForm && (
              <Row justify="end" className={styles.addTruckBtn}>
                <Col>
                  <Button variant="comment" children={SUBJECT.ADD_FUEL_CARD} onClick={handleOpenCardForm} />
                </Col>
              </Row>
            )}
          </>
        )}
      </ViewItemWrapper>

      <ViewItemWrapper
        title={SUBJECT.DOCUMENTATION}
        className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
        id={SUBJECT.DOCUMENTATION_ID}
      >
        <div className={styles.collapseWrapper}>
          <Collapse
            defaultActiveKey={mode === 'edit' ? [] : ['1']}
            expandIconPosition="end"
            ghost
            onChange={
              mode === 'edit' && (isAproveMode === 'false' || !isAproveMode)
                ? (e: any) =>
                    e[e.length - 1] ? getFilesByType({ type: e[e.length - 1], id: carrierInfo?.id }) : undefined
                : undefined
            }
            expandIcon={({ isActive }) => {
              return (
                <span
                  className={clsx(styles.arrow, {
                    [styles.activeArrow]: isActive,
                  })}
                >
                  <FilledArrowIcon />
                </span>
              );
            }}
          >
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Carrier Packet"
                      className={styles.documentationSubTitle}
                    />
                    {errors?.carrierPacketFile && (
                      <ErrorMessage fieldError={{ message: 'Carrier packet file is required' }} />
                    )}
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles['carrierPacketFile']?.length
                        ? allFiles['carrierPacketFile']?.length
                        : carrierInfo?.filesCounts?.[0]?.count
                      : carrierInfo?.filesCounts?.[0]?.count || 0 + allFiles['carrierPacketFile']?.length) || 0}
                    )
                  </span>
                </>
              }
              key="1"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['carrierPacketFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'carrierPacketFile')}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                        files={allFiles['carrierPacketFile']}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(e, 'carrierPacketFile', setLoading, FileTypes.FILE_TYPES_CARRIER_PACKET)
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Signed Agreement"
                      className={styles.documentationSubTitle}
                    />
                    <Row>
                      <Col>
                        {errors?.signAgreementFile && (
                          <ErrorMessage fieldError={{ message: 'Sign agreement file is required' }} />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles['signAgreementFile']?.length
                        ? allFiles['signAgreementFile']?.length
                        : carrierInfo?.filesCounts?.[1]?.count
                      : carrierInfo?.filesCounts?.[1]?.count || 0 + allFiles['signAgreementFile']?.length) || 0}
                    )
                  </span>
                </>
              }
              key="2"
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['signAgreementFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'signAgreementFile')}
                        files={allFiles['signAgreementFile']}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(e, 'signAgreementFile', setLoading, FileTypes.FILE_TYPES_SIGN_AGREEMENT)
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Company owner ID"
                      className={styles.documentationSubTitleNonRequire}
                    />
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles?.['companyOwnerIdFile']?.length
                        ? allFiles?.['companyOwnerIdFile']?.length
                        : carrierInfo?.filesCounts?.[5]?.count
                      : carrierInfo?.filesCounts?.[2]?.count || 0 + allFiles?.['companyOwnerIdFile']?.length) || 0}
                    )
                  </span>
                </>
              }
              key={mode === 'edit' ? '8' : '3'}
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['companyOwnerIdFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'companyOwnerIdFile')}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                        files={allFiles['companyOwnerIdFile']}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(
                        e,
                        'companyOwnerIdFile',
                        setLoading,
                        mode === 'edit' ? IFileTypes.companyOwnerIdFile : FileTypes.FILE_TYPES_PAID
                      )
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography variant="paragraph" children="W9" className={styles.documentationSubTitle} />
                    <Row>
                      <Col>
                        {errors?.paidAsW9File && (
                          <ErrorMessage fieldError={{ message: 'Paid as W9 file is required' }} />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles?.['paidAsW9File']?.length
                        ? allFiles?.['paidAsW9File']?.length
                        : carrierInfo?.filesCounts?.[6]?.count
                      : carrierInfo?.filesCounts?.[6]?.count || 0 + allFiles?.['paidAsW9File']?.length) || 0}
                    )
                  </span>
                </>
              }
              key={mode === 'edit' ? '9' : '4'}
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['paidAsW9File']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'paidAsW9File')}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                        files={allFiles['paidAsW9File']}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(
                        e,
                        'paidAsW9File',
                        setLoading,
                        mode === 'edit' ? IFileTypes.paidAsW9File : FileTypes.FILE_TYPES_PAID
                      )
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography variant="paragraph" children="Insurance" className={styles.documentationSubTitle} />
                    <Row>
                      <Col>
                        {errors?.insuranceFile && (
                          <ErrorMessage fieldError={{ message: 'Insurance file is required' }} />
                        )}
                        {(errors?.Liability || errors?.Cargo) && (
                          <ErrorMessage fieldError={{ message: 'You need choose insurance dates' }} />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles['insuranceFile']?.length
                        ? allFiles['insuranceFile']?.length
                        : carrierInfo?.filesCounts?.[3]?.count
                      : carrierInfo?.filesCounts?.[3]?.count || 0 + allFiles?.['insuranceFile']?.length) || 0}
                    )
                  </span>
                </>
              }
              key={mode === 'edit' ? '4' : '5'}
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['insuranceFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'insuranceFile')}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                        files={allFiles['insuranceFile']}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(e, 'insuranceFile', setLoading, FileTypes.FILE_TYPES_INSURANCE)
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
              <Row align="top" gutter={[19, 25]} className={styles.insuranceInputs}>
                <Col span={8}>
                  <DatePicker
                    name="Liability"
                    rules={{ required: true }}
                    errors={errors}
                    control={control}
                    placeholder={SUBJECT.LIABILITY}
                    onClear={() => {
                      setValue('Liability', '', { shouldDirty: true, shouldValidate: true });
                      trigger('Liability');
                    }}
                    required
                    isAbleToSelectFutureValue
                  />
                </Col>
                <Col span={8}>
                  <DatePicker
                    name="Cargo"
                    rules={{ required: true }}
                    errors={errors}
                    control={control}
                    placeholder={SUBJECT.CARGO}
                    onClear={() => {
                      setValue('Cargo', '', { shouldDirty: true, shouldValidate: true });
                      trigger('Cargo');
                    }}
                    required
                    isAbleToSelectFutureValue
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="cargoCoverageValue"
                    control={control}
                    suffix={<DollarIcon />}
                    placeholder={SUBJECT.CARGO_COVERAGE_VALUE}
                    onChange={(e: any) =>
                      setValue('cargoCoverageValue', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={
                      getValues('cargoCoverageValue')
                        ? formatNumberWithThousandsSeparatorAndDecimal(getValues('cargoCoverageValue'))
                        : ''
                    }
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="nonOwnedCoverage"
                    control={control}
                    suffix={<DollarIcon />}
                    placeholder={SUBJECT.NON_OWNED_COVERAGE}
                    onChange={(e: any) =>
                      setValue('nonOwnedCoverage', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={
                      getValues('nonOwnedCoverage')
                        ? formatNumberWithThousandsSeparatorAndDecimal(getValues('nonOwnedCoverage'))
                        : ''
                    }
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="trailerInterchange"
                    control={control}
                    suffix={<DollarIcon />}
                    placeholder={SUBJECT.TRAILER_INTERCHANGE}
                    onChange={(e: any) =>
                      setValue('trailerInterchange', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={
                      getValues('trailerInterchange')
                        ? formatNumberWithThousandsSeparatorAndDecimal(getValues('trailerInterchange'))
                        : ''
                    }
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Row align="middle" gutter={10} className={styles.cargoSwitchSection}>
                    <Col>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REEFER_BREAKDOWN}
                        className={styles.switchHeading}
                      />
                    </Col>
                    <Col>
                      <Switch
                        name="reeferBreakdown"
                        control={control}
                        checked={watch('reeferBreakdown')}
                        onChange={() =>
                          setValue('reeferBreakdown', !watch('reeferBreakdown'), {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <>
                  <div className={styles.collapseTitle}>
                    <Typography
                      variant="paragraph"
                      children="Voided Check / Bank Letter"
                      className={styles.documentationSubTitle}
                    />
                    <Row>
                      <Col>
                        {errors?.voidedCheckBankLatterFile && (
                          <ErrorMessage fieldError={{ message: 'Voided check / bank latter file is required' }} />
                        )}
                      </Col>
                    </Row>
                  </div>
                  <span>
                    (
                    {(mode === 'edit'
                      ? allFiles['voidedCheckBankLatterFile']?.length
                        ? allFiles['voidedCheckBankLatterFile']?.length
                        : carrierInfo?.filesCounts?.[4]?.count
                      : carrierInfo?.filesCounts?.[4]?.count || 0 + allFiles?.['voidedCheckBankLatterFile']?.length) ||
                      0}
                    )
                  </span>
                </>
              }
              key={mode === 'edit' ? '5' : '6'}
            >
              <Row align="middle" gutter={[19, 0]} className={styles.downloadSubBlock}>
                <Col span={24}>
                  <div className={styles.fileItemMainWrapper}>
                    {!!allFiles['voidedCheckBankLatterFile']?.length && (
                      <FileItems
                        onDeleteClick={(e: any) => handleDeleteCarrierFile(e, 'voidedCheckBankLatterFile')}
                        handleDownloadFile={handleDownloadFileCreate || handleDownloadCarrieryFile}
                        handleDownloadFileEdit={handleDownloadCarrieryFile}
                        handleViewFileEdit={handleViewCarrieryFileEdit}
                        handleViewFileCreate={handleViewFileCreate || handleViewCarrieryFileEdit}
                        files={allFiles['voidedCheckBankLatterFile']}
                      />
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <Upload
                    uploadText={SUBJECT.UPLOAD_TEXT}
                    className={styles.upload}
                    action={file}
                    beforeUpload={(e: any, setLoading: any) =>
                      beforeUpload(
                        e,
                        'voidedCheckBankLatterFile',
                        setLoading,
                        FileTypes.FILE_TYPES_VOIDED_CHECK_BANK_LATTER
                      )
                    }
                  />
                </Col>
                <Col>
                  <Typography
                    className={styles.downloadFormats}
                    variant="paragraph"
                    children="Allowed Format (jpeg, pdf, tiff, png)"
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </ViewItemWrapper>
    </form>
  );
};

export default CreateCarrier;
