import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { MainLoader } from 'ui/main-loader/MainLoader';

import { AddLoad } from './components/add-load/AddLoad';
import { Documentation } from './components/documentation/Documentation';
import { LoadInformation } from './components/load-information/LoadInformation';
import { PickupsDropoffs } from './components/pickups-dropoffs/PickupsDropoffs';

import styles from './CreateLoad.module.scss';

export const CreateLoad = ({
  createLoadForm,
  onSubmit,
  refetchLoadPoints,
  isCreateRegularLoading,
  isCreateConnectingLoading,
  isCreateInternalLoading,
  filledPickUpDropOffs,
  submitted,
  pointsState,
  setPointsState,
  loadPoints,
  loadPointsAutocomplete,
  invalidLoadPointIds,
}: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setIsValid({ isValid: !isCreateRegularLoading && !isCreateConnectingLoading && !isCreateInternalLoading })
    );
  }, [isCreateRegularLoading, isCreateConnectingLoading, isCreateInternalLoading]);

  return (
    <form className={styles.loadForm} onSubmit={createLoadForm.handleSubmit(onSubmit)}>
      <MainLoader isFetching={createLoadForm?.isForAgentByIdFetching} />
      <AddLoad {...createLoadForm} />
      <Documentation {...createLoadForm} />
      {createLoadForm?.loadType && (
        <>
          <LoadInformation {...createLoadForm} />
          <PickupsDropoffs
            {...createLoadForm}
            isPickupDropoffs
            refetchLoadPoints={refetchLoadPoints}
            filledPickUpDropOffs={filledPickUpDropOffs}
            submitted={submitted}
            pointsState={pointsState}
            setPointsState={setPointsState}
            loadPoints={loadPoints}
            loadPointsAutocomplete={loadPointsAutocomplete}
            invalidLoadPointIds={invalidLoadPointIds}
          />
        </>
      )}
    </form>
  );
};
