import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import {
  DEFAULT_AGENT_TYPE_OPTIONS,
  DEFAULT_AGENY_PAY_TYPE,
  DEFAULT_PAID_AS,
  DEFAULT_PAY_TYPE_OPTIONS,
  SUBJECT,
} from 'pages/profile/tabs/agent/constants/constants';
import {
  BrackedInput,
  Input,
  SearchableDropdown,
  SearchableSelect,
} from 'pages/profile/tabs/agent/hoc-instance/instance';
import FileItems from 'pages/profile/tabs/authorities/components/file-item/FileItems';
import { AgentPayType, AgentType, Location } from 'services/profile/agents/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import Upload from 'ui/upload/Upload';
import { LOCATION_DROPDOWN_DATA } from 'utils/constants';
import {
  emailFormatter,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  nonAlphanumericSpaceFormatter,
  onlyNumbersFormatter,
  unicodeFormatter,
} from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import DollarIcon from 'components/svgs/DollarIcon';
import { PercentIcon } from 'components/svgs/PercentIcon';
import ResetIcon from 'components/svgs/ResetIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { OFFICE_KEYS, SEARCH_OFFICE_COLUMNS } from '../../../branch/constants/constants';

import { ICreateEditAgentProps } from './constants/types';
import useCreateEdit from './useCreateEdit';

import styles from './CreateEdit.module.scss';

const CreateEdit = ({
  handleSubmit,
  onSubmit,
  control,
  watch,
  trigger,
  handleBrackedSubmit,
  brackedControl,
  getBrackedValues,
  isBrackedValid,
  brackeds,
  handleDeleteBracked,
  onBrackedSubmit,
  errors,
  brackedErrors,
  mode,
  setValues,
  statesData,
  data,
  authorities: authoritiesByBranchOrOfficeId,
  officesData,
  refetchAuthoritiesByAgentId,
  setError,
  // handleAuthoritySort,
  username,
  setOfficesByType,
  authoritiesByAgentId,
  branchesData,
  setBranchesByOffice,
  setAuthoritiesByOffice,
  setAuthoritiesByBranch,
  isDirty,
  beforeUploadForCreate,
  handleDeleteFileCreate,
  handleDownloadFileCreate,
  handleViewFileCreate,
  beforeUploadForEdit,
  handleDeleteFileEdit,
  handleDownloadFileEdit,
  handleViewFileEdit,
  agentId,
  allFiles,
  isFetchingById,
  setBrackedsValue,
  resetField,
  handleNewFileDownload,
  handleNewFileView,
  clearErrors,
  setUsername,
  isUserNameExists,
  isEditLoading,
  isCreateLoading,
  authoritiesByAgent,
  setAuthoritiesByAgent,
  isAutocompleteFetching,
}: ICreateEditAgentProps) => {
  const {
    authorities,
    addedAuthorities,
    handleAddAuthority,
    handleRowDelete,
    ssnFormatter,
    isPayrollDirty,
    handleResetPayroll,
    dataGridCheck,
    handlePaginationClick,
    canceledBlockCheck,
  } = useCreateEdit({
    setValues,
    watch,
    authoritiesByBranchOrOfficeId,
    authoritiesByAgentId,
    authoritiesByAgent,
    setAuthoritiesByAgent,
    trigger,
    setError,
    isDirty,
    username,
    refetchAuthoritiesByAgentId,
    clearErrors,
    setAuthoritiesByOffice,
    isAutocompleteFetching,
    setAuthoritiesByBranch,
  });
  const { copiedText, handleCopy } = useHandleCopyActive();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setIsValid({
        isValid: !isCreateLoading && !isEditLoading,
      })
    );
  }, [isEditLoading, isCreateLoading]);
  return (
    <div className={styles.agentCreateEditWrapper}>
      <MainLoader isFetching={isFetchingById} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {mode === 'edit' && (
          <ViewItemWrapper title={SUBJECT.AGENT_INFO} canceledBlock={canceledBlockCheck}>
            <Row className={styles.agentInfo} gutter={[10, 16]}>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.FIRST_NAME}:
                  </Typography>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.firstName}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.FIRST_NAME}`}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.OFFICE}:
                  </Typography>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.office?.name}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.OFFICE}`}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.AGENT_ID}:
                  </Typography>
                  <Col span={13}>
                    <div
                      className={clsx(styles.copyIconText, {
                        [styles.copyActive]: copiedText === 'topBannerId',
                      })}
                    >
                      <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                      <div
                        className={styles.copyIcon}
                        role="button"
                        onClick={() => handleCopy(data?.result?.id, 'topBannerId')}
                      >
                        <CopyIcon active={copiedText === 'topBannerId'} />
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>

              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.LAST_NAME}:
                  </Typography>
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.lastName}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.LAST_NAME}`}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.BRANCH}:
                  </Typography>

                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.branch?.name || SUBJECT.ALL}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.BRANCH}`}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.USERNAME}:
                  </Typography>

                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.username}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.USERNAME}`}
                  />
                </div>
              </Col>

              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.PHONE_NUMBER}:
                  </Typography>
                  <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                    {formatPhone(data?.result?.phone)}
                  </Link>
                </div>
              </Col>

              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.LOCATION}:
                  </Typography>
                  <Typography variant="paragraph" className="top-banner-value">
                    {Location[data?.result?.location]}
                  </Typography>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.EMAIL}:
                  </Typography>

                  <TooltipText
                    dataLink
                    prefixClass="top-banner-value"
                    data={data?.result?.email}
                    subject={`${SUBJECT.AGENT_INFO}${SUBJECT.USERNAME}`}
                  />
                </div>
              </Col>

              {data?.result?.phoneSecond && (
                <Col span={8}>
                  <div className={styles.headingBlock}>
                    <Typography variant="paragraph" className="top-banner-title">
                      {SUBJECT.SECOND_NUMBER}:
                    </Typography>
                    <Link className="top-banner-value" to={`tel:${data?.result?.phoneSecond}`}>
                      {formatPhone(data?.result?.phoneSecond)}
                    </Link>
                  </div>
                </Col>
              )}
              <Col span={8}>
                <div className={styles.headingBlock}>
                  <Typography variant="paragraph" className="top-banner-title">
                    {SUBJECT.AGENT_TYPE}:
                  </Typography>
                  <Typography variant="paragraph" className="top-banner-value">
                    {AgentType[data?.result?.agentType]}
                  </Typography>
                </div>
              </Col>
            </Row>
          </ViewItemWrapper>
        )}
        <ViewItemWrapper
          title={mode === 'add' ? SUBJECT.ADD_AGENT : SUBJECT.EDIT_AGENT}
          canceledBlock={canceledBlockCheck}
        >
          <Typography variant="paragraph" children={SUBJECT.PERSONAL_INFO} className={styles.subHeading} />
          <Row gutter={[18, 40]}>
            <Col span={12}>
              <Input
                name="first_name"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.FIRST_NAME}
                getValues={watch}
                onChange={(e: any) => {
                  setValues('first_name', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                required
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <Input
                name="last_name"
                type="string"
                onChange={(e: any) => {
                  setValues('last_name', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.LAST_NAME}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
            <Col span={24}>
              <Row gutter={18}>
                <Col span={8}>
                  <Input
                    name="phone_number"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.PHONE_NUMBER}
                    getValues={watch}
                    onChange={(e: any) =>
                      setValues('phone_number', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    required
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="second_number"
                    type="text"
                    control={control}
                    placeholder={SUBJECT.SECOND_NUMBER}
                    onChange={(e: any) => {
                      setValues('second_number', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    getValues={watch}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Input
                name="address"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ADDRESS}
                getValues={watch}
                required
                errors={errors}
                onChange={(e: any) => setValues('address', e.target.value.trimStart(), { shouldValidate: true })}
              />
            </Col>
            <Col span={12}>
              <Input
                name="secondAddress"
                type="text"
                control={control}
                placeholder={SUBJECT.SECOND_ADDRESS}
                getValues={watch}
                errors={errors}
                onChange={(e: any) => setValues('secondAddress', e.target.value.trimStart())}
              />
            </Col>
            <Col span={8}>
              <Input
                name="city"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.CITY}
                getValues={watch}
                required
                errors={errors}
                onChange={(e: any) => setValues('city', e.target.value.trimStart(), { shouldValidate: true })}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <StateSelect
                      options={statesData}
                      placeholder={SUBJECT.STATE}
                      name={field.name}
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string) =>
                        setValues('state', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                          shouldTouch: true,
                        })
                      }
                      value={field.value}
                      required
                      allowClear
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.state} />
            </Col>
            <Col span={8}>
              <Input
                name="zip_code"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.ZIP_CODE}
                onChange={(e: any) => {
                  setValues('zip_code', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
          </Row>
        </ViewItemWrapper>

        <ViewItemWrapper title={SUBJECT.AGENT_MANAGMENT} canceledBlock={canceledBlockCheck}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Controller
                name="agentType"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      options={DEFAULT_AGENT_TYPE_OPTIONS}
                      placeholder={SUBJECT.AGENT_TYPE}
                      name={field.name}
                      onBlur={() => trigger('agentType')}
                      onChange={(value: string | string[]) => {
                        AgentType[watch('agentType') as number] === SUBJECT.OWNER &&
                          resetField('branch', { defaultValue: [] });
                        setValues('agentType', String(value), { shouldValidate: true });
                      }}
                      errors={errors}
                      value={field.value}
                      isDisabled={mode === 'edit'}
                      required
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.agentType} />
            </Col>
            <Col span={8}>
              <Input
                name="user_name"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.USERNAME}
                getValues={watch}
                onChange={(e: any) => {
                  setValues('user_name', nonAlphanumericSpaceFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setUsername(e.target.value.trimStart());
                }}
                onKeyDown={event => {
                  if (event.key === 'Tab') {
                    trigger('user_name');
                  }
                }}
                required
                success={isDirty && watch('user_name') && !isUserNameExists}
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="email"
                type="text"
                control={control}
                rules={{ required: true }}
                onChange={(e: any) =>
                  setValues('email', emailFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.EMAIL}
                getValues={watch}
                required
                errors={errors}
                disabled={mode === 'edit'}
              />
            </Col>
            <Col span={8}>
              <SearchableDropdown
                options={
                  mode === 'edit'
                    ? [{ title: data?.result?.office?.name, value: data?.result?.office?.id }]
                    : officesData?.result
                }
                placeholder={SUBJECT.OFFICE}
                disabled={!watch('agentType')?.length || mode === 'edit'}
                offset={officesData?.count}
                onBlur={() => trigger('office')}
                setOffset={setOfficesByType}
                values={watch('office')}
                columns={SEARCH_OFFICE_COLUMNS}
                keys={OFFICE_KEYS}
                control={control}
                name="office"
                showKey="title"
                setValue={setValues}
                withoutBorder={true}
                required
              />
            </Col>
            <Col span={8}>
              <SearchableDropdown
                options={
                  AgentType[watch('agentType') as number] === SUBJECT.OWNER
                    ? [{ title: 'All', value: '0' }]
                    : branchesData?.result
                }
                placeholder={SUBJECT.BRANCH}
                disabled={
                  AgentType[watch('agentType') as number] === SUBJECT.OWNER ||
                  !watch('office')?.length ||
                  mode === 'edit'
                }
                offset={branchesData?.count}
                setOffset={setBranchesByOffice}
                values={watch('branch')}
                columns={SEARCH_OFFICE_COLUMNS}
                keys={OFFICE_KEYS}
                control={control}
                name="branch"
                showKey="title"
                setValue={setValues}
                withoutBorder={true}
                required
              />
            </Col>
            <Col span={8}>
              <Controller
                name="location"
                control={control}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      options={LOCATION_DROPDOWN_DATA}
                      placeholder={SUBJECT.LOCATION}
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      allowClear
                      resetFunc={() => {
                        resetField('location', { defaultValue: '' });
                      }}
                      onChange={(value: string | string[]) =>
                        setValues('location', String(value), { shouldValidate: true, shouldDirty: true })
                      }
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.location} />
            </Col>
          </Row>
        </ViewItemWrapper>

        <ViewItemWrapper title={SUBJECT.ADD_AUTHORITIES} required canceledBlock={canceledBlockCheck}>
          <Row>
            <Col span={24}>
              <SearchableSelect
                name="authorities"
                control={control}
                disabled={
                  AgentType[watch('agentType') as number] === SUBJECT.OWNER
                    ? !watch('office')?.[0]?.id
                    : !watch('branch')?.[0]?.id
                }
                rules={{ required: true }}
                required
                withMarking={false}
                placeholder={SUBJECT.SEARCH_SELECT}
                onBlur={() => {
                  setTimeout(() => {
                    trigger('authorities');
                  }, 700);
                }}
                withButton
                errors={errors}
                buttonText={SUBJECT.ADD_AUTHORITIES_BTN}
                options={authorities}
                handleClick={handleAddAuthority}
                offset={authoritiesByBranchOrOfficeId?.count}
                setOffset={
                  AgentType[watch('agentType') as number] === SUBJECT.OWNER
                    ? setAuthoritiesByOffice
                    : setAuthoritiesByBranch
                }
                isLoading={isAutocompleteFetching}
              />
            </Col>
            {dataGridCheck && (
              <Col span={24}>
                <div className={styles.addedBlock}>
                  <Typography variant="paragraph">{`${SUBJECT.ADDED}(${
                    mode === 'edit' ? authoritiesByAgentId?.result?.length : addedAuthorities?.length
                  })`}</Typography>
                </div>

                <DataGrid
                  handleRowDelete={handleRowDelete}
                  data={mode === 'edit' ? authoritiesByAgentId?.result : addedAuthorities}
                  count={mode === 'edit' ? authoritiesByAgentId?.count : addedAuthorities.length}
                  type="authorities"
                  handlePaginationClick={handlePaginationClick}
                  isDeleteDisabled={mode === 'edit' && addedAuthorities?.length === 1}
                />
              </Col>
            )}
          </Row>
        </ViewItemWrapper>

        <ViewItemWrapper title={SUBJECT.EMERGENCY_CONTACT} canceledBlock={canceledBlockCheck}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="emergencyName"
                type="text"
                control={control}
                onChange={(e: any) =>
                  setValues('emergencyName', unicodeFormatter(e.target.value.trimStart()), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.NAME}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="emergencyRelation"
                type="text"
                control={control}
                onChange={(e: any) =>
                  setValues('emergencyRelation', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                rules={{ required: true }}
                placeholder={SUBJECT.RELATION}
                getValues={watch}
                required
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Input
                name="emergencyPhone"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.PHONE_NUMBER}
                getValues={watch}
                onChange={(e: any) =>
                  setValues('emergencyPhone', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                required
                errors={errors}
              />
            </Col>
          </Row>
        </ViewItemWrapper>

        <ViewItemWrapper title={SUBJECT.PAYROLL} canceledBlock={canceledBlockCheck}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Controller
                name="pay_type"
                control={control}
                rules={{ required: watch('pay_type') || watch('paid_as') || watch('agent_pay_type') }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      required={watch('pay_type') || watch('paid_as') || watch('agent_pay_type')}
                      options={DEFAULT_PAY_TYPE_OPTIONS}
                      placeholder={SUBJECT.PAY_TYPE}
                      errors={errors}
                      {...field}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string | string[]) => field.onChange(value)}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.pay_type} />
            </Col>
            <Col span={8}>
              <Controller
                name="paid_as"
                control={control}
                rules={{ required: watch('pay_type') || watch('paid_as') || watch('agent_pay_type') }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      required={watch('pay_type') || watch('paid_as') || watch('agent_pay_type')}
                      options={DEFAULT_PAID_AS}
                      placeholder={SUBJECT.PAID_AS}
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string | string[]) => field.onChange(value)}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.paid_as} />
            </Col>
            <Col span={8}>
              <Controller
                name="agent_pay_type"
                rules={{ required: watch('pay_type') || watch('paid_as') || watch('agent_pay_type') }}
                control={control}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      required={watch('pay_type') || watch('paid_as') || watch('agent_pay_type')}
                      options={DEFAULT_AGENY_PAY_TYPE}
                      placeholder={SUBJECT.AGENT_PAY_TYPE}
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string | string[]) => field.onChange(value)}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.agent_pay_type} />
            </Col>
            {watch('agent_pay_type') && (
              <Col span={16}>
                <Input
                  name="ssn_ein_number"
                  required={!!watch('agent_pay_type')}
                  rules={{ required: !!watch('agent_pay_type') }}
                  type="text"
                  control={control}
                  onChange={e => ssnFormatter(e)}
                  placeholder={
                    AgentPayType[watch('agent_pay_type')] === SUBJECT.SSN ? SUBJECT.SSN_NUMBER : SUBJECT.EIN_NUMBER
                  }
                  getValues={watch}
                  errors={errors}
                />
              </Col>
            )}
            {isPayrollDirty && (
              <Col span={24}>
                <Row justify="end">
                  <Col className={styles.resetBtnWrapper}>
                    <Button
                      icon={<ResetIcon />}
                      variant="ghost"
                      children={SUBJECT.RESET}
                      onClick={handleResetPayroll}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      </form>
      <form onSubmit={handleBrackedSubmit(onBrackedSubmit)}>
        <ViewItemWrapper title={SUBJECT.ADD_BRACKED}>
          <Row gutter={[18, 40]}>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="percent"
                getValues={getBrackedValues}
                onChange={(e: any) =>
                  setBrackedsValue('percent', floatNumberFormatter(e.target.value as string), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.PERCENT}
                suffix={<PercentIcon />}
                errors={
                  Object.keys(brackedErrors).length
                    ? brackedErrors
                    : !getBrackedValues('percent')
                    ? { percent: errors.brackeds }
                    : {}
                }
                rules={{ required: true }}
                required
              />
            </Col>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="from"
                value={
                  getBrackedValues('from') ? formatNumberWithThousandsSeparatorAndDecimal(getBrackedValues('from')) : ''
                }
                placeholder={SUBJECT.FROM}
                suffix={<DollarIcon />}
                errors={brackedErrors}
                disabled
              />
            </Col>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="to"
                value={
                  getBrackedValues('to') ? formatNumberWithThousandsSeparatorAndDecimal(getBrackedValues('to')) : ''
                }
                onChange={(e: any) =>
                  setBrackedsValue('to', floatNumberFormatter(e.target.value as string, false), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.TO}
                suffix={<DollarIcon />}
                errors={
                  Object.keys(brackedErrors).length
                    ? brackedErrors
                    : !getBrackedValues('to')
                    ? { to: errors.brackeds }
                    : {}
                }
                rules={{ required: true }}
                required
              />
            </Col>
            <Col span={3}>
              <div className={styles.brackedButtonWrapper}>
                <Button
                  disabled={!getBrackedValues('to') || !getBrackedValues('percent') || !isBrackedValid}
                  htmlType="submit"
                  variant="comment"
                  children={SUBJECT.ADD}
                />
              </div>
            </Col>
            {!brackedErrors.percent && !brackedErrors.to && (
              <Col span={24}>
                <Row>
                  <Col>
                    <ErrorMessage fieldError={errors?.brackeds} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {!!brackeds?.length && (
            <div className={styles.brackedsBlock}>
              <Typography
                children={`${SUBJECT.ADDED} (${brackeds.length})`}
                variant="heading"
                className={styles.heading}
              />
              {[...brackeds]
                .sort((a: any, b: any) => a?.percent - b?.percent)
                .map((item, index) => {
                  return (
                    <div key={index} className={styles.bracked}>
                      <Row align="middle">
                        <Col span={12}>
                          <Typography variant="paragraph" className={styles.percent}>{`${
                            item.percent
                          } % (${formatNumberWithThousandsSeparatorAndDecimal(
                            item.from,
                            true
                          )} - ${formatNumberWithThousandsSeparatorAndDecimal(item.to, true)})`}</Typography>
                        </Col>
                        <Col span={1} offset={11}>
                          <div className={clsx(styles.trashIcon)} onClick={() => handleDeleteBracked(index)}>
                            <TrashIcon />
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </div>
                  );
                })}
            </div>
          )}
        </ViewItemWrapper>
      </form>
      <ViewItemWrapper title={SUBJECT.UPLOAD_FILE} canceledBlock={canceledBlockCheck}>
        <Row className={styles.uploadSection} align="middle" gutter={[20, 20]}>
          <Col span={12}>
            <Upload
              uploadText={SUBJECT.UPLOAD}
              className={styles.upload}
              beforeUpload={beforeUploadForCreate || beforeUploadForEdit}
            />
          </Col>
          <Col span={12}>
            <Typography variant="heading" children={SUBJECT.ALLOWED_FORMAT} className={styles.allowedFormat} />
          </Col>
          <Col span={24}>
            <Controller
              control={control}
              render={({ field: { value: _value } }) => (
                <FileItems
                  handleDownloadFileEdit={handleDownloadFileEdit}
                  handleDownloadFile={handleDownloadFileCreate || handleNewFileDownload}
                  handleViewFileEdit={handleViewFileEdit}
                  handleViewFileCreate={handleViewFileCreate || handleNewFileView}
                  itemId={agentId}
                  onDeleteClick={handleDeleteFileCreate || handleDeleteFileEdit}
                  files={allFiles}
                  disableLastElement={false}
                />
              )}
              name="file"
            />
          </Col>
          {errors?.file && (
            <Col span={24}>
              <ErrorMessage fieldError={errors?.file} />
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
    </div>
  );
};

export default CreateEdit;
