import React, { useDeferredValue } from 'react';
import { Col, Divider, Row } from 'antd';
import SelectDatePicker from 'ui/inputs/select-date-picker/SelectDatePicker';
import Typography from 'ui/typography/Typography';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import MainDrawer from 'components/main-drawer/MainDrawer';
import Table from 'components/table/Table';

import ResetIcon from '../../../../components/svgs/ResetIcon';
import Button from '../../../../ui/button/Button';
import { DateOptions } from '../../../../ui/inputs/select-date-picker/constants/types';
import { CARRIER_OPTIONS } from '../../../profile/tabs/carriers/constants/constants';

import {
  AGENT_COLUMNS,
  AGENT_KEYS,
  AUTHORITY_KEYS,
  CARRIER_KEYS,
  MC_TYPE_COLUMNS,
  PAYABLES_PAYMENT_METHOD,
  SEARCH_CARRIER_COLUMNS,
  SUBJECT,
} from './constants/constants';
import { FilterSelect, SearchableDropdown } from './hoc-instance/instance';
import usePayables from './usePayables';

import styles from './Payables.module.scss';

const Payables = ({ setExcelParams }: any) => {
  const {
    data,
    skip,
    limit,
    offices,
    control,
    columns,
    isDirty,
    branches,
    regOption,
    isLoading,
    paidOption,
    finalMcOptions,
    selectedMcOption,
    finalCarrierOptions,
    selectedCarrierOption,
    selectedAgentOption,
    finalAgentOptions,
    selectedOffices,
    selectedBranches,
    agentPayablesFilter,
    watch,
    setValue,
    handleRowClick,
    handleSortClick,
    setBranchFilter,
    setAgentsFilter,
    setOfficesFilter,
    handleStopResize,
    setCarriersState,
    handleResetFilters,
    handleDownloadClick,
    setAuthoritiesState,
    handlePaginationClick,
    handleColumnSearch,
    onReceivePayableInvoice,
    hasPermissionToReceiveInvoice,
    currentChildren: { component, withFooter, buttonText, onCancel, onSubmit },
  } = usePayables(setExcelParams);

  const tableProps = useDeferredValue({
    data,
    skip,
    limit,
    columns,
    withMenu: false,
    isLoading: isLoading,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onDownloadClick: handleDownloadClick,
    onPaginationClick: handlePaginationClick,
    onColumnSearch: handleColumnSearch,
    onReceivePayableInvoice: onReceivePayableInvoice,
    hasPermissionToReceiveInvoice: hasPermissionToReceiveInvoice,
  });

  return (
    <>
      <div className="page-content">
        <div className="filtersWrapperBig">
          <Row justify="space-between">
            <Col span={24}>
              <div className={styles.filtersWrapper}>
                <Row gutter={[18, 18]} align="middle">
                  <Col span={5}>
                    <SelectDatePicker fieldName="reg" label={SUBJECT.LOAD_REG_DATE} />
                  </Col>
                  <Col span={5}>
                    <SelectDatePicker fieldName="paid" label={SUBJECT.PAID_DATE} />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalMcOptions?.result}
                      columns={MC_TYPE_COLUMNS}
                      keys={AUTHORITY_KEYS}
                      label={SUBJECT.MC}
                      placeholder={SUBJECT.SEARCH}
                      name="payablesMc"
                      control={control}
                      setValue={setValue}
                      values={selectedMcOption}
                      offset={finalMcOptions?.count}
                      setOffset={setAuthoritiesState}
                      showKey="name"
                      width={500}
                    />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalCarrierOptions?.result}
                      placeholder={SUBJECT.SEARCH}
                      label={SUBJECT.CARRIER}
                      keys={CARRIER_KEYS}
                      offset={finalCarrierOptions?.count}
                      setOffset={setCarriersState}
                      values={selectedCarrierOption}
                      columns={SEARCH_CARRIER_COLUMNS}
                      control={control}
                      name="payablesCarrier"
                      showKey="name"
                      setValue={setValue}
                      withoutBorder={true}
                      width={400}
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      options={CARRIER_OPTIONS}
                      label={SUBJECT.CARRIER_TYPE}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="payablesCarrierType"
                      control={control}
                      setValue={setValue}
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      isSearchable
                      isMultiSelect
                      withCheckbox
                      options={offices?.result}
                      label={SUBJECT.OFFICE}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="payablesOffice"
                      control={control}
                      offset={offices?.count}
                      setOffset={setOfficesFilter}
                      setValue={setValue}
                      withMarking
                      isAutocomplete
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      isSearchable
                      isMultiSelect
                      isAutocomplete
                      withCheckbox
                      options={branches?.result}
                      label={SUBJECT.BRANCH}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="payablesBranch"
                      control={control}
                      offset={branches?.count}
                      setOffset={setBranchFilter}
                      setValue={setValue}
                      withMarking
                      isDisabled={watch('payablesOffice')!.length !== 1}
                    />
                  </Col>
                  <Col span={5}>
                    <SearchableDropdown
                      options={finalAgentOptions?.result}
                      columns={AGENT_COLUMNS}
                      keys={AGENT_KEYS}
                      label={SUBJECT.AGENT}
                      placeholder={SUBJECT.NOT_SELECTED}
                      name="payablesAgent"
                      control={control}
                      disabled={
                        (selectedOffices.length !== 1 ||
                          selectedBranches.length !== 1 ||
                          selectedBranches?.[0]?.default) &&
                        !agentPayablesFilter
                      }
                      setValue={setValue}
                      values={selectedAgentOption}
                      offset={finalAgentOptions?.count}
                      setOffset={setAgentsFilter}
                      showKey="username"
                      width={420}
                    />
                  </Col>
                  <Col span={5}>
                    <FilterSelect
                      withAll
                      name="carrierPaymentMethod"
                      options={PAYABLES_PAYMENT_METHOD}
                      control={control}
                      label={SUBJECT.PAYMENT_METHOD}
                      placeholder={SUBJECT.NOT_SELECTED}
                    />
                  </Col>
                  {isDirty ||
                  (regOption && +regOption !== DateOptions.All) ||
                  (paidOption && +paidOption !== DateOptions.All) ? (
                    <Col span={4}>
                      <div className={styles.reset}>
                        <Button
                          icon={<ResetIcon />}
                          variant="ghost"
                          onClick={handleResetFilters}
                          children={SUBJECT.RESET_ALL}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className={styles.tableHeaderTopInfo}>
          {!isLoading && (
            <Row align="middle" justify="space-between">
              <Col span={14}>
                <div className={styles.billingMoneyBlock}>
                  <Col>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.MUST_PAY} />
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                      <Typography className={styles.totalCount} variant="paragraph" children={data?.payTotalCount} />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.payTotalAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.payQuickAmount, true)}`}
                      />
                    </Row>
                  </Col>
                </div>
              </Col>
              <Col span={10}>
                <div className={styles.billingMoneyRightBlock}>
                  <Col>
                    <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.PAID} />
                  </Col>
                  <Col>
                    <Divider type="vertical" />
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.LOAD} />
                      <Typography className={styles.totalCount} variant="paragraph" children={data?.paidTotalCount} />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.TOTAL} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.paidTotalAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children={SUBJECT.QUICK_PAY} />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.paidQuickAmount, true)}`}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" className={styles.moneyInfo}>
                      <Typography className={styles.totalInfo} variant="paragraph" children="Fuel Advance" />
                      <Typography
                        className={styles.totalCount}
                        variant="paragraph"
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.paidFuelAdvanceAmount, true)}`}
                      />
                    </Row>
                  </Col>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <Table {...tableProps} />
        <MainDrawer
          Children={component}
          buttonText={buttonText || ''}
          withFooter={withFooter}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
};

export default Payables;
