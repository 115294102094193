import { IMonth } from './types';

export const months: IMonth[] = [
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export enum SUBJECT {
  MONTH = 'Month',
  ALL = 'All',
  CUSTOM = 'Custom',
  RANGE = 'Range',
  INCREASE = 'increase',
  DECREASE = 'decrease',
  MONTHS = 'MMMM',
  YEARS = 'YYYY',
  FULL_DATE_FORMAT = 'MM.DD.YYYY',
  MONTH_YEAR = 'MMM YYYY',
  MONTH_SINGLE = 'month',
  YEAR_SINGLE = 'year',
  CUSTOM_FILTER = 'Range Picker',
}
