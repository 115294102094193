import { LoadTypes } from 'pages/loads/components/create-load/constants/types';
import { ITransactionTypes } from 'pages/loads/components/edit-load/constants/constants';
import { OfficeSetUpTypes } from 'services/profile/offices/interfaces';
import { formatDate, formatPickUpDropOffWithTime } from 'utils/dates';
import { changeTimeZone, formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import { BillingTypesText } from '../profile/customers/interfaces';

import {
  IComment,
  ICreditRequestById,
  ICreditRequestForEmployeeByIdResponse,
  ICreditRequestForEmployeeResponse,
  IGetCommentsResponse,
  IGetLoadByIdModified,
  IGetLoadByIdResponse,
  IGetLoadPointsResponse,
  ILoadFile,
  ILoadFilesResponse,
  ILoadsRequestResponse,
  IPaymentTerm,
  IPaymentTermsBody,
  ITransactionBody,
  ITransactionItem,
} from './interface';

export const getLoadFilesSerializer = (body: ILoadFilesResponse) => {
  const result: { [key: string]: ILoadFile[] } = {};
  body.result.forEach(file => {
    if (result[file.type]) {
      result[file.type].push(file);
    } else {
      result[file.type] = [file];
    }
  });

  return {
    result,
    count: body.count,
  };
};

export const getLoadById = (body: IGetLoadByIdResponse): IGetLoadByIdModified => {
  return {
    agents: body?.result?.agents,
    commentsCount: body?.result?.commentsCount,
    canCreateInvoice: body?.result?.canCreateInvoice,
    finance: {
      agentBalance: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.agentBalance),
      agentFinalProfit: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.agentFinalProfit),
      ...(typeof body?.result?.finance?.secondAgentBalance === 'number'
        ? {
            secondAgentBalance: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.secondAgentBalance),
          }
        : {}),
      ...(typeof body?.result?.finance?.secondAgentFinalProfit === 'number'
        ? {
            secondAgentFinalProfit: formatNumberWithThousandsSeparatorAndDecimal(
              body?.result?.finance?.secondAgentFinalProfit
            ),
          }
        : {}),
      carrierPaymentTermGeneratedAmount: formatNumberWithThousandsSeparatorAndDecimal(
        body?.result?.finance?.carrierPaymentTermGeneratedAmount
      ),
      carrierAmountPaid: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.carrierAmountPaid),
      carrierBalance: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.carrierBalance),
      carrierFinalPayable: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.carrierFinalPayable),
      carrierInitialRate: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.carrierInitialRate),
      customerPaymentTermGeneratedAmount: formatNumberWithThousandsSeparatorAndDecimal(
        body?.result?.finance?.customerPaymentTermGeneratedAmount
      ),
      customerAmountPaid: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.customerAmountPaid),
      customerBalance: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.customerBalance),
      customerPaymentTermPercent: body?.result?.finance?.customerPaymentTermPercent,
      customerFinalReceivable: formatNumberWithThousandsSeparatorAndDecimal(
        body?.result?.finance?.customerFinalReceivable
      ),
      customerInitialRate: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.finance?.customerInitialRate),
    },
    information: body?.result?.information,
    hasCarrier: body?.result?.hasCarrier,
    load: {
      id: body?.result?.load?.id,
      office: body?.result?.load?.office,
      branch: body?.result?.load?.branch,
      fuelAdvance: body?.result?.load?.fuelAdvance,
      soldAsAuthority: {
        createdAt: body?.result?.load?.soldAsAuthority?.createdAt,
        id: body?.result?.load?.soldAsAuthority?.id,
        mc: body?.result?.load?.soldAsAuthority?.mc,
        name: body?.result?.load?.soldAsAuthority?.name,
        type: body?.result?.load?.soldAsAuthority?.type,
        title: body?.result?.load?.soldAsAuthority?.name,
        value: body?.result?.load?.soldAsAuthority?.id,
      },
      truck: body?.result?.load?.truck,
      carrier: body?.result?.load?.carrier,
      claimed: body?.result?.load?.claimed,
      rateConfirmationSentAt: body?.result?.load?.rateConfirmationSentAt,
      carrierAmount: String(body?.result?.load?.carrierAmount) || '',
      carrierFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.load?.carrierFinalAmount),
      customer: body?.result?.load?.customer,
      customerAmount: body?.result?.load?.customerAmount,
      customerContact: body?.result?.load?.customerContact,
      customerContactName: body?.result?.load?.customerContactName,
      customerDeptAmount: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.load?.customerDeptAmount),
      customerFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(body?.result?.load?.customerFinalAmount),
      loadType: body?.result?.load?.loadType,
      referenceNumber: body?.result?.load?.referenceNumber,
      status: body?.result?.load?.status || 0,
      carrierStatus: body?.result?.load?.carrierStatus || 0,
      customerStatus: body?.result?.load?.customerStatus || 0,
      processing: body?.result.load?.processing || false,
      createdAt: body?.result?.load?.createdAt,
      addedLoadAt: body?.result?.load?.addedLoadAt,
      paymentTermType: body?.result?.load?.paymentTermType,
      takenAsAuthority: body?.result?.load?.takenAsAuthority,
      customerAccess: body?.result?.load?.customerAccess,
      carrierAccess: body?.result?.load?.carrierAccess,
      retrieved: body?.result?.load?.retrieved,
      retrievedAt: body?.result?.load?.retrievedAt,
      requestStatus: body?.result?.load?.requestStatus || 0,
      code: body?.result?.load?.code,
      byPaymentTerm: body?.result?.load?.byPaymentTerm,
      noa: body?.result?.noa,
    },
  };
};
export const getLoadPointById = (body: IGetLoadPointsResponse) => {
  const pickups = body?.result
    ?.filter(filteredPickups => filteredPickups?.type === 1)
    .sort((a: any, b: any) => a.appointmentDate - b.appointmentDate);
  const dropoffs = body?.result
    ?.filter(filteredPickups => filteredPickups?.type === 2)
    .sort((a: any, b: any) => a.appointmentDate - b.appointmentDate);
  const pickup = pickups.map((el: any) => {
    const time = new Date(el?.appointmentDate).toLocaleString('en-US').split(' ')[1].split(':').slice(0, 2).join(':');
    return {
      address: el?.address,
      appointmentDate: new Date(el?.appointmentDate),
      appointmentTime: time,
      city: el?.city,
      comment: el?.comment,
      creator: {
        firstName: el?.creator?.firstName,
        lastName: el?.creator?.lastName,
        username: el?.creator?.username,
      },
      dropType: el?.type,
      facility: el?.facility,
      phoneNumber: el?.phone,
      state: el?.state?.id,
      zip: el?.zip,
      timePeriod: new Date(el?.appointmentDate).toLocaleString('en-US').split(' ')[2],
      id: el?.id,
    };
  });

  const dropoff = dropoffs.map((el: any) => {
    const time = new Date(el?.appointmentDate).toLocaleString('en-US').split(' ')[1].split(':').slice(0, 2).join(':');

    return {
      address: el?.address,
      appointmentDate: new Date(el?.appointmentDate),
      appointmentTime: time,
      city: el?.city,
      comment: el?.comment,
      creator: {
        firstName: el?.creator?.firstName,
        lastName: el?.creator?.lastName,
        username: el?.creator?.username,
      },
      dropType: el?.type,
      facility: el?.facility,
      phoneNumber: el?.phone,
      state: el?.state?.id,
      zip: el?.zip,
      timePeriod: new Date(el?.appointmentDate).toLocaleString('en-US').split(' ')[2],
      id: el?.id,
    };
  });

  const result = {
    pickup,
    dropoffs: dropoff,
  };

  return { result, count: body.count };
};

export const getCreditRequestForEmployeesSerializer = (body: ICreditRequestForEmployeeResponse) => {
  const result = body?.result?.map((item: ICreditRequestForEmployeeByIdResponse) => {
    return {
      createdAt: item?.creditCheck?.createdAt,
      id: item?.creditCheck?.id,
      code: item?.creditCheck?.code,
      customer_id: item?.creditCheck?.customerId,
      claimed: item?.creditCheck?.claimed,
      customer_name: item?.creditCheck?.customer?.name,
      office_name: item?.creditCheck?.office?.name,
      agencyType:
        item.creditCheck.office.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE
          ? 'Agency'
          : item.creditCheck.office.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE
          ? 'Broker'
          : '',
      branch_name: item?.creditCheck?.branch?.name,
      agent_username: item?.creditCheck?.creator?.username,
      customerAmount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerAmount, true)}`,
      real_amount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerFinalAmount, true)} `,
      debt_not_paid: `${formatNumberWithThousandsSeparatorAndDecimal(item?.depth?.depth, true)} `,
      ...(item?.paymentLimit
        ? {
            debt_limit: `${formatNumberWithThousandsSeparatorAndDecimal(item?.paymentLimit, true)} `,
          }
        : {}),
      debt_load_qty: item?.depth?.count,
      paymentMethod: {
        type: BillingTypesText[item?.creditCheck?.paymentTermType as BillingTypesText],
        percent: Number(item?.creditCheck?.paymentTermPercent),
        days: item?.creditCheck?.paymentTermDay,
      },
      comments_count: item?.commentsCount,
      action: item?.creditCheck?.requestStatus,
      loadType: item?.creditCheck?.loadType,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getNewLoadForAgentSerializer = (body: ICreditRequestForEmployeeResponse) => {
  const result = body?.result?.map((item: any) => {
    return {
      createdAt: item?.creditCheck?.createdAt,
      creatorId: item?.creditCheck?.creator?.id,
      id: item?.creditCheck?.id,
      code: item?.creditCheck?.code,
      customerId: item?.creditCheck?.customer?.id,
      claimed: item?.creditCheck?.claimed,
      customerName: item?.creditCheck.customer?.name,
      customerAmount: item?.creditCheck?.customerAmount,
      office: item?.creditCheck.office?.name,
      branch: item?.creditCheck?.branch?.name,
      agent: `${item?.creditCheck?.creator?.username}`,
      amount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerAmount, true)} `,
      realAmount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerFinalAmount, true)} `,
      status: item.creditCheck?.requestStatus,
      debtNotPaid: `${formatNumberWithThousandsSeparatorAndDecimal(item?.depth?.depth, true)} `,
      ...(item?.paymentLimit
        ? {
            debtLimit: `${formatNumberWithThousandsSeparatorAndDecimal(item?.paymentLimit, true)} `,
          }
        : {}),
      debtLoadQty: item.depth.count,
      paymentMethod: `${BillingTypesText[item?.creditCheck?.paymentTermType as BillingTypesText]} | ${
        item.creditCheck.paymentTermPercent
      }% | ${item.creditCheck?.paymentTermDay} day`,
      comments: item.commentsCount,
      comments_count: item.commentsCount,
      addLoad: 'Add Load',
      loadType: item?.creditCheck?.loadType,
    };
  });
  return {
    result,
    count: body.count,
  };
};

export const getLoadsSerializer = (body: ILoadsRequestResponse) => {
  const result = body?.result?.map((item: any) => {
    return {
      redConfirmation: item?.redConfirmationFileIds,
      uploadedPOD: item?.podFileIds,
      addedLoadAt: formatDate(item?.addedLoadAt),
      redConfirmationFileIds: 'Rate confirmation',
      podFileIds: 'POD',
      regDateAndTime: item?.addedLoadAt,
      loadId: item?.code,
      loadStatus: {
        status: item?.status || 0,
        carrierStatus: item?.carrierStatus || 0,
        customerStatus: item?.customerStatus || 0,
        processing: item?.processing || false,
      },
      fuelAdvance: item?.fuelAdvance,
      office: item?.office?.name,
      agencyType:
        item?.office?.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE
          ? 'Agency'
          : item?.office?.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE
          ? 'Broker'
          : '',
      officeId: item?.office?.id,
      branch: item?.branch?.name,
      branchId: item?.branch?.id,
      agent: `${item?.agent?.username}`,
      agentId: item?.agent?.id,
      customerName: item?.customer?.name,
      customerId: item?.customer?.id,
      customerRef: item?.referenceNumber,
      carrierName: item?.carrier?.companyName,
      carrierId: item?.carrier?.id,
      loadType: LoadTypes[item?.loadType],
      firstPickupCity: item?.firstPickUp?.city,
      firstPickupState: item?.firstPickUp?.state?.name,
      firstPickupDate:
        item?.firstPickUp?.appointmentDate && formatPickUpDropOffWithTime(item?.firstPickUp?.appointmentDate),
      lastDeliveryCity: item?.lastDropOff?.city,
      lastDeliveryState: item?.lastDropOff?.state?.name,
      lastDeliveryDate:
        item?.lastDropOff?.appointmentDate && formatPickUpDropOffWithTime(item?.lastDropOff?.appointmentDate),
      customerReceivables: item?.customerFinalAmount
        ? formatNumberWithThousandsSeparatorAndDecimal(item?.customerFinalAmount)
        : null,
      carrierPayables: item?.carrierFinalAmount
        ? formatNumberWithThousandsSeparatorAndDecimal(item?.carrierFinalAmount)
        : null,
      profit: formatNumberWithThousandsSeparatorAndDecimal(item?.profit),
      claimed: item.claimed,
      soldAsMc: item?.soldAuthority?.name,
      takenAsMc: item?.takenAuthority?.name,
      factored: item?.carrier?.factoredCompanyName,
      customerDebt: item?.customerDeptAmount
        ? formatNumberWithThousandsSeparatorAndDecimal(item?.customerDeptAmount)
        : null,
      customerInvoice: item?.customerInvoiceFileIds,
      invoice: formatDate(item?.customerInvoiceDate as Date),
      carrierPayment: item?.carrierBreakDownFileIds,
      carrierBackDownDate: formatDate(item?.carrierBackDownDate),
      comments_count: item?.commentsCount,
      id: item?.id,
      customerGrossAmount: item?.customerGrossAmount
        ? formatNumberWithThousandsSeparatorAndDecimal(item?.customerGrossAmount)
        : null,
      code: item?.code,
    };
  });
  return {
    result,
    count: body?.count,
    grossProfit: formatNumberWithThousandsSeparatorAndDecimal(body?.grossProfit),
    potentialGrossProfit: formatNumberWithThousandsSeparatorAndDecimal(body?.potentialGrossProfit),
    withoutRedConfirmation: body?.withoutRedConfirmation,
    average: formatNumberWithThousandsSeparatorAndDecimal(body?.average),
  };
};

export const getLoadsParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.statuses?.claimed) {
    queryParams.append('claimed', 'true');
  }
  if (params.statuses?.processing) {
    queryParams.append('processing', 'true');
  }
  if (params.fuelAdvance) {
    queryParams.append('fuelAdvance', params.fuelAdvance);
  }
  if (params.dateEnd) {
    queryParams.append(
      'dateEnd',
      params.isConvertingTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.dateEnd)), 'America/Los_Angeles'))
        : String(params.dateEnd)
    );
  }
  if (params.dateStart) {
    queryParams.append(
      'dateStart',
      params.isConvertingTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.dateStart)), 'America/Los_Angeles'))
        : String(params.dateStart)
    );
  }
  if (params.officeIds?.length) {
    params.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.branchIds?.length) {
    params.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.agentIds?.length) {
    params.agentIds?.forEach((el: string | number) => queryParams.append('agentIds[]', String(el)));
  }
  if (params.loadTypes?.length) {
    params.loadTypes?.forEach((el: string | number) => queryParams.append('loadTypes[]', String(el)));
  }
  if (params.document?.length) {
    params.document?.forEach((el: string | number) => queryParams.append('documentTypes[]', String(el)));
  }
  if (params.statuses?.statuses) {
    params.statuses?.statuses?.forEach((el: string | number) => queryParams.append('statuses[]', String(el)));
  }
  if (params.statuses?.customerStatuses?.length) {
    params.statuses?.customerStatuses?.forEach((el: string | number) =>
      queryParams.append('customerStatuses[]', String(el))
    );
  }
  if (params.statuses?.carrierStatuses?.length) {
    params.statuses?.carrierStatuses?.forEach((el: string | number) =>
      queryParams.append('carrierStatuses[]', String(el))
    );
  }
  return queryParams;
};
export const getCreditRequestForEmployeesByIdSerializer = (
  item: ICreditRequestForEmployeeByIdResponse
): ICreditRequestById => {
  return {
    createdAt: item?.creditCheck?.createdAt,
    creatorId: item?.creditCheck?.creator?.id,
    addedLoadAt: item?.creditCheck?.addedLoadAt,
    id: item?.creditCheck?.id,
    code: item?.creditCheck?.code,
    customerId: item?.creditCheck?.customerId,
    customerName: item?.creditCheck?.customer.name,
    officeName: item?.creditCheck?.office.name,
    branchName: item?.creditCheck?.branch.name,
    agentName: `${item?.creditCheck?.creator.username} `,
    customerAmount: item?.creditCheck?.customerAmount,
    amount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerAmount, true)} `,
    realAmount: `${formatNumberWithThousandsSeparatorAndDecimal(item?.creditCheck?.customerFinalAmount, true)}`,
    debtNotPaid: `${formatNumberWithThousandsSeparatorAndDecimal(item?.depth?.depth, true)} `,
    ...(item?.paymentLimit
      ? {
          debtLimit: `${formatNumberWithThousandsSeparatorAndDecimal(item?.paymentLimit, true)} `,
        }
      : {}),
    debtQty: item?.depth?.count,
    requestStatus: item?.creditCheck?.requestStatus || 0,
    status: item?.creditCheck?.status || 0,
    carrierStatus: item?.creditCheck?.carrierStatus || 0,
    customerStatus: item?.creditCheck?.customerStatus || 0,
    processing: item?.creditCheck?.processing || false,
    paymentTermPercent: item?.creditCheck?.paymentTermPercent,
    paymentMethod: `${BillingTypesText[item?.creditCheck?.paymentTermType as BillingTypesText]} | ${Number(
      item?.creditCheck?.paymentTermPercent
    )}% | ${item?.creditCheck?.paymentTermDay} day`,
    comments: item?.commentsCount,
    agentPhone: item?.creditCheck?.creator?.phone,
    mc: item?.creditCheck?.authority?.mc,
    authorityName: item?.creditCheck?.authority?.name,
    authorityId: item?.creditCheck?.authority?.id,
  };
};

export const getLoadComments = (body: IGetCommentsResponse) => {
  const result = body?.result?.map((comment: IComment) => {
    return {
      id: comment?.id,
      loadId: comment?.loadId,
      name: `${comment?.creator?.username}`,
      commentText: comment?.message,
      createdAt: comment?.createdAt,
    };
  });
  return {
    commentsList: result,
    count: body?.count,
  };
};

export const getPaymentTermsSerializer = (body: IPaymentTermsBody) => {
  const result = body?.result?.map((item: IPaymentTerm) => {
    return {
      id: item?.id,
      mcName: item?.authority?.name,
      mcNumber: item?.authority?.mc,
      customerName: item?.customer?.name,
      customerMc: item?.customer?.mc,
      paymentTermsType: `${BillingTypesText[item?.type as BillingTypesText]} / ${item?.days} day / ${item?.percent}%`,
      authorityId: item?.authority?.id,
      customerId: item?.customer?.id,
    };
  });
  return {
    result,
    count: body?.count,
  };
};

export const getTransactionstSerializer = (body: ITransactionBody) => {
  const result = body?.result?.map((item: ITransactionItem) => {
    return {
      type: ITransactionTypes[item?.type],
      label: item?.label,
      createdAt: item?.createdAt,
      addedBy: `${item?.creator?.firstName} ${item?.creator?.lastName}`,
      username: item?.creator?.username,
      customerAmout: item?.customerAmount,
      carrierAmout: item?.carrierAmount,
      secondAgent: item?.secondAgentAmount,
      balance: item?.customerAmount - item?.carrierAmount,
      id: item?.id,
      comment: item?.comment,
      deletedAt: item?.deletedAt,
      deletor: item?.deletor,
    };
  });

  return {
    result,
    count: body?.count,
  };
};

export const getRateConfirmationParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();

  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  return queryParams;
};
