export enum SUBJECT {
  PAYMENT = 'Payment',
  COLLECTION = 'Collection',
  AMOUNT = 'Amount',
  COMMENT = 'Comment',
  MONEY_TYPE = 'Money Type',
  PAY = 'Pay',
  CANCEL = 'Cancel',
  WARNING_TEXT = 'You are not paying the full amount, you`ll have',
  DEBT = 'debt',
}

export enum VALIDATION_MESSAGES {
  COMMENT_ID_REQUIRED = 'Comment is a required field',
  AMOUNT_REQUIRED = 'Amount is a required field',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  NOT_ZERO = 'Should be a positive number',
  AMOUNT_LESS_THAN_COLLECTION = 'Amount must be less or equal to collection amount',
}
