export enum SUBJECT {
  STATUS_CONNECTING = 'connecting',
  STATUS_OPEN = 'open',
  STATUS_CLOSED = 'closed',
}
export type ISocketConnectionStatuses = SUBJECT.STATUS_CONNECTING | SUBJECT.STATUS_OPEN | SUBJECT.STATUS_CLOSED;

export const { ws: socketUrl }: any = window.global;

export const CreatorTypes = {
  1: 'admin',
  2: 'employees',
  3: 'agents',
};

export const links = {
  agents: '/profile?tab=agents&open=true&mode=view&headerFilter=carriers&id=',
  offices: '/profile?tab=offices&open=true&mode=view&headerFilter=carriers&id=',
  branches: '/profile?tab=branches&open=true&mode=view&headerFilter=carriers&id=',
  authorities: '/profile?tab=authorities&open=true&mode=view&headerFilter=carriers&id=',
  carriers: '/profile?tab=carriers&open=true&mode=view&headerFilter=carriers&id=',
  customers: '/profile?tab=customers&open=true&mode=view&headerFilter=carriers&id=',
  employees: '/profile?tab=employees&open=true&mode=view&headerFilter=carriers&id=',
  loads:
    '/loads?tab=loads&open=true&mode=view&headerFilter=carriers&loadStartDate=1696104000000&loadEndDate=1698782399999&loadOption=3&id=',
  new_load: 'loads?tab=new_load&open=true&mode=viewNewLoad&headerFilter=loads&id=',
  verificationBoard: '/truck-board?tab=verification_board&open=true&mode=carrierTruckView&headerFilter=loads&id=',
};

export const transformHandlerName = (handler: string) => {
  if (!handler) return '';
  const parts = handler.split('.');
  if (parts.length === 2) {
    return handler;
  }
  if (parts.length === 3) {
    const firstPart = parts[0] + parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    const rest = parts.slice(2).join('.');
    return firstPart + '.' + rest;
  }

  return handler;
};
