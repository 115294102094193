export const defaultValues = {
  name: '',
  officeLocation: '',
  phoneNumber: null,
  fax: null,
  payout: '',
  address: '',
  secondAddress: '',
  city: '',
  stateId: '',
  zipcode: null,
  authorities: [],
  bankName: '',
  accounting_number: '',
  ach_routing_number: '',

  type: '',
  creditCheck: '',
  billing: '',
  payables: '',
  receivables: '',
  creditCheckPayType: '',
  creditCheckPayAmount: '',
  hasValidAccounting: false,
  feeType: undefined,
  fee: null,
};

export const filterDefaultValues = {
  officeFilter: null,
};

export enum SUBJECT {
  ADDED_BY = 'Added By',
  OUTSIDE_AGENCY = 'Broker Agency',
  AGENCY_TYPE = 'Agency type',
  ADD_NEW_OFFICE = 'Add New Agency',
  NAME = 'Name',
  TYPE = 'Type',
  CREDIT_CHECK = 'Credit check',
  ACCOUNTING = 'Accounting',
  BILLING = 'Billing',
  PAYABLES = 'Payables',
  RECEIVABLES = 'Receivables',
  OFFICE_LOCATION = 'Location',
  PHONE_NUMBER = 'Phone Number',
  FAX = 'Fax',
  ADDRESS = 'Address',
  SECOND_ADDRESS = 'Second Address',
  CITY = 'City',
  STATE = 'State',
  ZIPCODE = 'Zip Code',
  PAYOUT_PERCENTAGE = 'Payout Percentage',
  SAVE = 'Save',
  CANCEL = 'Close',
  ADD_OFFICE = 'Add Agency',
  UNSAVED_CHANGES = 'You Have Unsaved Changes',
  ADD_AUTHORITIES = 'Add Authorities',
  ADDED = 'Added',
  SEARCH_SELECT = 'Search Select',
  EDIT_BTN_TEXT = 'Save Changes',
  OFFICE_INFO = 'Agency Info',
  ADDITIONAL = 'Additional',
  DATE = 'Date',
  OFFICE_NAME = 'Agency Name',
  EDIT = 'Edit',
  OFFICE_ID = 'Agency ID',
  OWNER = 'Owner',
  OWNERS = 'Owners',
  NUMBER_OF_BRANCHES = 'Number Of Branches',
  STATUS = 'Status',
  LOCATION = 'Location',
  OWNERS_NUMBER = 'Agency Owners',
  OWNER_NUMBER = 'Agency Owner',
  EDIT_OFFICE = 'Edit Agency',
  OFFICES_LIST_IS_EMPTY = 'Agencies List Is Empty',
  NOT_SELECTED = 'Not Selected',
  OFFICE_FILTER = 'officeFilter',
  RESET_ALL = 'Reset All',
  ADD_AUTHORITIES_BTN = '+ Add Authorities',
  ARE_YOU_SURE = 'Are You Sure Want Do Delete?',
  DELETE = 'Delete',
  NO = 'No',
  YES = 'Yes',
  CHECKED_ACTIVATE = ' If Activated, Only The Selected Agency Will Become Active, All Attached Units(Branches, Agents) Will Stay Terminated',
  CHECKED_DEACTIVATE = 'If Ierminated, All Attached Units Will Be Terminated Too(Branches, Agents)',
  STAY_HERE = 'Stay Here',
  TERMINATE = 'Terminate',
  AUTHORITIES = 'Authorities',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
  FULL_INFO = 'Full Info',
  BANK_INFORMATION = 'Bank Information',
  BANK_NAME = 'Bank Name',
  ACCOUNTING_NUMBER = 'Accounting Number',
  ACH_ROUTING_NUMBER = 'ACH Routing Number',
  PAY_TYPE = 'Pay Type',
  MONTHLY_AMOUNT = 'Monthly amount',
  MONTHLY = 'Monthly',
  PER_LOAD = 'Per Load',
  WHITE_LIST = 'White List',
  LPF_SOFTWARE_FEE = 'LPF / Software fee',
  FEE_TYPE = 'Fee Type',
  PER_LOAD_AMOUNT = 'Per load amount',
}

export enum VALIDATION_MESSAGES {
  NAME_REQUIRED = 'Name is a required field',
  ZIP_REQUIRED = 'Zip Code is a required field',
  AUTHORITIES_REQUIRED = 'Authorities is a required field',
  PAYOUT_REQUIRED = 'Payout is a required field',
  TYPE_REQUIRED = 'Type is a required field',
  CREDIT_CHECK_REQUIRED = 'Credit check is a required field',
  OFFICE_LOCATION_REQUIRED = 'Agency location is a required field',
  PHONE_NUMBER_REQUIRED = 'Phone number is a required field',
  ADDRESS_REQUIRED = 'Address is a required field',
  ADDRESS_MAX_LENGTH = 'The address cannot be more than 300 characters long',
  CITY_REQUIRED = 'City name is a required field',
  STATE_ID_REQUIRED = 'State is a required field',
  MIN_OPTION = 'At least one option is required',
  PHONE = 'Incorrect phone format',
  INCORRECT_FAX = 'Incorrect fax format',
  MAX_LENGTH = 'Maximum of 10 digits allowed',
  MAX_MIN_ZIP_LENGTH = 'Must be 5 digits',
  WHITE_LIST = 'White List',
  FEE_TYPE_REQUIRED = 'Fee type is a required field',
  FEE_AMOUNT_REQUIRED = 'Fee amount is a required field',
}
