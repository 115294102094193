import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Divider, Popover, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { OFFICE_KEYS, SEARCH_OFFICE_COLUMNS, SUBJECT } from 'pages/profile/tabs/branch/constants/constants';
import { IBranchFormProps } from 'pages/profile/tabs/branch/constants/types';
import {
  BrackedInput,
  Input,
  SearchableDropdown,
  SearchableSelect,
} from 'pages/profile/tabs/branch/hoc-instance/instance';
import { Location } from 'services/profile/agents/interfaces';
import { IRoleLevel } from 'services/truck-board/carrier-request/interfaces';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { LOCATION_DROPDOWN_DATA } from 'utils/constants';
import {
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  onlyNumbersFormatter,
} from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import DollarIcon from 'components/svgs/DollarIcon';
import { PercentIcon } from 'components/svgs/PercentIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import useCreateEdit from './useCreateEdit';

import styles from './CreateEdit.module.scss';

const CreateEdit = ({
  handleSubmit,
  handleBrackedSubmit,
  brackedControl,
  getBrackedValues,
  watch,
  isBrackedValid,
  onBrackedSubmit,
  handleDeleteBracked,
  brackeds,
  brackedErrors,
  onSubmit,
  control,
  trigger,
  errors,
  mode,
  authoritiesByBranchId,
  statesData,
  data,
  authoritiesByOfficeId,
  setValue,
  refetch,
  setGetAuthoritiesFilter,
  setGetAuthoritiesByBranchFilter,
  isFetchingById,
  setBrackedsValue,
  isEditLoading,
  isEditDefaultBranchLoading,
  isCreateLoading,
  isAutocompleteFetching,
  brackedTrigger,
  resetField,
}: IBranchFormProps) => {
  const {
    authorities,
    addedAuthorities,
    setOfficesFilter,
    handleAddAuthority,
    handleRowDelete,
    officesOptions,
    isDefaultBranch,
    isDefaultOffice,
    handlePaginationClick,
    officesDataCount,
  } = useCreateEdit({
    authoritiesByBranchId,
    setValue,
    refetch,
    authoritiesByOfficeId,
    data,
    isAutocompleteFetching,
    setGetAuthoritiesByBranchFilter,
    watch,
    setGetAuthoritiesFilter,
  });

  const dispatch = useDispatch();
  const { copiedText, handleCopy } = useHandleCopyActive();

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading && !isEditLoading && !isEditDefaultBranchLoading }));
  }, [isCreateLoading, isEditLoading, isEditDefaultBranchLoading]);

  const isExistZeroBracked =
    !!watch('brackeds')?.length &&
    watch('brackeds').some((item: any) => item.to === 0) &&
    watch('brackeds').some((item: any) => item.percent === 0);

  const brackedsCheck = isExistZeroBracked ? false : isBrackedValid;

  const {
    permissionsInfo: { roleLevel },
  } = useSelector(selectUserPermissionsInfo);

  return (
    <div className={styles.branchWrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainLoader isFetching={isFetchingById} />
        {mode === 'edit' && (
          <ViewItemWrapper title={SUBJECT.BRANCH_INFO}>
            <Row className={styles.agentInfo} gutter={[0, 16]}>
              <Col span={8}>
                <div className={styles.topbannerPairs}>
                  <span className="top-banner-title">{SUBJECT.BRANCH_NAME}: </span>{' '}
                  <TooltipText
                    prefixClass="top-banner-value"
                    data={data?.result?.name}
                    subject={`${SUBJECT.BRANCH_INFO}${SUBJECT.BRANCH_NAME}`}
                  />
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.topbannerPairs}>
                  <span className="top-banner-title">{SUBJECT.OFFICE}: </span>{' '}
                  <span className="top-banner-value">{data?.result?.office?.name}</span>
                </div>
              </Col>
              <Col span={8}>
                <div className={styles.topbannerPairs}>
                  <span className="top-banner-title">{SUBJECT.BRANCH_ID}: </span>{' '}
                  <Col span={13}>
                    <div
                      className={clsx(styles.copyIconText, {
                        [styles.copyActive]: copiedText === 'topBannerId',
                      })}
                    >
                      <Typography variant="paragraph" children={data?.result?.id} className={styles.infoParagraph} />
                      <div
                        className={styles.copyIcon}
                        role="button"
                        onClick={() => handleCopy(data?.result?.id, 'topBannerId')}
                      >
                        <CopyIcon active={copiedText === 'topBannerId'} />
                      </div>
                    </div>
                  </Col>
                </div>
              </Col>

              <Col span={8}>
                <div className={styles.topbannerPairs}>
                  <span className="top-banner-title">{SUBJECT.LOCATION}: </span>
                  <span className="top-banner-value">{Location[data?.result?.location]}</span>
                </div>
              </Col>
              {!!data?.result?.branchConsignmentPercent && (
                <Col span={8}>
                  <div className={styles.topbannerPairs}>
                    <span className="top-banner-title">{SUBJECT.CONSIGMENT_PERCENT}: </span>
                    <span className="top-banner-value">{data?.result?.branchConsignmentPercent}</span>
                  </div>
                </Col>
              )}
              {data?.result?.managers?.length && (
                <Col span={8}>
                  <Row>
                    <Col>
                      <span className="top-banner-title">
                        {data?.result?.managers?.length === 1 ? SUBJECT.MANAGER : SUBJECT.MANAGERS}:{' '}
                      </span>
                    </Col>
                    <Col span={18}>
                      <div className={styles.textWithLinks}>
                        {data?.result?.managers.length === 1 && (
                          <TooltipText
                            phoneLink={formatPhone(data?.result?.managers?.[0]?.phone)}
                            prefixClass={styles.infoParagraph}
                            data={`${data?.result?.managers?.[0]?.firstName} ${data?.result?.managers?.[0]?.lastName}`}
                            subject={`${SUBJECT.BRANCH_INFO}${SUBJECT.MANAGERS}`}
                          />
                        )}
                        {data?.result?.managers?.length > 1 && (
                          <Popover
                            placement="top"
                            overlayClassName={styles.popoverOverlay}
                            content={data?.result?.managers?.map((el: any, index: any) => (
                              <Col key={index}>
                                <Row>
                                  <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                                    {formatPhone(el?.phone)}
                                  </a>
                                  <Typography
                                    variant="paragraph"
                                    className={clsx(styles.phoneNameInArray, 'top-banner-value')}
                                  >
                                    &nbsp;{`${el?.firstName} ${el?.lastName}`}
                                  </Typography>
                                </Row>
                              </Col>
                            ))}
                          >
                            <a>{`+${data?.result?.managers?.length}`}</a>
                          </Popover>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
        )}
        <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_NEW_BRANCH : SUBJECT.EDIT_BRANCH}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="name"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.NAME}
                getValues={watch}
                required
                errors={errors}
                disabled={isDefaultBranch}
                onChange={(e: any) => setValue('name', e.target.value, { shouldValidate: true, shouldDirty: true })}
              />
            </Col>
            <Col span={8}>
              <SearchableDropdown
                options={mode === 'edit' ? isDefaultOffice : officesOptions?.result}
                placeholder={SUBJECT.OFFICE}
                disabled={mode === 'edit'}
                offset={officesDataCount}
                setOffset={setOfficesFilter}
                values={watch('office')}
                columns={SEARCH_OFFICE_COLUMNS}
                keys={OFFICE_KEYS}
                control={control}
                name="office"
                showKey="title"
                setValue={setValue}
                required
                withoutBorder
                errors={errors}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="location"
                control={control}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      options={LOCATION_DROPDOWN_DATA}
                      placeholder={SUBJECT.LOCATION}
                      withCheckbox={false}
                      onBlur={() => trigger(field.name)}
                      errors={errors}
                      allowClear
                      resetFunc={() => resetField('location', { defaultValue: '' })}
                      isDisabled={isDefaultBranch}
                      onChange={(value: string | string[]) => field.onChange(value)}
                      value={field.value || ''}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.location} />
            </Col>
            <Col span={24}>
              <Row gutter={18}>
                <Col span={8}>
                  <Input
                    name="phoneNumber"
                    type="text"
                    control={control}
                    rules={{ required: true }}
                    placeholder={SUBJECT.PHONE_NUMBER}
                    getValues={watch}
                    onChange={(e: any) =>
                      setValue('phoneNumber', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    required
                    errors={errors}
                  />
                </Col>
                <Col span={8}>
                  <Input
                    name="fax"
                    type="text"
                    control={control}
                    rules={{ required: false }}
                    placeholder={SUBJECT.FAX}
                    onChange={(e: any) =>
                      setValue('fax', formatPhone(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    getValues={watch}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Input
                name="address"
                type="text"
                control={control}
                placeholder={SUBJECT.ADDRESS}
                getValues={watch}
                errors={errors}
                disabled={isDefaultBranch}
                onChange={(e: any) =>
                  setValue('address', e.target.value.trimStart(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Col>
            <Col span={12}>
              <Input
                name="secondAddress"
                type="text"
                control={control}
                placeholder={SUBJECT.SECOND_ADDRESS}
                getValues={watch}
                errors={errors}
                disabled={isDefaultBranch}
                onChange={(e: any) =>
                  setValue('secondAddress', e.target.value.trimStart(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Col>
            <Col span={8}>
              <Input
                name="city"
                type="text"
                control={control}
                placeholder={SUBJECT.CITY}
                getValues={watch}
                errors={errors}
                disabled={isDefaultBranch}
                onChange={(e: any) =>
                  setValue('city', e.target.value.trimStart(), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Col>
            <Col span={8}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => {
                  return (
                    <StateSelect
                      options={statesData}
                      placeholder={SUBJECT.STATE}
                      name={field.name}
                      onChange={(value: string) =>
                        setValue('state', value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      value={field.value}
                      allowClear
                      disabled={data?.result?.default}
                    />
                  );
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="zipcode"
                type="text"
                control={control}
                placeholder={SUBJECT.ZIPCODE}
                getValues={watch}
                onBlur={() => trigger('state')}
                onChange={(e: any) =>
                  setValue('zipcode', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                errors={errors}
                disabled={isDefaultBranch}
              />
              <ErrorMessage fieldError={errors?.state} />
            </Col>
          </Row>
        </ViewItemWrapper>
        {/* {mode === 'edit' && (
            <div className={styles.rowsGap}>
              <Row>
                <Col span={8}>
                  <Typography variant="paragraph" className={styles.percentText}>
                    {SUBJECT.BRANCH_CONSIGMENT_PERCENT}
                  </Typography>
                </Col>
                <Col span={4}>
                  <Typography variant="paragraph" className={styles.percentText}>
                    {SUBJECT.GROSS}
                  </Typography>
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={4}>
                  <Input
                    name="percent"
                    control={control}
                    onChange={(e: any) =>
                      setValue('percent', floatNumberFormatter(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    suffix={<PercentIcon />}
                    getValues={watch}
                    errors={errors}
                  />
                </Col>
                <Col span={4}>
                  <Input
                    name="amount"
                    suffix={<DollarIcon />}
                    onChange={(e: any) =>
                      setValue('amount', floatNumberFormatter(e.target.value as string), {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    control={control}
                    getValues={watch}
                    errors={errors}
                  />
                </Col>
                <Col span={4}>
                  <div className={styles.grossInputWrapper}>
                    <Input
                      className={styles.grossInput}
                      name="gross"
                      control={control}
                      getValues={watch}
                      onChange={(e: any) =>
                        setValue('gross', floatNumberFormatter(e.target.value), {
                          shouldDirty: true,
                          shouldValidate: true,
                        })
                      }
                      errors={errors}
                      readOnly
                      disabled
                    />
                    <span className={styles.grossInputSpan}>{`${formatNumberWithThousandsSeparatorAndDecimal(
                      Number(getValues('gross'))
                    )} $`}</span>
                  </div>
                </Col>
              </Row>
            </div>
          )} */}
        {!isDefaultBranch && (
          <ViewItemWrapper title={SUBJECT.ADD_AUTHORITIES} required>
            <Row>
              <Col span={24}>
                <SearchableSelect
                  isMulti="multiple"
                  name="authorities"
                  disabled={!watch('office').length}
                  control={control}
                  rules={{ required: true }}
                  onBlur={() =>
                    setTimeout(() => {
                      trigger('authorities');
                    }, 700)
                  }
                  required
                  withMarking={false}
                  placeholder={SUBJECT.SEARCH_SELECT}
                  withButton
                  buttonText={SUBJECT.ADD_AUTHORITIES_BTN}
                  options={authorities}
                  handleClick={handleAddAuthority}
                  offset={authoritiesByOfficeId?.count}
                  setOffset={setGetAuthoritiesFilter}
                  errors={errors}
                  isLoading={isAutocompleteFetching}
                />
              </Col>
              {!!addedAuthorities?.length && (
                <Col span={24}>
                  <div className={styles.addedBlock}>
                    <Typography variant="paragraph">{`${SUBJECT.ADDED}(${
                      mode === 'edit' ? authoritiesByBranchId?.count : addedAuthorities.length
                    })`}</Typography>
                  </div>

                  <DataGrid
                    handleRowDelete={handleRowDelete}
                    data={mode === 'edit' ? authoritiesByBranchId?.result : addedAuthorities}
                    count={mode === 'edit' ? authoritiesByBranchId?.count : addedAuthorities.length}
                    type="authorities"
                    handlePaginationClick={handlePaginationClick}
                    isDeleteDisabled={mode === 'edit' && addedAuthorities.length === 1}
                  />
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
        )}{' '}
      </form>
      <form onSubmit={handleBrackedSubmit(onBrackedSubmit)} className={styles.brackedsForm}>
        <ViewItemWrapper title={SUBJECT.ADD_BRACKED} canceledBlock={isDefaultBranch}>
          <Row gutter={[18, 40]}>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="percent"
                getValues={getBrackedValues}
                onChange={(e: any) => {
                  setBrackedsValue('percent', floatNumberFormatter(e.target.value as string), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  if (String(getBrackedValues('to'))) {
                    brackedTrigger('to');
                  }
                }}
                placeholder={SUBJECT.PERCENT}
                suffix={<PercentIcon />}
                errors={
                  Object.keys(brackedErrors).length
                    ? brackedErrors
                    : !getBrackedValues('percent')
                    ? { percent: errors.brackeds }
                    : {}
                }
                rules={{ required: true }}
                required
                warning={!brackeds?.length && Number(getBrackedValues('percent')) === 100}
                warningMessage={SUBJECT.WARNING_MESSAGE}
              />
            </Col>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="from"
                value={
                  getBrackedValues('from') ? formatNumberWithThousandsSeparatorAndDecimal(getBrackedValues('from')) : ''
                }
                placeholder={SUBJECT.FROM}
                suffix={<DollarIcon />}
                errors={brackedErrors}
                disabled
              />
            </Col>
            <Col span={5}>
              <BrackedInput
                control={brackedControl}
                name="to"
                value={
                  getBrackedValues('to') ? formatNumberWithThousandsSeparatorAndDecimal(getBrackedValues('to')) : ''
                }
                onChange={(e: any) =>
                  setBrackedsValue('to', floatNumberFormatter(e.target.value, false), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                placeholder={SUBJECT.TO}
                suffix={<DollarIcon />}
                errors={
                  Object.keys(brackedErrors).length
                    ? brackedErrors
                    : !getBrackedValues('to')
                    ? { to: errors.brackeds }
                    : {}
                }
                rules={{ required: true }}
                required
              />
            </Col>
            <Col span={3}>
              <div className={styles.brackedButtonWrapper}>
                {isExistZeroBracked ? (
                  <Tooltip title={SUBJECT.BRACKED_DISABLED} color="white" zIndex={9999999}>
                    <span>
                      <Button disabled htmlType="submit" variant="comment" children={SUBJECT.ADD} />
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    disabled={!getBrackedValues('to') || !getBrackedValues('percent') || !brackedsCheck}
                    htmlType="submit"
                    variant="comment"
                    children={SUBJECT.ADD}
                  />
                )}
              </div>
            </Col>
          </Row>
          {!brackedErrors.percent && !brackedErrors.to && (
            <Row>
              <Col>
                <ErrorMessage fieldError={errors?.brackeds} />
              </Col>
            </Row>
          )}
          {!!brackeds?.length && (
            <div className={styles.brackedsBlock}>
              <Typography
                children={`${SUBJECT.ADDED} (${brackeds?.length})`}
                variant="heading"
                className={styles.heading}
              />
              {brackeds?.map((item, index) => (
                <div key={index} className={styles.bracked}>
                  <Row align="middle">
                    <Col span={12}>
                      <Typography variant="paragraph" className={styles.percent}>{`${
                        item.percent
                      } % (${formatNumberWithThousandsSeparatorAndDecimal(
                        item.from,
                        true
                      )} - ${formatNumberWithThousandsSeparatorAndDecimal(item.to, true)})`}</Typography>
                    </Col>
                    <Col span={1} offset={11}>
                      {mode === 'edit' &&
                      brackeds.length === 1 &&
                      brackeds?.[0]?.percent !== 0 &&
                      roleLevel !== IRoleLevel.corpLevel ? (
                        <Tooltip
                          title="Sorry, you are not allowed to delete the last bracked"
                          placement="top"
                          color="white"
                        >
                          <div
                            className={clsx(styles.trashIcon, {
                              [styles.disabledTrashIcon]:
                                mode === 'edit' &&
                                brackeds.length === 1 &&
                                brackeds?.[0]?.percent !== 0 &&
                                roleLevel !== IRoleLevel.corpLevel,
                            })}
                            onClick={() => mode === 'edit' && brackeds.length > 1 && handleDeleteBracked(index)}
                          >
                            <TrashIcon />
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          className={clsx(styles.trashIcon, {
                            [styles.disabledTrashIcon]:
                              mode === 'edit' &&
                              brackeds.length === 1 &&
                              brackeds?.[0]?.percent !== 0 &&
                              roleLevel !== IRoleLevel.corpLevel,
                          })}
                          onClick={() => handleDeleteBracked(index)}
                        >
                          <TrashIcon />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Divider />
                </div>
              ))}
            </div>
          )}
        </ViewItemWrapper>
      </form>
    </div>
  );
};

export default CreateEdit;
