import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { selectModals } from 'store/modal-slice/selector';
import Button from 'ui/button/Button';
import RequestError from 'ui/request-error/RequestError';
import Typography from 'ui/typography/Typography';
import {
  checkIsNumberDecimal,
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
} from 'utils/helpers';

import RequestErroIcon from 'components/svgs/RequestErroIcon';

import { SUBJECT } from './constants/constants';
import { Input } from './hoc-instance/instance';
import useCollectionModal from './useCollectionPayModal';

import styles from './CollectionPayModal.module.scss';

const CollectionPayModal = ({ onSubmit, collection }: any) => {
  const { handleSubmit, control, getValues, watch, errors, handleCancel, isValid, setValue, isMoneyWarningGrader } =
    useCollectionModal({ collection });

  const { isLoading } = useSelector(selectModals);

  return (
    <>
      <form
        onSubmit={handleSubmit((data: any) => {
          if (!isLoading) {
            onSubmit(data);
          }
        })}
      >
        <Typography variant="heading" children={SUBJECT.PAYMENT} className={styles.heading} />
        <Row gutter={[0, 31]}>
          <Col span={24}>
            <Row align="middle" gutter={12}>
              <Col>
                <span className={styles.totalInfo}>{SUBJECT.COLLECTION}: </span>
                <span className={styles.totalInfo}>{`${formatNumberWithThousandsSeparatorAndDecimal(
                  collection
                )} $`}</span>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[24, 31]}>
              <Col span={12}>
                <Input
                  name="amount"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.AMOUNT}
                  getValues={getValues}
                  errors={errors}
                  value={getValues('amount') ? formatNumberWithThousandsSeparatorAndDecimal(getValues('amount')) : ''}
                  onChange={(e: any) =>
                    setValue('amount', floatNumberFormatter(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                />
              </Col>
              <Col span={12}>
                <Input
                  name="comment"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.COMMENT}
                  getValues={getValues}
                  errors={errors}
                  required
                />
              </Col>
              {isMoneyWarningGrader && (
                <Col span={24}>
                  <RequestError
                    icon={<RequestErroIcon />}
                    title={`${SUBJECT.WARNING_TEXT} ${formatNumberWithThousandsSeparatorAndDecimal(
                      Math.abs(collection) - checkIsNumberDecimal(Number(watch('amount'))),
                      true
                    )} ${SUBJECT.DEBT}`}
                    mode="warning"
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={10} justify="end">
              <Col>
                <Button variant="outlined" htmlType="button" size="large" onClick={handleCancel}>
                  {SUBJECT.CANCEL}
                </Button>
              </Col>
              <Col>
                <Button disabled={!isValid} variant="default" htmlType="submit" size="large">
                  {SUBJECT.PAY}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default CollectionPayModal;
