import { ICarrierByIdData } from 'services/profile/carriers/interfaces';
import { formatDate } from 'utils/dates';

import { paymentTermTypeTextNew } from '../../../components/billing-block/constants/types';
import { changeTimeZone, formatNumberWithThousandsSeparatorAndDecimal } from '../../../utils/helpers';

import { ICarrier, ICarrierInfoData, IPayable, IPayablesData } from './interface';

export const getPayablesSerializer = (body: IPayablesData) => {
  const result = body?.result?.map((payable: IPayable) => {
    return {
      id: payable?.id,
      acceptBillingTimeAt: formatDate(payable?.acceptBillingTimeAt as Date),
      loadId: payable?.id,
      loadStatus: {
        processing: payable?.processing,
        status: payable?.status || 0,
        carrierStatus: payable?.carrierStatus || 0,
        customerStatus: payable?.customerStatus || 0,
      },
      retrieved: !!payable?.retrieved,
      refNumber: payable?.referenceNumber,
      aging: payable?.invoiceReceiveAt ? payable?.aging + 1 : payable?.aging,
      customerName: payable?.customer?.name,
      customerFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(payable?.customerFinalAmount),
      customerDeptAmount: formatNumberWithThousandsSeparatorAndDecimal(payable?.customerDeptAmount),
      carrierName: payable?.carrier?.companyName,
      carrierFinalAmount: formatNumberWithThousandsSeparatorAndDecimal(payable?.carrierFinalAmount),
      carrierDepthAmount: formatNumberWithThousandsSeparatorAndDecimal(payable?.carrierDepthAmount),
      carrierId: payable?.carrierId,
      mcNumber: payable?.soldAuthority?.mc,
      authorityId: payable?.soldAuthority?.id || payable?.takenAuthority?.id,
      invoice: formatDate(payable?.invoiceCreatedAt as Date),
      invoiceIds: payable?.invoiceFileIds,
      invoiceReceived: payable?.invoiceReceiveAt || 0,
      invoiceReceivedBy: payable?.invoiceReceiveEmployee?.username || '',
      lastComment: payable?.comment?.message,
      paymentMethod: paymentTermTypeTextNew[payable?.carrierPaymentTermType] || '',
      loadType: payable?.type,
      claimed: payable?.claimed,
      code: payable?.code,
      dataType: 'payables',
      agencyName: payable?.office?.name,
      branchName: payable?.branch?.name,
      agentName: `${payable?.creator?.username}`,
      soldAs: payable?.soldAuthority?.name,
      takenAsMc: payable?.takenAuthority?.name,
    };
  });

  return {
    paidQuickAmount: body?.paidQuickAmount,
    paidTotalAmount: body?.paidTotalAmount,
    paidTotalCount: body?.paidTotalCount,
    payQuickAmount: body?.payQuickAmount,
    payTotalAmount: body?.payTotalAmount,
    payTotalCount: body?.payTotalCount,
    paidFuelAdvanceAmount: body?.paidFuelAdvanceAmount,
    result,
    count: body?.count,
  };
};

export const getCarrierSerializer = (body: ICarrierByIdData) => {
  return {
    ...body,
    createdAt: formatDate(body.createdAt),
    type: body.type,
  };
};

export const getCarrierInfoSerializer = (body: ICarrierInfoData) => {
  const result = body?.result?.map((item: ICarrier) => {
    return {
      keyType: 'payables',
      key: item.id,
      id: item?.id,
      code: item?.code,
      regDate: formatDate(item?.createdAt as Date),
      refNumber: item?.referenceNumber,
      carrierBalance: item?.carrierDepthAmount,
      carrierPayable: item?.carrierFinalAmount,
      mc: item?.soldAuthority?.mc,
      authorityId: item?.authorityId,
      loadId: item?.id,
      loadType: item?.type,
      status: item?.carrierStatus,
      invoice: {
        date: formatDate(item?.invoiceCreatedAt as Date),
        ids: item?.invoiceFileIds,
      },
      breakdown: {
        date: formatDate(item?.carrierBreakdownCreatedAt as Date),
        ids: item?.carrierBreakdownFileIds,
      },
      claimed: item?.claimed,
      haveUnacceptedTransactions: item?.haveUnacceptedTransactions,
      soldAs: item?.soldAuthority?.name,
      takenAs: item?.takenAuthority?.name,
    };
  });

  return {
    result,
    count: body?.count,
    expected: body?.expected,
    paid: body?.paid,
  };
};

export const getPayablesParamsSerializer = (params: any) => {
  const queryParams = new URLSearchParams();
  if (params.search) {
    queryParams.append('search', String(params.search));
  }
  if (params.field) {
    queryParams.append('field', String(params.field));
  }
  if (String(params.skip)) {
    queryParams.append('skip', String(params.skip));
  }
  if (params.limit) {
    queryParams.append('limit', String(params.limit));
  }
  if (params.order) {
    queryParams.append('order', String(params.order));
  }
  if (params.orderBy) {
    queryParams.append('orderBy', String(params.orderBy));
  }
  if (params.filter?.officeIds?.length) {
    params.filter?.officeIds?.forEach((el: string | number) => queryParams.append('officeIds[]', String(el)));
  }
  if (params.filter?.branchIds?.length) {
    params.filter?.branchIds?.forEach((el: string | number) => queryParams.append('branchIds[]', String(el)));
  }
  if (params.filter?.agentId) {
    queryParams.append('agentId', String(params.filter?.agentId));
  }
  if (params.filter?.carrierId) {
    queryParams.append('carrierId', String(params.filter?.carrierId));
  }
  if (params.filter?.carrierType) {
    queryParams.append('carrierType', String(params.filter?.carrierType));
  }
  if (params.filter?.authorityId) {
    queryParams.append('authorityId', String(params.filter?.authorityId));
  }
  if (params.filter?.carrierPaymentTermType) {
    queryParams.append('carrierPaymentTermType', String(params.filter?.carrierPaymentTermType));
  }
  if (params.filter?.createdAtFrom) {
    queryParams.append(
      'createdAtFrom',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdAtFrom)), 'America/Los_Angeles'))
        : String(params.filter?.createdAtFrom)
    );
  }
  if (params.filter?.createdAtTo) {
    queryParams.append(
      'createdAtTo',
      params.filter?.isConvertingCreatedAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.createdAtTo)), 'America/Los_Angeles'))
        : String(params.filter?.createdAtTo)
    );
  }
  if (params.filter?.carrierPaidAtFrom) {
    queryParams.append(
      'carrierPaidAtFrom',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.carrierPaidAtFrom)), 'America/Los_Angeles'))
        : String(params.filter?.carrierPaidAtFrom)
    );
  }
  if (params.filter?.carrierPaidAtTo) {
    queryParams.append(
      'carrierPaidAtTo',
      params.filter?.isConvertingPaidAtTimeNeeded
        ? String(changeTimeZone(new Date(Number(params.filter?.carrierPaidAtTo)), 'America/Los_Angeles'))
        : String(params.filter?.carrierPaidAtTo)
    );
  }
  return queryParams;
};
