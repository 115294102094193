import { IDateSelect } from './types';

export const dateSelect: IDateSelect[] = [
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Current Month', value: 'currentMonth' },
  { name: 'All', value: 'all' },
];

export enum SUBJECT {
  MONTH = 'Month',
  All = 'All',
  CUSTOM = 'Custom',
  INCREASE = 'increase',
  DECREASE = 'decrease',
  MONTHS = 'MMMM',
  YEARS = 'YYYY',
  FULL_DATE_FORMAT = 'MM.DD.YYYY',
  MONTH_SINGLE = 'month',
  YEAR_SINGLE = 'year',
  CURRENT_MONTH = 'Current Month',
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
}

export enum DateTypes {
  currentMonth = 'Current month',
  yesterday = 'Yesterday',
  today = 'Today',
}
