export enum VALIDATION_MESSAGES {
  IP_ADDRESS_REQUIRED = 'IP address is a required field',
  COMMENTS_REQUIRED = 'Comments is a required field',
}

export enum SUBJECT {
  WHITE_LIST = 'Whitelist',
  IP = 'IP address',
  COMMENTS = 'Comments',
  ADD = 'Add',
  ARE_YOU_SURE = 'Are You Sure Want To Delete This IP Address?',
  DELETE = 'Delete',
  CLOSE = 'Close',
  RESET = 'Reset',
  SAVE = 'Save',
}

export const defaultValues = {
  ip: '',
  comment: '',
};
