import dayjs from 'dayjs';

import {
  CreditCheckCard,
  FactoredCards,
  ICollectionValues,
  IInformationFilters,
  IInformationTabColumnsName,
  IInformTabCards,
  LPF_SOFTWARE_FEE_CARDS_ENUM,
  NotFactoredCards,
} from './types';

export const FILTER_DEFAULT_VALUES: IInformationFilters = {
  officePayroll: [],
  branchPayroll: [],
  agentPayroll: [],
  monthPickerPayroll: {
    startDate: dayjs().startOf('month').valueOf(),
    endDate: dayjs().endOf('month').valueOf().toString(),
  },
};

export enum CardNames {
  loads = 'payrollLoad',
  potentialGrossProfit = 'payrollPotentialGrossProfit',
  grossProfit = 'payrollGrossProfit',
  credit = 'payrollCredit',
  charges = 'payrollCharge',
  serviceFee = 'payrollServiceFee',
  otherServiceFee = 'payrollOtherServiceFee',
  advance = 'payrollAdvance',
  collection = 'payrollCollection',
  creditCheckChargeSum = 'payrollCreditCheck',
  softwareFee = 'payrollSoftwareFee',
  lpf = 'payrollLpf',
}

export const CardNumber = {
  1: 'payrollLoad',
  2: 'payrollPotentialGrossProfit',
  3: 'payrollGrossProfit',
  4: 'payrollCredit',
  5: 'payrollCharge',
  6: 'payrollServiceFee',
  7: 'payrollOtherServiceFee',
  8: 'payrollAdvance',
  9: 'payrollCollection',
  10: 'payrollCreditCheck',
  11: 'payrollSoftwareFee',
  12: 'payrollLpf',
};

export enum PAYROLL_CARDS_ID {
  'payrollLoad' = 1,
  'payrollPotentialGrossProfit' = 2,
  'payrollGrossProfit' = 3,
  'payrollCredit' = 4,
  'payrollCharge' = 5,
  'payrollServiceFee' = 6,
  'payrollOtherServiceFee' = 7,
  'payrollAdvance' = 8,
  'payrollCollection' = 9,
  'payrollCreditCheck' = 10,
  'payrollSoftwareFee' = 11,
  'payrollLpf' = 12,
}

export const cardsNames = {
  loads: 'Loads',
  potentialGrossProfit: 'Potential Gross Profit',
  grossProfit: 'Gross Profit',
  credit: 'Credit',
  charges: 'Charges',
  serviceFee: 'Service Fee',
  otherServiceFee: 'Other Service Fee',
  advance: 'Advance',
  collection: 'Collection',
  creditCheckChargeSum: 'Credit Check',
  softwareFee: 'Software Fee',
  lpf: 'LPF',
};

export enum SUBJECT {
  ARE_YOU_SURE_FILTER = 'After This Action The Chosen Filters Will be Reset',
  CHECK_EMAIL = 'Please Check Your Email',
  CANCEL = 'Cancel',
  SAVE = 'Save',
  EDIT = 'Edit',
  AGENT = 'Agent',
  OFFICE = 'Agency',
  BRANCH = 'Branch',
  RESET_ALL = 'Reset All',
  NOT_SELECTED = 'Not Selected',
  FINAL_PAY = 'Final Pay',
  ISSUED_ADVANCES = 'Issued advances',
  AMOUNT = 'Amount',
  SUBMIT = 'Submit',
  ERROR_MESSAGE = 'It Can’t Be More Than ',
  NEGATIVE_ERROR = 'It Can’t Be Less Than ',
  UNSAVED_CHANGES = 'You Have a Unsaved Changes',
  ADD_CHARGE = 'Add Charge',
  ADD_ADVANCE = 'Add Advance',
  SAVE_CHANGES = 'Save Changes',
  STAY_HERE = 'Stay Here',
  ARE_YOU_SURE = 'Are You Sure Want To Delete?',
  DELETE = 'Delete',
  CHECKED_ACTIVATE = 'Are You Sure Want To Activate?',
  CHECKED_DEACTIVATE = 'Are You Sure Want To Deactivate?',
  NO = 'No',
  YES = 'Yes',
  SUCCESSFULLY_ACTIVATED = 'Successfully Activated',
  SUCCESSFULLY_DEACTIVATED = 'Successfully Deactivated',
}

export const DEFAULT_VALUES_ADVANCE: ICollectionValues = {
  agency: [],
  branch: [],
  agent: [],
  type: '',
  amount: '-',
  reason: '',
};

export const DEFAULT_VALUES_CHARGE: ICollectionValues = {
  agency: [],
  branch: [],
  agent: [],
  type: '',
  amount: '-',
};

export const DEFAULT_VALUES_COLLECTION: Partial<ICollectionValues> = {
  paymentPlan: null,
  paymentPlanId: null,
};

export const AGENT_COLUMNS = [
  { key: 'firstName', name: 'Name' },
  { key: 'lastName', name: 'Last Name' },
  { key: 'username', name: 'Username' },
];

export const AGENT_KEYS = ['id', 'firstName', 'lastName', 'username'];

export const tabCards: IInformTabCards = {
  '1': IInformationTabColumnsName.Loads,
  '2': IInformationTabColumnsName.PotencialGrossProfit,
  '3': IInformationTabColumnsName.GrossProfit,
  '4': IInformationTabColumnsName.Credit,
  '5': IInformationTabColumnsName.Charges,
  '6': IInformationTabColumnsName.ServiceFee,
  '7': IInformationTabColumnsName.OtherServiceFee,
  '8': IInformationTabColumnsName.Advance,
  '9': IInformationTabColumnsName.Collection,
  '10': IInformationTabColumnsName.CreditCheck,
  '11': IInformationTabColumnsName.SoftwareFee,
  '12': IInformationTabColumnsName.Lpf,
};

export const excludeSortWithSearch = [
  'payrollInformationLoads',
  'payrollInformationPotencialGrossProfit',
  'payrollInformationGrossProfit',
  'payrollInformationServiceFee',
  'payrollInformationLoadProfitFee',
];
export const FACTORED_CARDS = [FactoredCards.LOADS, FactoredCards.GROSS_PROFIT, FactoredCards.SERVICE_FEE];

export const CREEDIT_CHECK_CARDS = [CreditCheckCard.CREDIT_CHECK];
export const LPF_SOFTWARE_FEE_CARDS = [LPF_SOFTWARE_FEE_CARDS_ENUM.LPF, LPF_SOFTWARE_FEE_CARDS_ENUM.SOFTWARE_FEE];

export const CARDS_HAS_MINE = [
  IInformationTabColumnsName.Advance,
  IInformationTabColumnsName.OtherServiceFee,
  IInformationTabColumnsName.Collection,
];

export const QueryRenders = {
  '7': 'editCharge',
  '8': 'editAdvance',
  '10': 'collection',
};

export const NOT_FACTORED_CARDS = [NotFactoredCards.POTENTIAL_GROSS_PROFIT];
