import { VALIDATION_MESSAGES } from 'pages/loads/components/edit-load/constants/constants';
import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { changeTimeZone } from '../../../../../utils/helpers';
import { LoadTypes } from '../../edit-load/constants/types';

import { SUBJECT } from './constants';
import { BookedTypes, IPickUp, TransportTypes } from './types';

const {
  CARRIER_REQUIRED,
  CARRIER_AMOUNT_REQUIRED,
  CUSTOMER_CONTACT_REQUIRED,
  CUSTOMER_AMOUNT_REQUIRED,
  CUSTOMER_NAME_REQUIRED,
  REFERENCE_NUMBER_REQUIRED,
  COMMODITY_REQUIRED,
  TRUCK_REQUIRED,
  WEIGHT_REQUIRED,
  ADDRESS_MAX_LENGTH,
  CELCIUS_REQUIRED,
  AGENT_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  AUTHORITIES_REQUIRED,
  PHONE,
  MAX_DIGIT,
  LOAD_TYPE_REQUIRED,
  CUSTOMER_CONTACT_NAME_REQUIRED,
  CARRIER_AMOUNT_NOT_ZERO,
  NOT_ZERO,
  NOT_GRADER_THAN,
  LESS_THAN_MILLION,
} = VALIDATION_MESSAGES;

export const validation = (customerAmount: number, data?: any, mode?: string, iAmFirstAgent?: boolean) => {
  return yup.object().shape({
    load_type: yup.string().required(LOAD_TYPE_REQUIRED),
    customerName: yup.string().required(CUSTOMER_NAME_REQUIRED),
    referenceNumber: yup.string().test('required', REFERENCE_NUMBER_REQUIRED, function (value) {
      if (this.parent.customerName) {
        return !!value;
      }
      return true;
    }),
    customerAmount: yup
      .number()
      .typeError(SUBJECT.CUSTOMER_AMOUNT_MUST_BE_A_NUMBER)
      .required(CUSTOMER_AMOUNT_REQUIRED)
      .max(customerAmount, `You can't fill more then ${customerAmount}`)
      .test('is-decimal', 'Up to 2 decimal places allowed', function checkIsValid(value) {
        if (!value && value != 0) return true;
        if (String(value).replace('.', '')! == '0') {
          return this.createError({
            message: NOT_ZERO,
            path: 'customerAmount',
          });
        }
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      }),
    customerContact: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.matches(phoneRegExp, PHONE).max(12, MAX_DIGIT).required(CUSTOMER_CONTACT_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    customerContactName: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.required(CUSTOMER_CONTACT_NAME_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    authorities: yup.mixed().test('length', AUTHORITIES_REQUIRED, function checkCarrierValue(value) {
      return !(this.parent.soldUsSwitch && Array.isArray(value) && !value.length);
    }),

    carrier: yup.array().test('length', CARRIER_REQUIRED, function checkCarrier(value) {
      if (
        !value?.length &&
        this.parent.load_type === String(LoadTypes['Connecting']) &&
        !iAmFirstAgent &&
        data?.agents?.length === 2
      ) {
        return this.createError({
          message: CARRIER_REQUIRED,
          path: 'carrier',
        });
      }
      if (
        this.parent.load_type === String(LoadTypes['Connecting']) ||
        this.parent.load_type === String(LoadTypes['Internal'])
      ) {
        return true;
      }
      if ((this.parent.load_type === String(LoadTypes['Internal']) || !data?.load) && mode !== 'create') {
        return true;
      } else {
        return Array.isArray(value) && !!value.length;
      }
    }),
    carrierAmount: yup.string().test('length', CARRIER_AMOUNT_REQUIRED, function checkCarrierAmount(value) {
      if (Number(value) >= 1000000) {
        return this.createError({
          message: LESS_THAN_MILLION,
          path: 'carrierAmount',
        });
      }
      if (this.parent.load_type === String(LoadTypes['Internal'])) {
        return true;
      }
      if (this.parent.load_type === String(LoadTypes['Connecting']) && data?.agents?.length === 2 && iAmFirstAgent) {
        return true;
      }
      if (this.parent.load_type === String(LoadTypes['Connecting']) && data?.load?.loadType === LoadTypes['Internal']) {
        return true;
      }
      if (this.parent.load_type === String(LoadTypes['Connecting']) && String(value) == '0') {
        return this.createError({
          message: CARRIER_AMOUNT_NOT_ZERO,
          path: 'carrierAmount',
        });
      } else if (
        !value &&
        this.parent.load_type === String(LoadTypes['Connecting']) &&
        !iAmFirstAgent &&
        data?.agents?.length === 2
      ) {
        return this.createError({
          message: CARRIER_AMOUNT_REQUIRED,
          path: 'carrierAmount',
        });
      } else if (this.parent.load_type === String(LoadTypes['Connecting']) && mode === 'create') {
        return true;
      }
      if (data?.load?.customerAccess || mode === 'create') {
        if (Number(value) > Number(data?.agents[1]?.amount)) {
          return this.createError({
            message: SUBJECT.CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_SECOND_AGENT_AMOUNT,
            path: 'carrierAmount',
          });
        } else if (this.parent.load_type === String(LoadTypes['Regular'])) {
          if (Number(value) > Number(this?.parent?.customerAmount)) {
            return this.createError({
              message: SUBJECT.CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_CUSTOMER_AMOUNT,
              path: 'carrierAmount',
            });
          } else if (value == '0') {
            return this.createError({
              message: CARRIER_AMOUNT_NOT_ZERO,
              path: 'carrierAmount',
            });
          } else return !!value?.length;
        } else if ((this.parent.load_type === String(LoadTypes['Internal']) || !data?.load) && mode !== 'create') {
          return true;
        } else if (String(value)?.length && !Number(value)) {
          return this.createError({
            message: CARRIER_AMOUNT_NOT_ZERO,
            path: 'carrierAmount',
          });
        } else return !!value?.length;
      } else if (!data?.load?.customerAccess && data?.load?.carrierAccess) {
        if (Number(value) > Number(data?.agents[1]?.amount)) {
          return this.createError({
            message: SUBJECT.CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_SECOND_AGENT_AMOUNT,
            path: 'carrierAmount',
          });
        } else if (this.parent.load_type === String(LoadTypes['Internal']) || !data?.load) {
          return true;
        } else if (String(value)?.length && !Number(value)) {
          return this.createError({
            message: CARRIER_AMOUNT_NOT_ZERO,
            path: 'carrierAmount',
          });
        } else return !!value?.length;
      } else {
        if (Number(value) > Number(this.parent.customerAmount)) {
          return this.createError({
            message: SUBJECT.CARRIER_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_CUSTOMER_AMOUNT,
            path: 'carrierAmount',
          });
        } else {
          return true;
        }
      }
    }),
    truck: yup.array().test('length', TRUCK_REQUIRED, function checkCarrier(value) {
      if (this.parent.carrier[0]?.type === 'Preferred Carrier') {
        if (
          !value?.length &&
          this.parent.load_type === String(LoadTypes['Connecting']) &&
          !iAmFirstAgent &&
          data?.agents?.length === 2
        ) {
          return this.createError({
            message: TRUCK_REQUIRED,
            path: 'truck',
          });
        }
        if (
          this.parent.load_type === String(LoadTypes['Connecting']) ||
          this.parent.load_type === String(LoadTypes['Internal'])
        ) {
          return true;
        }
        if ((this.parent.load_type === String(LoadTypes['Internal']) || !data?.load) && mode !== 'create') {
          return true;
        } else {
          return Array.isArray(value) && !!value.length;
        }
      } else return true;
    }),
    agent: yup.array().test('length', AGENT_REQUIRED, function checkCarrierValue(value) {
      return !(this.parent.load_type === String(LoadTypes.Connecting) && Array.isArray(value) && !value.length);
    }),
    agentAmount: yup
      .string()
      .test('isRequired', SUBJECT.AGENT_AMOUNT_MUST_BE_LESS_OR_EQUAL_THAN_CUSTOMER_AMOUNT, function checkLength(value) {
        if (Number(value) >= 1000000) {
          return this.createError({
            message: LESS_THAN_MILLION,
            path: 'agentAmount',
          });
        }
        if (this.parent.load_type === String(LoadTypes.Connecting) && !value && value !== '0') {
          return false;
        } else if (String(value).replace('.', '')! == '0') {
          return this.createError({
            message: NOT_ZERO,
            path: 'agentAmount',
          });
        } else return !(value && Number(value) > this.parent.customerAmount);
      }),
    transportType: yup.string().required('Load type is a required field'),
    celsius: yup.string().when('transportType', {
      is: (value: string) => value === String(TransportTypes.Refrigerated),
      then: schema => schema.required(CELCIUS_REQUIRED),
      otherwise: schema => schema.notRequired(),
    }),
    takenAs: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.required('Taken as is a required field'),
      otherwise: schema => schema.notRequired(),
    }),
    givenAs: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.required('Given as is a required field'),
      otherwise: schema => schema.notRequired(),
    }),
    commodity: yup.string().required(COMMODITY_REQUIRED),
    bookedAs: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.required('Booked as is a required field'),
      otherwise: schema => schema.notRequired(),
    }),
    soldAs: yup.string().when('load_type', {
      is: (value: string) => value !== String(LoadTypes['Internal']),
      then: schema => schema.required('Sold as is a required field'),
      otherwise: schema => schema.notRequired(),
    }),
    weight: yup
      .string()
      .required(WEIGHT_REQUIRED)
      .test('isRequired', WEIGHT_REQUIRED, function checkLength(value) {
        if (String(value).replace('.', '')! == '0') {
          return this.createError({
            message: NOT_ZERO,
            path: 'weight',
          });
        } else if (value.replace('.', '').length > 7) {
          return this.createError({
            message: NOT_GRADER_THAN,
            path: 'weight',
          });
        } else {
          return true;
        }
      }),
    feetPartial: yup
      .string()
      .when('soldAs', {
        is: (value: string) => value === String(BookedTypes.Partial),
        then: schema => schema.required('Feet partial is a required field'),
        otherwise: schema => schema.notRequired(),
      })
      .test('isRequired', 'Feet partial is a required field', function checkLength(value) {
        if (this.parent.soldAs === String(BookedTypes.Partial) && !value && value !== '0') {
          return false;
        } else if (this.parent.soldAs === String(BookedTypes.Partial) && String(value).replace('.', '')! == '0') {
          return this.createError({
            message: NOT_ZERO,
            path: 'feetPartial',
          });
        } else {
          return true;
        }
      }),
    pickups: yup.array().min(1).required(SUBJECT.PICKUPS_REQUIRED),
    dropOffs: yup.array().min(1).required(SUBJECT.DROPOFFS_REQUIRED),
  });
};

export const pickupsDropoffsValidation = ({
  loadType,
  pickups: initialPickups,
  dropOffs: initialDropOffs,
  timePeriod,
  isEditCreating,
  editedId,
  editMode,
}: {
  loadType: string | number;
  pickups: IPickUp[];
  dropOffs: any;
  timePeriod: string;
  isEditCreating: boolean;
  editedId: number | undefined;
  editMode: boolean;
}) => {
  return yup.object().shape({
    dropType:
      +loadType !== LoadTypes['Internal']
        ? yup.string().required('Drop type is a required field')
        : yup.string().notRequired(),
    appointmentDate: yup.string().required('Appointment date is a required field'),
    appointmentTime: !isEditCreating
      ? yup
          .string()
          .required('Appointment time is required field')
          .test('isCorrectTime', 'isCorrectTime', function checkIsTimeCorrect(value) {
            const pickups = editMode
              ? initialPickups?.map(el => ({
                  ...el,
                  appointmentDate: changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true),
                  appointmentTime: new Date(changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true))
                    .toLocaleString('en-US')
                    .split(',')[1]
                    .split(' ')[1]
                    .slice(0, -3),
                  timePeriod: new Date(changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true))
                    .toLocaleString('en-US')
                    .split(',')[1]
                    .split(' ')[2],
                }))
              : initialPickups;

            const dropOffs = editMode
              ? initialDropOffs?.map((el: any) => ({
                  ...el,
                  appointmentDate: changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true),
                  appointmentTime: new Date(changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true))
                    .toLocaleString('en-US')
                    .split(',')[1]
                    .split(' ')[1]
                    .slice(0, -3),
                  timePeriod: new Date(changeTimeZone(new Date(el?.appointmentDate), 'America/Los_Angeles', true))
                    .toLocaleString('en-US')
                    .split(',')[1]
                    .split(' ')[2],
                }))
              : initialDropOffs;
            if (!this.parent.appointmentDate) return true;
            if (value?.split(':')?.[0].length === 1 && value?.[0] == '0') {
              return this?.createError({
                message: `Hour couldn't be 0`,
                path: 'appointmentTime',
              });
            }
            if (value.includes('null') && this.parent.appointmentDate) {
              return this?.createError({
                message: `Appointment time is required field`,
                path: 'appointmentTime',
              });
            }
            const hourTransformer = (hour: any) => (Number(hour) === 12 ? 0 : Number(hour));
            const newHour = hourTransformer(Number(value?.split(':')[0]));
            const newMinute = Number(value?.split(':')[1]);

            const lastHour =
              Number(pickups[0]?.appointmentTime?.split(':')[0]) === 12
                ? 0
                : Number(pickups[0]?.appointmentTime?.split(':')[0]);
            const lastMinute = Number(pickups[0]?.appointmentTime?.split(':')[1]);

            const newDate = new Date(
              new Date(this.parent?.appointmentDate).toLocaleString('en-US').split(',')[0]
            ).getTime();
            let lastDate;
            if (pickups.length) {
              lastDate =
                this.parent.dropType == '2' && !dropOffs.length
                  ? new Date(new Date(pickups[0]?.appointmentDate).toLocaleString('en-US').split(',')[0])?.getTime()
                  : this.parent.dropType == '2'
                  ? new Date(
                      new Date(dropOffs[dropOffs.length - 1]?.appointmentDate).toLocaleString('en-US').split(',')[0]
                    )?.getTime()
                  : new Date(
                      new Date(pickups[pickups.length - 1]?.appointmentDate).toLocaleString('en-US').split(',')[0]
                    )?.getTime();
            }

            const firstPickUpTimePeriod = pickups[0]?.timePeriod;
            const lastPickUpTimePeriod = pickups[pickups.length - 1]?.timePeriod;

            const lastDropOfTimePeriod = dropOffs[dropOffs.length - 1]?.timePeriod;

            const lastPickUpHour = hourTransformer(Number(pickups[pickups.length - 1]?.appointmentTime?.split(':')[0]));
            const lastPickUpMinute = Number(pickups[pickups.length - 1]?.appointmentTime?.split(':')[1]);

            const firstPickUpHour = hourTransformer(Number(pickups[0]?.appointmentTime?.split(':')[0]));
            const firstPickUpMinute = Number(pickups[0]?.appointmentTime?.split(':')[1]);

            const lastDropOffHour = hourTransformer(
              Number(dropOffs[dropOffs.length - 1]?.appointmentTime?.split(':')[0])
            );
            const lastDropOffMinute = Number(dropOffs[dropOffs.length - 1]?.appointmentTime?.split(':')[1]);

            const isSecond = pickups?.length === 1 && !dropOffs?.length;
            const isThirdOrMore = (pickups?.length && dropOffs?.length) || pickups?.length > 1;

            let conditionBy;
            if (
              (pickups?.length === 1 && this.parent.dropType == '1') ||
              (pickups?.length && this.parent.dropType == '2' && !dropOffs.length)
            ) {
              conditionBy = 'first pickUp';
            } else if (pickups.length > 1 && this.parent.dropType == '1') {
              conditionBy = 'last pickUp';
            } else if (dropOffs.length && this.parent.dropType == '2') {
              conditionBy = 'last dropOff';
            }

            let baseTimePeriod;
            if (this.parent.dropType == '1') {
              baseTimePeriod = lastPickUpTimePeriod;
            } else {
              if (dropOffs.length) {
                baseTimePeriod = lastDropOfTimePeriod;
              } else {
                baseTimePeriod = firstPickUpTimePeriod;
              }
            }

            if (lastDate && lastDate < newDate) {
              return true;
            } else {
              if (baseTimePeriod === 'PM' && timePeriod === 'AM') {
                return this?.createError({
                  message: `Time couldn't be less or equal to ${conditionBy} time`,
                  path: 'appointmentTime',
                });
              } else if (baseTimePeriod === 'AM' && timePeriod === 'PM') {
                return true;
              } else {
                if (isSecond && (newHour < lastHour || (newHour === lastHour && newMinute <= lastMinute))) {
                  return this?.createError({
                    message: `Time couldn't be less or equal to first pickUp time`,
                    path: 'appointmentTime',
                  });
                } else if (
                  isThirdOrMore &&
                  this.parent.dropType == '1' &&
                  (newHour < lastPickUpHour || (newHour === lastPickUpHour && newMinute <= lastPickUpMinute))
                ) {
                  return this?.createError({
                    message: `Time couldn't be less or equal to last pickUp time`,
                    path: 'appointmentTime',
                  });
                } else if (
                  isThirdOrMore &&
                  this.parent.dropType == '2' &&
                  dropOffs.length &&
                  (newHour < lastDropOffHour || (newHour === lastDropOffHour && newMinute <= lastDropOffMinute))
                ) {
                  return this?.createError({
                    message: `Time couldn't be less or equal to last dropOff time`,
                    path: 'appointmentTime',
                  });
                } else if (
                  isThirdOrMore &&
                  this.parent.dropType == '2' &&
                  !dropOffs.length &&
                  (newHour < firstPickUpHour || (newHour === firstPickUpHour && newMinute <= firstPickUpMinute))
                ) {
                  return this?.createError({
                    message: `Time couldn't be less or equal to first pickUp time`,
                    path: 'appointmentTime',
                  });
                } else {
                  return true;
                }
              }
            }
          })
      : yup
          .string()
          .required('Appointment time is required field')
          .test('isCorrectTime', 'isCorrectTime', function checkIsTimeCorrect(value) {
            if (!this.parent.appointmentDate) return true;
            if (value?.split(':')?.[0].length === 1 && value?.[0] == '0') {
              return this?.createError({
                message: `Hour couldn't be 0`,
                path: 'appointmentTime',
              });
            }
            const pickups = initialPickups;
            const dropOffs = initialDropOffs;
            if (value.includes('null') && this.parent.appointmentDate) {
              return this?.createError({
                message: `Appointment time is required field`,
                path: 'appointmentTime',
              });
            }

            const newHour = Number(value?.split(':')[0]);
            const newMinute = Number(value?.split(':')[1]);
            const getTimeInMilliseconds = (hour: number, minute: number, amPm: string) =>
              ((hour == 12 ? 0 : +hour) * 3600 + +minute * 60) * 1000 + (amPm === 'PM' ? 12 * 3600 * 1000 : 0);

            const firstPickUpHour = Number(pickups[0]?.appointmentTime?.split(':')[0]);
            const firstPickUpMinute = Number(pickups[0]?.appointmentTime?.split(':')[1]);

            const firstDropOfUpHour = Number(dropOffs[0]?.appointmentTime?.split(':')[0]);
            const firstDropOfMinute = Number(dropOffs[0]?.appointmentTime?.split(':')[1]);
            const newDateInLocalTimeZone =
              new Date(new Date(this.parent?.appointmentDate).toLocaleString('en-US').split(',')[0]).getTime() +
              getTimeInMilliseconds(newHour, newMinute, timePeriod);

            const newDate = editMode
              ? changeTimeZone(new Date(newDateInLocalTimeZone), 'America/Los_Angeles')
              : newDateInLocalTimeZone;

            const firstPickUpDate =
              new Date(new Date(pickups[0]?.appointmentDate).toLocaleString('en-US').split(',')[0]).getTime() +
              getTimeInMilliseconds(firstPickUpHour, firstPickUpMinute, pickups[0]?.timePeriod);

            const firstDropOffDate =
              new Date(new Date(dropOffs[0]?.appointmentDate).toLocaleString('en-US').split(',')[0]).getTime() +
              getTimeInMilliseconds(firstDropOfUpHour, firstDropOfMinute, dropOffs[0]?.timePeriod);

            //index drop and pick
            const pickupEditedIndex = pickups.findIndex((el: any) => el.id === editedId);
            const dropOffEditedIndex = dropOffs.findIndex((el: any) => el.id === editedId);

            const prevPickUpIndex =
              pickups.length && pickups.length > 1 && pickupEditedIndex !== 0
                ? pickupEditedIndex - 1
                : pickupEditedIndex;

            const prevPickUpHour = Number(pickups[prevPickUpIndex]?.appointmentTime?.split(':')[0]);
            const prevPickUpMinute = Number(pickups[prevPickUpIndex]?.appointmentTime?.split(':')[1]);

            const nextPickUpIndex =
              pickups.length && pickups.length > 1 && pickupEditedIndex + 1 <= pickups.length - 1
                ? pickupEditedIndex + 1
                : pickupEditedIndex;

            const nextPickUpHour = Number(pickups[nextPickUpIndex]?.appointmentTime?.split(':')[0]);
            const nextPickUpMinute = Number(pickups[nextPickUpIndex]?.appointmentTime?.split(':')[1]);

            const prevPickDate =
              new Date(
                new Date(pickups[prevPickUpIndex]?.appointmentDate).toLocaleString('en-US').split(',')[0]
              ).getTime() +
              getTimeInMilliseconds(prevPickUpHour, prevPickUpMinute, pickups[prevPickUpIndex]?.timePeriod);
            const nextPickDate =
              new Date(
                new Date(pickups[nextPickUpIndex]?.appointmentDate).toLocaleString('en-US').split(',')[0]
              ).getTime() +
              getTimeInMilliseconds(nextPickUpHour, nextPickUpMinute, pickups[nextPickUpIndex]?.timePeriod);

            //dropOff
            const prevDropOffIndex =
              dropOffs.length && dropOffs.length > 1 && dropOffEditedIndex !== 0
                ? dropOffEditedIndex - 1
                : dropOffEditedIndex;

            const prevDropOffUpHour = Number(dropOffs[prevDropOffIndex]?.appointmentTime?.split(':')[0]);
            const prevDropOffMinute = Number(dropOffs[prevDropOffIndex]?.appointmentTime?.split(':')[1]);

            const nextDropOffIndex =
              dropOffs.length > 1 && dropOffEditedIndex + 1 <= dropOffs.length - 1
                ? dropOffEditedIndex + 1
                : dropOffEditedIndex;

            const nextDropOffUpHour = Number(dropOffs[nextDropOffIndex]?.appointmentTime?.split(':')[0]);
            const nextDropOffMinute = Number(dropOffs[nextDropOffIndex]?.appointmentTime?.split(':')[1]);

            const prevDropOffDate =
              new Date(
                new Date(dropOffs[prevDropOffIndex]?.appointmentDate).toLocaleString('en-US').split(',')[0]
              ).getTime() +
              getTimeInMilliseconds(prevDropOffUpHour, prevDropOffMinute, dropOffs[prevDropOffIndex]?.timePeriod);
            const nextDropOffDate =
              new Date(
                new Date(dropOffs[nextDropOffIndex]?.appointmentDate).toLocaleString('en-US').split(',')[0]
              ).getTime() +
              getTimeInMilliseconds(nextDropOffUpHour, nextDropOffMinute, dropOffs[nextDropOffIndex]?.timePeriod);

            // conditions start
            if (
              (this.parent.dropType == '1' && newDate > prevPickDate && !dropOffs.length && newDate < nextPickDate) ||
              (this.parent.dropType == '2' && newDate > prevDropOffDate && newDate < nextDropOffDate) ||
              (this.parent.dropType == '1' && dropOffs.length && pickups.length === 1 && newDate < firstDropOffDate)
            ) {
              return true;
            } else if (this.parent.dropType == '1' && pickupEditedIndex > prevPickUpIndex && prevPickDate >= newDate) {
              return this?.createError({
                message: `Time couldn't be less or equal to previous pickUp time`,
                path: 'appointmentTime',
              });
            }
            if (this.parent.dropType == '1' && pickupEditedIndex < nextPickUpIndex && newDate >= nextPickDate) {
              return this?.createError({
                message: `Time couldn't be more or equal to next pickUp time`,
                path: 'appointmentTime',
              });
            }
            if (this.parent.dropType == '2' && dropOffEditedIndex > prevDropOffIndex && newDate <= prevDropOffDate) {
              return this?.createError({
                message: `Time couldn't be less or equal to previous dropOff time`,
                path: 'appointmentTime',
              });
            }
            if (this.parent.dropType == '2' && dropOffEditedIndex < nextDropOffIndex && newDate >= nextDropOffDate) {
              return this?.createError({
                message: `Time couldn't be more or equal to next dropOff time`,
                path: 'appointmentTime',
              });
            }
            if (this.parent.dropType == '2' && dropOffEditedIndex === nextDropOffIndex && newDate <= firstPickUpDate) {
              return this?.createError({
                message: `Time couldn't be less or equal to first pickUp time`,
                path: 'appointmentTime',
              });
            } else {
              if (this.parent.dropType == '1' && pickupEditedIndex > prevPickUpIndex && newDate <= prevPickDate) {
                return this?.createError({
                  message: `Time couldn't be less or equal to previous pickUp time`,
                  path: 'appointmentTime',
                });
              }
              if (
                this.parent.dropType == '1' &&
                pickups.length === 1 &&
                dropOffs.length &&
                newDate >= firstDropOffDate
              ) {
                return this?.createError({
                  message: `Time couldn't be more or equal to first dropOff time`,
                  path: 'appointmentTime',
                });
              }
              if (
                this.parent.dropType == '1' &&
                pickups.length > 1 &&
                dropOffs.length &&
                pickupEditedIndex < nextPickUpIndex &&
                nextPickDate < firstDropOffDate &&
                newDate >= nextPickDate
              ) {
                return this?.createError({
                  message: `Time couldn't be more or equal to nex pickUp time`,
                  path: 'appointmentTime',
                });
              }
              if (
                this.parent.dropType == '1' &&
                pickups.length > 1 &&
                dropOffs.length &&
                pickupEditedIndex < nextPickUpIndex &&
                nextPickDate > firstDropOffDate &&
                newDate >= firstDropOffDate
              ) {
                return this?.createError({
                  message: `Time couldn't be more or equal to first dropOff time`,
                  path: 'appointmentTime',
                });
              }
              if (this.parent.dropType == '1' && pickupEditedIndex < nextPickUpIndex && newDate >= nextPickDate) {
                return this?.createError({
                  message: `Time couldn't be more or equal to next pickUp time`,
                  path: 'appointmentTime',
                });
              }
              if (
                this.parent.dropType == '2' &&
                dropOffEditedIndex === prevDropOffIndex &&
                newDate <= firstPickUpDate
              ) {
                return this?.createError({
                  message: `Time couldn't be less or equal to first pickUp time`,
                  path: 'appointmentTime',
                });
              }
              if (this.parent.dropType == '2' && dropOffEditedIndex > prevDropOffIndex && newDate <= prevDropOffDate) {
                return this?.createError({
                  message: `Time couldn't be less or equal to previous dropOff time`,
                  path: 'appointmentTime',
                });
              }
              if (this.parent.dropType == '2' && dropOffEditedIndex < nextDropOffIndex && newDate >= nextDropOffDate) {
                return this?.createError({
                  message: `Time couldn't be more or equal to next dropOff time`,
                  path: 'appointmentTime',
                });
              } else {
                return true;
              }
            }
          }),
    facility:
      +loadType !== LoadTypes['Internal']
        ? yup.string().required(SUBJECT.FACILITY_REQUIRED)
        : yup.string().notRequired(),
    address:
      +loadType !== LoadTypes['Internal']
        ? yup.string().required(SUBJECT.ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH)
        : yup.string().notRequired().max(300, ADDRESS_MAX_LENGTH),
    city: yup.string().required(SUBJECT.CITY_REQUIRED),
    state: yup.string().required(SUBJECT.STATE_REQUIRED),
    zip: yup.string().test('isZipRequired', SUBJECT.ZIP_REQUIRED, function (value: any) {
      if (!value) {
        return false;
      } else if (value?.length > 5 || value?.length < 5) {
        return this.createError({
          path: 'zip',
          message: MAX_MIN_ZIP_LENGTH,
        });
      }
      return true;
    }),
    phoneNumber: yup.string().test('isCorrectPhone', 'isCorrectPhone', function checkIsPhoneCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: SUBJECT.PHONE_FORMAT,
          path: 'phoneNumber',
        });
      } else {
        return this.createError({
          message: SUBJECT.MAX_LENGTH,
          path: 'phoneNumber',
        });
      }
    }),
  });
};
