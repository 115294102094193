import { useEffect, useMemo, useState } from 'react';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { pickupsDropOffsSerializer } from 'pages/loads/components/create-load/components/pickups-dropoffs/helpers';
import { CONNECTING_SECTIONS_VIEW } from 'pages/loads/components/edit-load/constants/constants';
import { transactionsForDataGrind } from 'pages/loads/components/edit-load/constants/helpers';
import {
  LoadCancelStatus,
  TakenType,
  TransportCapabilityTypes,
} from 'pages/loads/components/edit-load/constants/types';
import DataGrid from 'ui/data-grid/DataGrid';
import { LoadGlobalStatus } from 'ui/load-status/constants/types';
import { MainLoader } from 'ui/main-loader/MainLoader';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import Typography from 'ui/typography/Typography';
import { FileTypesText } from 'ui/upload-row/constants/types';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate } from 'utils/dates';
import {
  formatNumberWithThousandsSeparatorAndDecimal,
  multiplyDownloadWithDelay,
  multiplyViewWithDelay,
} from 'utils/helpers';

import { filesFormatter, filesNoaFormatter } from 'components/billing-block/constants/helpers';
import { PaymentTermType, PaymentTermTypeText } from 'components/billing-block/constants/types';
import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import CustomComment from 'components/custom-comment/CustomComment';
import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import CopyIcon from 'components/svgs/CopyIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import TransactionsTable from 'components/transactions-table/TransactionsTable';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { IRoleLevel } from '../../../../../../services/truck-board/carrier-request/interfaces';
import { ILoadProps } from '../../constants/types';
import AutoRateConfirmation from '../auto-rate-confirmation/AutoRateConfirmation';
import ConnectingBanner from '../loads-comment/components/connecting-banner/ConnectingBanner';
import RedConfimrationViewFiles from '../read-confimariton-view-files/RedConfimrationViewFiles';
import { BookedTypes } from '../regular-load-view/constants/types';

import { SUBJECT } from './constants/constants';
import useConnectingLoadView from './useConnectingLoadView';

import styles from './ConnectingLoadView.module.scss';

const ConnectingLoadView = ({
  data,
  files,
  points,
  transactions,
  isFetching,
  refetchLoadById,
  comments,
  getMoreComments,
  isShowMoreVisible,
  loadsPagePermissions,
  hasEditPermission,
  serializedStates,
  isLoadsCommentsLoading,
  handleCreateRateConfirmation,
  rateConfirmations,
  createRateConfCheck,
  handleSendRateConfirmation,
}: ILoadProps) => {
  const {
    handleCancelLoad,
    handleSwitchMarkAsClaim,
    userId,
    handleViewFile,
    handleDownloadFileClick,
    control,
    watch,
    setValue,
    breakdowns,
    breakdownParams,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    loadHistory,
    isShowMoreVisibleNotes,
    isSecondAgent,
    isFirstAgent,
    getMoreNotes,
    employeeLevel,
    userType,
    hasAccessToRateConf,
    copiedText,
    handleCopy,
    isDefaultHistory,
    setIsDefaultHistory,
    handleScrollToHistory,
    drawerRef,
    roleLevel,
    isFetchingHistory,
    activeSection,
    width,
    userInfo,
  } = useConnectingLoadView({
    refetchLoadById,
    data,
  });

  const loadPagePermissions = usePermittedActions('loads.loads');

  const hasAccessToViewHistory =
    userType === UserTypes.ADMIN || (employeeLevel === EmployeeLevels.CORPORATE && loadPagePermissions.view);

  const filesWithoutRedConf =
    files?.result &&
    Object.values(files?.result)
      ?.flat()
      .filter(item => item.type !== SUBJECT.RED_CONFIRMATION);

  const mailableFiles = filesWithoutRedConf?.filter(item => item.mailable);

  const [financeSpan, setFinanceSpan] = useState(0);

  const carrierLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.carrierAmountPaid && data?.finance?.carrierAmountPaid) ||
      (data?.finance?.carrierBalance && data?.finance?.carrierBalance) ||
      (data?.finance?.carrierFinalPayable && data?.finance?.carrierFinalPayable) ||
      (data?.finance?.carrierInitialRate && data?.finance?.carrierInitialRate) ||
      (data?.finance?.carrierPaymentTermGeneratedAmount && data?.finance?.carrierPaymentTermGeneratedAmount !== '0')
    );
  }, [
    data?.finance?.carrierAmountPaid,
    data?.finance?.carrierBalance,
    data?.finance?.carrierFinalPayable,
    data?.finance?.carrierInitialRate,
    data?.finance?.carrierPaymentTermGeneratedAmount,
  ]);

  const customerLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.customerAmountPaid && data?.finance?.customerAmountPaid) ||
      (data?.finance?.customerBalance && data?.finance?.customerBalance) ||
      (data?.finance?.customerFinalReceivable && data?.finance?.customerFinalReceivable) ||
      (data?.finance?.customerInitialRate && data?.finance?.customerInitialRate)
    );
  }, [
    data?.finance?.customerAmountPaid,
    data?.finance?.customerBalance,
    data?.finance?.customerFinalReceivable,
    data?.finance?.customerInitialRate,
  ]);

  const firstAgentLoadFinanceCheck = useMemo(() => {
    return !!(
      (data?.finance?.agentFinalProfit && data?.finance?.agentFinalProfit) ||
      (data?.finance?.agentBalance && data?.finance?.agentBalance)
    );
  }, [data?.finance?.agentFinalProfit, data?.finance?.agentBalance]);

  const secondAgentLoadFinanceCheck = useMemo(() => {
    return (
      !!(
        (data?.finance?.secondAgentBalance && data?.finance?.secondAgentBalance) ||
        data?.finance?.secondAgentFinalProfit
      ) && !isFirstAgent
    );
  }, [data?.finance?.secondAgentBalance, data?.finance?.secondAgentFinalProfit, isFirstAgent]);

  useEffect(() => {
    if (!isFetching) {
      if (carrierLoadFinanceCheck) {
        setFinanceSpan(prev => prev + 1);
      }
      if (customerLoadFinanceCheck && !isSecondAgent) {
        setFinanceSpan(prev => prev + 1);
      }
      if (firstAgentLoadFinanceCheck && !isSecondAgent) {
        setFinanceSpan(prev => prev + 1);
      }
      if (secondAgentLoadFinanceCheck) {
        setFinanceSpan(prev => prev + 1);
      }
    }
    return () => {
      setFinanceSpan(0);
    };
  }, [isFetching]);

  return (
    <div
      className={clsx({
        ['scrollBarWrapper']: width === 'full',
      })}
    >
      {width === 'full' && <ScrollBar sections={CONNECTING_SECTIONS_VIEW} />}
      <div
        className={clsx('scrollBarContent', {
          ['openedDrawer']: width === 'full',
        })}
      >
        <div>
          <MainLoader isFetching={isFetching} />
          <ConnectingBanner
            activeSection={activeSection}
            data={data!}
            userId={Number(userId)}
            inView
            handleSwitchMarkAsClaim={handleSwitchMarkAsClaim}
            connectingWatch={data?.load?.claimed}
            withActions={data?.load?.status !== LoadCancelStatus.Canceled}
            handleCancelLoad={handleCancelLoad}
            hasClaimPermission={loadsPagePermissions.claim}
            hasDeClaimPermission={loadsPagePermissions.resolve}
            hasCancelPermission={loadsPagePermissions.cancel}
            hasEditPermission={hasEditPermission}
            isFirstAgent={isFirstAgent}
            isSecondAgent={isSecondAgent}
            hasAccessToViewHistory={hasAccessToViewHistory}
            handleCommentClick={() => handleScrollToHistory('comment')}
            handleScrollToHistory={() => handleScrollToHistory('history')}
            loadHistory={loadHistory}
          />
          <ViewItemWrapper
            title={SUBJECT.LOAD_FINANCE}
            id={SUBJECT.LOAD_FINANCE_ID}
            className={activeSection === SUBJECT.LOAD_FINANCE_ID ? 'active' : ''}
          >
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row gutter={[50, 0]}>
                  {customerLoadFinanceCheck && (
                    <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER} className={styles.financeTitle} />
                    </Col>
                  )}
                  {firstAgentLoadFinanceCheck && !isSecondAgent && (
                    <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.FIRST_AGENT_HEADER}
                        className={styles.financeTitle}
                      />
                    </Col>
                  )}
                  {secondAgentLoadFinanceCheck && (
                    <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.SECOND_AGENT_HEADER}
                        className={styles.financeTitle}
                      />
                    </Col>
                  )}
                  {carrierLoadFinanceCheck && (
                    <Col span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}>
                      <Typography variant="heading" children={SUBJECT.CARRIER} className={styles.financeTitle} />
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={50}>
                  {customerLoadFinanceCheck && (
                    <Col
                      className={styles.borderedColumn}
                      span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}
                    >
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.CUSTOMER_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.INITIAL_RATE_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerInitialRate,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {data?.load?.paymentTermType !== PaymentTermType.BILLING_TYPES_FACTORING &&
                          !!Number(data?.finance?.customerPaymentTermGeneratedAmount) && (
                            <Col span={24}>
                              <Row justify="space-between" align="middle">
                                <Col span={8}>
                                  <Typography
                                    variant="paragraph"
                                    className={styles.infoHeading}
                                    children={
                                      PaymentTermTypeText[
                                        data?.load?.paymentTermType as keyof typeof PaymentTermTypeText
                                      ]
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Typography
                                    variant="paragraph"
                                    className={styles.infoParagraph}
                                    children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                      data?.finance?.customerPaymentTermGeneratedAmount,
                                      true
                                    )} (${data?.finance?.customerPaymentTermPercent} %)`}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          )}
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_RECEIVABLE_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerFinalReceivable,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AMOUNT_PAID_CUSTOMER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.customerAmountPaid,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {firstAgentLoadFinanceCheck && !isSecondAgent && (
                    <Col
                      className={styles.borderedColumn}
                      span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}
                    >
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AGENT_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.agentBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_PROFIT}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.agentFinalProfit,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {secondAgentLoadFinanceCheck && (
                    <Col
                      className={styles.borderedColumn}
                      span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}
                    >
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AGENT_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.secondAgentBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_PROFIT}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.secondAgentFinalProfit,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {carrierLoadFinanceCheck && (
                    <Col
                      className={styles.borderedColumn}
                      span={financeSpan === 1 ? 6 : financeSpan === 4 ? 6 : financeSpan === 3 ? 8 : 8}
                    >
                      <Row gutter={[0, 24]}>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.CARRIER_BALANCE}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierBalance,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.INITIAL_RATE_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierInitialRate,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        {!!Number(data?.finance?.carrierPaymentTermGeneratedAmount) && (
                          <Col span={24}>
                            <Row justify="space-between" align="middle">
                              <Col span={8}>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoHeading}
                                  children={SUBJECT.QUICK_PAY}
                                />
                              </Col>
                              <Col>
                                <Typography
                                  variant="paragraph"
                                  className={styles.infoParagraph}
                                  children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                    data?.finance?.carrierPaymentTermGeneratedAmount,
                                    true
                                  )} `}
                                />
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.FINAL_PAYABLE_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierFinalPayable,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify="space-between" align="middle">
                            <Col span={8}>
                              <Typography
                                variant="paragraph"
                                className={styles.infoHeading}
                                children={SUBJECT.AMOUNT_PAID_CARRIER}
                              />
                            </Col>
                            <Col>
                              <Typography
                                variant="paragraph"
                                className={styles.infoParagraph}
                                children={`${formatNumberWithThousandsSeparatorAndDecimal(
                                  data?.finance?.carrierAmountPaid,
                                  true
                                )} `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </ViewItemWrapper>
          {transactions?.count ? (
            <ViewItemWrapper
              title={SUBJECT.LOAD_TRANSACTION}
              id={SUBJECT.TRANSACTION_ID}
              className={activeSection === SUBJECT.TRANSACTION_ID ? 'active' : ''}
            >
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <TransactionsTable
                    data={transactionsForDataGrind(transactions)}
                    isCustomer
                    isCarrier
                    isSecondAgent={
                      (data?.load?.customerAccess && data?.load?.carrierAccess) ||
                      (data?.load?.customerAccess && !data?.load?.carrierAccess)
                    }
                  />
                </Col>
              </Row>
            </ViewItemWrapper>
          ) : null}
          <ViewItemWrapper
            title={SUBJECT.LOAD_INFORMATION}
            id={SUBJECT.LOAD_INFORMATION_ID}
            className={activeSection === SUBJECT.LOAD_INFORMATION_ID ? 'active' : ''}
          >
            <Row justify="space-between" align="top" gutter={[10, 30]}>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.LOAD_TYPE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={TransportCapabilityTypes[data?.information?.transportCapability as number]}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.refrigeratorCelsius && (
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REFRIGERATOR_CELSIUS}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={data?.information?.refrigeratorCelsius}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.TAKEN_AS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={TakenType[data?.information?.takenType as number]}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.GIVEN_AS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={TakenType[data?.information?.givenType as number]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.COMMODITY} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={data?.information?.commodity}
                      subject={`${SUBJECT.CUSTOMER}${SUBJECT.COMMODITY}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.BOOKED_AS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={BookedTypes[String(data?.information?.bookedType)]}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.SOLD_AS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      className={styles.infoParagraph}
                      children={BookedTypes[String(data?.information?.soldType)]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row gutter={10} align="middle">
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.WEIGHT} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={`${data?.information?.weight} LBS`}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              {!!data?.information?.feetOfPartial && (
                <Col span={10}>
                  <Row gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.FEET_OF_PARTIAL} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={data?.information?.feetOfPartial}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
          {points?.count && (
            <ViewItemWrapper
              title={SUBJECT.PICKUPS_AND_DROP_OFFS}
              id={SUBJECT.VIEW_PICKUPS_DROPOFFS_ID}
              className={activeSection === SUBJECT.VIEW_PICKUPS_DROPOFFS_ID ? 'active' : ''}
            >
              <div className={styles.pickupDropOffMainContainer}>
                <Row gutter={[0, 28]}>
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.PICKUPS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(
                        points?.result?.pickup,
                        true,
                        serializedStates,
                        userInfo?.username
                      )}
                      isNotEditable={true}
                      isPickupDropoffs
                      withoutActions
                    />
                  </Col>
                  <Col span={24}>
                    <DataGrid
                      title={SUBJECT.DROP_OFFS}
                      type="pickup_dropOffs"
                      data={pickupsDropOffsSerializer(
                        points?.result?.dropoffs,
                        true,
                        serializedStates,
                        userInfo?.username
                      )}
                      isNotEditable={true}
                      isPickupDropoffs
                      withoutActions
                    />
                  </Col>
                </Row>
              </div>
            </ViewItemWrapper>
          )}
          {data?.agents[1]?.user?.id && (
            <ViewItemWrapper
              title="Second Agent"
              id={SUBJECT.VIEW_SECOND_AGENT_ID}
              className={activeSection === SUBJECT.VIEW_SECOND_AGENT_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.SECOND_AGENCY} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.agents[1]?.office?.name}
                        subject={SUBJECT.SECOND_AGENCY}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.SECOND_AGENCY_ID}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'secondAgency',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.agents[1]?.office?.id}
                          subject={SUBJECT.SECOND_AGENCY_ID}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.agents[1]?.office?.id), 'secondAgency')}
                        >
                          <CopyIcon active={copiedText === 'secondAgency'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.SECOND_BRANCH} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.agents[1]?.branch?.name}
                        subject={SUBJECT.SECOND_BRANCH}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.SECOND_BRANCH_ID}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'secondBranch',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.agents[1]?.branch?.id}
                          subject={SUBJECT.SECOND_BRANCH_ID}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.agents[1]?.branch?.id), 'secondBranch')}
                        >
                          <CopyIcon active={copiedText === 'secondBranch'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.SECOND_AGENT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={`${data?.agents[1]?.user?.firstName} ${data?.agents[1]?.user?.lastName}`}
                        subject={SUBJECT.SECOND_AGENT}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.SECOND_AGENT_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'secondAgent',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.agents[1]?.user?.id}
                          subject={SUBJECT.SECOND_AGENT_ID}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.agents[1]?.user?.id), 'secondAgent')}
                        >
                          <CopyIcon active={copiedText === 'secondAgent'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.SECOND_AGENT_AMOUNT}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.agents[1]?.amount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
          {data?.load?.customer?.id && (
            <ViewItemWrapper
              title={SUBJECT.CUSTOMER}
              id={SUBJECT.VIEW_CUSTOMER_ID}
              className={activeSection === SUBJECT.VIEW_CUSTOMER_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.customer?.name}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.REFERENCE_NUMBER}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'referenceNumber',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.referenceNumber}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.REFERENCE_NUMBER}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.referenceNumber), 'referenceNumber')}
                        >
                          <CopyIcon active={copiedText === 'referenceNumber'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_AMOUNT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.load?.customerAmount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.CUSTOMER_CONTACT}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.customerContact}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_CONTACT}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CUSTOMER_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'customerId',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.customer?.id}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.CUSTOMER_ID}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.customer?.id), 'customerId')}
                        >
                          <CopyIcon active={copiedText === 'customerId'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.TAKEN_AS_MC} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={`${data?.load?.takenAsAuthority?.name} / MC ${data?.load?.takenAsAuthority?.mc} / ID ${data?.load?.takenAsAuthority?.id}`}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.TAKEN_AS_MC}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ViewItemWrapper>
          )}
          {files?.result?.RED_CONFIRMATION &&
          (!isSecondAgent || hasAccessToRateConf || UserTypes.ADMIN === userType) ? (
            <div className={styles.documentationWrapper}>
              <ViewItemWrapper
                title="Customer Rate Confirmation"
                id={SUBJECT.RATE_CONFIRMATION_ID}
                className={activeSection === SUBJECT.RATE_CONFIRMATION_ID ? 'active' : ''}
              >
                {files?.result?.RED_CONFIRMATION?.map(fileItem => (
                  <RedConfimrationViewFiles
                    fileItem={fileItem}
                    handleViewFile={handleViewFile}
                    handleDownloadFileClick={handleDownloadFileClick}
                  />
                ))}
              </ViewItemWrapper>
            </div>
          ) : null}
          {data?.load?.carrier?.id && (
            <ViewItemWrapper
              title={SUBJECT.CARRIER}
              id={SUBJECT.VIEW_CARRIER_ID}
              className={activeSection === SUBJECT.VIEW_CARRIER_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_NAME} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={data?.load?.carrier?.name}
                        subject={`${SUBJECT.CUSTOMER}${SUBJECT.CARRIER_NAME}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_AMOUNT} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={`${formatNumberWithThousandsSeparatorAndDecimal(data?.load?.carrierAmount, true)} `}
                      />
                    </Col>
                  </Row>
                </Col>
                {data?.load?.truck?.truckNumber && (
                  <Col span={10}>
                    <Row gutter={10} align="middle">
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.TRUCK} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.truck.truckNumber}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.TRUCK}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.CARRIER_ID} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'carrierId',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.carrier?.id}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.CARRIER_ID}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.carrier?.id), 'carrierId')}
                        >
                          <CopyIcon active={copiedText === 'carrierId'} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {data?.load?.soldAsAuthority?.id && (
                  <Col span={10}>
                    <Row gutter={10} align="middle">
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.SOLD_AS} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={`${data?.load?.soldAsAuthority?.name} / MC ${data?.load?.soldAsAuthority?.mc} / ID ${data?.load?.soldAsAuthority?.id}`}
                          subject={`${SUBJECT.CUSTOMER}${SUBJECT.SOLD_AS}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </ViewItemWrapper>
          )}
          {rateConfirmations?.result?.length && !isFirstAgent ? (
            <div className={styles.documentationWrapper}>
              <ViewItemWrapper
                title="Carrier Rate Confirmation"
                id={SUBJECT.CARRIER_RATE_CONFIRMATION_ID}
                className={activeSection === SUBJECT.CARRIER_RATE_CONFIRMATION_ID ? 'active' : ''}
              >
                {rateConfirmations?.result?.map((fileItem: any, index: number) => (
                  <AutoRateConfirmation
                    fileItem={fileItem}
                    handleViewFile={handleViewFile}
                    handleDownloadFileClick={handleDownloadFileClick}
                    handleSendRateConfirmation={handleSendRateConfirmation}
                    rateConfirmations={rateConfirmations}
                    index={index}
                  />
                ))}
                {!!createRateConfCheck && (
                  <Typography
                    onClick={handleCreateRateConfirmation}
                    variant="paragraph"
                    children={SUBJECT.CREATE_RATE}
                    className={styles.newCarrierRateBtn}
                  />
                )}
              </ViewItemWrapper>
            </div>
          ) : null}
          {data?.load?.carrierAccess && !!breakdowns?.result?.length && (
            <ViewItemWrapper
              title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
              id={SUBJECT.VIEW_BREAKDOWN_ID}
              className={activeSection === SUBJECT.VIEW_BREAKDOWN_ID ? 'active' : ''}
            >
              <CarrierPaymentBreakdown
                data={{
                  result: breakdowns?.result?.map((item: any) => {
                    return {
                      download: item?.file?.download,
                      id: item?.id,
                      name: item?.file?.name,
                      createdAt: formatDate(item?.createdAt),
                      preview: item?.file?.preview,
                    };
                  }),
                  count: breakdowns?.count,
                }}
                skip={breakdownParams.skip}
                limit={breakdownParams.limit}
                isLoading={isBreakdownLoading}
                onPaginationClick={handleCarrierBreakdownPaginationClick}
                onSort={handleBreakdownSortClick}
                withPagination={true}
              />
            </ViewItemWrapper>
          )}

          {!!filesWithoutRedConf?.length && (
            <ViewItemWrapper
              title="Documents"
              id={SUBJECT.CONFIRMATION_DOCUMENTATION_ID}
              className={activeSection === SUBJECT.CONFIRMATION_DOCUMENTATION_ID ? 'active' : ''}
            >
              <Row gutter={[0, 20]}>
                {filesWithoutRedConf?.map(item => (
                  <>
                    <Col span={24} key={item.id}>
                      <Row
                        align="middle"
                        gutter={10}
                        className={clsx(styles.fileItemView, {
                          [styles.blurred]: item?.blurred,
                        })}
                      >
                        <Col span={9}>
                          <Typography
                            variant="heading"
                            children={FileTypesText[item?.type as keyof typeof FileTypesText] ?? item?.type}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={8}>
                          <Typography
                            variant="heading"
                            children={item?.mailable ? 'Send E-Mail' : 'Don’t E-Mail'}
                            className={styles.infoParagraph}
                          />
                        </Col>
                        <Col span={1}>
                          <Popover
                            overlayClassName={styles.popoverOverlay}
                            placement="top"
                            overlayStyle={{
                              width: 480,
                              paddingRight: 10,
                            }}
                            content={
                              <>
                                <Row gutter={[20, 24]}>
                                  <Col span={7}>
                                    <Typography
                                      variant="heading"
                                      children={SUBJECT.ADDED_BY}
                                      className={styles.infoHeading}
                                    />
                                  </Col>
                                  <Col span={17}>
                                    <Typography
                                      variant="paragraph"
                                      className={styles.infoParagraph}
                                      children={`${item?.creator?.username}`}
                                    />
                                  </Col>
                                  <Col span={7}>
                                    <Typography
                                      variant="heading"
                                      children={SUBJECT.DATE}
                                      className={styles.infoHeading}
                                    />
                                  </Col>
                                  <Col span={17}>
                                    <Typography
                                      variant="paragraph"
                                      className={styles.infoParagraph}
                                      children={formatDate(item?.updatedAt)}
                                    />
                                  </Col>
                                </Row>
                              </>
                            }
                          >
                            <span className={styles.infoIcon}>
                              <BlueInfoIcon />
                            </span>
                          </Popover>
                        </Col>
                        <Col role="button" onClick={() => multiplyViewWithDelay(filesFormatter([item]))}>
                          <EyeIconSvg outlined={true} />
                        </Col>
                        <Col role="button">
                          <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesFormatter([item]))} />
                        </Col>
                      </Row>
                    </Col>
                  </>
                ))}
              </Row>
            </ViewItemWrapper>
          )}
          {roleLevel === IRoleLevel.corpLevel && data?.load?.status !== LoadGlobalStatus.LOAD_STATUS_NOT_PROCESSING && (
            <ViewItemWrapper
              title="Billing"
              id={SUBJECT.BILLING_ID}
              className={activeSection === SUBJECT.BILLING_ID ? 'active' : ''}
            >
              <Row justify="space-between" align="top" gutter={[10, 30]}>
                <Col span={10}>
                  <Row gutter={10} align="middle">
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.FACTORED_YES_NO} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={
                          data?.load?.paymentTermType === PaymentTermType.BILLING_TYPES_FACTORING
                            ? SUBJECT.CUSTOMER_IS_FACTORED
                            : SUBJECT.CUSTOMER_IS_NOT_FACTORED
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                {data?.load?.noa?.length &&
                  data?.load?.noa?.map((item: any) => {
                    return (
                      <Col span={10} key={item.id}>
                        <Row gutter={10} align="middle">
                          <Col span={11}>
                            <Typography variant="heading" children="NOA" className={styles.infoHeading} />
                          </Col>
                          <Col role="button" onClick={() => multiplyViewWithDelay(filesNoaFormatter([item]))}>
                            <EyeIconSvg outlined={true} />
                          </Col>
                          <Col role="button">
                            <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesNoaFormatter([item]))} />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                {mailableFiles?.length &&
                  mailableFiles?.map((item: any) => {
                    return (
                      <Col span={10}>
                        <Row gutter={10} align="middle">
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={item.type?.charAt(0)?.toUpperCase() + item.type?.slice(1)?.toLowerCase()}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col role="button" onClick={() => multiplyViewWithDelay(filesFormatter([item]))}>
                            <EyeIconSvg outlined={true} />
                          </Col>
                          <Col role="button">
                            <NoaDownloadIcon onClick={() => multiplyDownloadWithDelay(filesFormatter([item]))} />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                {files?.result?.RED_CONFIRMATION && (
                  <Col span={10}>
                    <Row gutter={10} align="middle">
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children="Customer Rate Confirmation"
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col
                        role="button"
                        onClick={() => multiplyViewWithDelay(filesFormatter(files?.result?.RED_CONFIRMATION))}
                      >
                        <EyeIconSvg outlined={true} />
                      </Col>
                      <Col role="button">
                        <NoaDownloadIcon
                          onClick={() => multiplyDownloadWithDelay(filesFormatter(files?.result?.RED_CONFIRMATION))}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </ViewItemWrapper>
          )}
          {!!(comments?.count || (loadHistory?.result?.length && hasAccessToViewHistory)) && (
            <div ref={drawerRef}>
              <CustomComment
                activeSection={activeSection}
                id="comment"
                watch={watch}
                name="comment"
                setValue={setValue}
                control={control}
                inView
                canCreate={false}
                canDelete={false}
                comments={comments}
                getMoreComments={getMoreComments}
                isShowMoreVisible={isShowMoreVisible}
                loadHistory={loadHistory}
                withNotes={loadHistory?.result?.length}
                drawerRef={drawerRef}
                isShowMoreVisibleNotes={isShowMoreVisibleNotes}
                getMoreNotes={getMoreNotes}
                hasDeleteCommentPermission={loadsPagePermissions.deleteComment}
                isDefaultHistory={isDefaultHistory}
                setIsDefaultHistory={setIsDefaultHistory}
                isLoadsCommentsLoading={isLoadsCommentsLoading}
                isFetchingHistory={isFetchingHistory}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectingLoadView;
