import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

export const validation = ({ collection }: { collection: number }) => {
  return yup.object().shape({
    amount: yup
      .string()
      .typeError(VALIDATION_MESSAGES.VALID_NUMBER)
      .nullable()
      .test('isRequired', VALIDATION_MESSAGES.AMOUNT_REQUIRED, function checkLength(value) {
        if (value && value <= '0') {
          return this.createError({
            message: VALIDATION_MESSAGES.NOT_ZERO,
            path: 'amount',
          });
        } else if (Number(value) > Math.abs(collection)) {
          return this.createError({
            message: VALIDATION_MESSAGES.AMOUNT_LESS_THAN_COLLECTION,
            path: 'amount',
          });
        }
        return !!value;
      }),
    comment: yup.string().required(VALIDATION_MESSAGES.COMMENT_ID_REQUIRED),
  });
};
