import { Col, Row } from 'antd';
import clsx from 'clsx';
import { AlignType } from 'rc-table/lib/interface';
import Typography from 'ui/typography/Typography';

import { EditPenIcon } from 'components/svgs/EditPenIcon';
import { TrashIcon } from 'components/svgs/TrashIcon';

import styles from '../WhiteListDataGrid.module.scss';

export const getWhiteListColumns = ({ handleRowDelete, handleRowEdit, setEditingRow, withoutAction }: any) => {
  const actionRender = (_: string, record: any) => (
    <div className={styles.actionBlock}>
      <div
        onClick={() => {
          setEditingRow(record.id);
          handleRowEdit(record);
        }}
        className={styles.trashIcon}
      >
        <EditPenIcon />
      </div>
      {
        <div onClick={() => handleRowDelete!(record)} className={clsx(styles.trashIcon)}>
          <TrashIcon />
        </div>
      }
    </div>
  );

  const CURRENT_COLUMNS = [
    {
      title: 'IP address',
      dataIndex: 'ip',
      key: 'ip',
      width: 150,
      render: (record: any) => {
        return (
          <Row align="middle" gutter={10}>
            <Col>
              <Typography children={record} variant="paragraph" />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      width: 150,
      render: (record: any) => {
        return (
          <Row align="middle" gutter={10}>
            <Col>
              <Typography children={record} variant="paragraph" />
            </Col>
          </Row>
        );
      },
    },
    ...(!withoutAction
      ? [
          {
            title: 'Action',
            id: 'action',
            dataIndex: 'action',
            align: 'left' as AlignType,
            width: 55,
            render: actionRender,
          },
        ]
      : []),
  ];

  return CURRENT_COLUMNS;
};
