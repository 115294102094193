import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import { IComponent } from 'pages/profile/constants/constants';
import {
  useExportInformationMutation,
  useExportPaymentAgencyMutation,
  useExportPaymentAgentMutation,
  useExportPaymentBranchMutation,
} from 'services/exports/exports';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import Button from 'ui/button/Button';
import { AgentTypes, notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { CardNumber, SUBJECT } from './tabs/information/constants/constants';
import Information from './tabs/information/Information';
import { Cards } from './tabs/payout/constants/types';
import Payout from './tabs/payout/Payout';

const usePayroll = () => {
  const userInfo = useSelector(selectUserInfo);
  const { agentType } = userInfo;

  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { tab, card, activeTab, feeType } = searchParams;

  const payrollPermissions = usePermittedActions('payroll');

  const canDownload =
    (card &&
      tab === 'information' &&
      payrollPermissions[CardNumber[Number(card) as keyof typeof CardNumber]]?.download) ||
    (payrollPermissions?.payment?.download && tab === 'payout');

  const isButtonVisible = true;
  const isCreateButtonVisible =
    ((+card === 7 && permissionsInfo?.permissions?.payroll?.payrollOtherServiceFee?.actions?.create) ||
      (+card === 8 && permissionsInfo?.permissions?.payroll?.payrollAdvance?.actions?.create)) &&
    agentType !== AgentTypes.AGENT;
  const openMainDrawer = (mode: string) => {
    setSearchParams({ ...searchParams, mode, open: 'true' });
  };

  const [exportInformation] = useExportInformationMutation();
  const [exportPaymentAgency] = useExportPaymentAgencyMutation();
  const [exportPaymentBranch] = useExportPaymentBranchMutation();
  const [exportPaymentAgent] = useExportPaymentAgentMutation();

  const [excelParams, setExcelParams] = useState<any>();

  const downloadExcel = () => {
    const exportFunctionMap: Record<string, any> = {
      information: exportInformation,
      Agency: exportPaymentAgency,
      Branch: exportPaymentBranch,
      Agent: exportPaymentAgent,
    };

    const exportFunction = tab === 'payout' ? exportFunctionMap?.[activeTab || 'Agency'] : exportFunctionMap[tab];

    exportFunction(excelParams).then((data: any) => {
      if (data?.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.CHECK_EMAIL,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const Component: IComponent = {
    information: <Information setExcelParams={setExcelParams} />,
    payout: <Payout setExcelParams={setExcelParams} />,
  };

  const panelButton = {
    [Cards['Other service fee']]: (
      <Button onClick={() => openMainDrawer('charge')} children={SUBJECT.ADD_CHARGE} className="panelAddButton" />
    ),
    [Cards.Advance]: (
      <Button onClick={() => openMainDrawer('advance')} children={SUBJECT.ADD_ADVANCE} className="panelAddButton" />
    ),
  };

  return {
    tab,
    card,
    Component,
    canDownload,
    panelButton,
    isButtonVisible,
    permissionsInfo,
    isCreateButtonVisible,
    downloadExcel,
    openMainDrawer,
    feeType,
  };
};
export default usePayroll;
