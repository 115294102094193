import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const { IP_ADDRESS_REQUIRED, COMMENTS_REQUIRED } = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  ip: yup
    .string()
    .matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/, 'Please enter a valid IP')
    .required(IP_ADDRESS_REQUIRED),
  comment: yup.string().required(COMMENTS_REQUIRED),
});
