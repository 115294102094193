export enum SUBJECT {
  REGULAR_LOAD = 'Regular Load',
  ADDITIONAL = 'Additional',
  UPLOADED_RED_CONFIRMATION = 'Uploaded Rate Confirmation',
  UPLOADED_POD = 'Uploaded POD',
  TAKEN_AS_MC = 'Taken As MC',
  LOAD_ID = 'Load ID',
  LOAD_STATUS = 'Load Status',
  OFFICE = 'Agency',
  OFFICE_ID = 'Agency ID',
  BRANCH = 'Branch',
  BRANCH_ID = 'Branch ID',
  AGENT = 'Agent',
  AGENT_ID = 'Agent ID',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_ID = 'Customer ID',
  PICKUPS = 'Pick Ups',
  DROP_OFFS = 'Drop Offs',
  CARRIER_NAME = 'Carrier Name',
  CARRIER_ID = 'Carrier ID',
  LOAD_TYPE = 'Load Type',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CUSTOMER_CONTACT = 'Phone Number',
  CARRIER_AMOUNT = 'Final Payable',
  TRUCK = 'Truck',
  ALL_AUTHORITIES = 'All Authorities',
  CREATE_RATE = 'Create New Carrier Rate Confirmation',
  CARRIER = 'Carrier Detail',
  REFRIGERATOR_CELSIUS = 'Refrigerator Fahrenheit',
  CARRIER_RATE = 'Carrier Rate',
  PROFIT = 'Profit',
  SOLID_AS_MC = 'Solid as MC',
  FACTORED = 'Factored',
  CUSTOMER_DEBT = 'Customer Debt',
  CUSTOMER_INVOICE_PAYMENT_AMOUNT = 'Customer Invoice Payment Amount',
  CUSTOMER_INVOICE = 'Customer Invoice',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  COMMENTS = 'Comments',
  EDIT = 'Edit',
  DOCUMENTATION = 'Documents',
  QUICK_PAY = 'Quick Pay',
  LOAD_FINANCE = 'Load Finance',
  CUSTOMER_BALANCE = 'Balance',
  INITIAL_RATE_CUSTOMER = 'Initial Rate',
  FINAL_RECEIVABLE_CUSTOMER = 'Final Receivable',
  AMOUNT_PAID_CUSTOMER = 'Amount Received',
  CARRIER_BALANCE = 'Balance',
  INITIAL_RATE_CARRIER = 'Initial Rate',
  FINAL_PAYABLE_CARRIER = 'Final Payable',
  AMOUNT_PAID_CARRIER = 'Amount Paid',
  AGENT_BALANCE = 'Initial Profit',
  FINAL_PROFIT = 'Final Profit',
  TRANSACTION = 'Transaction',
  TAKEN_AS = 'Taken As',
  LOAD_INFORMATION = 'Load Detail',
  GIVEN_AS = 'Given As',
  COMMODITY = 'Commodity',
  BOOKED_AS = 'Booked As',
  SOLD_AS = 'Sold As',

  FEET_OF_PARTIAL = 'Feet Of Partial',
  WEIGHT = 'Weight',
  PICKUPS_AND_DROP_OFFS = 'Pick Ups & Drop Offs',
  ADDED_BY = 'Added By',
  DATE = 'Date',
  DATE_AND_TIME = 'Date And Time',
  USERNAME = 'Username',
  CUST_AMOUNT = 'Customer Amount',
  BALANCE_AMOUNT = 'Balance Amount',
  COMMENT = 'Comment',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP_CODE = 'ZIP Code',
  FACILITY = 'Facility',
  STATE = 'State',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  YES = 'Yes',
  NO = 'No',
  CANCEL = 'Cancel',
  GENERAL_INFO = 'General Info',
  CONFIRMATION_TYPE = 'Confirmation Type',
  HISTORY = 'History',
  FACTORED_YES_NO = 'Factored Yes/No',
  CUSTOMER_IS_FACTORED = 'Customer Is Factored',
  CUSTOMER_IS_NOT_FACTORED = 'Customer Is Not Factored',
  LOAD_TRANSACTION = 'Transactions',
  CUSTOMER = 'Customer Detail',
  RED_CONFIRMATION = 'RED_CONFIRMATION',
  NOA = 'NOA',
  VIEW_CUSTOMER_ID = 'regular-customer',
  VIEW_CARRIER_ID = 'regular-carrier',
  BILLING_ID = 'regular-billing',
  RATE_CONFIRMATION_ID = 'regular-rate-confirmation',
  CONFIRMATION_DOCUMENTATION_ID = 'regular-confirmation-documentation',
  LOAD_FINANCE_ID = 'regular-finance',
  LOAD_TRANSACTION_ID = 'regular-transaction',
  LOAD_INFORMATION_ID = 'regular-information',
  VIEW_PICKUPS_DROPOFFS_ID = 'regular-pickup-dropoffs',
  BREAKDOWN_ID = 'regular-carrier-breakdown',
  CARRIER_RATE_CONFIRMATION_ID = 'regular-bottom-carrier-confirmation',
  COMMENT_ID = 'comment',
}
