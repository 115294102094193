import { OfficeSetUpTypes } from 'services/profile/offices/interfaces';
import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';

const {
  NAME_REQUIRED,
  ZIP_REQUIRED,
  PAYOUT_REQUIRED,
  TYPE_REQUIRED,
  OFFICE_LOCATION_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  CREDIT_CHECK_REQUIRED,
  STATE_ID_REQUIRED,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  AUTHORITIES_REQUIRED,
  MAX_MIN_ZIP_LENGTH,
  FEE_TYPE_REQUIRED,
  FEE_AMOUNT_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  officeLocation: yup.string(),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
  city: yup.string().required(CITY_REQUIRED),
  stateId: yup.string().required(STATE_ID_REQUIRED),
  zipcode: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
  payout: yup.string().required(PAYOUT_REQUIRED),
  authorities: yup.mixed().test('length', AUTHORITIES_REQUIRED, function checkLength(arr = []) {
    return Array.isArray(arr) && arr.length > 0;
  }),
  bankName: yup.string(),
  accounting_number: yup.string(),
  ach_routing_number: yup.string(),

  type: yup.string().required(TYPE_REQUIRED),
  creditCheck: yup.string().test('length', CREDIT_CHECK_REQUIRED, function checkLength(value) {
    if (!this.parent.type || this.parent.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE)) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  billing: yup.string().test('required', 'Billing is a required field', function checkIsRequired(value) {
    if (!this.parent.type || this.parent.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE)) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  payables: yup.string().test('required', 'Payables is a required field', function checkIsRequired(value) {
    if (!this.parent.type || this.parent.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE)) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  receivables: yup.string().test('required', 'Receivables is a required field', function checkIsRequired(value) {
    if (!this.parent.type || this.parent.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE)) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  creditCheckPayType: yup.string().test('required', 'Pay Type is a required field', function checkIsRequired(value) {
    return true;
  }),
  creditCheckPayAmount: yup.string().test('required', 'Amount is a required field', function checkIsRequired(value) {
    if (!this.parent.creditCheck) return true;
    if (+this.parent.creditCheck === 2) {
      return true;
    } else {
      return !!value?.length;
    }
  }),
  feeType: yup.string().required(FEE_TYPE_REQUIRED),
  fee: yup.string().test('required', FEE_AMOUNT_REQUIRED, function checkIsRequired(value) {
    if (this.parent.feeType) {
      return !!value;
    }
    return true;
  }),
  hasValidAccounting: yup
    .boolean()
    .test(
      'required',
      'You need to give at least one permission for view or action to agency',
      function checkIsRequired() {
        if (this.parent.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE)) {
          return true;
        } else {
          if (!this.parent.billing && !this.parent.payables && !this.parent.receivables) {
            return true;
          } else {
            return this.parent.billing !== '1' || this.parent.payables !== '1' || this.parent.receivables !== '1';
          }
        }
      }
    ),
});
