/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Divider, Popover, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import LastLoadsTable from 'pages/truck-board/components/view-edit-carrier-truck/components/last-loads-table/LastLoadsTable';
import { EquipmentTypes } from 'services/carriersOnBoard/interface';
import { CarrierFiles, ICarrierType, RatingAccessTypes } from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import Checkbox from 'ui/inputs/checkbox/Checkbox';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Rate from 'ui/rate/Rate';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import Typography from 'ui/typography/Typography';
import { EmployeeLevels, UserTypes } from 'utils/constants';
import { formatDate, formatDateBirth } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import CarrierPaymentBreakdown from 'components/carrier-payment-breakdown/CarrierPaymentBreakdown';
import { ICarrierPaymentBreakDownData } from 'components/carrier-payment-breakdown/constants/types';
import CustomComment from 'components/custom-comment/CustomComment';
import Favorite from 'components/favorite/Favorite';
import { BlueInfoIcon } from 'components/svgs/BlueInfoIcon';
import CopyIcon from 'components/svgs/CopyIcon';
import DownloadIcon from 'components/svgs/DownloadIcon';
import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import PaymentTerms from '../../paymentTerms/PaymentTerms';
import { CARRIER_SECTIONS_VIEW } from '../../tabs/carriers-table/constants/constants';
import { FileTypes } from '../edit-carrier/constants/types';

import { SUBJECT } from './constants/constants';
import useCarrierRowInfo from './useCarrierRowInfo';

import styles from './CarrierRowInfo.module.scss';

const CarrierRowInfo = ({
  carrierInfo,
  trucksByCarrierId,
  isFetchingCarrier,
  carrierRequestInfo,
  getMoreComments,
  carrierLastLoads,
  handleCarrierLasLoadsPaginationClick,
  handleCarrierBreakdownPaginationClick,
  handleBreakdownSortClick,
  isCarrierLastLoadsLoading,
  comments,
  breakdowns,
  isShowMoreVisible,
  isBreakdownLoading,
  breakdownParams,
  handleTruckPaginationClick,
  setBreakdownParams,
  pagePermissions: { edit, terminateOn, terminateOff, deleteComment },
  isCommentFetching,
  refetchTrucksById,
}: any) => {
  const {
    handleSwitchClick,
    handleChangeMode,
    handleCommentClick,
    handleDownloadFile,
    handleViewFile,
    userType,
    employeeLevel,
    handleCarrierRate,
    getCarrierRatings,
    rateList,
    handleDeleteRate,
    control,
    watch,
    setValue,
    hasAccessToView,
    handleDownloadAll,
    activeSection,
    width,
    isFavorite,
    handleDeleteFavorite,
    handleCreateFavorite,
    equipmentType,
  } = useCarrierRowInfo({ carrierInfo, setBreakdownParams, refetchTrucksById });
  const { copiedText, handleCopy } = useHandleCopyActive();
  const isCheckedTerminatedSwitch = !carrierInfo?.terminatedAt || false;

  const hasPermissionToView =
    UserTypes.ADMIN === userType || (EmployeeLevels.CORPORATE === employeeLevel && hasAccessToView);
  return (
    <div
      className={clsx({
        ['scrollBarWrapper']: width === 'full',
      })}
    >
      {width === 'full' && <ScrollBar sections={CARRIER_SECTIONS_VIEW} />}
      <div
        className={clsx('scrollBarContent', {
          ['openedDrawer']: width === 'full',
        })}
      >
        <div className={styles.viewMain}>
          <MainLoader isFetching={isFetchingCarrier} />
          <div className={styles.carrierInfoSection}>
            <ViewItemWrapper
              title={SUBJECT.CARRIER_INFO}
              id={SUBJECT.CARRIER_INFO_ID}
              className={activeSection === SUBJECT.CARRIER_INFO_ID ? 'active' : ''}
              Component={
                edit && <Button onClick={handleChangeMode} children={SUBJECT.EDIT} className="viewDrawerEditBtn" />
              }
            >
              <Row gutter={[33, 0]} align="middle">
                <Col span={8}>
                  <div className={styles.topbannerPairs}>
                    <span className="top-banner-title">{SUBJECT.COMPANY_NAME_COLON}</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={carrierInfo?.companyName || carrierRequestInfo?.companyName}
                      subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.COMPANY_NAME_COLON}`}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.topbannerPairs}>
                    <span className="top-banner-title">{SUBJECT.CARRIER_ID_COLON}</span>{' '}
                    <div
                      className={clsx(styles.copyIconText, {
                        [styles.copyActive]: copiedText === 'topBannerCarrierId',
                      })}
                    >
                      <Typography
                        variant="paragraph"
                        children={carrierInfo?.id || carrierRequestInfo?.id}
                        className={styles.infoParagraph}
                      />
                      <div
                        className={styles.copyIcon}
                        role="button"
                        onClick={() => handleCopy(carrierInfo?.id || carrierRequestInfo?.id, 'topBannerCarrierId')}
                      >
                        <CopyIcon active={copiedText === 'topBannerCarrierId'} />
                      </div>
                    </div>
                  </div>
                </Col>
                {(carrierInfo?.mc || carrierRequestInfo?.mc) && (
                  <Col span={8}>
                    <div className={styles.topbannerPairs}>
                      <span className="top-banner-title">{SUBJECT.MC_COLON}</span>{' '}
                      <TooltipText
                        prefixClass="top-banner-value"
                        data={carrierInfo?.mc || carrierRequestInfo?.mc}
                        subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.MC_COLON}`}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
                <Col span={8}>
                  <div className={styles.topbannerPairs}>
                    <span className="top-banner-title">{SUBJECT.CONTACT_NAME_COLON}</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={carrierInfo?.contactName || carrierRequestInfo?.contactName}
                      subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.CONTACT_NAME_COLON}`}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.CARRIER_TYPE_COLON}</span>{' '}
                  <span className="top-banner-value">
                    {ICarrierType[carrierInfo?.type] || carrierRequestInfo?.type}
                  </span>
                </Col>
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.PHONE_NUMBER_COLON} </span>
                  <Link className="top-banner-value" to={`tel:${carrierInfo?.phone || carrierRequestInfo?.phone}`}>
                    {formatPhone(carrierInfo?.phone || carrierRequestInfo?.phone)}
                  </Link>
                </Col>
              </Row>
              <Row gutter={[33, 0]} className={styles.columnsGap} align="middle">
                {carrierInfo?.fax && (
                  <Col span={8}>
                    <div className={styles.topbannerPairs}>
                      <span className="top-banner-title">{SUBJECT.FAX_COLON}</span>
                      <TooltipText
                        prefixClass="top-banner-value"
                        data={carrierInfo?.fax}
                        subject={`${SUBJECT.CARRIER_INFO}${SUBJECT.FAX_COLON}`}
                      />
                    </div>
                  </Col>
                )}
                {userType === UserTypes.AGENT && (
                  <Col span={8}>
                    <div className={styles.topbannerPairs}>
                      <span className="top-banner-title">{SUBJECT.FAVORITE}:</span>
                      <span className="top-banner-value">
                        <Favorite
                          selected={isFavorite}
                          onFavoriteClick={isFavorite ? handleDeleteFavorite : handleCreateFavorite}
                        />
                      </span>
                    </div>
                  </Col>
                )}
                <Col span={8}>
                  <span className="top-banner-title">{SUBJECT.RATING_COLON}</span>
                  <Rate
                    canRate={carrierInfo?.accessRating}
                    averageValue={carrierInfo?.rating}
                    handleRate={handleCarrierRate}
                    getRatings={getCarrierRatings}
                    isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
                    rateCount={carrierInfo?.ratingCount}
                    rateList={rateList}
                    handleDeleteRate={handleDeleteRate}
                  />
                </Col>
              </Row>
              {hasPermissionToView && carrierInfo?.secondPhone && (
                <Row className={styles.columnsGap} align="middle">
                  <Col span={24}>
                    <span className="top-banner-title">{SUBJECT.SECOND_NUMBER_COLON}</span>
                    <Link className="top-banner-value" to={`tel:${carrierInfo?.secondPhone}`}>
                      {' '}
                      {formatPhone(carrierInfo?.secondPhone)}
                    </Link>
                  </Col>
                </Row>
              )}
              {/* TODO Add after creating new payment term type */}

              {/* {hasPermissionToView && (
                <>
                  <Divider className={styles.divider} />
                  <Row gutter={[33, 0]} align="middle">
                    <Col span={8}>
                      <span className="top-banner-title">{SUBJECT.PAYMENT_COLON} </span>
                      <span className="top-banner-value">{IPaymentType[carrierInfo?.paymentTermType]}</span>
                    </Col>
                    <Col span={8}>
                      <span className="top-banner-title">{SUBJECT.DAY_COLON}</span>{' '}
                      <span className="top-banner-value">
                        {carrierInfo?.paymentTermType === 1
                          ? '3-5 days'
                          : carrierInfo?.paymentTermType === 2
                          ? '30 days'
                          : ''}
                      </span>
                    </Col>
                    <Col span={8}>
                      <span className="top-banner-title">{SUBJECT.PERCENT_COLON} </span>
                      <span className="top-banner-value">
                        {carrierInfo?.paymentTermType === 1 ? '3.5' : carrierInfo?.paymentTermType === 2 ? '0' : ''}%
                      </span>
                    </Col>
                  </Row>
                </>
              )} */}
              {!!carrierInfo?.coordinator?.length && (
                <>
                  <Divider className={styles.divider} />
                  <Row className={styles.columnsGap} align="middle">
                    <Col span={24}>
                      <span className="top-banner-title">{SUBJECT.COORDINATOR_COLON} </span>
                      <span className="top-banner-value">Mikel</span>
                    </Col>
                  </Row>
                </>
              )}
              {!!carrierInfo?.cargo && (
                <>
                  <Divider className={styles.divider} />
                  <Row gutter={[33, 0]} align="middle">
                    <Col span={8}>
                      <span className="top-banner-title">{SUBJECT.INSURANCE_LIABILITY}</span>
                      <span className="top-banner-value">{formatDateBirth(carrierInfo?.liability)}</span>
                    </Col>
                    <Col span={8}>
                      <span className="top-banner-title">{SUBJECT.INSURANCE_CARGO}</span>
                      <span className="top-banner-value">{formatDateBirth(carrierInfo?.cargo)}</span>
                    </Col>
                    {!!carrierInfo?.cargoCoverageValue && (
                      <Col span={8}>
                        <span className="top-banner-title">{SUBJECT.CARGO_COVERAGE_VALUE + ':'}</span>{' '}
                        <span className="top-banner-value">
                          {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.cargoCoverageValue, true)}
                        </span>
                      </Col>
                    )}
                  </Row>
                  {(!!carrierInfo?.nonOwnedCoverage ||
                    !!carrierInfo?.trailerInterchange ||
                    carrierInfo?.reeferBreakdown) && <Divider className={styles.divider} />}
                  <Row gutter={[33, 0]} align="middle">
                    {!!carrierInfo?.nonOwnedCoverage && (
                      <Col span={8}>
                        <span className="top-banner-title">{SUBJECT.NON_OWNED_COVERAGE + ':'}</span>{' '}
                        <span className="top-banner-value">
                          {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.nonOwnedCoverage, true)}
                        </span>
                      </Col>
                    )}
                    {!!carrierInfo?.trailerInterchange && (
                      <Col span={8}>
                        <span className="top-banner-title">{SUBJECT.TRAILER_INTERCHANGE + ':'}</span>{' '}
                        <span className="top-banner-value">
                          {formatNumberWithThousandsSeparatorAndDecimal(carrierInfo?.trailerInterchange, true)}
                        </span>
                      </Col>
                    )}
                    {!!carrierInfo?.reeferBreakdown && (
                      <Col span={8}>
                        <span className="top-banner-title">{SUBJECT.REEFER_BREAKDOWN + ':'}</span>{' '}
                        <span className="top-banner-value">{SUBJECT.CAN_HAUL}</span>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </ViewItemWrapper>
          </div>
          <ViewItemWrapper
            title={SUBJECT.FULL_INFO}
            id={SUBJECT.FULL_INFO_ID}
            className={activeSection === SUBJECT.FULL_INFO_ID ? 'active' : ''}
          >
            <Row gutter={[10, 25]} align="top" justify="space-between">
              {hasPermissionToView && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.DATE} className="elementTitle" />
                    </Col>
                    <Col span={13}>
                      <Typography variant="paragraph" children={carrierInfo?.createdAt} className="elementParagraph" />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.CARRIER_ID} className="elementTitle" />
                  </Col>
                  <Col span={13}>
                    <div
                      className={clsx(styles.copyIconText, {
                        [styles.copyActive]: copiedText === 'carrierInfoId',
                      })}
                    >
                      <Typography variant="paragraph" children={carrierInfo?.id} className={styles.infoParagraph} />
                      <div
                        className={styles.copyIcon}
                        role="button"
                        onClick={() => handleCopy(carrierInfo?.id, 'carrierInfoId')}
                      >
                        <CopyIcon active={copiedText === 'carrierInfoId'} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.COMPANY_NAME} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.companyName}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.COMPANY_NAME}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Link className="top-banner-value" to={`tel:${carrierInfo?.phone}`}>
                      {formatPhone(carrierInfo?.phone)}
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.email}
                      subject={`${SUBJECT.EMAIL}`}
                    />
                  </Col>
                </Row>
              </Col>
              {carrierInfo?.secondPhone && hasPermissionToView && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.SECONDARY_PHONE_NUMBER}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <Link className="top-banner-value" to={`tel:${carrierInfo?.secondPhone}`}>
                        {formatPhone(carrierInfo?.phone)}
                      </Link>
                    </Col>
                  </Row>
                </Col>
              )}
              {carrierInfo?.fax && hasPermissionToView && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.FAX} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.fax}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.FAX}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {carrierInfo?.ff && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.FF_NUMBER} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.ff}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.FF_NUMBER}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              {carrierInfo?.stateNumber && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.STATE_NUMBER} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.stateNumber}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.STATE_NUMBER}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.CONTACT_NAME} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.contactName}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.CONTACT_NAME}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.PAYMENT_PAID_AS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.paymentPaidAs || carrierRequestInfo?.paymentPaidAs || ''}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.PAYMENT_PAID_AS}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.TYPE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography
                      variant="paragraph"
                      children={ICarrierType[carrierInfo?.type]}
                      className={styles.infoParagraph}
                    />
                  </Col>
                </Row>
              </Col>
              {carrierInfo?.mc && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.MC} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.mc}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.MC}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.DOT} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.dot}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.DOT}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.MAIL_ADDRESS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.address}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_ADDRESS}`}
                    />
                  </Col>
                </Row>
              </Col>
              {carrierInfo?.secondAddress && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography
                        variant="heading"
                        children={SUBJECT.MAIL_SECOND_ADDRESS}
                        className={styles.infoHeading}
                      />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.secondAddress}
                        subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_SECOND_ADDRESS}`}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.MAIL_CITY} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.city}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_CITY}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.MAIL_STATE} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <TooltipText
                      prefixClass={styles.infoParagraph}
                      data={carrierInfo?.state?.name}
                      subject={`${SUBJECT.FULL_INFO}${SUBJECT.MAIL_STATE}`}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.MAIL_ZIP} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Typography variant="paragraph" children={carrierInfo?.zip} className={styles.infoParagraph} />
                  </Col>
                </Row>
              </Col>
              {!!carrierInfo?.physicalCity && (
                <>
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.PHYSICAL_ADDRESS_VIEW}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={carrierInfo?.physicalAddress}
                          subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_ADDRESS_VIEW}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {carrierInfo?.physicalSecondAddress && (
                    <Col span={10}>
                      <Row gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.PHYSICAL_SECOND_ADDRESS}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.physicalSecondAddress}
                            subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_SECOND_ADDRESS}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.PHYSICAL_CITY} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={carrierInfo?.physicalCity}
                          subject={`${SUBJECT.FULL_INFO}${SUBJECT.PHYSICAL_CITY}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography
                          variant="heading"
                          children={SUBJECT.PHYSICAL_STATE}
                          className={styles.infoHeading}
                        />
                      </Col>
                      <Col span={13}>
                        <Typography
                          variant="paragraph"
                          children={carrierInfo?.physicalState?.name}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.PHYSICAL_ZIP} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <Typography
                          variant="paragraph"
                          children={carrierInfo?.physicalZip}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.COMMENT} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <div className={styles.infoCommentParagraph} onClick={handleCommentClick}>
                      <Typography variant="paragraph" children={`Comment ${carrierInfo?.commentCount}`} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.RATING} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Rate
                      canRate={carrierInfo?.accessRating}
                      averageValue={carrierInfo?.rating}
                      handleRate={handleCarrierRate}
                      getRatings={getCarrierRatings}
                      isFirstTime={carrierInfo?.accessRating === RatingAccessTypes.ADD_RATING}
                      rateCount={carrierInfo?.ratingCount}
                      rateList={rateList}
                      handleDeleteRate={handleDeleteRate}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row align="middle" gutter={10}>
                  <Col span={11}>
                    <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
                  </Col>
                  <Col span={13}>
                    <Switch
                      checked={isCheckedTerminatedSwitch}
                      onChange={handleSwitchClick}
                      disabled={
                        (isCheckedTerminatedSwitch && !terminateOff) || (!isCheckedTerminatedSwitch && !terminateOn)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              {carrierInfo?.creator?.username && (
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={`${carrierInfo?.creator?.username}`}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </ViewItemWrapper>
          <PaymentTerms activeSection={activeSection} setValue={setValue} />
          {ICarrierType['Preferred Carrier'] === carrierInfo?.type && (
            <ViewItemWrapper
              title={SUBJECT.ADDED_TRUCK}
              id={SUBJECT.ADDED_TRUCK_ID}
              className={activeSection === SUBJECT.ADDED_TRUCK_ID ? 'active' : ''}
            >
              {trucksByCarrierId?.result?.length ? (
                <DataGrid
                  data={trucksByCarrierId?.result}
                  type="addedTrucks"
                  count={trucksByCarrierId?.count}
                  handlePaginationClick={handleTruckPaginationClick}
                />
              ) : null}
              {(carrierInfo?.numberOfTrucks || carrierInfo?.numberOfTrailers) && (
                <div style={{ marginTop: '45px' }}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      {!!carrierInfo?.numberOfTrucks && (
                        <Row>
                          <Col span={24}>
                            <Row align="middle" gutter={10}>
                              <Col span={18}>
                                <Typography
                                  variant="heading"
                                  children={SUBJECT.NUMBER_OF_TRUCK}
                                  className={styles.infoHeading}
                                />
                              </Col>
                              <Col span={6}>
                                <Typography
                                  variant="paragraph"
                                  children={`${carrierInfo?.numberOfTrucks}`}
                                  className={styles.infoParagraph}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <div className={styles.col}>
                      {!!carrierInfo?.numberOfTrailers && (
                        <Row>
                          <Col span={24}>
                            <Row align="middle" gutter={10}>
                              <Col span={18}>
                                <Typography
                                  variant="heading"
                                  children={SUBJECT.NUMBER_OF_TRAILERS}
                                  className={styles.infoHeading}
                                />
                              </Col>
                              <Col span={6}>
                                <Typography
                                  variant="paragraph"
                                  children={`${carrierInfo?.numberOfTrailers}`}
                                  className={styles.infoParagraph}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                  <div className={styles.truckTypes}>
                    <label className={styles.truckType}>
                      Dry Van
                      <Checkbox size="default" checked={equipmentType.includes(EquipmentTypes.DRY_VAN)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Reefer
                      <Checkbox size="default" checked={equipmentType.includes(EquipmentTypes.REEFER)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Flat bed
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.FLAT_BED)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Box truck
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.BOX_TRUCK)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Conestoga
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.CONESTOGA)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Step deck
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.STEP_DECK)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Sprinter van
                      <Checkbox
                        size="default"
                        checked={equipmentType?.includes(EquipmentTypes.SPRINTER_VAN)}
                        disabled
                      />
                    </label>
                    <label className={styles.truckType}>
                      Curtain Van
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.CURTAIN_VAN)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Low boy
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.LOW_BOY)} disabled />
                    </label>
                    <label className={styles.truckType}>
                      Hot shot
                      <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.HOT_SHOT)} disabled />
                    </label>
                  </div>
                </div>
              )}
            </ViewItemWrapper>
          )}

          {ICarrierType['Outside'] === carrierInfo?.type &&
            (carrierInfo?.numberOfTrucks || carrierInfo?.numberOfTrailers) && (
              <ViewItemWrapper
                title={SUBJECT.ADDED_TRUCK}
                id={SUBJECT.ADDED_TRUCK_ID}
                className={activeSection === SUBJECT.ADDED_TRUCK_ID ? 'active' : ''}
              >
                <div className={styles.row}>
                  <div className={styles.col}>
                    {!!carrierInfo?.numberOfTrucks && (
                      <Row>
                        <Col span={24}>
                          <Row align="middle" gutter={10}>
                            <Col span={18}>
                              <Typography
                                variant="heading"
                                children={SUBJECT.NUMBER_OF_TRUCK}
                                className={styles.infoHeading}
                              />
                            </Col>
                            <Col span={6}>
                              <Typography
                                variant="paragraph"
                                children={`${carrierInfo?.numberOfTrucks}`}
                                className={styles.infoParagraph}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div className={styles.col}>
                    {!!carrierInfo?.numberOfTrailers && (
                      <Row>
                        <Col span={24}>
                          <Row align="middle" gutter={10}>
                            <Col span={18}>
                              <Typography
                                variant="heading"
                                children={SUBJECT.NUMBER_OF_TRAILERS}
                                className={styles.infoHeading}
                              />
                            </Col>
                            <Col span={6}>
                              <Typography
                                variant="paragraph"
                                children={`${carrierInfo?.numberOfTrailers}`}
                                className={styles.infoParagraph}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
                <div className={styles.truckTypes}>
                  <label className={styles.truckType}>
                    Dry Van
                    <Checkbox size="default" checked={equipmentType.includes(EquipmentTypes.DRY_VAN)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Reefer
                    <Checkbox size="default" checked={equipmentType.includes(EquipmentTypes.REEFER)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Flat bed
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.FLAT_BED)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Box truck
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.BOX_TRUCK)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Conestoga
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.CONESTOGA)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Step deck
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.STEP_DECK)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Sprinter van
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.SPRINTER_VAN)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Curtain Van
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.CURTAIN_VAN)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Low boy
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.LOW_BOY)} disabled />
                  </label>
                  <label className={styles.truckType}>
                    Hot shot
                    <Checkbox size="default" checked={equipmentType?.includes(EquipmentTypes.HOT_SHOT)} disabled />
                  </label>
                </div>
              </ViewItemWrapper>
            )}
          {hasPermissionToView && !!carrierInfo?.fuelCards?.length && (
            <ViewItemWrapper
              title={SUBJECT.ADDED_FUEL_CARDS}
              id={SUBJECT.ADDED_FUEL_CARDS_ID}
              className={activeSection === SUBJECT.ADDED_FUEL_CARDS_ID ? 'active' : ''}
            >
              <Row gutter={[10, 24]} justify="space-between">
                {carrierInfo?.fuelCards?.map((item: any) => {
                  return (
                    <Col span={10}>
                      <Row>
                        <Col span={8}>
                          <Typography
                            variant="heading"
                            children={item?.main ? SUBJECT.MAIN_FUEL_CARD : SUBJECT.FUEL_CARD}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={10}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={item?.card}
                            subject={`${SUBJECT.ADDED_FUEL_CARDS}${SUBJECT.FUEL_CARD}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
              </Row>
            </ViewItemWrapper>
          )}
          {/* TODO Add after creating new payment term type */}

          {/* {hasPermissionToView &&
            (carrierInfo?.factoringType === 1 ||
              carrierInfo?.paymentMethod === 2 ||
              carrierInfo?.paymentMethod === 3) && (
              <ViewItemWrapper
                title={SUBJECT.FACTORING}
                id={SUBJECT.FACTORING_ID}
                className={activeSection === SUBJECT.FACTORING_ID ? 'active' : ''}
              >
                <Row gutter={[10, 24]} justify="space-between">
                  {carrierInfo?.factoringType === 1 && (
                    <>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.FACTORED_COMPANY_NAME}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.factoredCompanyName}
                              subject={`${SUBJECT.FACTORING}${SUBJECT.FACTORED_COMPANY_NAME}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.factoredEmail}
                              subject={`${SUBJECT.FACTORING}${SUBJECT.EMAIL}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.PHONE_NUMBER}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <Link className="top-banner-value" to={`tel:${carrierInfo?.factoredPhone}`}>
                              {formatPhone(carrierInfo?.factoredPhone)}
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  {carrierInfo?.paymentMethod === 2 && (
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.FUEL_CARD} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.paymentMethodFuelCard}
                            subject={`${SUBJECT.FACTORING}${SUBJECT.FUEL_CARD}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {carrierInfo?.paymentMethod === 3 && (
                    <>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.bankName}
                              subject={`${SUBJECT.FACTORING}${SUBJECT.BANK_NAME}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.ROUTING_NUMBER}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.routingNumber}
                              subject={`${SUBJECT.FACTORING}${SUBJECT.ROUTING_NUMBER}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.CHECKING_NUMBER}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.checkingNumber}
                              subject={`${SUBJECT.FACTORING}${SUBJECT.CHECKING_NUMBER}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>
              </ViewItemWrapper>
            )} */}
          {/* TODO Add after creating new payment term type */}
          {/* {hasPermissionToView && (
            <ViewItemWrapper
              title={SUBJECT.PAYMENT_TERMS}
              id={SUBJECT.PAYMENT_TERMS_ID}
              className={activeSection === SUBJECT.PAYMENT_TERMS_ID ? 'active' : ''}
            >
              <Row gutter={[10, 25]} align="top" justify="space-between">
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.EMAIL} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.paymentTermEmail}
                        subject={`${SUBJECT.PAYMENT_TERMS}${SUBJECT.EMAIL}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.PAYMENT_TERMS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={IPaymentType[carrierInfo?.paymentTermType]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                {!!carrierInfo?.paymentTermSecondEmail && (
                  <Col span={10}>
                    <Row align="middle" gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.SECOND_EMAIL} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={carrierInfo?.paymentTermSecondEmail}
                          subject={`${SUBJECT.PAYMENT_TERMS}${SUBJECT.SECOND_EMAIL}`}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </ViewItemWrapper>
          )} */}
          {/* TODO Add after creating new payment term type */}

          {/* {hasPermissionToView && (
            <ViewItemWrapper
              title={SUBJECT.PAYMENT_METHOD}
              id={SUBJECT.PAYMENT_METHOD_ID}
              className={activeSection === SUBJECT.PAYMENT_METHOD_ID ? 'active' : ''}
            >
              <Row gutter={[10, 25]} align="top" justify="space-between">
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.PAID_AS} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <TooltipText
                        prefixClass={styles.infoParagraph}
                        data={carrierInfo?.paidAs}
                        subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.PAID_AS}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}>
                  <Row align="middle" gutter={10}>
                    <Col span={11}>
                      <Typography variant="heading" children={SUBJECT.PAYMENT_METHOD} className={styles.infoHeading} />
                    </Col>
                    <Col span={13}>
                      <Typography
                        variant="paragraph"
                        children={IPaymentMethod[carrierInfo?.paymentMethod]}
                        className={styles.infoParagraph}
                      />
                    </Col>
                  </Row>
                </Col>
                {IPaymentMethod[carrierInfo?.paymentMethod] === 'Paper check' && (
                  <>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.PAYMENT_COMPANY_NAME}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.paymentMethodCompanyName}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.PAYMENT_COMPANY_NAME}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.paymentMethodCity}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.CITY}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.paymentMethodState?.name}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.STATE}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.ZIP} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <Typography
                            variant="paragraph"
                            children={carrierInfo?.paymentMethodZip}
                            className={styles.infoParagraph}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.paymentMethodAddress}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.ADDRESS}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {!!carrierInfo?.paymentMethodSecondAddress && (
                      <Col span={10}>
                        <Row align="middle" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={SUBJECT.SECOND_ADDRESS}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col span={13}>
                            <TooltipText
                              prefixClass={styles.infoParagraph}
                              data={carrierInfo?.paymentMethodSecondAddress}
                              subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.SECOND_ADDRESS}`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </>
                )}
                {IPaymentMethod[carrierInfo?.paymentMethod] === 'Fuel card' && (
                  <Col span={10}>
                    <Row align="middle" gutter={10}>
                      <Col span={11}>
                        <Typography variant="heading" children={SUBJECT.FUEL_CARD} className={styles.infoHeading} />
                      </Col>
                      <Col span={13}>
                        <Typography
                          variant="paragraph"
                          children={carrierInfo?.paymentMethodFuelCard}
                          className={styles.infoParagraph}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
                {IPaymentMethod[carrierInfo?.paymentMethod] === 'Direct deposit' && (
                  <>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography variant="heading" children={SUBJECT.BANK_NAME} className={styles.infoHeading} />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.bankName}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.BANK_NAME}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.ACCOUNT_NUMBER}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.checkingNumber}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.ACCOUNT_NUMBER}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10}>
                      <Row align="middle" gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={SUBJECT.ROUTING_NUMBER}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col span={13}>
                          <TooltipText
                            prefixClass={styles.infoParagraph}
                            data={carrierInfo?.routingNumber}
                            subject={`${SUBJECT.PAYMENT_METHOD}${SUBJECT.ROUTING_NUMBER}`}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </ViewItemWrapper>
          )} */}

          <ViewItemWrapper
            title={SUBJECT.DOCUMENTATION}
            id={SUBJECT.DOCUMENTATION_ID}
            className={activeSection === SUBJECT.DOCUMENTATION_ID ? 'active' : ''}
          >
            <Row gutter={[10, 20]} align="top" justify="space-between">
              {carrierInfo?.filesCounts?.map((file: any) => {
                if (file?.type === FileTypes.FILE_TYPES_INSURANCE) {
                  return (
                    <Col span={10}>
                      <Row align="top" gutter={10}>
                        <Col span={11}>
                          <Typography
                            variant="heading"
                            children={CarrierFiles[file?.type]}
                            className={styles.infoHeading}
                          />
                        </Col>
                        <Col>
                          <Row align="middle" gutter={8}>
                            <Col role="button">
                              <Typography variant="paragraph" children={file?.count} className={styles.infoParagraph} />
                            </Col>
                            <Col role="button">
                              <Button variant="ghost" onClick={() => handleViewFile(file?.type)}>
                                <EyeIconSvg outlined={true} />
                              </Button>
                            </Col>
                            <Col role="button">
                              <Button variant="ghost" onClick={() => handleDownloadFile(file?.type)}>
                                <NoaDownloadIcon />
                              </Button>
                            </Col>
                            {CarrierFiles[file?.type] === SUBJECT.INSURANCE && (
                              <Col>
                                <Popover
                                  overlayClassName={styles.popoverOverlay}
                                  placement="top"
                                  overlayStyle={{
                                    width: 400,
                                  }}
                                  content={
                                    <>
                                      <Row gutter={[0, 18]} align="middle">
                                        <Col span={13}>
                                          <Typography
                                            variant="heading"
                                            children={SUBJECT.LIABILITY}
                                            className={styles.infoHeading}
                                          />
                                        </Col>
                                        <Col span={11}>
                                          <Typography
                                            variant="paragraph"
                                            children={formatDateBirth(carrierInfo?.liability)}
                                            className={styles.infoParagraph}
                                          />
                                        </Col>
                                        <Col span={13}>
                                          <Typography
                                            variant="heading"
                                            children={SUBJECT.CARGO}
                                            className={styles.infoHeading}
                                          />
                                        </Col>
                                        <Col span={11}>
                                          <Typography
                                            variant="paragraph"
                                            children={formatDateBirth(carrierInfo?.cargo)}
                                            className={styles.infoParagraph}
                                          />
                                        </Col>
                                        {(!!carrierInfo?.nonOwnedCoverage ||
                                          !!carrierInfo?.trailerInterchange ||
                                          carrierInfo?.reeferBreakdown ||
                                          !!carrierInfo?.cargoCoverageValue) && <Divider className={styles.divider} />}
                                        {!!carrierInfo?.cargoCoverageValue && (
                                          <>
                                            <Col span={13}>
                                              <Typography
                                                variant="heading"
                                                children={SUBJECT.CARGO_COVERAGE_VALUE}
                                                className={styles.infoHeading}
                                              />
                                            </Col>
                                            <Col span={11}>
                                              <Typography
                                                variant="paragraph"
                                                children={formatNumberWithThousandsSeparatorAndDecimal(
                                                  carrierInfo?.cargoCoverageValue,
                                                  true
                                                )}
                                                className={styles.infoParagraph}
                                              />
                                            </Col>
                                          </>
                                        )}
                                        {!!carrierInfo?.nonOwnedCoverage && (
                                          <>
                                            <Col span={13}>
                                              <Typography
                                                variant="heading"
                                                children={SUBJECT.NON_OWNED_COVERAGE}
                                                className={styles.infoHeading}
                                              />
                                            </Col>
                                            <Col span={11}>
                                              <Typography
                                                variant="paragraph"
                                                children={formatNumberWithThousandsSeparatorAndDecimal(
                                                  carrierInfo?.nonOwnedCoverage,
                                                  true
                                                )}
                                                className={styles.infoParagraph}
                                              />
                                            </Col>
                                          </>
                                        )}
                                        {!!carrierInfo?.trailerInterchange && (
                                          <>
                                            <Col span={13}>
                                              <Typography
                                                variant="heading"
                                                children={SUBJECT.TRAILER_INTERCHANGE}
                                                className={styles.infoHeading}
                                              />
                                            </Col>
                                            <Col span={11}>
                                              <Typography
                                                variant="paragraph"
                                                children={formatNumberWithThousandsSeparatorAndDecimal(
                                                  carrierInfo?.trailerInterchange,
                                                  true
                                                )}
                                                className={styles.infoParagraph}
                                              />
                                            </Col>
                                          </>
                                        )}
                                        {!!carrierInfo?.reeferBreakdown && (
                                          <>
                                            <Col span={13}>
                                              <Typography
                                                variant="heading"
                                                children={SUBJECT.REEFER_BREAKDOWN}
                                                className={styles.infoHeading}
                                              />
                                            </Col>
                                            <Col span={11}>
                                              <Typography
                                                variant="paragraph"
                                                children={SUBJECT.CAN_HAUL}
                                                className={styles.infoParagraph}
                                              />
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </>
                                  }
                                >
                                  <div>
                                    <BlueInfoIcon />
                                  </div>
                                </Popover>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  );
                } else {
                  if (hasPermissionToView && CarrierFiles[file?.type]) {
                    return (
                      <Col span={10}>
                        <Row align="top" gutter={10}>
                          <Col span={11}>
                            <Typography
                              variant="heading"
                              children={CarrierFiles[file?.type]}
                              className={styles.infoHeading}
                            />
                          </Col>
                          <Col>
                            <Row align="middle" gutter={8}>
                              <Col role="button">
                                <Typography
                                  variant="paragraph"
                                  children={file?.count}
                                  className={styles.infoParagraph}
                                />
                              </Col>
                              <Col role="button">
                                <Button variant="ghost" onClick={() => handleViewFile(file?.type)}>
                                  <EyeIconSvg outlined={true} />
                                </Button>
                              </Col>
                              <Col role="button">
                                <Button variant="ghost" onClick={() => handleDownloadFile(file?.type)}>
                                  <NoaDownloadIcon />
                                </Button>
                              </Col>
                              {CarrierFiles[file?.type] === SUBJECT.INSURANCE && (
                                <Col>
                                  <Popover
                                    overlayClassName={styles.popoverOverlay}
                                    placement="top"
                                    overlayStyle={{
                                      width: 250,
                                    }}
                                    content={
                                      <>
                                        <Row gutter={[0, 18]} align="middle">
                                          <Col span={13}>
                                            <Typography
                                              variant="heading"
                                              children={SUBJECT.LIABILITY}
                                              className={styles.infoHeading}
                                            />
                                          </Col>
                                          <Col span={11}>
                                            <Typography
                                              variant="paragraph"
                                              children={formatDateBirth(carrierInfo?.liability)}
                                              className={styles.infoParagraph}
                                            />
                                          </Col>
                                          <Col span={13}>
                                            <Typography
                                              variant="heading"
                                              children={SUBJECT.CARGO}
                                              className={styles.infoHeading}
                                            />
                                          </Col>
                                          <Col span={11}>
                                            <Typography
                                              variant="paragraph"
                                              children={formatDateBirth(carrierInfo?.cargo)}
                                              className={styles.infoParagraph}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                  >
                                    <div>
                                      <BlueInfoIcon />
                                    </div>
                                  </Popover>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    );
                  }
                }
              })}
            </Row>
          </ViewItemWrapper>

          {!!breakdowns?.result?.length && (
            <ViewItemWrapper
              title={SUBJECT.CARRIER_PAYMENT_BREAKDOWN}
              id={SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID}
              className={activeSection === SUBJECT.CARRIER_PAYMENT_BREAKDOWN_ID ? 'active' : ''}
              Component={
                <>
                  <Row gutter={11} align="middle">
                    <Col>
                      <Typography
                        variant="paragraph"
                        className={styles.infoParagraph}
                        children={SUBJECT.DOWNLOAD_ALL}
                      />
                    </Col>
                    <Col>
                      <div
                        className={styles.downloadAllFiles}
                        onClick={() => handleDownloadAll(breakdowns?.result?.map((file: any) => file))}
                      >
                        <DownloadIcon />
                      </div>
                    </Col>
                  </Row>
                </>
              }
            >
              <CarrierPaymentBreakdown
                data={{
                  result: breakdowns?.result?.map((item: ICarrierPaymentBreakDownData) => {
                    return {
                      download: item?.download,
                      id: item?.id,
                      name: item?.name,
                      createdAt: formatDate(item?.createdAt),
                      preview: item?.preview,
                    };
                  }),
                  count: breakdowns?.count,
                }}
                skip={breakdownParams.skip}
                limit={breakdownParams.limit}
                isLoading={isBreakdownLoading}
                onPaginationClick={handleCarrierBreakdownPaginationClick}
                onSort={handleBreakdownSortClick}
                withPagination={true}
              />
            </ViewItemWrapper>
          )}

          {!!carrierLastLoads?.count && (
            <ViewItemWrapper
              title={SUBJECT.LAST_LOADS}
              id={SUBJECT.LAST_LOADS_ID}
              className={activeSection === SUBJECT.LAST_LOADS_ID ? 'active' : ''}
            >
              <LastLoadsTable
                carrierLastLoads={carrierLastLoads}
                isLoading={isCarrierLastLoadsLoading}
                onPaginationClick={handleCarrierLasLoadsPaginationClick}
              />
            </ViewItemWrapper>
          )}

          {!!comments?.count && (
            <CustomComment
              id="comment"
              watch={watch}
              name="comment"
              setValue={setValue}
              control={control}
              canCreate={false}
              canDelete={false}
              inView
              comments={comments}
              getMoreComments={getMoreComments}
              isShowMoreVisible={isShowMoreVisible}
              hasDeleteCommentPermission={deleteComment}
              isLoadsCommentsLoading={isCommentFetching}
              activeSection={activeSection}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CarrierRowInfo;
