import { AuthorityTypes } from 'pages/profile/tabs/authorities/constants/constants';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import {
  IAuthorityByOfficeId,
  IDepthByCustomerAndAuthority,
  IDepthByCustomerAndAuthorityModified,
  OfficeSetUpTypes,
} from './interfaces';

export const getOfficesSerializer = (body: any): any => {
  const result = body?.result?.map((office: any) => {
    return {
      ...office,
      payoutPercent: `${office?.payoutPercent}%`,
      owner: office?.owners,
      state: office?.state?.name,
      phone: formatPhone(office?.phone),
      locationTypeName: office?.location ? office?.locationTypeName : '',
      type:
        office.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE
          ? 'Agency'
          : office.type === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE
          ? 'Broker'
          : '',
    };
  });
  return {
    result,
    count: body?.count,
  };
};

export const getOfficeByIdSerializer = (body: any): any => ({
  result: {
    ...body.result,
    payoutPercent: +body.result.payoutPercent,
  },
});

export const getDepthByCustomerAndAuthoritySerializer = (
  body: IDepthByCustomerAndAuthority
): IDepthByCustomerAndAuthorityModified => {
  return {
    result: {
      ...body.result,
      depth: formatNumberWithThousandsSeparatorAndDecimal(body.result?.depth),
      limit: body.result?.limit ? formatNumberWithThousandsSeparatorAndDecimal(body.result?.limit) : null,
    },
  };
};
export const getAuthoritiesByOfficeId = (body: {
  result: IAuthorityByOfficeId[];
  count: number;
}): { result: IAuthorityByOfficeId[]; count: number } => {
  const result = body?.result?.map((authority: IAuthorityByOfficeId) => {
    return {
      ...authority,
      type: AuthorityTypes[authority.type],
    };
  });
  return {
    result,
    count: body.count,
  };
};
