export enum SUBJECT {
  ADD_LOAD = 'Add Load',
  REQUEST_CREDIT = 'Request credit',
  SEND_REQUEST = 'Send Request',
  ARE_YOU_SURE_RATE = 'Are You Sure You Want To Create Carrier New Rate Confirmation?',
  ARE_YOU_SURE_RATE_SEND = 'Are You Sure You Want To Send Carrier Rate Confirmation?',
  CREATE = 'Create',
  SAVE = 'Save',
  CANCEL = 'Cancel',
  UNSAVED_CHANGES = 'You Have Usaved Changes',
  CHECK_EMAIL = 'Please Check Your Email',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  ARE_YOU_SURE_ACCEPT = 'Do You Want To Send An Invoice To The Customer?',
  NO = 'No',
  TONU = 'Tonu',
  ADD_TONU = 'Add Tonu',
  ARE_YOU_SURE = 'Are You Sure ?',
  DELETE = 'Delete',
  CLOSE = 'Close',
  STAY_HERE = 'Stay Here',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  SUCCESSFULLY_CREATED = 'Successfully Created',
  SUCCESSFULLY_DELETED = 'Successfully Deleted',
  SUCCESSFULLY_UPDATED = 'Successfully Updated',
  REQUEST_SENT_SUCCESSFULLY = 'Request Sent Successfully',
  INVOICE_SUCCESSFULLY = 'Invoice Successfully Created',
  ACCEPT = 'Accept',
  ARE_YOU_BILLING = 'Are You Sure Want To Send To Billing',
  SEND = 'Send',
  REJECT = 'Reject',
  YES = 'Yes',
  ARE_YOU_SURE_DELETE = 'Are You Sure Want To Delete Rate?',
  ACCEPTED_SUCCESSFULLY = 'Accepted Successfully',
  REJECTED_SUCCESSFULLY = 'Rejected Successfully',
  CREATE_INVOICE_IN_PROCCESS = 'Invoice Generation In Process',
  JUST_ARE_YOU_SURE = 'Are You Sure?',
  CARRIER_INVOICE = 'CARRIER_INVOICE',
}

export const LoadTypes = {
  REGULAR_LOAD: '1',
  TONU_LOAD: '2',
  CONNECTING_LOAD: '3',
  INTERNAL_BOARD_LOAD: '4',
};

export const LoadTypesFromBack: { [key: string]: string } = {
  0: 'LOAD_TYPES_UNSPECIFIED',
  1: 'Regular',
  2: 'Tonu',
  3: 'Connecting',
  4: 'Internal',
};

export const LoadTypesModes = {
  1: 'regularLoad',
  2: 'tonuLoad',
  3: 'connectingLoad',
  4: 'internalBoardLoad',
};
