import React, { useMemo } from 'react';
import Button from 'ui/button/Button';
import ExcelButton from 'ui/excel-button/ExcelButton';
import { getLoadsTabs } from 'utils/constants';

import CustomizeColumnButton from 'components/customize-column-button/CustomizeColumnButton';
import MainDrawer from 'components/main-drawer/MainDrawer';
import TabPanel from 'components/tab-panel/TabPanel';

import { SUBJECT } from './constants/constants';
import useLoads from './useLoads';

const Loads = () => {
  const {
    tab,
    openMainDrawer,
    downloadExcel,
    Component,
    isButtonVisible,
    currentChildren: { component, buttonText, onSubmit, withFooter, onCancel },
    permissionsInfo,
    userType,
    isLoading,
    loadsPagePermissions,
    userInfo,
  } = useLoads();

  const RenderCreateLoadBBtn = useMemo(() => {
    if (userInfo.outside) {
      if (userInfo.creditCheckType) {
        return (
          <Button onClick={() => openMainDrawer('add')} children={SUBJECT.REQUEST_CREDIT} className="panelAddButton" />
        );
      } else {
        return (
          <Button
            onClick={() => openMainDrawer('outsideCreate')}
            children={SUBJECT.ADD_LOAD}
            className="panelAddButton"
          />
        );
      }
    } else {
      return (
        <Button onClick={() => openMainDrawer('add')} children={SUBJECT.REQUEST_CREDIT} className="panelAddButton" />
      );
    }
  }, [userInfo]);

  return (
    <div>
      <TabPanel tabs={getLoadsTabs(permissionsInfo?.permissions?.loads?.visibleTabs)}>
        <div className="tabPanelButtons">
          <CustomizeColumnButton onClick={() => openMainDrawer('customize')} isButtonVisible={isButtonVisible} />
          {userType === 3 && (
            <>
              <Button onClick={() => openMainDrawer('tonuCreate')} children={SUBJECT.ADD_TONU} className="boldDark" />
              {RenderCreateLoadBBtn}
            </>
          )}
          {loadsPagePermissions?.download && (
            <ExcelButton
              onClick={() => {
                if (!isLoading) {
                  downloadExcel();
                }
              }}
              isButtonVisible={isButtonVisible}
            />
          )}
        </div>
      </TabPanel>
      <div>{Component[tab]}</div>
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
      />
    </div>
  );
};
export default Loads;
