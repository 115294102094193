import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { closeModal } from 'store/modal-slice/modals';
import { selectModals } from 'store/modal-slice/selector';

import { validation } from './constants/validation';

const useCollectionModal = ({ collection }: any) => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModals);
  const opened = modalData?.opened;

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    clearErrors,
    watch,
    getValues,
    reset,
    setValue,
    trigger,
  } = useForm<any>({
    defaultValues: { amount: null, comment: '' },
    mode: 'onBlur',
    resolver: yupResolver(validation({ collection })),
  });

  const handleCancel = () => {
    dispatch(closeModal());
    clearErrors();
    reset();
  };

  useEffect(() => {
    if (!opened) {
      reset();
      clearErrors();
    }
  }, [opened]);

  const isMoneyWarningGrader = useMemo(() => {
    return watch('amount') ? +watch('amount') < Math.abs(collection) : null;
  }, [watch('amount')]);

  return {
    handleSubmit,
    control,
    trigger,
    watch,
    errors,
    getValues,
    handleCancel,
    isValid,
    setValue,
    isMoneyWarningGrader,
  };
};

export default useCollectionModal;
