import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { useUploadFileNOAMutation } from 'services/profile/authorities/authorities-file';
import {
  useAddFileToCarrierMutation,
  useCreateCarrierMutation,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDeleteFileFromCarrierMutation,
  useEditCarrierMutation,
  useFillCarrierMutation,
  useGetCarrierBlackListByGroupIdQuery,
  useGetCarrierBreakdownsQuery,
  useGetCarrierLastLoadsQuery,
  useGetCarrierQuery,
  useGetCarrierRequestCarrierQuery,
  useGetCarrierRequestQuery,
  useGetCarriersQuery,
  useGetCommentsByCarrierIdQuery,
  useGetTrucksByCarrierIdQuery,
  useLazyGetFilesByCarrierIdAndTypeQuery,
} from 'services/profile/carriers/carriers';
import { ICarrierFillFileTypes, ICarrierType, IFileTypes } from 'services/profile/carriers/interfaces';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import ScrollBar from 'ui/scroll-bar/ScrollBar';
import { carrierSubTabs, notificationKey } from 'utils/constants';
import { formatDate } from 'utils/dates';
import { FILE_MAX_SIZE_ERROR, ServerErrorCodes } from 'utils/errors';
import { formatPhone, isFileAllowedToBeUpload } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import { CarrierSubTabs } from 'components/routes/routes';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { TSkipLimit } from '../../../loads/tabs/new-load/constants/types';

import BlackListInfo from './components/blacklist-info/BlackListInfo';
import CarrierInfo from './components/carrier-info/CarrierInfo';
import CarrierRequestInfo from './components/carrier-request-info/CarrierRequestInfo';
import CarrierRowInfo from './components/carrier-row-info/CarrierRowInfo';
import CreateCarrier from './components/create-carrier/CreateCarrier';
import EditCarrier from './components/edit-carrier/EditCarrier';
import { columnsTypes, defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import { ICarriersFilter, ICarriersFormData, ICarriersParams } from './constants/types';
import { validation } from './constants/validation';
import BlacklistTable from './tabs/blacklist-table/BlacklistTable';
import CarriersTable from './tabs/carriers-table/CarriersTable';
import { SECTIONS } from './tabs/carriers-table/constants/constants';
import RequestTable from './tabs/request-table/RequestTable';
import RequestTableCarrier from './tabs/request-table-carrier/RequestTableCarrier';

const useCarriers = ({ setExcelParams }: ICarriersParams) => {
  const dispatch = useDispatch();
  const [paymentTermDirty, setPaymentTermDirty] = useState(false);

  const activeSection = useSelector(selectActiveSection);
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, activePage, width, id, headerFilter, isAproveMode } = searchParams;
  const inSearchPage = window.location.pathname === '/search-result';

  const { data: statesData = [] } = useGetStatesQuery({});
  const { currentData: carrierInfoByIdAgent, isFetching: isFetchingCarrierAgentId } = useGetCarrierQuery(
    { id },
    {
      skip:
        !id ||
        mode === 'add' ||
        activePage === 'carrierOnBoard' ||
        (mode === 'view' && !id) ||
        (inSearchPage && headerFilter !== 'carriers'),
    }
  );

  const [getCommentsFilter, setCommentsFilter] = useState<any>({
    skip: 0,
    limit: 10,
  });

  const getMoreComments = () => {
    setCommentsFilter((prev: TSkipLimit) => ({
      ...prev,
      limit: prev?.limit + 10,
    }));
  };

  const { data: carrierCommentsInfo, isFetching: isCommentFetching } = useGetCommentsByCarrierIdQuery(
    { id, ...getCommentsFilter },
    {
      skip: !id || activePage === 'blacklist' || (inSearchPage && headerFilter !== 'carriers'),
    }
  );
  const { data: carrierRequestAgent, isFetching: isFetchingCarrierAgent } = useGetCarrierRequestQuery(
    { id },
    {
      skip: !id || activePage !== 'request' || (inSearchPage && headerFilter !== 'carriers'),
    }
  );

  const { data: carrierRequestCarrier, isFetching: isFetchingCarrierCarrier } = useGetCarrierRequestCarrierQuery(
    { id },
    {
      skip: !id || activePage !== 'carrierOnBoard' || (inSearchPage && headerFilter !== 'carriers'),
    }
  );

  const isCarrierOnBoard = activePage === 'carrierOnBoard';
  const carrierRequestInfo = isCarrierOnBoard ? carrierRequestCarrier?.result : carrierRequestAgent;

  const isFetchingCarrierRequest = isCarrierOnBoard ? isFetchingCarrierCarrier : isFetchingCarrierAgent;

  const carrierInfoById = isCarrierOnBoard ? carrierRequestCarrier?.result : carrierInfoByIdAgent;

  const isFetchingCarrier = isCarrierOnBoard ? isFetchingCarrierCarrier : isFetchingCarrierAgentId;

  const [truckState, setTruckState] = useState<any>({
    skip: 0,
    limit: 10,
  });
  const { data: trucksByCarrierId, refetch: refetchTrucksById } = useGetTrucksByCarrierIdQuery(
    { id, ...truckState },
    {
      skip:
        !id ||
        activePage === 'request' ||
        isCarrierOnBoard ||
        activePage === 'blacklist' ||
        (inSearchPage && headerFilter !== 'carriers'),
    }
  );

  useEffect(() => {
    setTruckState({
      skip: 0,
      limit: 10,
    });
  }, [mode]);

  const handleTruckPaginationClick = (skip: number, limit: number) => {
    setTruckState((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const { data: carrierByGroupId = [], isFetching: isFetchingBlackListById } = useGetCarrierBlackListByGroupIdQuery(
    { id },
    {
      skip: activePage !== 'blacklist' || !id || (inSearchPage && headerFilter !== 'carriers'),
    }
  );

  const [carrierLastLoadsFilter, setCarrierLastLoadsFilter] = useState({
    search: '',
    field: '',
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const handleCarrierLasLoadsPaginationClick = (skip: number, limit: number) => {
    setCarrierLastLoadsFilter((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const { data: carrierLastLoads, isLoading: isCarrierLastLoadsLoading } = useGetCarrierLastLoadsQuery(
    {
      id,
      ...carrierLastLoadsFilter,
    },
    { skip: !id || (inSearchPage && headerFilter !== 'carriers') || activePage === 'carrierOnBoard' }
  );

  const [breakdownParams, setBreakdownParams] = useState({
    skip: 0,
    limit: 10,
    order: 2,
    orderBy: 'createdAt',
  });

  const { data: breakdowns, isFetching: isBreakdownLoading } = useGetCarrierBreakdownsQuery(
    { id, ...breakdownParams },
    { skip: !id || (inSearchPage && headerFilter !== 'carriers') }
  );

  const handleCarrierBreakdownPaginationClick = (skip: number, limit: number) => {
    setBreakdownParams((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBreakdownSortClick = (sortOrder: number, dataIndex: string) => {
    setBreakdownParams((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const isShowMoreVisible = useMemo(
    () => carrierCommentsInfo?.count > getCommentsFilter.limit,
    [carrierCommentsInfo, getCommentsFilter]
  );

  const [carriersFilter, setCarriersFilter] = useState({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
    insuranceDays: 0,
  });
  const {
    data = [],
    isFetching,
    refetch: refetchCarriersData,
  } = useGetCarriersQuery(carriersFilter, { skip: inSearchPage });

  const [carrierInfo, setCarrierInfo] = useState<any>(null);

  useEffect(() => {
    setCarrierInfo(carrierInfoById);
  }, [carrierInfoById, mode, id]);

  useEffect(() => {
    if (activePage !== 'table') {
      setCarriersFilter({
        search: '',
        field: '',
        skip: 0,
        limit: 20,
        order: 2,
        orderBy: 'createdAt',
        filter: {},
        insuranceDays: 0,
      });
    }
  }, [activePage]);

  useEffect(() => {
    if (mode === 'add') {
      setCarrierInfo(null);
    }
  }, [mode]);
  const [createCarrier, { isLoading: isCreateLoading }] = useCreateCarrierMutation();
  const [editCarrier, { isLoading: isEditLoading }] = useEditCarrierMutation();
  const [fillCarrier, { isLoading: isFillLoading }] = useFillCarrierMutation();

  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const [addNewFile] = useAddFileToCarrierMutation();
  const [deleteFile] = useDeleteFileFromCarrierMutation();

  const { data: carrierTableColumns } = useGetColumnsQuery(
    { type: 'carriers' },
    { skip: inSearchPage || activePage !== 'table' }
  );
  const { data: carrierRequestColumns } = useGetColumnsQuery(
    { type: 'carrierRequest' },
    { skip: activePage !== 'request' }
  );
  const { data: carrierBlackListColumns } = useGetColumnsQuery(
    { type: 'carrierBlackList' },
    { skip: activePage !== 'blacklist' }
  );

  const { data: carrierOnBoardColumns } = useGetColumnsQuery(
    { type: 'carrierOnBoard' },
    { skip: activePage !== 'carrierOnBoard' }
  );

  const [editColumns, { isLoading: isLoadingColumnEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [getFilesByType, { data: filesDataForEdit }] = useLazyGetFilesByCarrierIdAndTypeQuery();

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (carrierTableColumns?.columns?.length && activePage === 'table') {
      setDragColumns(carrierTableColumns?.columns);
    }
  }, [carrierTableColumns?.columns, activePage]);

  useEffect(() => {
    if (carrierRequestColumns?.columns?.length && activePage === 'request') {
      setDragColumns(carrierRequestColumns?.columns);
    }
  }, [carrierRequestColumns?.columns, activePage]);

  useEffect(() => {
    if (carrierBlackListColumns?.columns?.length && activePage === 'blacklist') {
      setDragColumns(carrierBlackListColumns?.columns);
    }
  }, [carrierBlackListColumns?.columns, activePage]);

  useEffect(() => {
    if (carrierOnBoardColumns?.columns?.length && isCarrierOnBoard) {
      setDragColumns(carrierOnBoardColumns?.columns);
    }
  }, [carrierOnBoardColumns?.columns, activePage]);

  const ref = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields, isSubmitted },
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
    resetField,
  } = useForm<ICarriersFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation(mode, activePage)),
  });

  const {
    formState: { isDirty: isFilterDirty },
    reset: resetFilterForm,
    control: filterControl,
    setValue: setFilterValue,
    watch: watchFilter,
  } = useForm<ICarriersFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const carrierTypeFilter = watchFilter('carrierType');
  const ratingFilter = watchFilter('ratings')?.toString();
  const carrierTypeFilterQueryCheck = typeof carrierTypeFilter === 'string';

  const uniqueVisibleCarrierSubTabs = Array.from(new Set(permissionsInfo?.allPossibleSubTabs));
  const firstAvailableCarrierSubTab = CarrierSubTabs.find(el => uniqueVisibleCarrierSubTabs.includes(el));

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      editingId: '',
      ...(String(activePage) === String(undefined)
        ? uniqueVisibleCarrierSubTabs?.length
          ? {
              activePage: carrierSubTabs[firstAvailableCarrierSubTab as keyof typeof carrierSubTabs],
            }
          : {}
        : { activePage }),
    });
  }, [activePage, mode]);
  const paymentTermsFromSignUp = useMemo(() => {
    const paymentBroker = carrierRequestInfo?.paymentTerms?.paymentTerm?.result || [];
    const paymentFM = carrierRequestInfo?.paymentTerms?.paymentTermFM || [];
    return [...paymentBroker, ...paymentFM];
  }, [carrierRequestInfo]);

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        numberOfTrailers: carrierInfo?.numberOfTrailers || carrierRequestInfo?.numberOfTrailers || '',
        numberOfTrucks: carrierInfo?.numberOfTrucks || carrierRequestInfo?.numberOfTrucks || '',
        paymentPaidAs: carrierInfo?.paymentPaidAs || '',
        company: carrierInfo?.companyName || carrierRequestInfo?.companyName,
        paymentTerms: paymentTermsFromSignUp || [],
        primaryPhone: formatPhone(carrierInfo?.phone || carrierRequestInfo?.phone),
        secondaryPhone: formatPhone(carrierInfo?.secondPhone),
        fax: carrierInfo?.fax,
        contact: carrierInfo?.contactName || carrierRequestInfo?.contactName,
        type: carrierInfo?.type || ICarrierType[carrierRequestInfo?.type],
        MC: carrierInfo?.mc || carrierRequestInfo?.mc,
        email: carrierInfo?.email || '',
        dot: carrierInfo?.dot || carrierRequestInfo?.dot,
        stateNumber: carrierInfo?.stateNumber || carrierRequestInfo?.stateNumber,
        ff: carrierInfo?.ff || carrierRequestInfo?.ff,
        address: carrierInfo?.address,
        secondAddress: carrierInfo?.secondAddress || '',
        city: carrierInfo?.city,
        state: carrierInfo?.state?.id,
        zip: carrierInfo?.zip,
        physical: carrierInfo?.physical === 1,
        physicalAddress: carrierInfo?.physicalAddress || '',
        physicalSecondAddress: carrierInfo?.physicalSecondAddress || '',
        physicalCity: carrierInfo?.physicalCity || '',
        physicalState: carrierInfo?.physicalState?.id || '',
        physicalZip: carrierInfo?.physicalZip || '',
        fuelCards: carrierInfo?.fuelCards || [],
        carrierPacketFile: allFiles?.carrierPacketFile.map((item: any) => item.id) || [],
        signAgreementFile: allFiles?.signAgreementFile.map((item: any) => item.id) || [],
        paidAsW9File: allFiles?.paidAsW9File.map((item: any) => item.id) || [],
        insuranceFile: allFiles?.insuranceFile.map((item: any) => item.id) || [],
        voidedCheckBankLatterFile: allFiles?.voidedCheckBankLatterFile.map((item: any) => item.id) || [],
        companyOwnerIdFile: allFiles?.companyOwnerIdFile.map((item: any) => item.id) || [],
        Liability: carrierInfo?.liability || '',
        Cargo: carrierInfo?.cargo || '',
        cargoCoverageValue: carrierInfo?.cargoCoverageValue || '',
        nonOwnedCoverage: carrierInfo?.nonOwnedCoverage || '',
        trailerInterchange: carrierInfo?.trailerInterchange || '',
        reeferBreakdown: carrierInfo?.reeferBreakdown,
        truckNumber: '',
        yearAndMake: '',
        boxTruck: 2,
        length: '',
        truckId: '',
        trucks: [],
        equipmentType: carrierInfo?.equipmentType || carrierRequestInfo?.equipmentType || [],
      });
    } else {
      reset(defaultValues);
      setAllFiles({
        carrierPacketFile: [],
        companyOwnerIdFile: [],
        signAgreementFile: [],
        paidAsW9File: [],
        insuranceFile: [],
        voidedCheckBankLatterFile: [],
      });
    }
  }, [mode, carrierInfo, carrierRequestInfo]);

  useEffect(() => {
    if (activePage === 'request' || isCarrierOnBoard) {
      setValue('trucks', []);
    }
    if (mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard') {
      if (carrierInfo?.type === ICarrierType['Outside'] && watch('type') === ICarrierType['Preferred Carrier']) {
        return;
      }
      setValue('trucks', trucksByCarrierId?.result);
    }
  }, [mode, activePage, carrierInfo, trucksByCarrierId]);

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      isAproveMode: 'false',
      mode: 'view',
      id: '',
    });
    reset(defaultValues, { keepDirty: false });
    setAllFiles({
      carrierPacketFile: [],
      companyOwnerIdFile: [],
      signAgreementFile: [],
      paidAsW9File: [],
      insuranceFile: [],
      voidedCheckBankLatterFile: [],
    });
    setCarrierInfo(null);
    setCommentsFilter({
      skip: 0,
      limit: 10,
    });
    setCarrierLastLoadsFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'createdAt',
    });
    setBreakdownParams({
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'createdAt',
    });
  };

  const onSubmit: SubmitHandler<ICarriersFormData> = data => {
    if (paymentTermDirty) {
      return;
    }
    if ((mode === 'add' || (mode === 'edit' && isCarrierOnBoard)) && !isCreateLoading) {
      createCarrier({
        ...data,
        ...(isCarrierOnBoard && mode === 'edit' ? { id } : {}),
      }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    } else if (mode === 'edit' && activePage !== 'request' && activePage !== 'carrierOnBoard' && !isEditLoading) {
      if (
        carrierInfo?.type === ICarrierType['Outside'] &&
        watch('type') === String(ICarrierType['Preferred Carrier'])
      ) {
        editCarrier({ body: data, id }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully edited',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
            if (!inSearchPage) {
              refetchCarriersData();
            }
          }
        });
        return;
      } else {
        editCarrier({
          body: {
            ...data,
            trucks: [],
          },
          id,
        }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully edited',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
            if (!inSearchPage) {
              refetchCarriersData();
            }
          }
        });
      }
    } else if (activePage === 'request' && !isFillLoading) {
      fillCarrier({ body: data, id }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(carrierTypeFilter && carrierTypeFilterQueryCheck ? { type: carrierTypeFilter } : {}),
      ...(ratingFilter && ratingFilter?.length ? { rating: ratingFilter } : {}),
      open: 'false',
      isAproveMode: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
    setAllFiles({
      carrierPacketFile: [],
      companyOwnerIdFile: [],
      signAgreementFile: [],
      paidAsW9File: [],
      insuranceFile: [],
      voidedCheckBankLatterFile: [],
    });
    setCarrierInfo(null);
    setCarrierLastLoadsFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'createdAt',
    });
    setTruckState({
      skip: 0,
      limit: 10,
    });
    clearErrors();
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({
      setDragColumns,
      deleteColumns,
      editColumns,
      handleCloseModal,
      dragColumns,
      type: columnsTypes[activePage],
    });

  const handleTabChange = (activePage: string) => {
    resetFilterForm();
    setSearchParams({
      ...searchParams,
      activePage,
      open: 'false',
    });
  };

  const handleCreateComment = (text: string) => {
    createComment({ id, message: text }).then((messageData: any) => {
      if (messageData.error) {
        notification.error({
          message: ServerErrorCodes[Number(messageData?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        if (!inSearchPage) {
          refetchCarriersData();
        }
      }
    });
  };

  const handleDeleteCommentModal = (commentId: string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        cancelButtonVariant: 'outlined',
        okButtonVariant: 'danger',
        onOk: () => {
          deleteComment({ id, commentId }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              if (!inSearchPage) {
                refetchCarriersData();
              }
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const [allFiles, setAllFiles] = useState<any>({
    carrierPacketFile: [],
    signAgreementFile: [],
    companyOwnerIdFile: [],
    paidAsW9File: [],
    insuranceFile: [],
    voidedCheckBankLatterFile: [],
  });
  const handleCancel = () => {
    if (Object.keys(dirtyFields)?.length || paymentTermDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk: isValid
            ? () => {
                onSubmit(watch());
                dispatch(closeModal());
                setSearchParams({
                  ...searchParams,
                  mode: 'view',
                  id: '',
                });
                setBreakdownParams({
                  skip: 0,
                  limit: 10,
                  order: 2,
                  orderBy: 'createdAt',
                });
                setTruckState({
                  skip: 0,
                  limit: 10,
                });
                clearErrors();
              }
            : () => {
                dispatch(closeModal());
              },
          onCancel: handleCloseModal,
        })
      );
    } else {
      clearErrors();
      resetForm();
      setSearchParams({
        ...searchParams,
        ...(carrierTypeFilter && carrierTypeFilterQueryCheck ? { type: carrierTypeFilter } : {}),
        ...(ratingFilter && ratingFilter?.length ? { rating: ratingFilter } : {}),
        open: 'false',
        isAproveMode: 'false',
        id: '',
      });
      setAllFiles({
        carrierPacketFile: [],
        companyOwnerIdFile: [],
        signAgreementFile: [],
        paidAsW9File: [],
        insuranceFile: [],
        voidedCheckBankLatterFile: [],
      });
      setCarrierInfo(null);
      setCommentsFilter({
        skip: 0,
        limit: 10,
      });
      setBreakdownParams({
        skip: 0,
        limit: 10,
        order: 2,
        orderBy: 'createdAt',
      });
      setTruckState({
        skip: 0,
        limit: 10,
      });
    }
    setCarrierLastLoadsFilter({
      search: '',
      field: '',
      skip: 0,
      limit: 10,
      order: 2,
      orderBy: 'createdAt',
    });
  };

  const [localFiles, setLocalFiles] = useState<any>([]);

  const [uploadFileNOA] = useUploadFileNOAMutation();
  const userInfo = useSelector(selectUserInfo);

  const beforeUploadForCreate = (file: any, ctx: any, setLoading: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then(({ data: { result } }: any) => {
          setLocalFiles((prev: any) => [file, ...prev]);
          setValue(ctx, getValues(ctx) ? [...getValues(ctx), ...result!.ids] : result);
          setAllFiles((prev: any) => {
            prev[ctx]?.unshift({
              fullName: `${userInfo?.username}`,
              fileName: file.name,
              id: result?.ids?.[0],
              createdAt: new Date(),
              uid: file.uid,
              preview: file.preview,
              download: file.download,
            });
            return {
              ...prev,
            };
          });
          trigger(ctx);
        })
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });

      return false;
    } else {
      notification.error({
        message: FILE_MAX_SIZE_ERROR,
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  useEffect(() => {
    if (filesDataForEdit) {
      setAllFiles((prev: any) => {
        for (let i = 0; i < filesDataForEdit.result.length; i++) {
          if (
            !prev[IFileTypes[filesDataForEdit.result[i]?.carrierFileType]]?.find(
              (el: any) => el.id === filesDataForEdit.result[i]?.id
            )
          ) {
            prev[IFileTypes[filesDataForEdit.result[i]?.carrierFileType]].push({
              fullName: `${filesDataForEdit?.result[i]?.creator?.username}`,
              fileName: filesDataForEdit.result[i]?.name,
              id: filesDataForEdit?.result[i]?.id,
              createdAt: formatDate(filesDataForEdit?.result[i]?.createdAt),
              uid: filesDataForEdit?.result[i]?.id,
              preview: filesDataForEdit?.result[i]?.preview,
              download: filesDataForEdit?.result[i]?.download,
            });
          }
        }
        return {
          ...prev,
        };
      });
    }
    if (mode === 'edit' && isAproveMode === 'true') {
      setAllFiles((prev: any) => {
        for (let i = 0; i < carrierRequestInfo?.files?.length; i++) {
          if (
            !prev[ICarrierFillFileTypes[carrierRequestInfo?.files?.[i]?.fileType]]?.find(
              (el: any) => el.id === carrierRequestInfo?.files?.[i]?.id
            )
          ) {
            prev[ICarrierFillFileTypes[carrierRequestInfo?.files?.[i]?.fileType]]?.push({
              fullName: carrierRequestInfo?.files?.[i]?.creator?.username
                ? `${carrierRequestInfo?.files?.[i]?.creator?.username}`
                : undefined,
              fileName: carrierRequestInfo?.files?.[i]?.name,
              id: carrierRequestInfo?.files?.[i]?.id,
              createdAt: formatDate(carrierRequestInfo?.files?.[i]?.createdAt),
              uid: carrierRequestInfo?.files?.[i]?.id,
              preview: carrierRequestInfo?.files?.[i]?.preview,
              download: carrierRequestInfo?.files?.[i]?.download,
            });
          }
        }
        return {
          ...prev,
        };
      });
    }
  }, [filesDataForEdit, carrierRequestInfo?.files, isAproveMode, mode]);

  const beforeUploadForEdit = (file: any, ctx: any, setLoading: any, type: any) => {
    if (isFileAllowedToBeUpload(file?.size)) {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('type', '1');
      uploadFileNOA(formData)
        .then(({ data: { result } }: any) => {
          addNewFile({ id, fileId: result?.ids?.[0], type }).then(() => {
            setLocalFiles((prev: Array<any>) => [file, ...prev]);
            setAllFiles((prev: any) => {
              prev[ctx].unshift({
                fullName: `${userInfo?.username}`,
                fileName: file.name,
                id: result?.ids[0],
                createdAt: new Date(),
                uid: file.uid,
              });
              return {
                ...prev,
              };
            });
          });
          trigger(ctx);
        })
        .catch(() => null)
        .finally(() => {
          setLoading(false);
        });
      return false;
    } else {
      notification.error({
        message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
        duration: 3,
        icon: <DangerIcon />,
        placement: 'topRight',
        closeIcon: <CloseIcon />,
        key: notificationKey,
        btn: (
          <button type="button" onClick={() => notification.destroy(notificationKey)}>
            <CloseIcon />
          </button>
        ),
      });
    }
  };

  const handleDeleteCarrierFileEdit = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          if (activePage === 'carrierOnBoard') {
            setAllFiles((prev: any) => {
              const updated = prev[ctx].filter((el: any) => el.id !== fileId);
              return {
                ...prev,
                [ctx]: updated,
              };
            });
          } else {
            deleteFile({ id, fileId }).then(() => {
              setAllFiles((prev: any) => {
                const updated = prev[ctx].filter((el: any) => el.id !== fileId);
                return {
                  ...prev,
                  [ctx]: updated,
                };
              });
            });
          }
          trigger(ctx);
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDeleteCarrierFileCreate = (fileId: number, ctx: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          setValue(
            ctx,
            getValues(ctx).filter((el: any) => el !== fileId)
          );
          setAllFiles((prev: any) => {
            const updated = prev[ctx].filter((el: any) => el.id !== fileId);
            return {
              ...prev,
              [ctx]: updated,
            };
          });
          trigger(ctx);
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleDownloadCarrierFileEdit = (download: any) => {
    const link = document.createElement('a');
    link.href = download;
    link.download = download.substring(download.lastIndexOf('/') + 1);
    link.click();
  };

  const handleDownloadCarrierFileCreate = (file: any) => {
    const fileToDownload = localFiles.find((el: any) => el.uid === file.uid);
    const blob = new Blob([fileToDownload]);

    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');

    downloadLink!.href = url;
    downloadLink.download = fileToDownload!.name;

    // Programmatically click the link to trigger the download
    downloadLink.click();

    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  const handleViewCarrierFileEdit = (url: string) => {
    window.open(`${url}`, '_blank');
  };
  const handleViewCarrierFileCreate = (file: any) => {
    const fileToView = localFiles.find((el: any) => el.uid === file.uid);
    if (fileToView) {
      const blob = new Blob([fileToView], { type: fileToView.type });

      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');

      window.URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    if (allFiles.carrierPacketFile?.length) {
      trigger('carrierPacketFile');
    }
  }, [allFiles.carrierPacketFile?.length]);

  useEffect(() => {
    if (allFiles.voidedCheckBankLatterFile?.length) {
      trigger('voidedCheckBankLatterFile');
    }
  }, [allFiles.voidedCheckBankLatterFile?.length]);

  useEffect(() => {
    if (allFiles.signAgreementFile?.length) {
      trigger('signAgreementFile');
    }
  }, [allFiles.signAgreementFile?.length]);

  useEffect(() => {
    if (allFiles.insuranceFile?.length) {
      trigger('insuranceFile');
    }
  }, [allFiles.insuranceFile?.length]);

  useEffect(() => {
    if (allFiles.paidAsW9File?.length) {
      trigger('paidAsW9File');
    }
  }, [allFiles.paidAsW9File?.length]);

  useEffect(() => {
    if (watch('trucks')?.length) {
      trigger('trucks');
    }
  }, [watch('trucks')?.length]);

  const truckSectionRef = useRef(null);

  const carrierTablePagePermissions = usePermittedActions('profiles.carriers.carrierTable');
  const carrierRequestPagePermissions = usePermittedActions('profiles.carriers.carrierRequest');
  const carrierBlackListPagePermissions = usePermittedActions('profiles.carriers.blacklist');
  const carrierOnBoardPagePermissions = usePermittedActions('profiles.carriers.carrierOnBoard');

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={
            carrierTableColumns?.columns ||
            carrierRequestColumns?.columns ||
            carrierBlackListColumns?.columns ||
            carrierOnBoardColumns?.columns
          }
          isDefault={
            activePage === 'table'
              ? carrierTableColumns?.isDefault
              : activePage === 'request'
              ? carrierRequestColumns?.isDefault
              : activePage === 'blackList'
              ? carrierBlackListColumns?.isDefault
              : carrierOnBoardColumns?.isDefault
          }
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: carrierTablePagePermissions.create
      ? {
          component: (
            <CreateCarrier
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              getValues={getValues}
              setValue={setValue}
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              mode={mode}
              statesData={statesData}
              activeSection={activeSection}
              beforeUpload={beforeUploadForCreate}
              allFiles={allFiles}
              handleDeleteCarrierFile={handleDeleteCarrierFileCreate}
              handleDownloadCarrieryFile={handleDownloadCarrierFileCreate}
              handleViewCarrieryFileEdit={handleViewCarrierFileCreate}
              carrierInfo={carrierInfo}
              getFilesByType={getFilesByType}
              trigger={trigger}
              watch={watch}
              truckSectionRef={truckSectionRef}
              resetField={resetField}
              isCreateLoading={isCreateLoading}
              isEditLoading={isEditLoading}
              handleTruckPaginationClick={handleTruckPaginationClick}
              trucksByCarrierId={trucksByCarrierId}
              truckState={truckState}
              setTruckState={setTruckState}
              refetchTrucksById={refetchTrucksById}
              setPaymentTermDirty={setPaymentTermDirty}
              isSubmitted={isSubmitted}
              dirtyFields={dirtyFields}
            />
          ),
          buttonText: SUBJECT.ADD,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: carrierTablePagePermissions.edit
      ? {
          component: (
            <div
              className={clsx({
                ['scrollBarWrapper']: width === 'full',
              })}
            >
              {width === 'full' && (
                <ScrollBar
                  sections={[
                    ...SECTIONS(!!carrierLastLoads?.count, !!breakdowns?.result?.result?.length),
                    {
                      id: 'comment',
                      title: 'Comment',
                    },
                  ]}
                />
              )}
              <div
                className={clsx('scrollBarContent', {
                  ['openedDrawer']: width === 'full',
                })}
              >
                <EditCarrier
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  getValues={getValues}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  watch={watch}
                  mode={mode}
                  statesData={statesData}
                  activeSection={activeSection}
                  beforeUpload={
                    activePage === 'request' || isCarrierOnBoard ? beforeUploadForCreate : beforeUploadForEdit
                  }
                  handleDeleteCarrierFile={handleDeleteCarrierFileEdit}
                  handleDownloadCarrieryFile={handleDownloadCarrierFileEdit}
                  handleViewCarrieryFileEdit={handleViewCarrierFileEdit}
                  allFiles={allFiles}
                  carrierCommentsInfo={carrierCommentsInfo}
                  carrierInfo={carrierInfo}
                  carrierRequestInfo={carrierRequestInfo}
                  getFilesByType={getFilesByType}
                  trigger={trigger}
                  createComment={handleCreateComment}
                  handleDeleteCommentModal={handleDeleteCommentModal}
                  isLoading={isFetchingCarrier}
                  getMoreComments={getMoreComments}
                  isShowMoreVisible={isShowMoreVisible}
                  truckSectionRef={truckSectionRef}
                  handleNewFileView={handleViewCarrierFileCreate}
                  handleNewFileDownload={handleDownloadCarrierFileCreate}
                  userInfo={userInfo}
                  carrierLastLoads={carrierLastLoads}
                  handleCarrierLasLoadsPaginationClick={handleCarrierLasLoadsPaginationClick}
                  isCarrierLastLoadsLoading={isCarrierLastLoadsLoading}
                  isEditLoading={isEditLoading}
                  isCreateLoading={isCreateLoading}
                  resetField={resetField}
                  breakdowns={breakdowns?.result}
                  breakdownParams={breakdownParams}
                  handleCarrierBreakdownPaginationClick={handleCarrierBreakdownPaginationClick}
                  handleBreakdownSortClick={handleBreakdownSortClick}
                  isBreakdownLoading={isBreakdownLoading}
                  handleTruckPaginationClick={handleTruckPaginationClick}
                  hasDeleteCommentPermission={carrierTablePagePermissions.deleteComment}
                  trucksByCarrierId={trucksByCarrierId}
                  isCommentFetching={isCommentFetching}
                  truckState={truckState}
                  setTruckState={setTruckState}
                  refetchTrucksById={refetchTrucksById}
                  setPaymentTermDirty={setPaymentTermDirty}
                  isSubmitted={isSubmitted}
                  dirtyFields={dirtyFields}
                  clearErrors={clearErrors}
                  carrierRequestCarrier={carrierRequestCarrier}
                />
              </div>
            </div>
          ),
          buttonText: activePage === 'carrierOnBoard' ? 'Approve & Add Carrier' : SUBJECT.SAVE_CHANGES,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <CarrierRowInfo
          carrierRequestInfo={carrierRequestInfo}
          carrierInfo={carrierInfo}
          trucksByCarrierId={trucksByCarrierId}
          isFetchingCarrier={isFetchingCarrier}
          pagePermissions={carrierTablePagePermissions}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          comments={carrierCommentsInfo}
          carrierLastLoads={carrierLastLoads}
          handleCarrierLasLoadsPaginationClick={handleCarrierLasLoadsPaginationClick}
          isCarrierLastLoadsLoading={isCarrierLastLoadsLoading}
          breakdowns={breakdowns?.result}
          breakdownParams={breakdownParams}
          setBreakdownParams={setBreakdownParams}
          handleCarrierBreakdownPaginationClick={handleCarrierBreakdownPaginationClick}
          handleBreakdownSortClick={handleBreakdownSortClick}
          isBreakdownLoading={isBreakdownLoading}
          handleTruckPaginationClick={handleTruckPaginationClick}
          isCommentFetching={isCommentFetching}
          refetchTrucksById={refetchTrucksById}
        />
      ),
      onCancel: handleCancel,
    },
    comments: {
      component: (
        <CarrierInfo
          carrierRequestInfo={carrierRequestInfo}
          createComment={handleCreateComment}
          deleteComment={handleDeleteCommentModal}
          carrierCommentsInfo={carrierCommentsInfo}
          carrierInfo={carrierInfo}
          getMoreComments={getMoreComments}
          isShowMoreVisible={isShowMoreVisible}
          isCommentFetching={isCommentFetching}
          userInfo={userInfo}
          hasDeleteCommentPermission={carrierTablePagePermissions.deleteComment}
        />
      ),
      onCancel: handleCancel,
    },
    request: carrierRequestPagePermissions.view
      ? {
          component: (
            <CarrierRequestInfo
              data={carrierRequestInfo}
              isFetching={isFetchingCarrierRequest}
              carrierRequestPagePermissions={carrierRequestPagePermissions}
            />
          ),
          onCancel: handleCancel,
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    carrierOnBoard: carrierOnBoardPagePermissions.view
      ? {
          component: (
            <CarrierRequestInfo
              data={carrierRequestInfo}
              isFetching={isFetchingCarrierRequest}
              carrierRequestPagePermissions={carrierOnBoardPagePermissions}
            />
          ),
          onCancel: handleCancel,
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    blacklist: carrierBlackListPagePermissions.view
      ? {
          component: (
            <BlackListInfo
              data={carrierByGroupId}
              isFetchingBlackListById={isFetchingBlackListById}
              blackListPagePermissions={carrierBlackListPagePermissions}
            />
          ),
          onCancel: handleCancel,
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode === 'add' || mode === 'edit' || mode === 'customize',
    };
  }, [
    id,
    mode,
    activeSection,
    width,
    errors,
    isDirty,
    carrierInfo,
    trucksByCarrierId,
    allFiles,
    watch(),
    setDragColumns,
    dragColumns,
    watch(['carrierPacketFile', 'voidedCheckBankLatterFile', 'signAgreementFile', 'insuranceFile', 'paidAsW9File']),
    carrierTableColumns?.columns,
    carrierRequestColumns?.columns,
    carrierBlackListColumns?.columns,
    carrierOnBoardColumns?.columns,
    carrierRequestInfo,
    carrierInfo?.result,
    carrierInfoById,
    carrierRequestCarrier?.result,
    isCreateLoading,
    isEditLoading,
    isCarrierOnBoard,
    breakdowns,
    isBreakdownLoading,
    isLoadingColumnEdit,
    isLoadingColumnsDelete,
  ]);

  const carrierTabs: any[] = useMemo(
    () => [
      {
        ...(permissionsInfo?.permissions?.profile.carrier.visibleSubTabs.includes('carrierTable')
          ? {
              label: SUBJECT.CARRIERS_TABLE,
              key: 'table',
              children: (
                <CarriersTable
                  filterControl={filterControl}
                  watchFilter={watchFilter}
                  setFilterValue={setFilterValue}
                  resetFilterForm={resetFilterForm}
                  isFilterDirty={isFilterDirty}
                  setExcelParams={setExcelParams}
                  carriersFilter={carriersFilter}
                  setCarriersFilter={setCarriersFilter}
                  data={data}
                  isLoading={isFetching}
                />
              ),
            }
          : {}),
      },
      {
        ...(permissionsInfo?.permissions?.profile.carrier.visibleSubTabs.includes('carrierOnBoard')
          ? {
              label: SUBJECT.CARRIERS_REQUEST_CARRIER,
              key: 'carrierOnBoard',
              children: (
                <RequestTableCarrier setExcelParams={setExcelParams} pagePermissions={carrierOnBoardPagePermissions} />
              ),
            }
          : {}),
      },
      {
        ...(permissionsInfo?.permissions?.profile.carrier.visibleSubTabs.includes('carrierRequest')
          ? {
              label: SUBJECT.CARRIERS_REQUEST_AGENT,
              key: 'request',
              children: (
                <RequestTable setExcelParams={setExcelParams} pagePermissions={carrierRequestPagePermissions} />
              ),
            }
          : {}),
      },
      {
        ...(permissionsInfo?.permissions?.profile.carrier.visibleSubTabs.includes('carriersBlackList')
          ? {
              label: SUBJECT.CARRIERS_BLACK_LIST,
              key: 'blacklist',
              children: (
                <BlacklistTable setExcelParams={setExcelParams} pagePermissions={carrierBlackListPagePermissions} />
              ),
            }
          : {}),
      },
    ],
    [activePage, watchFilter(['carrierType', 'ratings', 'insuranceDays']), permissionsInfo?.routes]
  );

  return {
    activePage,
    handleTabChange,
    currentChildren,
    carrierTabs,
    ref,
    handleSubmit,
    onSubmit,
    getValues,
    setValue,
    control,
    errors,
    watch,
    mode,
    isDirty,
    reset,
  };
};

export default useCarriers;
