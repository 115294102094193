import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CardNumber } from '../pages/payroll/tabs/information/constants/constants';
import { ProfileAllSubTabs } from '../pages/profile/constants/constants';
import { selectUserPermissionsInfo } from '../store/user-slice/selector';
import { allTabs, EmployeeLevels, UserTypes } from '../utils/constants';

import { IDetectedParams } from './types';

export const useDetectedParams = (): IDetectedParams => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissionsInfo, agentType, userType, employeeLevel } = useSelector(selectUserPermissionsInfo);
  const allAllowedCards = permissionsInfo?.permissions?.payroll?.informationCards || [];

  const { allPossibleTabs, allPossibleSubTabs } = permissionsInfo;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Global
  const tab = searchParams.get('tab') || '';
  const id = searchParams.get('id') || '';
  const paymentType = searchParams.get('paymentType') || '';
  const editingId = searchParams.get('editingId') || '';
  const carrierId = searchParams.get('carrierId') || '';
  const currentPage = searchParams.get('currentPage') || '';
  const open = searchParams.get('open') || 'false';
  const mode = searchParams.get('mode') || 'customize';
  const width = searchParams.get('width') || '';
  const headerFilter = searchParams.get('headerFilter') || 'loads';
  const renderMode = searchParams.get('renderMode') || '';

  const permission = searchParams.get('permission') || '';
  const permissionBranch = searchParams.get('permissionBranch') || '';
  const permissionOffice = searchParams.get('permissionOffice') || '';
  const authorities = searchParams.get('authorities') || '';
  const status = searchParams.get('status') || '';
  const authoritiesType = searchParams.get('type') || '';
  const officeFilter = searchParams.get('officeFilter') || '';
  const factoringCompaniesFilter = searchParams.get('factoringCompaniesFilter') || '';
  const customerType = searchParams.get('customerType') || '';
  const office_filter = searchParams.get('office_filter') || '';
  const branch_filter = searchParams.get('branch_filter') || '';
  const agent_type_filter = searchParams.get('agent_type_filter') || '';
  const location = searchParams.get('location') || '';
  const carrierType = searchParams.get('type') || '';
  const rating = searchParams.get('rating');
  const customerName = searchParams.get('customerName') || '';

  const activePage = searchParams.get('activePage') || '';
  const activeTab = searchParams.get('activeTab') || '';
  const card = searchParams.get('card') || '';
  const date = searchParams.get('date') || '';
  const loadsOffice = searchParams.get('loadsOffice') || '';
  const loadsBranch = searchParams.get('loadsBranch') || '';
  const loadsAgent = searchParams.get('loadsAgent') || '';
  const loadsByType = searchParams.get('loadsByType') || '';
  const document = searchParams.get('document') || '';
  const loadStatus = searchParams.get('loadStatus') || '';
  const startDate = searchParams.get('startDate') || '';
  const dateMode = searchParams.get('dateMode') || '';
  const endDate = searchParams.get('endDate') || '';
  const allMonths = searchParams.get('allMonths') || '';
  const all = searchParams.get('all') || '';
  const officePayroll = searchParams.get('officePayroll') || '';
  const branchPayroll = searchParams.get('branchPayroll') || '';
  const agentPayroll = searchParams.get('agentPayroll') || '';
  const officePayout = searchParams.get('officePayout') || '';
  const branchPayout = searchParams.get('branchPayout') || '';
  const agentPayout = searchParams.get('agentPayout') || '';
  const mine = searchParams.get('mine') || '';
  const mcType = searchParams.get('receivableMcType') || '';
  const customerFilter = searchParams.get('receivableCustomerType') || '';
  const isEdit = searchParams.get('isEdit') || '';
  const contactListFilter = searchParams.get('contactListFilter') || '';
  const searchId = searchParams.get('searchId') || '';
  const permissionNameFilter = searchParams.get('permissionNameFilter') || '';
  const rowName = searchParams.get('rowName') || '';

  // Factoring > Payables
  const mcPayablesFilter = searchParams.get('mcPayablesFilter') || '';
  const carrierPayablesFilter = searchParams.get('carrierPayablesFilter') || '';
  const officePayablesFilter = searchParams.get('officePayablesFilter') || '';
  const branchPayablesFilter = searchParams.get('branchPayablesFilter') || '';
  const agentPayablesFilter = searchParams.get('agentPayablesFilter') || '';
  const paymentMethodFilter = searchParams.get('paymentMethodFilter') || '';
  const payablesCarrierTypeFilter = searchParams.get('payablesCarrierTypeFilter') || '';
  //////////

  // Factoring > Receivables
  const mcReceivablesFilter = searchParams.get('mcReceivablesFilter') || '';
  const customerReceivablesFilter = searchParams.get('customerReceivablesFilter') || '';
  const officeReceivablesFilter = searchParams.get('officeReceivablesFilter') || '';
  const branchReceivablesFilter = searchParams.get('branchReceivablesFilter') || '';
  const agentReceivablesFilter = searchParams.get('agentReceivablesFilter') || '';
  const paymentMethodReceivablesFilter = searchParams.get('paymentMethodReceivablesFilter') || '';
  ///////

  // Factoring > Billing
  const customerPaymentMethod = searchParams.get('customerPaymentMethod') || '';
  const carrierPaymentMethod = searchParams.get('carrierPaymentMethod') || '';
  const officeBilling = searchParams.get('officeBilling') || '';
  const branchBilling = searchParams.get('branchBilling') || '';
  const agentBilling = searchParams.get('agentBilling') || '';
  /////////

  const carrier_type = searchParams.get('carrier_type') || '';
  const insuranceDay = searchParams.get('insuranceDay') || '';
  const isAproveMode = searchParams.get('isAproveMode') || '';
  const newPass = searchParams.get('new-pass') || '';
  const createPass = searchParams.get('create-pass') || '';
  const reset = searchParams.get('reset') || '';
  const hash = searchParams.get('hash') || '';
  const startSelectDate = searchParams.get('startSelectDate') || '';
  const endSelectDate = searchParams.get('endSelectDate') || '';
  const allSelectDate = searchParams.get('allSelectDate') || '';
  const regStartDate = searchParams.get('regStartDate' || '');
  const regEndDate = searchParams.get('regEndDate' || '');
  const regAllDate = searchParams.get('regAllDate' || '');
  const paidStartDate = searchParams.get('paidStartDate' || '');
  const paidEndDate = searchParams.get('paidEndDate' || '');
  const paidAllDate = searchParams.get('paidAllDate' || '');
  const regOption = searchParams.get('regOption' || '');
  const paidOption = searchParams.get('paidOption' || '');
  const loadStartDate = searchParams.get('loadStartDate' || '');
  const loadEndDate = searchParams.get('loadEndDate' || '');
  const loadAllDate = searchParams.get('loadAllDate' || '');
  const loadOption = searchParams.get('loadOption' || '');
  const availableStartDate = searchParams.get('availableStartDate' || '');
  const availableEndDate = searchParams.get('availableEndDate' || '');
  const availableAllDate = searchParams.get('availableAllDate' || '');
  const availableOption = searchParams.get('availableOption' || '');

  const authorityId = searchParams.get('authorityId' || '');
  const officeId = searchParams.get('officeId' || '');
  const confirm = searchParams.get('confirm') || '';
  const authorityUsed = searchParams.get('authorityUsed') || '';

  const equipmentType = searchParams.get('equipmentType') || '';
  const trailerDetails = searchParams.get('trailerDetails') || '';
  const equipmentTypeSingle = searchParams.get('equipmentTypeSingle') || '';
  const trailerDetailsSingle = searchParams.get('trailerDetailsSingle') || '';
  const carrierStatus = searchParams.get('carrierStatus') || '';
  const coordinator = searchParams.get('coordinator') || '';
  const truckId = searchParams.get('truckId' || '');
  const isSingle = searchParams.get('isSingle' || '');
  const singleStatus = searchParams.get('singleStatus' || '');
  const editId = searchParams.get('editId' || '');
  const fuelAdvance = searchParams.get('fuelAdvance' || '');
  const feeType = searchParams.get('feeType' || '');

  if (allPossibleTabs?.length && tab && tab !== 'user-settings') {
    if (!allPossibleTabs.includes(allTabs[tab])) {
      navigate({ pathname: '/not-found' });
    }
  }

  if (allPossibleSubTabs?.length && activePage && activePage !== 'undefined' && tab !== 'employees') {
    if (!allPossibleSubTabs.includes(ProfileAllSubTabs[activePage as keyof typeof ProfileAllSubTabs])) {
      navigate({ pathname: '/not-found' });
    }
  }

  if (allPossibleSubTabs?.length && activePage && activePage !== 'undefined' && tab === 'employees') {
    if (!allPossibleSubTabs.includes(activePage)) {
      navigate({ pathname: '/not-found' });
    }
  }

  if (
    (userType == UserTypes.ADMIN || (userType == UserTypes.EMPLOYEE && employeeLevel == EmployeeLevels.CORPORATE)) &&
    (activePage === 'branch' || activePage === 'office') &&
    mode === 'edit' &&
    open === 'true' &&
    tab === 'employees'
  ) {
    navigate({ pathname: '/not-found' });
  } else if (
    (agentType == 1 || (userType == 2 && employeeLevel == 2)) &&
    activePage === 'branch' &&
    tab === 'employees' &&
    mode === 'edit'
  ) {
    navigate({ pathname: '/not-found' });
  }

  if (
    !tab &&
    pathname !== '/' &&
    pathname !== '/sign-in' &&
    pathname !== '/user-settings' &&
    pathname !== '/search-result' &&
    pathname !== '/carrier-sign-in'
  ) {
    navigate({ pathname: '/not-found' });
  }

  // PAYROLL -> Information
  if (tab === 'information' && card) {
    if (!!allAllowedCards.length && !allAllowedCards?.includes(CardNumber[Number(card) as keyof typeof CardNumber])) {
      navigate({ pathname: '/not-found' });
    }
  }

  useEffect(() => {
    if (!permissionsInfo?.permissions) {
      return;
    }
    if (mode === 'advance' && open === 'true') {
      if (id && !permissionsInfo?.permissions?.payroll?.payrollAdvance?.actions?.edit) {
        navigate({ pathname: '/not-found' });
      }
      if (!id && !permissionsInfo?.permissions?.payroll?.payrollAdvance?.actions?.create) {
        navigate({ pathname: '/not-found' });
      }
    }
    if (mode === 'charge' && open === 'true') {
      if (id && !permissionsInfo?.permissions?.payroll?.payrollOtherServiceFee?.actions?.edit) {
        navigate({ pathname: '/not-found' });
      }
      if (!id && !permissionsInfo?.permissions?.payroll?.payrollOtherServiceFee?.actions?.create) {
        navigate({ pathname: '/not-found' });
      }
    }
  }, [
    mode,
    open,
    id,
    permissionsInfo?.permissions?.payroll?.payrollAdvance?.actions?.edit,
    permissionsInfo?.permissions?.payroll?.payrollAdvance?.actions?.create,
    permissionsInfo?.permissions?.payroll?.payrollOtherServiceFee?.actions?.edit,
    permissionsInfo?.permissions?.payroll?.payrollOtherServiceFee?.actions?.create,
  ]);

  return {
    searchParams: {
      ...{ tab },
      ...{ open },
      ...{ mode },
      ...(currentPage && { currentPage }),
      ...(permission && { permission }),
      ...(permissionBranch && { permissionBranch }),
      ...(permissionOffice && { permissionOffice }),
      ...(authorities && { authorities }),
      ...(status && { status }),
      ...(authoritiesType && { type: authoritiesType }),
      ...(officeFilter && { officeFilter }),
      ...(factoringCompaniesFilter && { factoringCompaniesFilter }),
      ...(customerType && { customerType }),
      ...(office_filter && { office_filter }),
      ...(branch_filter && { branch_filter }),
      ...(agent_type_filter && { agent_type_filter }),
      ...(location && { location }),
      ...(carrierType && { type: carrierType }),
      ...(rating && { rating }),
      ...(activePage && { activePage }),
      ...(width && { width }),
      ...(card && { card }),
      ...(date && { date }),
      ...(loadsOffice && { loadsOffice }),
      ...(loadsBranch && { loadsBranch }),
      ...(loadsAgent && { loadsAgent }),
      ...(loadsByType && { loadsByType }),
      ...(document && { document }),
      ...(loadStatus && { loadStatus }),
      ...(startDate && { startDate }),
      ...(dateMode && { dateMode }),
      ...(endDate && { endDate }),
      ...(allMonths && { allMonths }),
      ...(all && { all }),
      ...(officePayroll && { officePayroll }),
      ...(branchPayroll && { branchPayroll }),
      ...(agentPayroll && { agentPayroll }),
      ...(activeTab && { activeTab }),
      ...(officePayout && { officePayout }),
      ...(branchPayout && { branchPayout }),
      ...(agentPayout && { agentPayout }),
      ...(mine && { mine }),
      ...(mcType && { mcType }),
      ...(customerFilter && { customerFilter }),
      ...(isEdit && { isEdit }),
      ...(customerName && { customerName }),

      ...(mcReceivablesFilter && { mcReceivablesFilter }),
      ...(customerReceivablesFilter && { customerReceivablesFilter }),
      ...(mcPayablesFilter && { mcPayablesFilter }),
      ...(carrierPayablesFilter && { carrierPayablesFilter }),
      ...(agentPayablesFilter && { agentPayablesFilter }),
      ...(paymentMethodFilter && { paymentMethodFilter }),
      ...(payablesCarrierTypeFilter && { payablesCarrierTypeFilter }),
      ...(contactListFilter && { contactListFilter }),
      ...(headerFilter && { headerFilter }),
      ...(searchId && { searchId }),
      ...(permissionNameFilter && { permissionNameFilter }),
      ...(rowName && { rowName }),
      ...(officePayablesFilter && { officePayablesFilter }),
      ...(branchPayablesFilter && { branchPayablesFilter }),
      ...(paymentMethodReceivablesFilter && { paymentMethodReceivablesFilter }),
      ...(officeReceivablesFilter && { officeReceivablesFilter }),
      ...(branchReceivablesFilter && { branchReceivablesFilter }),
      ...(agentReceivablesFilter && { agentReceivablesFilter }),

      ...(customerPaymentMethod && { customerPaymentMethod }),
      ...(carrierPaymentMethod && { carrierPaymentMethod }),
      ...(officeBilling && { officeBilling }),
      ...(branchBilling && { branchBilling }),
      ...(agentBilling && { agentBilling }),

      ...(id && { id }),
      ...(carrierId && { carrierId }),
      ...(carrier_type && { carrier_type }),
      ...(insuranceDay && { insuranceDay }),
      ...(isAproveMode && { isAproveMode }),
      ...(newPass && { newPass }),
      ...(createPass && { createPass }),
      ...(reset && { reset }),
      ...(hash && { hash }),
      ...(startSelectDate && { startSelectDate }),
      ...(endSelectDate && { endSelectDate }),
      ...(allSelectDate && { allSelectDate }),
      ...(regStartDate && { regStartDate }),
      ...(regEndDate && { regEndDate }),
      ...(regAllDate && { regAllDate }),
      ...(regOption && { regOption }),
      ...(paidStartDate && { paidStartDate }),
      ...(paidEndDate && { paidEndDate }),
      ...(paidAllDate && { paidAllDate }),
      ...(paidOption && { paidOption }),
      ...(loadStartDate && { loadStartDate }),
      ...(loadEndDate && { loadEndDate }),
      ...(loadAllDate && { loadAllDate }),
      ...(loadOption && { loadOption }),
      ...(availableStartDate && { availableStartDate }),
      ...(availableEndDate && { availableEndDate }),
      ...(availableAllDate && { availableAllDate }),
      ...(availableOption && { availableOption }),
      ...(authorityId && { authorityId }),
      ...(renderMode && { renderMode }),
      ...(officeId && { officeId }),
      ...(editingId && { editingId }),
      ...(paymentType && { paymentType }),
      ...(confirm && { confirm }),
      ...(authorityUsed && { authorityUsed }),
      ...(equipmentType && { equipmentType }),
      ...(trailerDetails && { trailerDetails }),
      ...(equipmentTypeSingle && { equipmentTypeSingle }),
      ...(trailerDetailsSingle && { trailerDetailsSingle }),
      ...(carrierStatus && { carrierStatus }),
      ...(coordinator && { coordinator }),
      ...(truckId && { truckId }),
      ...(isSingle && { isSingle }),
      ...(singleStatus && { singleStatus }),
      ...(editId && { editId }),
      ...(fuelAdvance && { fuelAdvance }),
      ...(feeType && { feeType }),
    },
    setSearchParams,
  };
};
