import { createApi } from '@reduxjs/toolkit/query/react';
import { ICollectionValues, IDistributeValues } from 'pages/payroll/tabs/information/constants/types';
import { baseQuery } from 'services/service';

import {
  createAdvanceDeserializer,
  createChargeDeserializer,
  createDistributeDeserializer,
  editAdvanceDeserializer,
  editChargeDeserializer,
} from './deserializers';
import {
  getAdvanceSerializer,
  getCollectionSerializer,
  getDistributeSerializer,
  getOtherServiceFeeSerializer,
  getRequestsParamsSerializer,
  getSummariesParamsSerializer,
  informationCreditCheckSerializer,
  informationCreditsChargesSerializer,
  informationFactoredSerializer,
  informationNotFactoredSerializer,
  informationWithFeeSerializer,
} from './serializers';
import {
  IAdvanceRes,
  ICollectionRes,
  IDistribute,
  IOtherServiceFee,
  IOtherServiceFeeItem,
  IPayrollCreditsCharges,
  ISummariesCards,
} from './types';

export const informationAPI = createApi({
  reducerPath: 'informationAPI',
  baseQuery,
  tagTypes: ['Information', 'Advance', 'Distribute', 'Charge', 'Collection'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getSummaries: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getSummariesParamsSerializer(params);
        return {
          url: '/payrolls/summaries',
          params: queryParams,
        };
      },
      transformResponse: (body: { result: ISummariesCards }) => {
        return body?.result;
      },
      providesTags: ['Information', 'Advance', 'Distribute'],
    }),
    getInformationFactored: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/factored',
          params: queryParams,
        };
      },
      transformResponse: (body: any) => {
        return informationFactoredSerializer(body);
      },
      providesTags: ['Information'],
    }),
    getInformationNotFactored: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/not-factored',
          params: queryParams,
        };
      },
      transformResponse: (body: any) => {
        return informationNotFactoredSerializer(body);
      },
      providesTags: ['Information'],
    }),
    // DISTRIBUTE
    getCredits: builder.query({
      query: (params: any) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/credits',
          params: queryParams,
        };
      },
      providesTags: ['Distribute'],
      transformResponse: (body: IPayrollCreditsCharges) => {
        return informationCreditsChargesSerializer(body, true);
      },
    }),
    getCharges: builder.query({
      query: (params: any) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/charges',
          params: queryParams,
        };
      },
      transformResponse: (body: IPayrollCreditsCharges) => {
        return informationCreditsChargesSerializer(body, false);
      },
      providesTags: ['Distribute'],
    }),
    moveToNextMonth: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `payrolls/charges/next-month/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Distribute'],
    }),
    moveToCollection: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `payrolls/charges/move-collection/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Distribute'],
    }),
    payCollection: builder.mutation({
      query: ({ id, amount, comment }: { id: number; amount: number; comment: number }) => ({
        url: `payrolls/collections/${id}/pay`,
        method: 'POST',
        body: { amount, comment },
      }),
      invalidatesTags: ['Distribute'],
    }),
    getCollectionPayDetailsById: builder.query({
      query: (id: string) => ({
        url: `/payrolls/collections/${id}/pay/histories`,
      }),
    }),
    getDistributeById: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/payrolls/distributes/${id}`,
      }),
      transformResponse: (body: IDistribute) => {
        return getDistributeSerializer(body);
      },
    }),
    createDistribute: builder.mutation({
      query: ({ id, body }: { id: string; body: IDistributeValues }) => ({
        url: `payrolls/distributes/${id}`,
        method: 'PUT',
        body: createDistributeDeserializer(body),
      }),
      invalidatesTags: ['Distribute'],
    }),
    // ADVANCE
    getAdvances: builder.query({
      query: (params: any) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/advance',
          params: queryParams,
        };
      },
      providesTags: ['Advance'],
      transformResponse: (body: IAdvanceRes) => {
        return getAdvanceSerializer(body);
      },
    }),
    getAdvanceById: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/payrolls/advance/${id}`,
      }),
    }),
    createAdvance: builder.mutation({
      query: ({ body }: { body: ICollectionValues }) => ({
        url: `payrolls/advance`,
        method: 'POST',
        body: createAdvanceDeserializer(body),
      }),
      invalidatesTags: ['Advance'],
    }),
    editAdvance: builder.mutation({
      query: ({ body, id }: { body: ICollectionValues; id: string }) => ({
        url: `payrolls/advance/${id}`,
        method: 'PUT',
        body: editAdvanceDeserializer(body, id),
      }),
      invalidatesTags: ['Advance'],
    }),
    deleteAdvance: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `payrolls/advance/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Advance'],
    }),
    // OTHER SERVICE FEE
    getOtherServiceFee: builder.query({
      query: (params: any) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/other-service-fee',
          params: queryParams,
        };
      },
      providesTags: ['Charge'],
      transformResponse: (body: IOtherServiceFee) => {
        return getOtherServiceFeeSerializer(body);
      },
    }),
    getOtherServiceFeeById: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/payrolls/other-service-fee/${id}`,
      }),
      transformResponse: (body: { result: IOtherServiceFeeItem }) => {
        return {
          ...body?.result,
          status: body?.result?.status === 2 ? false : true,
        };
      },
      providesTags: ['Charge'],
    }),
    createCharge: builder.mutation({
      query: (body: ICollectionValues) => ({
        url: `payrolls/other-service-fee`,
        method: 'POST',
        body: createChargeDeserializer(body),
      }),
      invalidatesTags: ['Charge'],
    }),
    editCharge: builder.mutation({
      query: ({ body, id }: { body: ICollectionValues; id: string }) => ({
        url: `payrolls/other-service-fee/${id}`,
        method: 'PUT',
        body: editChargeDeserializer(body, id),
      }),
      invalidatesTags: ['Charge'],
    }),
    deleteCharge: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `payrolls/other-service-fee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Charge'],
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }: { id: string; status: number }) => ({
        url: `payrolls/other-service-fee/${id}/status`,
        method: 'PUT',
        body: {
          id: Number(id),
          status,
        },
      }),
      invalidatesTags: ['Charge'],
    }),
    // COLLECTION
    getCollection: builder.query({
      query: (params: any) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/collections',
          params: queryParams,
        };
      },
      providesTags: ['Collection'],
      transformResponse: (body: ICollectionRes) => {
        return getCollectionSerializer(body);
      },
    }),
    getCollectionById: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/payrolls/collections/${id}`,
      }),
    }),
    editPaymentPlan: builder.mutation({
      query: ({ body, id }: { body: Partial<ICollectionValues>; id: string }) => ({
        url: `payrolls/collection/${id}`,
        method: 'PUT',
        body: {
          paymentPlan: Number(body?.paymentPlan),
        },
      }),
      invalidatesTags: ['Collection'],
    }),
    getInformationCreditCheck: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/creditCheck',
          params: queryParams,
        };
      },
      transformResponse: (body: any) => {
        return informationCreditCheckSerializer(body);
      },
      providesTags: ['Information'],
    }),
    getInformationWithFee: builder.query({
      query: (params: Partial<any>) => {
        const queryParams = getRequestsParamsSerializer(params);
        return {
          url: '/payrolls/factored/with-fee',
          params: queryParams,
        };
      },
      transformResponse: (body: any) => {
        return informationWithFeeSerializer(body);
      },
      providesTags: ['Information'],
    }),
  }),
});

export const {
  useGetSummariesQuery,
  useGetInformationFactoredQuery,
  useLazyGetInformationFactoredQuery,
  useGetInformationNotFactoredQuery,
  useLazyGetInformationNotFactoredQuery,
  useLazyGetChargesQuery,
  useLazyGetCreditsQuery,
  useCreateAdvanceMutation,
  useCreateDistributeMutation,
  useGetDistributeByIdQuery,
  useEditAdvanceMutation,
  useGetAdvanceByIdQuery,
  useLazyGetAdvancesQuery,
  useDeleteAdvanceMutation,
  useLazyGetOtherServiceFeeQuery,
  useCreateChargeMutation,
  useDeleteChargeMutation,
  useLazyGetCollectionQuery,
  useEditChargeMutation,
  useGetOtherServiceFeeByIdQuery,
  useGetCollectionByIdQuery,
  useEditPaymentPlanMutation,
  useChangeStatusMutation,
  useMoveToCollectionMutation,
  useMoveToNextMonthMutation,
  usePayCollectionMutation,
  useLazyGetCollectionPayDetailsByIdQuery,
  useGetInformationCreditCheckQuery,
  useLazyGetInformationCreditCheckQuery,
  useGetInformationWithFeeQuery,
  useLazyGetInformationWithFeeQuery,
} = informationAPI;
