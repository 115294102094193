import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import useCreateEditOffice from 'pages/profile/tabs/office/useCreateEditOffice';
import { OfficeSetUpTypes } from 'services/profile/offices/interfaces';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import DataGrid from 'ui/data-grid/DataGrid';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import StateSelect from 'ui/inputs/state-select/StateSelect';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { LOCATION_DROPDOWN_DATA, UserTypes } from 'utils/constants';
import {
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  nonAlphanumericSpaceFormatter,
  onlyNumbersFormatter,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { setIsValid } from '../../../../../../store/check-is-dirty-slice/isValid';
import { SUBJECT } from '../../constants/constants';
import { FeeTypes, IOfficeFormProps } from '../../constants/types';
import { Input, SearchableSelect, Select, StatusBar } from '../../hoc-instance/instance';
import WhiteList from '../white-list/WhiteList';

import styles from './CreateOfficeForm.module.scss';

const CreateOfficeForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  setValue,
  statesData,
  authoritiesData,
  authorityByOfficeId,
  refetch,
  setGetAuthoritiesFilter,
  setGetAuthoritiesByOfficeFilter,
  watch,
  isFetchingById,
  onAuthoritiesBlur,
  isCreateLoading,
  isAutocompleteFetching,
  isEditLoading,
  clearErrors,
  resetField,
}: IOfficeFormProps) => {
  const dispatch = useDispatch();
  const { permissionsInfo, userType } = useSelector(selectUserPermissionsInfo);

  useEffect(() => {
    dispatch(setIsValid({ isValid: !isCreateLoading && !isEditLoading }));
  }, [isCreateLoading, isEditLoading]);

  const { authorities, addedAuthorities, mode, handleRowDelete, handleAddAuthority, handlePaginationClick } =
    useCreateEditOffice({
      getValues,
      setValue,
      refetch,
      authoritiesData,
      authorityByOfficeId,
      isAutocompleteFetching,
      setGetAuthoritiesFilter,
      setGetAuthoritiesByOfficeFilter,
    });

  useEffect(() => {
    if (mode === 'add') {
      clearErrors();
    }
  }, []);

  useEffect(() => {
    trigger('hasValidAccounting');
  }, [watch('billing'), watch('payables'), watch('receivables')]);

  const billingOptions = useMemo(() => {
    return [
      { title: 'No access for agency', value: 1 },
      ...(permissionsInfo.permissions.factoring.billing.view ? [{ title: 'Only view for agency', value: 2 }] : []),
      ...(Object.values(permissionsInfo.permissions.factoring.billing.actions).some(el => el)
        ? [{ title: 'Action for agency', value: 3 }]
        : []),
      ...(Object.values(permissionsInfo.permissions.factoring.billing.actions).some(el => el)
        ? [{ title: 'Access for both', value: 4 }]
        : []),
    ];
  }, [permissionsInfo]);

  const payableOptions = useMemo(() => {
    return [
      { title: 'No access for agency', value: 1 },
      ...(permissionsInfo.permissions.factoring.payable.view ? [{ title: 'Only view for agency', value: 2 }] : []),
      ...(Object.values(permissionsInfo.permissions.factoring.payable.actions).some(el => el)
        ? [{ title: 'Action for agency', value: 3 }]
        : []),
      ...(Object.values(permissionsInfo.permissions.factoring.payable.actions).some(el => el)
        ? [{ title: 'Access for both', value: 4 }]
        : []),
    ];
  }, [permissionsInfo]);

  const receivableOptions = useMemo(() => {
    return [
      { title: 'No access for agency', value: 1 },
      ...(permissionsInfo.permissions.factoring.receivable.view ? [{ title: 'Only view for agency', value: 2 }] : []),
      ...(Object.values(permissionsInfo.permissions.factoring.receivable.actions).some(el => el)
        ? [{ title: 'Action for agency', value: 3 }]
        : []),
      ...(Object.values(permissionsInfo.permissions.factoring.receivable.actions).some(el => el)
        ? [{ title: 'Access for both', value: 4 }]
        : []),
    ];
  }, [permissionsInfo]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: '30px' }}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper title={mode === 'add' ? SUBJECT.ADD_NEW_OFFICE : SUBJECT.EDIT_OFFICE}>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="name"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.NAME}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) =>
                setValue('name', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
              }
            />
          </Col>
          <Col span={8}>
            <Controller
              name="type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <SimpleSelect
                    options={[
                      { title: 'Broker', value: OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE },
                      { title: 'Agency', value: OfficeSetUpTypes.OFFICE_SET_UP_TYPES_INSIDE },
                    ]}
                    placeholder={SUBJECT.TYPE}
                    withCheckbox={false}
                    required
                    errors={errors}
                    onBlur={() => trigger(field.name)}
                    onChange={(value: string | string[]) => {
                      field.onChange(value);
                      clearErrors();
                    }}
                    value={field.value}
                    name={field.name}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.type} />
          </Col>
          {+watch('type') === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE && (
            <Col span={8}>
              <Controller
                name="creditCheck"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <SimpleSelect
                      options={[
                        { title: 'Yes', value: 1 },
                        { title: 'No', value: 2 },
                      ]}
                      placeholder={SUBJECT.CREDIT_CHECK}
                      withCheckbox={false}
                      required
                      errors={errors}
                      onBlur={() => trigger(field.name)}
                      onChange={(value: string | string[]) => {
                        field.onChange(value);
                        clearErrors();
                      }}
                      value={field.value}
                      name={field.name}
                    />
                  );
                }}
              />
              <ErrorMessage fieldError={errors?.creditCheck} />
            </Col>
          )}
          <Col span={24}>
            <Row gutter={18}>
              <Col span={8}>
                <Input
                  name="phoneNumber"
                  type="text"
                  control={control}
                  rules={{ required: true }}
                  placeholder={SUBJECT.PHONE_NUMBER}
                  getValues={watch}
                  onChange={(e: any) =>
                    setValue('phoneNumber', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  required
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Input
                  name="fax"
                  type="text"
                  control={control}
                  rules={{ required: false }}
                  onChange={(e: any) =>
                    setValue('fax', formatPhone(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  placeholder={SUBJECT.FAX}
                  getValues={watch}
                  errors={errors}
                />
              </Col>
              <Col span={8}>
                <Controller
                  name="officeLocation"
                  control={control}
                  render={({ field }) => {
                    return (
                      <SimpleSelect
                        options={LOCATION_DROPDOWN_DATA}
                        placeholder={SUBJECT.OFFICE_LOCATION}
                        withCheckbox={false}
                        errors={errors}
                        allowClear
                        resetFunc={() => resetField('officeLocation')}
                        onBlur={() => trigger(field.name)}
                        onChange={(value: string | string[]) => field.onChange(value)}
                        value={field.value}
                        name={field.name}
                      />
                    );
                  }}
                />
                <ErrorMessage fieldError={errors?.officeLocation} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Input
              name="address"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.ADDRESS}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) => setValue('address', e.target.value.trimStart(), { shouldValidate: true })}
            />
          </Col>
          <Col span={12}>
            <Input
              name="secondAddress"
              type="text"
              control={control}
              placeholder={SUBJECT.SECOND_ADDRESS}
              getValues={getValues}
              errors={errors}
              onChange={(e: any) => setValue('secondAddress', e.target.value.trimStart())}
            />
          </Col>
          <Col span={8}>
            <Input
              name="city"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.CITY}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) => setValue('city', e.target.value.trimStart(), { shouldValidate: true })}
            />
          </Col>
          <Col span={8}>
            <Controller
              name="stateId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <StateSelect
                    options={statesData}
                    placeholder={SUBJECT.STATE}
                    name={field.name}
                    errors={errors}
                    onBlur={() => trigger('stateId')}
                    onChange={(value: string) =>
                      setValue('stateId', value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    value={field.value}
                    required
                    allowClear
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.stateId} />
          </Col>
          <Col span={8}>
            <Input
              name="zipcode"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.ZIPCODE}
              onChange={(e: any) =>
                setValue('zipcode', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              getValues={getValues}
              required
              errors={errors}
            />
          </Col>
        </Row>

        <div className={styles.payoutBlock}>
          <Typography variant="paragraph" className={styles.title}>
            {SUBJECT.PAYOUT_PERCENTAGE}
          </Typography>
          <StatusBar name="payout" control={control} required errors={errors} />
        </div>
      </ViewItemWrapper>
      {+watch('type') === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE && (
        <ViewItemWrapper title={SUBJECT.ACCOUNTING}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Select
                options={billingOptions}
                placeholder={SUBJECT.BILLING}
                control={control}
                name="billing"
                withAll={false}
                rules={{ required: true }}
                required
                errors={errors}
                onBlur={() => trigger('billing')}
              />
            </Col>
            <Col span={8}>
              <Select
                options={payableOptions}
                placeholder={SUBJECT.PAYABLES}
                control={control}
                name="payables"
                withAll={false}
                rules={{ required: true }}
                required
                errors={errors}
                onBlur={() => trigger('payables')}
              />
            </Col>
            <Col span={8}>
              <Select
                options={receivableOptions}
                placeholder={SUBJECT.RECEIVABLES}
                control={control}
                name="receivables"
                withAll={false}
                rules={{ required: true }}
                required
                errors={errors}
                onBlur={() => trigger('receivables')}
              />
            </Col>
            <Col span={24}>
              <ErrorMessage fieldError={errors?.hasValidAccounting} />
            </Col>
          </Row>
        </ViewItemWrapper>
      )}
      {+watch('type') === OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE && +watch('creditCheck') === 1 && (
        <ViewItemWrapper title={SUBJECT.CREDIT_CHECK}>
          <Row gutter={[18, 40]}>
            <Col span={8}>
              <Input
                name="creditCheckPayAmount"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.MONTHLY_AMOUNT}
                onChange={(e: any) =>
                  setValue('creditCheckPayAmount', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={
                  watch('creditCheckPayAmount')
                    ? formatNumberWithThousandsSeparatorAndDecimal(watch('creditCheckPayAmount'))
                    : ''
                }
                required
                suffix={<DollarIcon />}
                errors={errors}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
      )}

      <ViewItemWrapper title={SUBJECT.BANK_INFORMATION}>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Input
              name="bankName"
              type="text"
              control={control}
              placeholder={SUBJECT.BANK_NAME}
              getValues={getValues}
              onChange={(e: any) => {
                setValue('bankName', nonAlphanumericSpaceFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <Input
              name="accounting_number"
              type="text"
              control={control}
              placeholder={SUBJECT.ACCOUNTING_NUMBER}
              getValues={getValues}
              onChange={(e: any) => {
                setValue('accounting_number', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              errors={errors}
            />
          </Col>
          <Col span={8}>
            <Input
              name="ach_routing_number"
              type="number"
              control={control}
              placeholder={SUBJECT.ACH_ROUTING_NUMBER}
              getValues={getValues}
              errors={errors}
              onChange={(e: any) => {
                setValue('ach_routing_number', onlyNumbersFormatter(e.target.value), {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </Col>
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.ADD_AUTHORITIES} required>
        <Row>
          <Col span={24}>
            <SearchableSelect
              isMulti="multiple"
              name="authorities"
              control={control}
              rules={{ required: true }}
              withMarking={false}
              placeholder={SUBJECT.SEARCH_SELECT}
              withButton
              buttonText={SUBJECT.ADD_AUTHORITIES_BTN}
              options={authorities}
              handleClick={handleAddAuthority}
              value={getValues('authorities')}
              offset={authoritiesData?.count}
              setOffset={setGetAuthoritiesFilter}
              onBlur={onAuthoritiesBlur}
              errors={errors}
              required
              isLoading={isAutocompleteFetching}
            />
          </Col>
          {!!addedAuthorities?.length && (
            <Col span={24}>
              <div className={styles.addedBlock}>
                <Typography variant="paragraph">{`${SUBJECT.ADDED}(${
                  mode === 'edit' ? authorityByOfficeId.count : addedAuthorities.length
                })`}</Typography>
              </div>
              <DataGrid
                handleRowDelete={handleRowDelete}
                data={mode === 'edit' ? authorityByOfficeId?.result : addedAuthorities}
                count={mode === 'edit' ? authorityByOfficeId?.count : addedAuthorities.length}
                type="authorities"
                handlePaginationClick={handlePaginationClick}
                isDeleteDisabled={mode === 'edit' && addedAuthorities.length === 1}
              />
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.LPF_SOFTWARE_FEE}>
        <Row gutter={[18, 40]}>
          <Col span={8}>
            <Controller
              name="feeType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <SimpleSelect
                    options={[
                      { title: 'LPF', value: FeeTypes.lpf },
                      { title: 'Software fee', value: FeeTypes.software },
                    ]}
                    placeholder={SUBJECT.FEE_TYPE}
                    withCheckbox={false}
                    required
                    errors={errors}
                    onBlur={() => trigger(field.name)}
                    onChange={(value: string | string[]) => {
                      field.onChange(value);
                      clearErrors();
                      resetField('fee');
                    }}
                    value={field.value}
                    name={field.name}
                  />
                );
              }}
            />
            <ErrorMessage fieldError={errors?.feeType} />
          </Col>
          {watch('feeType') && (
            <Col span={8}>
              <Input
                name="fee"
                type="text"
                control={control}
                rules={{ required: !!watch('feeType') }}
                placeholder={watch('feeType') === FeeTypes.lpf ? SUBJECT.PER_LOAD_AMOUNT : SUBJECT.MONTHLY_AMOUNT}
                onChange={(e: any) =>
                  setValue('fee', onlyNumbersFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                value={watch('fee') ? formatNumberWithThousandsSeparatorAndDecimal(watch('fee')) : ''}
                required={!!watch('feeType')}
                suffix={<DollarIcon />}
                errors={errors}
              />
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      {userType === UserTypes.ADMIN && mode === 'edit' && <WhiteList />}
    </form>
  );
};
export default CreateOfficeForm;
