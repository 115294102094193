import { ReactNode } from 'react';
import { CreatorTypes } from 'api/notifications/constants';
import { EMessageTypes, Handler, ISocketMessage } from 'api/notifications/interfaces';
import Notification from 'api/notifications/notification/Notification';
import { formatDate } from 'utils/dates';

export class PayrollCollectionHandler implements Handler {
  public readonly handlers: Record<string, (event: ISocketMessage) => void> = {};
  public notification: ReactNode | null = null;
  private creatorLink = '';
  constructor() {
    this.handlers = {
      pay: this.handlePay,
    };
  }
  handlePay = (event: ISocketMessage) => {
    const {
      creator: { title: creatorName, id: creatorID, type },
      event: { title },
      createdAt,
      read,
    } = event.payload;
    this.creatorLink = `/profile?tab=${
      CreatorTypes[type as keyof typeof CreatorTypes]
    }&open=true&mode=view&headerFilter=carriers&id=${creatorID}`;
    this.notification = (
      <Notification
        read={read}
        message={[
          { id: creatorID, title: creatorName, ...(type !== 1 ? { link: this.creatorLink } : {}) },
          { title: `Paid $${title} from collection` },
        ]}
        title="Collection paid"
        date={formatDate(createdAt)}
        messageType={EMessageTypes.success}
      />
    );
  };
}
