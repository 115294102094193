import React from 'react';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { SUBJECT } from 'pages/profile/tabs/office/constants/constants';
import { IOfficeFormProps } from 'pages/profile/tabs/office/constants/types';
import { Location } from 'services/profile/agents/interfaces';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatPhone } from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import CreateOfficeForm from '../create-form/CreateOfficeForm';

import styles from './EditOfficeForm.module.scss';

const EditOfficeForm = ({
  handleSubmit,
  onSubmit,
  control,
  trigger,
  getValues,
  errors,
  setValue,
  data,
  statesData,
  watch,
  authoritiesData,
  authorityByOfficeId,
  refetch,
  getAuthoritiesFilter,
  setGetAuthoritiesFilter,
  getAuthoritiesByOfficeFilter,
  setGetAuthoritiesByOfficeFilter,
  isFetchingById,
  onAuthoritiesBlur,
  isEditLoading,
  isCreateLoading,
  isAutocompleteFetching,
  clearErrors,
  resetField,
}: IOfficeFormProps) => {
  const { copiedText, handleCopy } = useHandleCopyActive();
  return (
    <div className={styles.officeWrapper}>
      <MainLoader isFetching={isFetchingById} />

      <ViewItemWrapper title={SUBJECT.OFFICE_INFO}>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.OFFICE_NAME}:{' '}
              </Typography>
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.name}
                subject={`${SUBJECT.OFFICE_INFO}${SUBJECT.OFFICE_NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.LOCATION}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {Location[data?.result?.location]}
              </Typography>
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.OFFICE_ID}:{' '}
              </Typography>
              <Col span={13}>
                <div
                  className={clsx(styles.copyIconText, {
                    [styles.copyActive]: copiedText === 'topBannerId',
                  })}
                >
                  <TooltipText
                    subject={`${SUBJECT.OFFICE_INFO}${SUBJECT.OFFICE_ID}`}
                    prefixClass="top-banner-value"
                    data={data?.result?.id}
                  />
                  <div
                    className={styles.copyIcon}
                    role="button"
                    onClick={() => handleCopy(data?.result?.id, 'topBannerId')}
                  >
                    <CopyIcon active={copiedText === 'topBannerId'} />
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 15]}>
          <Col span={8}>
            <div className={styles.headingBlock}>
              <Typography variant="paragraph" className="top-banner-title">
                {SUBJECT.NUMBER_OF_BRANCHES}:{' '}
              </Typography>
              <Typography variant="paragraph" className="top-banner-value">
                {data?.result?.branchCount}
              </Typography>
            </div>
          </Col>
          {data?.result?.owners?.length ? (
            <Col span={15}>
              <div className={clsx(styles.headingBlock, styles.phoneWrapper)}>
                <Typography variant="paragraph" className="top-banner-title">
                  {data?.result?.owners?.length === 1 ? SUBJECT.OWNER_NUMBER : SUBJECT.OWNERS_NUMBER}:
                </Typography>
                {data?.result?.owners?.length === 1 && (
                  <div className={styles.phoneItem}>
                    <a className={styles.phoneLinkInArray} href={`tel:${data?.result?.owners[0]?.phone}`}>
                      {formatPhone(data?.result?.owners[0]?.phone)}
                    </a>
                    <Typography variant="paragraph" className={clsx(styles.phoneNameInArray, 'top-banner-value')}>
                      {`${data?.result?.owners[0]?.firstName} ${data?.result?.owners[0]?.lastName}`}
                    </Typography>
                  </div>
                )}
                {data?.result?.owners?.length > 1 && (
                  <Popover
                    placement="top"
                    content={data?.result?.owners?.map((el: any, index: any) => (
                      <div className={clsx(styles.phoneItem)} key={index}>
                        <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                          {formatPhone(el?.phone)}
                        </a>
                        <Typography variant="paragraph" className={clsx(styles.phoneNameInArray, 'top-banner-value')}>
                          {`${el?.firstName} ${el?.lastName}`}
                        </Typography>
                      </div>
                    ))}
                  >
                    <a className="top-banner-value">{`+${data?.result?.owners?.length}`}</a>
                  </Popover>
                )}
              </div>
            </Col>
          ) : null}
        </Row>
      </ViewItemWrapper>
      <CreateOfficeForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        control={control}
        trigger={trigger}
        getValues={getValues}
        errors={errors}
        setValue={setValue}
        statesData={statesData}
        authoritiesData={authoritiesData}
        authorityByOfficeId={authorityByOfficeId}
        refetch={refetch}
        getAuthoritiesFilter={getAuthoritiesFilter}
        setGetAuthoritiesFilter={setGetAuthoritiesFilter}
        getAuthoritiesByOfficeFilter={getAuthoritiesByOfficeFilter}
        setGetAuthoritiesByOfficeFilter={setGetAuthoritiesByOfficeFilter}
        watch={watch}
        isFetchingById={isFetchingById}
        onAuthoritiesBlur={onAuthoritiesBlur}
        isEditLoading={isEditLoading}
        isCreateLoading={isCreateLoading}
        isAutocompleteFetching={isAutocompleteFetching}
        clearErrors={clearErrors}
        resetField={resetField}
        // authorityOrder={authorityOrder}
        // handleSortAuthorities={handleSortAuthorities}
      />
    </div>
  );
};

export default EditOfficeForm;
