import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import {
  useCreateWhiteListIPMutation,
  useDeleteWhiteListIPMutation,
  useGetWhiteListQuery,
  useUpdateWhiteListIPMutation,
} from 'services/permission/permission';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { defaultValues, SUBJECT } from './constants/constants';
import { WhiteList } from './constants/interfaces';
import { validation } from './constants/validation';

export const useWhiteList = () => {
  const whiteListFormRef = useRef<HTMLDivElement>(null);

  const [editingRow, setEditingRow] = useState<number>();

  const dispatch = useDispatch();

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, id: officeId, editingId } = searchParams;

  const { data: whiteListData } = useGetWhiteListQuery({ entityId: officeId }, { skip: !officeId });
  const [editWhiteListIP, { isLoading }] = useUpdateWhiteListIPMutation();
  const [addWhiteListIP, { isLoading: isCreateLoading }] = useCreateWhiteListIPMutation();
  const [deleteWhiteListIP] = useDeleteWhiteListIPMutation();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, dirtyFields },
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    trigger,
    setError,
  } = useForm<WhiteList>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validation),
  });

  const handleAddWhiteListIP = (data: any) => {
    if (editingId) {
      handleUpdateWhiteListIP(data);
    } else {
      handleCreateWhiteListIP(data);
    }
  };

  const handleCreateWhiteListIP = (data: any) => {
    addWhiteListIP({
      officeId,
      data,
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully added',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({ ...searchParams, editingId: '' });
        setTimeout(() => whiteListFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
        setTimeout(() => reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false }), 0);
        clearErrors();
      }
    });
  };
  const handleUpdateWhiteListIP = (data: any) => {
    editWhiteListIP({
      id: editingId,
      data,
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Successfully edited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        setSearchParams({ ...searchParams, editingId: '' });
        setTimeout(() => whiteListFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
        setTimeout(() => reset(defaultValues, { keepDirty: false, keepErrors: false, keepTouched: false }), 0);
        clearErrors();
      }
    });
  };

  const handleRowEdit = (values: any) => {
    setTimeout(() => whiteListFormRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
    setSearchParams({ ...searchParams, editingId: values.id });
    const editingElement = whiteListData?.result?.find((el: any) => el.id == values.id);
    reset(editingElement);
  };
  const handleRowDelete = (values: any) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteWhiteListIP({
            id: values?.id,
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(true));
            } else {
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  return {
    reset,
    watch,
    trigger,
    setValue,
    getValues,
    clearErrors,
    setError,
    control,
    isValid,
    isDirty,
    errors,
    dirtyFields,
    handleAddIpAddress: handleSubmit(handleAddWhiteListIP),
    mode,
    editingRow,
    setEditingRow,
    handleRowEdit,
    handleRowDelete,
    editingId,
    isLoading: isLoading || isCreateLoading,
    whiteListFormRef,
    whiteListData: whiteListData?.result || [],
    whiteListCount: whiteListData?.count || 0,
  };
};
