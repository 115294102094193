import React, { useDeferredValue } from 'react';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import Button from 'ui/button/Button';
import Typography from 'ui/typography/Typography';

import ResetIcon from 'components/svgs/ResetIcon';
import SelectArrowIcon from 'components/svgs/SelectArrowIcon';
import Table from 'components/table/Table';

import { SUBJECT, TYPE_FILTER_OPTIONS } from '../../constants/constants';
import { FiltersSelect } from '../../hoc-instance/instance';

import { RATING_OPTIONS } from './constants/constants';
import { ICarriersTableProps } from './constants/types';
import useCarriersTable from './useCarriersTable';

import styles from '../../Carriers.module.scss';

const CarriersTable = ({
  filterControl,
  watchFilter,
  setFilterValue,
  resetFilterForm,
  setExcelParams,
  carriersFilter,
  setCarriersFilter,
  data,
  isLoading,
}: ICarriersTableProps) => {
  const {
    columns,
    insuranceDays,
    insuranceDay,
    carrierTypeFilter,
    rating,
    rateList,
    handleGetRatings,
    handleResetFilters,
    handleColumnSearch,
    handleRowClick,
    handleRowEdit,
    handleSortClick,
    handleStopResize,
    handleRateClick,
    handleCommentClick,
    handleSwitchClick,
    handlePaginationClick,
    handleSortByInsurance,
    carrierTablePagePermissions: { edit, terminateOn, terminateOff },
  } = useCarriersTable({
    watchFilter,
    setFilterValue,
    resetFilterForm,
    setExcelParams,
    carriersFilter,
    setCarriersFilter,
    data,
    isLoading,
  });

  const tableProps = useDeferredValue({
    data,
    skip: carriersFilter?.skip,
    limit: carriersFilter?.limit,
    columns,
    isLoading,
    rateList,
    onGetRatings: handleGetRatings,
    onSort: handleSortClick,
    onRowClick: handleRowClick,
    onResizeStop: handleStopResize,
    onSwitchClick: handleSwitchClick,
    onColumnSearch: handleColumnSearch,
    handleRowEdit,
    onPaginationClick: handlePaginationClick,
    onRateChange: handleRateClick,
    onCommentClick: handleCommentClick,
    hasTerminateOnPermission: terminateOn,
    hasTerminateOffPermission: terminateOff,
    hasEditPermission: edit,
    withMenu: edit,
  });

  return (
    <>
      <Row align="bottom" gutter={[18, 16]} className={styles.filters}>
        <Col span={10}>
          <Row gutter={20}>
            <Col span={12}>
              <FiltersSelect
                suffixIcon={<SelectArrowIcon />}
                withAll
                options={TYPE_FILTER_OPTIONS}
                control={filterControl}
                label={SUBJECT.CARRIER_TYPE}
                placeholder={SUBJECT.NOT_SELECTED}
                name="carrierType"
                isFilter
              />
            </Col>
            <Col span={12}>
              <FiltersSelect
                suffixIcon={<SelectArrowIcon />}
                withAll
                withCheckbox
                isMultiSelect
                options={RATING_OPTIONS}
                control={filterControl}
                label={SUBJECT.RATING}
                placeholder={SUBJECT.NOT_SELECTED}
                name="ratings"
                isFilter
              />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row align="middle" justify="space-between">
            {insuranceDays?.result && (
              <div className={styles.carrierTopFilters}>
                <Col
                  className={clsx(styles.carrierDayFilter, {
                    [styles.active]: insuranceDay === '4',
                  })}
                  onClick={() => handleSortByInsurance(4)}
                >
                  <Col className={styles.leftColorBlockGrey} />
                  <Typography
                    variant="paragraph"
                    children={`90 day: ${insuranceDays.result?.thirdInterval}`}
                    className={styles.carrierDayText}
                  />
                </Col>
                <Col
                  className={clsx(styles.carrierDayFilter, {
                    [styles.active]: insuranceDay === '2',
                  })}
                  onClick={() => handleSortByInsurance(2)}
                >
                  <Col className={styles.leftColorBlockBlue} />
                  <Typography
                    variant="paragraph"
                    children={`60 day: ${insuranceDays.result?.secondInterval}`}
                    className={styles.carrierDayText}
                  />
                </Col>
                <Col
                  className={clsx(styles.carrierDayFilter, {
                    [styles.active]: insuranceDay === '1',
                  })}
                  onClick={() => handleSortByInsurance(1)}
                >
                  <Col className={styles.leftColorBlockYellow} />
                  <Typography
                    variant="paragraph"
                    children={`30 day: ${insuranceDays.result?.firstInterval}`}
                    className={styles.carrierDayText}
                  />
                </Col>
                <Col
                  className={clsx(styles.carrierDayFilter, {
                    [styles.active]: insuranceDay === '3',
                  })}
                  onClick={() => handleSortByInsurance(3)}
                >
                  <Col className={styles.leftColorBlockRed} />
                  <Typography
                    variant="paragraph"
                    children={`0 day: ${insuranceDays.result?.expired}`}
                    className={styles.carrierDayText}
                  />
                </Col>
              </div>
            )}

            {(carrierTypeFilter || insuranceDay || !!rating?.length) && (
              <Col>
                <div className={styles.reset}>
                  <Button
                    icon={<ResetIcon />}
                    variant="ghost"
                    onClick={handleResetFilters}
                    children={SUBJECT.RESET_ALL}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {!isLoading && !data && (
        <div className={styles.customersEmpty}>
          <Typography variant="paragraph">{SUBJECT.CARRIERS_LIST_IS_EMPTY}</Typography>
        </div>
      )}
      <Table {...tableProps} />
    </>
  );
};

export default CarriersTable;
