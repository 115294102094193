const CreditCheckIcon = () => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.38962 17.4534V15.8734H0.597656V13.837H8.2167V10.0801H1.7212C1.41691 10.0801 1.15358 9.97482 0.931209 9.76415C0.708841 9.55349 0.597656 9.2726 0.597656 8.92149V3.30377C0.597656 3.02288 0.702988 2.77125 0.913653 2.54888C1.12432 2.32651 1.3818 2.21533 1.68609 2.21533H4.38962V0.705566H6.46116V2.21533H10.2531V4.28687H2.63408V8.04372H9.12958C9.43387 8.04372 9.6972 8.14906 9.91957 8.35972C10.1419 8.57038 10.2531 8.83957 10.2531 9.16727V14.785C10.2531 15.0893 10.1478 15.3468 9.93713 15.5574C9.72646 15.7681 9.46898 15.8734 9.16469 15.8734H6.46116V17.4534H4.38962ZM14.5718 25.88L8.74336 20.0516L10.1829 18.6121L14.5718 23.0009L22.9632 14.6094L24.4028 16.049L14.5718 25.88Z"
        fill="black"
      />
    </svg>
  );
};

export default CreditCheckIcon;
