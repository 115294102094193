import { VALIDATION_MESSAGES } from 'pages/authorization/sign-in/constants/constants';
import { AgentPayType, AgentType } from 'services/profile/agents/interfaces';
import { emailPattern, phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { SUBJECT } from './constants';
import { IAddBrackedFormData } from './types';

const {
  FIRST_NAME_REQUIRED,
  BRANCH_REQUIRED,
  PAY_TYPE_REQUIRED,
  PAID_AS_REQUIRED,
  AGENT_PAY_TYPE_REQUIRED,
  LAST_NAME_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  STATE_REQUIRED,
  ZIP_REQUIRED,
  AGENT_TYPE_REQUIRED,
  USERNAME_REQUIRED,
  EMAIL_REQUIRED,
  OFFICE_REQUIRED,
  SSN_OR_EIN_NUMBER_IS_REQUIRED,
  LOCATION_REQUIRED,
  BRACKET_REQUIRED,
  SSN_NUMBER,
  EIN_NUMBER,
  INVALID_EMAIL,
  PHONE,
  MAX_LENGTH,
  NAME_REQUIRED,
  RELATION_REQUIRED,
  PHONE_NUMBER_EMERGENCY_REQUIRED,
  AUTHORITIES_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MAX_MIN_ZIP_LENGTH,
} = VALIDATION_MESSAGES;

export const validation = (isUserNameExists: any) => {
  return yup.object().shape({
    first_name: yup.string().required(FIRST_NAME_REQUIRED),
    last_name: yup.string().required(LAST_NAME_REQUIRED),
    user_name: yup
      .string()
      .required(USERNAME_REQUIRED)
      .test('isUserNameExists', SUBJECT.USERNAME_EXISTS, function checkTotal(value = '') {
        if (value) {
          return !isUserNameExists;
        }
        return false;
      }),
    phone_number: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),

    second_number: yup
      .string()
      .nullable()
      .test('isCorrectPhone', 'isCorrectPhone', function checkIsPhoneCorrect(value) {
        if ((value && value.length === 12) || !value) {
          return true;
        } else if (!value?.match(phoneRegExp)) {
          return this.createError({
            message: PHONE,
            path: 'second_number',
          });
        } else {
          return this.createError({
            message: MAX_LENGTH,
            path: 'second_number',
          });
        }
      }),
    address: yup.string().required(ADDRESS_REQUIRED).max(300, ADDRESS_MAX_LENGTH),
    city: yup.string().required(CITY_REQUIRED),
    state: yup.string().required(STATE_REQUIRED),
    zip_code: yup.string().required(ZIP_REQUIRED).max(5, MAX_MIN_ZIP_LENGTH).min(5, MAX_MIN_ZIP_LENGTH),
    agentType: yup.string().required(AGENT_TYPE_REQUIRED),
    email: yup.string().email(INVALID_EMAIL).required(EMAIL_REQUIRED).matches(emailPattern, INVALID_EMAIL),
    office: yup.lazy(value =>
      Array.isArray(value) ? yup.array().required().min(1, OFFICE_REQUIRED) : yup.string().required()
    ),
    branch: yup.array().test('length', BRANCH_REQUIRED, function checkLength(value: any) {
      if (this?.parent.agentType === String(AgentType.Owner)) {
        return value !== '' || `${value}` !== 'All' || !value.length;
      } else {
        return !!value?.length;
      }
    }),
    pay_type: yup.string().test('isRequired', PAY_TYPE_REQUIRED, function checkIsRequired(value) {
      return !((this.parent?.paid_as?.length || this.parent?.agent_pay_type?.length) && !value?.length);
    }),
    paid_as: yup.string().test('isRequired', PAID_AS_REQUIRED, function checkIsRequired(value) {
      return !((this.parent?.agent_pay_type?.length || this.parent?.pay_type?.length) && !value?.length);
    }),
    agent_pay_type: yup.string().test('isRequired', AGENT_PAY_TYPE_REQUIRED, function checkIsRequired(value) {
      return !((this.parent?.paid_as?.length || this.parent?.pay_type?.length) && !value?.length);
    }),
    ssn_ein_number: yup
      .string()
      .test('isRequired', SSN_OR_EIN_NUMBER_IS_REQUIRED, function checkIsRequired(value = '') {
        if (
          (this.parent?.paid_as?.length || this.parent?.pay_type?.length || this.parent?.agent_pay_type?.length) &&
          !value?.length
        ) {
          return this.createError({
            message: AgentPayType[this?.parent?.agent_pay_type] === 'SSN' ? SSN_NUMBER : EIN_NUMBER,
            path: 'ssn_ein_number',
          });
        } else if (AgentPayType[this?.parent?.agent_pay_type] === 'SSN' && value?.length !== 11) {
          return this.createError({
            message: SSN_NUMBER,
            path: 'ssn_ein_number',
          });
        } else if (AgentPayType[this?.parent?.agent_pay_type] === 'EIN' && value?.length !== 10) {
          return this.createError({
            message: EIN_NUMBER,
            path: 'ssn_ein_number',
          });
        }
        return true;
      }),
    location: yup.string(),
    authorities: yup.mixed().test('length', AUTHORITIES_REQUIRED, function checkLength(arr = []) {
      return Array.isArray(arr) && arr.length > 0;
    }),
    brackeds: yup.array().test('length', BRACKET_REQUIRED, function checkLength(arr = []) {
      return arr.length > 0;
    }),

    file: yup.array(),
    emergencyName: yup.string().required(NAME_REQUIRED),
    emergencyRelation: yup.string().required(RELATION_REQUIRED),
    emergencyPhone: yup
      .string()
      .required(PHONE_NUMBER_EMERGENCY_REQUIRED)
      .max(12, MAX_LENGTH)
      .matches(phoneRegExp, PHONE),
  });
};

export const brackedValidation = (values: IAddBrackedFormData[]) => {
  const percents = values?.map(item => item?.percent) || [];
  return yup.object().shape({
    percent: yup
      .number()
      .required('Percent is a required field')
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .max(100, 'Percent must be less than 100')
      .notOneOf([undefined, 0, null, NaN], 'Value must be more than 0 or equal 1')
      .positive('Value must be positive')
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .moreThan(Math.max(...(percents as number[])), 'Value should be more than existing percents'),
    from: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .lessThan(yup.ref('to'), ''),
    to: yup
      .number()
      .required('To is a required field')
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .positive('Please enter positive number')
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .moreThan(yup.ref('from'), 'To should be more than from'),
  });
};
