import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tooltip } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { LoadTypes } from 'pages/loads/components/edit-load/constants/types';
import { Switch } from 'pages/loads/components/edit-load/hoc-instance/instance';
import { RatingAccessTypes } from 'services/profile/carriers/interfaces';
import Button from 'ui/button/Button';
import CustomSwitch from 'ui/inputs/switch/Switch';
import LoadStatus from 'ui/load-status/LoadStatus';
import Rate from 'ui/rate/Rate';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatPhone } from 'utils/helpers';

import CopyIcon from 'components/svgs/CopyIcon';
import FuelIcon from 'components/svgs/FuelIcon';
import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { ConfirmationTypes, SUBJECT } from '../../constants/constants';
import { IBanner } from '../../constants/types';
import { useBanner } from '../../useBanner';

import styles from '../../LoadsComment.module.scss';

const RegularBanner = ({
  data,
  regularControl,
  handleSwitchMarkAsClaim,
  regularWatch,
  activeSection,
  withActions = false,
  handleCancelLoad,
  inView,
  hasClaimPermission,
  hasDeClaimPermission,
  hasCancelPermission,
  hasEditPermission,
  handleChangeMode,
  hasAccessToViewHistory,
  handleCommentClick,
  handleScrollToHistory,
  loadHistory,
}: IBanner) => {
  const {
    searchParams: { mode },
  } = useDetectedParams();
  const { copiedText, handleCopy } = useHandleCopyActive();

  const { rateList, handleCarrierRate, getCarrierRatings, handleDeleteRate } = useBanner({
    data,
  });

  return (
    <>
      <div
        className={clsx(styles.topBanner, {
          [styles.editMode]: mode === 'edit',
        })}
      >
        <ViewItemWrapper
          id="regular-load"
          title={SUBJECT.LOAD_NUMBER}
          className={activeSection === SUBJECT.REGULAR_LOAD_ID ? 'active' : ''}
          copyValue={data?.load?.code}
          Component={
            hasEditPermission && (
              <>
                {data?.load?.fuelAdvance && (
                  <Tooltip placement="top" title="Fuel Advance" color={'white'}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#DDFDFDCC',
                        padding: '8px',
                        borderRadius: '50%',
                      }}
                    >
                      <FuelIcon />
                    </div>
                  </Tooltip>
                )}
                <LoadStatus
                  loadStatus={Number(data?.load?.status)}
                  carrierStatus={Number(data?.load?.carrierStatus)}
                  customerStatus={Number(data?.load?.customerStatus)}
                  isProcessing={data?.load?.processing || false}
                  withoutLoadsText
                />
                <Button children={SUBJECT.EDIT} onClick={handleChangeMode} className="viewDrawerEditBtn" />
              </>
            )
          }
        >
          <Row gutter={[10, 20]}>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.LOAD_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title"> {SUBJECT.LOAD_TYPE}</span>{' '}
                    <span className="top-banner-value"> {LoadTypes[data?.load?.loadType]}</span>
                  </Typography>
                </Col>
                {!!data?.load?.requestStatus && (
                  <Col span={24}>
                    <Typography variant="paragraph" className={styles.textItem}>
                      <span className="top-banner-title">{SUBJECT.CONFIRMATION_TYPE}</span>{' '}
                      <span className="top-banner-value">{ConfirmationTypes[data?.load?.requestStatus]}</span>
                    </Typography>
                  </Col>
                )}
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.OFFICE_NAME}</span>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.load?.office?.name}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.OFFICE_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title"> {SUBJECT.BRANCH_NAME}</span>
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.load?.branch?.name}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.BRANCH_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.REG_DATE}</span>{' '}
                    <span className="top-banner-value">{formatDate(data?.load?.addedLoadAt)}</span>
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.COMMENTS}</span>{' '}
                    <span className={clsx('top-banner-value', styles.count)} onClick={handleCommentClick}>
                      {`Comment ${data?.commentsCount}`}
                    </span>
                  </Typography>
                </Col>
                {hasAccessToViewHistory && (
                  <Col span={24}>
                    <Typography variant="paragraph" className={styles.textItem}>
                      <span className="top-banner-title">{SUBJECT.HISTORY}:</span>{' '}
                      <span className={clsx('top-banner-value', styles.count)} onClick={handleScrollToHistory}>
                        {`${SUBJECT.HISTORY} ${loadHistory?.count || 0}`}
                      </span>
                    </Typography>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.CUSTOMER_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.CUSTOMER_NAME}</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.load?.customer?.name}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.CUSTOMER_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <div className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.REF_NUMBER}</span>{' '}
                    <Col span={13}>
                      <div
                        className={clsx(styles.copyIconText, {
                          [styles.copyActive]: copiedText === 'topBannerReferenceNumber',
                        })}
                      >
                        <TooltipText
                          prefixClass={styles.infoParagraph}
                          data={data?.load?.referenceNumber}
                          subject={`${SUBJECT.LOAD_INFO}${SUBJECT.REF_NUMBER}`}
                        />
                        <div
                          className={styles.copyIcon}
                          role="button"
                          onClick={() => handleCopy(String(data?.load?.referenceNumber), 'topBannerReferenceNumber')}
                        >
                          <CopyIcon active={copiedText === 'topBannerReferenceNumber'} />
                        </div>
                      </div>
                    </Col>
                  </div>
                </Col>

                <Col span={24}>
                  <Typography variant="heading" level={4} className={clsx(styles.columnItemTitle, styles.blockTitle)}>
                    {SUBJECT.CARRIER_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.CARRIER_COMPANY_NAME}</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.load?.carrier?.companyName}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.CARRIER_COMPANY_NAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title"> {SUBJECT.CARRIER_PHONE_NUMBER} </span>
                    <Link className="top-banner-value" to={`tel:${data?.load?.carrier?.phone}`}>
                      {formatPhone(String(data?.load?.carrier?.phone))}
                    </Link>
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title"> {SUBJECT.RATING} </span>
                    <Rate
                      canRate={data?.load?.carrier?.accessRating}
                      averageValue={data?.load?.carrier?.rating}
                      handleRate={handleCarrierRate}
                      getRatings={getCarrierRatings}
                      isFirstTime={data?.load?.carrier?.accessRating === RatingAccessTypes.ADD_RATING}
                      rateCount={data?.load?.carrier?.ratingCount}
                      rateList={rateList}
                      handleDeleteRate={handleDeleteRate}
                    />
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography variant="heading" level={4} className={styles.columnItemTitle}>
                    {SUBJECT.AGENT_INFO}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.AGENT_USERNAME}</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={`${data?.agents[0]?.user.username} `}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.AGENT_USERNAME}`}
                    />
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.AGENT_PHONE}</span>{' '}
                    <Link className="top-banner-value" to={`tel:${data?.agents[0]?.user.phone}`}>
                      {data?.agents[0]?.user.phone}
                    </Link>
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography variant="paragraph" className={styles.textItem}>
                    <span className="top-banner-title">{SUBJECT.TAKEN_AS_MC}:</span>{' '}
                    <TooltipText
                      prefixClass="top-banner-value"
                      data={data?.load?.takenAsAuthority?.name}
                      subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.TAKEN_AS_MC}`}
                    />
                  </Typography>
                </Col>
                {data?.load?.soldAsAuthority?.id ? (
                  <Col span={24}>
                    <Typography variant="paragraph" className={styles.textItem}>
                      <span className="top-banner-title"> {SUBJECT.SOLD_US_MC}</span>{' '}
                      <TooltipText
                        prefixClass="top-banner-value"
                        data={data?.load?.soldAsAuthority?.name}
                        subject={`${SUBJECT.REGULAR_LOAD}${SUBJECT.SOLD_US_MC}`}
                      />
                    </Typography>
                  </Col>
                ) : null}
              </Row>
            </Col>
            {withActions && (
              <Col span={24}>
                <div className={styles.topBannerActions}>
                  <Row justify="end" align="middle" gutter={18}>
                    <Col>
                      <div className={styles.markasClaim}>
                        <Typography variant="paragraph" className={styles.textItem}>
                          {SUBJECT.MARK_AS_CLAIM}
                        </Typography>
                        {inView ? (
                          <Tooltip
                            placement="top"
                            title={
                              !regularWatch && !hasClaimPermission
                                ? 'Your role can not mark load as claim'
                                : regularWatch && !hasDeClaimPermission
                                ? 'Your role can not resolve the claim'
                                : ''
                            }
                            color={'white'}
                          >
                            <span>
                              <CustomSwitch
                                name="markAsSwitch"
                                {...(handleSwitchMarkAsClaim && { onChange: handleSwitchMarkAsClaim })}
                                checked={regularWatch}
                                disabled={
                                  (!regularWatch && !hasClaimPermission) || (regularWatch && !hasDeClaimPermission)
                                }
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="top"
                            title={
                              !regularWatch('markAsSwitch') && !hasClaimPermission
                                ? 'Your role can not mark load as claim'
                                : regularWatch('markAsSwitch') && !hasDeClaimPermission
                                ? 'Your role can not resolve the claim'
                                : ''
                            }
                            color={'white'}
                          >
                            <span>
                              <Switch
                                name="markAsSwitch"
                                control={regularControl}
                                {...(handleSwitchMarkAsClaim && { onChange: handleSwitchMarkAsClaim })}
                                checked={regularWatch('markAsSwitch')}
                                disabled={
                                  (!regularWatch('markAsSwitch') && !hasClaimPermission) ||
                                  (regularWatch('markAsSwitch') && !hasDeClaimPermission)
                                }
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Col>
                    {hasCancelPermission && (
                      <Col>
                        <Button
                          variant="gray"
                          children={SUBJECT.CANCEL_LOAD}
                          onClick={() => handleCancelLoad(data?.load?.id)}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            )}
          </Row>
        </ViewItemWrapper>
      </div>
    </>
  );
};

export default RegularBanner;
