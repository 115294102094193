import React from 'react';
import { Tabs } from 'antd';
import clsx from 'clsx';
import { useHandleCopyActive } from 'hooks/useCopy';
import Typography from 'ui/typography/Typography';

import MainDrawer from 'components/main-drawer/MainDrawer';

import { ICarriersParams } from './constants/types';
import useCarriers from './useCarriers';

import styles from './Carriers.module.scss';

const Carriers = ({ setExcelParams }: ICarriersParams) => {
  const {
    activePage,
    handleTabChange,
    currentChildren: { component, buttonText, onCancel, onSubmit, withFooter },
    carrierTabs,
  } = useCarriers({ setExcelParams });
  const { copiedText, handleCopy } = useHandleCopyActive();
  const textForCopy = `https://${window.location.host.includes('app') ? 'app' : 'test'}.freightmax.com/carrierotp`;

  const handleClick = (e: any) => {
    if (e.button === 0) {
      handleCopy(textForCopy, textForCopy);
    } else if (e.button === 2) {
      window.open(textForCopy, '_blank');
    }
  };

  return (
    <div className="page-content">
      <Tabs
        defaultActiveKey={activePage}
        onChange={activeTab => handleTabChange(activeTab)}
        items={carrierTabs}
        className={styles.carriersHeaderTabs}
        tabBarExtraContent={
          <Typography
            variant="paragraph"
            onContextMenu={(e: any) => e.preventDefault()}
            onMouseDown={handleClick}
            className={clsx(styles.signUpLink, { [styles.copyActive]: copiedText === textForCopy })}
          >
            Carrier sign up link
          </Typography>
        }
      />
      <MainDrawer
        Children={component}
        buttonText={buttonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
        withFooter={withFooter}
        withScrollBar
      />
    </div>
  );
};

export default Carriers;
