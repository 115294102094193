import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { useGetAgreementsQuery } from 'services/carriersOnBoard/carriersOnBoard';
import Button from 'ui/button/Button';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import Typography from 'ui/typography/Typography';
import { handleDownloadClick, handleViewFileClick } from 'utils/helpers';

import EyeIconSvg from 'components/svgs/EyeIconSvg';
import NoaDownloadIcon from 'components/svgs/NOADownloadIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { Checkbox } from '../paymentTerms-sign-up/hoc-instance/instance';

import styles from './FormAgreement.module.scss';

const FormAgreement: FC<any> = ({ control, watch, errors }) => {
  const { data: agreements } = useGetAgreementsQuery({});

  return (
    <ViewItemWrapper withHeader={false} className="small">
      <div className={styles.row}>
        {agreements?.data?.length &&
          agreements?.data?.map((file: any) => (
            <Row gutter={10} align="middle" key={file.id}>
              <Col span={14}>
                <Typography variant="paragraph" children={file?.name} className={styles.fileTypeHeading} />
              </Col>
              <Col span={10}>
                <Row gutter={8} align="middle">
                  <Col role="button">
                    <Button variant="ghost" onClick={() => handleViewFileClick(file?.url)}>
                      <EyeIconSvg outlined={true} />
                    </Button>
                  </Col>
                  <Col role="button">
                    <Button variant="ghost" onClick={() => handleDownloadClick(file?.url)}>
                      <NoaDownloadIcon />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        <div className={styles.checkboxWrapper}>
          <Checkbox
            name="iAgreeForm"
            control={control}
            rules={{
              validator: (_: any, value: boolean) => (value ? Promise.resolve() : Promise.reject(new Error())),
            }}
            required
            checked={watch('iAgreeForm')}
            size="small"
            children="I Agree"
          />
          {errors?.iAgreeForm && (
            <div className={styles.agreeError}>
              <ErrorMessage fieldError={errors?.iAgreeForm ? { message: 'Required Field' } : {}} />
            </div>
          )}
        </div>
      </div>
    </ViewItemWrapper>
  );
};

export default FormAgreement;
