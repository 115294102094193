import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';
import { setIsValid } from 'store/check-is-dirty-slice/isValid';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { AgentTypes } from 'utils/constants';

import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import {
  defaultPermissionsOptions,
  defaultPermissionsValues,
  onlyViewPermissionsOptions,
  permissionEditTitles,
  permissions,
  PERMISSIONS_TYPES,
  SUBJECT,
} from '../../constants/constants';
import { IPermissionTypesItem } from '../../constants/types';

import { ICreateEditPermissionsProps } from './constants/types';
import { Select } from './hoc-instance/instance';

import styles from './CreateEdit.module.scss';

export const CreateEdit = ({
  control,
  rowName,
  watch,
  actionsOptions,
  setValue,
  submittedInvalidFields,
  currentForm,
  isLoading,
  setSubmittedInvalidFields,
}: ICreateEditPermissionsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setIsValid({
        isValid: !isLoading,
      })
    );
  }, [isLoading]);

  const { agentType, userType, outside, permissionsInfo } = useSelector(selectUserPermissionsInfo);

  const type = userType === 1 ? 0 : agentType;
  const permittedDefaultValues = Object.keys(
    defaultPermissionsValues?.[rowName as keyof typeof defaultPermissionsValues] || {}
  );
  const permittedTypes = permissions(
    Number(type),
    agentType === AgentTypes.OWNER && outside && permissionsInfo?.permissions?.payroll?.payrollCreditCheck?.view,
    !!watch('payrollLpf')?.permissions
  )?.[rowName]?.map((el: any) => el?.id);
  const defaultValues = permittedDefaultValues?.filter(el => permittedTypes?.includes(el));
  const handleSelectAction = (selectedItems: string[], field: string) => {
    if (!selectedItems.includes('actions')) {
      setValue(`${field}.actions`, [], { shouldDirty: true });
    } else if (!selectedItems.includes('view')) {
      setValue(`${field}.permissions`, [...watch(`${field}.permissions`), 'view'], { shouldDirty: true });
    }
  };

  const updateSelectedValues = (field: any, newValues: any) => {
    const orderedSecondArray = currentForm?.initialValues[field]?.actions
      .map((key: any) => newValues.find((item: any) => item === key))
      .filter((item: any) => item !== undefined);

    setValue(
      `${field}.actions`,
      orderedSecondArray.length
        ? [...orderedSecondArray, ...newValues.filter((item: any) => !orderedSecondArray.includes(item))]
        : newValues,
      {
        shouldDirty: true,
      }
    );
  };

  const agentsActionsByOwnerAndManager = ['terminateOff', 'terminateOn', 'create', 'edit'];
  const loadsActionsByOwnerAndManager = [
    'addPickUpDropOff',
    'editPickUpDropOff',
    'uploadsFiles',
    'filesDelete',
    'transaction',
    'editReferenceNumber',
    'sendToBilling',
    'claim',
    'cancel',
    'editSoldAsMc',
  ];
  const getCurrentActionsOptions = (index: number) => {
    if (userType == 1) {
      return actionsOptions[defaultValues[index]];
    } else if (type === 1 || type === 2) {
      if (defaultValues[index] === 'agent') {
        return actionsOptions[defaultValues[index]]?.filter((el: any) => {
          return agentsActionsByOwnerAndManager.includes(el?.value);
        });
      } else if (defaultValues[index] === 'load') {
        return actionsOptions[defaultValues[index]]?.filter((el: any) => {
          return loadsActionsByOwnerAndManager.includes(el?.value);
        });
      } else return actionsOptions[defaultValues[index]]?.filter((el: any) => el?.value !== 'download');
    } else {
      return actionsOptions[defaultValues[index]]?.filter((el: any) => el?.value !== 'download');
    }
  };

  const isActionSelectVisible = (index: number) => {
    return (
      !(userType != 1 && !actionsOptions[defaultValues[index]]?.filter((el: any) => el?.value !== 'download').length) &&
      !((type === 1 || type === 2) && defaultValues[index] === 'authority') &&
      !((type === 1 || type === 2) && defaultValues[index] === 'contact')
    );
  };

  const getCurrentPermissionsOptions = (index: number) => {
    if (
      (userType != 1 && !actionsOptions[defaultValues[index]]?.filter((el: any) => el?.value !== 'download').length) ||
      ((type === 1 || type === 2) && defaultValues[index] === 'authority') ||
      ((type === 1 || type === 2) && defaultValues[index] === 'contact')
    ) {
      return onlyViewPermissionsOptions;
    } else return defaultPermissionsOptions;
  };

  return (
    <form className={styles.permissionContainer}>
      <MainLoader additionalTimer={3} />
      <ViewItemWrapper title={permissionEditTitles[rowName as keyof typeof permissionEditTitles]}>
        {permissions(
          Number(type),
          agentType === AgentTypes.OWNER && outside && permissionsInfo?.permissions?.payroll?.payrollCreditCheck?.view,
          !!watch('payrollLpf')?.permissions
        )?.[rowName]?.map((el: any, index: number) => {
          return (
            <>
              <Row gutter={[65, 0]} align="top" key={el.id}>
                <Col span={5} className={styles.subHeader}>
                  <Typography variant="heading" className={styles.subHeaderTitle} children={el.name} />
                </Col>
                <Col span={9} className={styles.subHeader}>
                  <Select
                    name={`${defaultValues[index]}.permissions`}
                    control={control}
                    placeholder={SUBJECT.PERMISSION}
                    value={watch(`${defaultValues[index]}.permissions`)}
                    disableFirstOption={watch(`${defaultValues[index]}.permissions`)?.length === 2}
                    withCheckbox
                    isMultiSelect
                    options={
                      el.id === 'payrollCreditCheck' || el.id === 'payrollSoftwareFee' || el.id === 'payrollLpf'
                        ? onlyViewPermissionsOptions
                        : getCurrentPermissionsOptions(index)
                    }
                    onChange={(selectedItems: string[]) => {
                      setValue(`${defaultValues[index]}.permissions`, selectedItems, { shouldDirty: true });
                      handleSelectAction(selectedItems, `${defaultValues[index]}`);
                    }}
                  />
                </Col>
                {PERMISSIONS_TYPES[rowName][el.id]?.every(
                  ({ value }: Partial<IPermissionTypesItem>) => value === 'view'
                ) ? null : isActionSelectVisible(index) ? (
                  <Col span={9} className={styles.subHeader}>
                    <Select
                      name={`${defaultValues[index]}.actions`}
                      control={control}
                      placeholder={SUBJECT.ACTION}
                      value={watch(`${defaultValues[index]}.actions`)}
                      withCheckbox
                      isMultiSelect
                      errors={{
                        [`${defaultValues[index]}.actions`]:
                          submittedInvalidFields?.includes(defaultValues[index]) &&
                          watch(`${defaultValues[index]}.permissions`)?.includes('actions') &&
                          !watch(`${defaultValues[index]}.actions`)?.length,
                      }}
                      onBlur={() => setSubmittedInvalidFields((prev: any) => [...prev, defaultValues[index]])}
                      options={getCurrentActionsOptions(index)}
                      isDisabled={!watch(`${defaultValues[index]}.permissions`)?.includes('actions')}
                      onChange={(options: any) => {
                        const selectedOptions = Array.from(options, (opt: any) => {
                          return opt;
                        });
                        updateSelectedValues(`${defaultValues[index]}`, selectedOptions);
                      }}
                      rules={{
                        required:
                          watch(`${defaultValues[index]}.permissions`)?.includes('actions') &&
                          !watch(`${defaultValues[index]}.actions`)?.length,
                      }}
                      required={
                        watch(`${defaultValues[index]}.permissions`)?.includes('actions') &&
                        !watch(`${defaultValues[index]}.actions`)?.length
                      }
                    />
                    {submittedInvalidFields?.includes(defaultValues[index]) &&
                      watch(`${defaultValues[index]}.permissions`)?.includes('actions') &&
                      !watch(`${defaultValues[index]}.actions`)?.length && (
                        <Typography variant="paragraph" className={styles.error}>
                          Required field, in case of Action in Permission
                        </Typography>
                      )}
                  </Col>
                ) : null}
              </Row>
              {index !==
                permissions(
                  Number(type),
                  agentType === AgentTypes.OWNER &&
                    outside &&
                    permissionsInfo?.permissions?.payroll?.payrollCreditCheck?.view,
                  !!watch('payrollLpf')?.permissions
                )?.[rowName].length -
                  1 && <Divider />}
            </>
          );
        })}
      </ViewItemWrapper>
    </form>
  );
};
