import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import { useResetPasswordMutation } from 'services/auth/auth';
import {
  useAddNewFileToAgentMutation,
  useChangeStatusMutation,
  useCheckUserNameQuery,
  useDeleteFileFromAgentMutation,
  useGetAgentQuery,
  useGetAgentsQuery,
  useGetAuthoritiesByAgentIdQuery,
  useInviteAgentAgainMutation,
  useInviteAgentMutation,
  useUpdateAgentMutation,
} from 'services/profile/agents/agents';
import { AgentType, IAuthorityByAgentId, IBracket, IGetAgentsParams } from 'services/profile/agents/interfaces';
import {
  authoritiesAPI,
  useGetAuthoritiesByBranchIdQuery,
  useGetAuthoritiesByOfficeIdQuery,
} from 'services/profile/authorities/authorities';
import { useGetBranchesAutocompleteQuery, useGetBranchQuery } from 'services/profile/branches/branches';
import { useGetOfficeQuery, useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectOnlineAgents } from 'store/online-agents-slice/selector';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';

import CreateEdit from './components/create-edit/CreateEdit';
import View from './components/view/View';
import { defaultBrackedValues, defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import { IAgentFormData, IAgentsFilter, IAgentsParams, IAgentTableColumns } from './constants/types';
import { brackedValidation, validation } from './constants/validation';

const useAgent = ({ setExcelParams, sendAgentsActivityWatchMessage }: IAgentsParams) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const { searchParams, setSearchParams } = useDetectedParams();
  const { permissionsInfo, employeeLevel, userType, agentType } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const { mode, office_filter, branch_filter, agent_type_filter, open, id } = searchParams;

  const agentPagePermissions = usePermittedActions('profiles.agents');

  const {
    reset: resetFilterForm,
    control: filterControl,
    setValue: setFilterValue,
    formState: { isDirty: isFilterDirty },
    getValues: getFilterValues,
    watch: filterWatch,
  } = useForm<IAgentsFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onBlur',
  });
  const { currentData: agentById, isFetching: isFetchingById } = useGetAgentQuery(id, { skip: !id });
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    sendAgentsActivityWatchMessage();
  }, []);

  useEffect(() => {
    const agentsInterval = setInterval(() => {
      sendAgentsActivityWatchMessage();
    }, 20000);
    return () => {
      clearInterval(agentsInterval);
    };
  }, []);

  const { data: isUserNameExists } = useCheckUserNameQuery(
    { username: searchTerm },
    { skip: !searchTerm || searchTerm === agentById?.result?.username }
  );

  useEffect(() => {
    trigger('user_name');
  }, [isUserNameExists]);

  useEffect(() => {
    if (agentById?.result?.username) {
      setUsername(agentById?.result?.username);
    }
  }, [agentById?.result?.username, isFetchingById]);

  useEffect(() => {
    if (searchTerm === agentById?.result?.username) {
      clearErrors();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (username.length) {
      setSearchTerm(username);
    }
  }, [username, isFetchingById]);

  useEffect(() => {
    if (userInfo.officeIds && userType !== 1) {
      if (
        (office_filter && !userInfo?.officeIds?.some(el => el === Number(office_filter))) ||
        (branch_filter &&
          !userInfo?.branchIds?.some(el => el === Number(branch_filter)) &&
          Number(branch_filter) != Number(userInfo.defaultBranchId))
      ) {
        setTimeout(() => {
          navigate({ pathname: '/not-found' });
        }, 0);
      }
    }
  }, [userInfo]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    getValues,
    setValue,
    watch,
    reset,
    clearErrors,
    setError,
    trigger,
    resetField,
  } = useForm<IAgentFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation(searchTerm !== agentById?.result?.username ? isUserNameExists : false)),
  });

  useEffect(() => {
    dispatch(authoritiesAPI.util.resetApiState());
    setAuthoritiesByOffice({
      search: '',
      skip: 0,
      limit: 10,
      ignoreAuthorityIds: [],
    });
    setAuthoritiesByBranch({
      search: '',
      skip: 0,
      limit: 10,
      ignoreAuthorityIds: [],
    });
  }, [watch('office'), watch('branch'), watch('agentType')]);

  const [getAgentsFilter, setGetAgentsFilter] = useState<Partial<IGetAgentsParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {
      activity: 0,
    },
  });

  const [officesByType, setOfficesByType] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const [branchesByOffice, setBranchesByOffice] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const [authoritiesByOffice, setAuthoritiesByOffice] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
    ignoreAuthorityIds: [],
  });

  const [authoritiesByBranch, setAuthoritiesByBranch] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
    ignoreAuthorityIds: [],
  });

  const [authoritiesByAgent, setAuthoritiesByAgent] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const [branchesFilter, setBranchesFilter] = useState<Partial<IGetAgentsParams>>({
    search: '',
    skip: 0,
    limit: 10,
    withDefaults: true,
  });

  const { data, isFetching, refetch: refetchAgentData } = useGetAgentsQuery(getAgentsFilter);

  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' });

  const { data: officesData } = useGetOfficesAutocompleteQuery(officesByType, {
    skip: mode === 'view' || mode === 'edit',
  });

  const { data: branchesData } = useGetBranchesAutocompleteQuery(
    { ...branchesByOffice, officeId: watch('office')?.[0]?.id?.toString() },
    {
      skip: !watch('office')?.length,
    }
  );

  const { data: filterOfficeData } = useGetOfficesAutocompleteQuery(officesFilter);
  const { data: filterBranchesData } = useGetBranchesAutocompleteQuery(branchesFilter, {
    skip: roleLevel === IRoleLevel.corpLevel && !office_filter,
  });

  const { data: authorityByOfficeId, isFetching: isAutocompleteFetchingByOffice } = useGetAuthoritiesByOfficeIdQuery(
    { officeId: watch('office')?.[0]?.id, ...authoritiesByOffice },
    {
      skip: !watch('office').length || AgentType[watch('agentType') as number] !== SUBJECT.OWNER,
    }
  );

  const {
    data: authorityByBranchId,
    isFetching: isAutocompleteFetchingByBranch,
    refetch: refetchShortAuthorities,
  } = useGetAuthoritiesByBranchIdQuery(
    { branchId: watch('branch')?.[0]?.id, ...authoritiesByBranch, ...(mode === 'edit' && { ignoreAgentId: id }) },
    {
      skip:
        !watch('branch')?.length ||
        String(watch('branch')[0]) === 'All' ||
        AgentType[watch('agentType') as number] === SUBJECT.OWNER,
    }
  );

  const { data: selectedOffice } = useGetOfficeQuery(office_filter, {
    skip: !office_filter,
  });
  const { data: selectedBranch } = useGetBranchQuery(branch_filter, {
    skip: !branch_filter || !userInfo?.branchIds?.some(el => el === +branch_filter),
  });

  const [inviteAgent, { isLoading: isCreateLoading }] = useInviteAgentMutation();
  const [updateAgent, { isLoading: isEditLoading }] = useUpdateAgentMutation();
  const [inviteAgentAgain] = useInviteAgentAgainMutation();
  const [changeStatus] = useChangeStatusMutation();

  const [resetPassword] = useResetPasswordMutation();

  const { data: authoritiesByAgentId, refetch: refetchAuthoritiesByAgentId } = useGetAuthoritiesByAgentIdQuery(
    { id, ...authoritiesByAgent },
    {
      skip: !id || mode === 'add',
    }
  );

  const { data: columnsData } = useGetColumnsQuery({ type: 'agents' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();
  /////////////////////////////////work with files
  const [addNewFile] = useAddNewFileToAgentMutation();
  const [deleteFile] = useDeleteFileFromAgentMutation();
  const {
    handleViewFileCreate,
    handleViewFileEdit,
    handleDeleteFileCreate,
    handleDeleteFileEdit,
    handleDownloadFileCreate,
    handleDownloadFileEdit,
    beforeUploadForCreate,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({ data: agentById, setValue, getValues, id, addNewFile, deleteFile, trigger });

  /////////////////////////////////////////
  const [brackeds, setBrackeds] = useState<IBracket[]>([]);
  const [deletedIndex, setDeletedIndex] = useState<number | null>(null);

  //////////Options manipulation block start
  const [selectedOfficeOption, setSelectedOfficeOption] = useState<any>({});
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranchOption, setSelectedBranchOption] = useState<any>({});
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });
  useEffect(() => {
    setSelectedOfficeOption({
      title: selectedOffice?.result.name,
      id: selectedOffice?.result.id,
      value: String(selectedOffice?.result.id),
    });
  }, [selectedOffice]);

  useEffect(() => {
    if (filterOfficeData && !selectedOfficeOption.id) {
      setFinalOfficeOptions(filterOfficeData);
    } else if (filterOfficeData) {
      setFinalOfficeOptions({
        result: [
          selectedOfficeOption,
          ...filterOfficeData.result.filter((el: any) => el.id !== selectedOfficeOption.id),
        ],
        count: filterOfficeData?.count,
      });
    }
  }, [selectedOfficeOption, filterOfficeData]);

  useEffect(() => {
    setSelectedBranchOption({
      title: selectedBranch?.result.name,
      id: selectedBranch?.result.id,
      value: String(selectedBranch?.result.id),
    });
  }, [selectedBranch]);

  useEffect(() => {
    if (filterBranchesData && !selectedBranchOption.id) {
      setFinalBranchesOptions(filterBranchesData);
    } else if (filterBranchesData) {
      setFinalBranchesOptions({
        result: [
          selectedBranchOption,
          ...filterBranchesData.result.filter((el: any) => el.id !== selectedBranchOption.id),
        ],
        count: officesData?.count,
      });
    }
  }, [selectedBranchOption, filterBranchesData]);

  ///////////////////////Options manipulation block end
  const filterOfficeValue = filterWatch('office_filter');
  const filterBranchValue = filterWatch('branch_filter');
  const filterAgentType = filterWatch('agent_type_filter');

  const officeQueryCheck = !!(typeof filterOfficeValue === 'string' && filterOfficeValue);
  const branchQueryCheck = !!(typeof filterBranchValue === 'string' && filterBranchValue);

  const agentPayType = watch('agent_pay_type');

  const [activeFilter, setActiveFilter] = useState<number | null>(null);

  const isSelectedDefaultBranch = useMemo(() => {
    if (filterBranchesData?.result?.length) {
      return filterBranchesData?.result?.filter((el: any) => el.id == Number(filterWatch('branch_filter')))?.[0]
        ?.default;
    } else return false;
  }, [filterBranchesData?.result?.length, filterWatch('branch_filter')]);

  useEffect(() => {
    if (roleLevel === IRoleLevel.corpLevel) {
      setBranchesFilter(prev => ({ ...prev, search: '', officeId: office_filter }));
      setSelectedBranchOption({});
    } else {
      setBranchesFilter(prev => ({ ...prev, search: '', officeId: userInfo?.officeIds?.[0] }));
      setSelectedBranchOption({});
    }
  }, [office_filter]);

  const handleOfficeFilterSelect = (item: any) => {
    if (item.id != office_filter) {
      setSearchParams({
        ...searchParams,
        office_filter: item.id,
        branch_filter: '',
      });

      setBranchesFilter(prev => ({ ...prev, officeId: office_filter }));
      setFilterValue('branch_filter', '');
    }
  };

  const handleBranchFilterSelect = (item: any) => {
    setTimeout(() => {
      setSearchParams({
        ...searchParams,
        branch_filter: item.value,
      });
    }, 0);

    setFilterValue('branch_filter', item.value);
    if (isSelectedDefaultBranch) {
      setFilterValue('agent_type_filter', null);
      setSearchParams({
        ...searchParams,
        agent_type_filter: '',
      });
    }
  };

  const resetOfficeParams = () => {
    setSearchParams({
      ...searchParams,
      office_filter: '',
    });
    setFilterValue('office_filter', '');
    setFilterValue('branch_filter', '');
    setOfficesFilter(prev => ({ ...prev, limit: 10, search: '' }));
    setGetAgentsFilter((prev: any) => {
      const {
        filter: { branchId: _, officeId: __, ...rest },
      } = prev;

      return { ...prev, skip: 0, filter: { ...rest } };
    });
  };

  const resetBranchParams = () => {
    setSearchParams({
      ...searchParams,
      branch_filter: '',
    });
    setBranchesFilter(prev => ({ ...prev, limit: 10, search: '' }));
    setFilterValue('branch_filter', '');
    setGetAgentsFilter((prev: any) => {
      const {
        filter: { branchId: _, ...rest },
      } = prev;

      return { ...prev, skip: 0, filter: { ...rest } };
    });
  };

  useEffect(() => {
    if (office_filter && !filterWatch('office_filter')) {
      setFilterValue('office_filter', office_filter, { shouldDirty: true });
      setFilterValue('branch_filter', branch_filter, { shouldDirty: true });
      setGetAgentsFilter((prev: any) => {
        const { filter } = prev;

        return { ...prev, skip: 0, filter: { ...filter, officeId: office_filter, branchId: branch_filter } };
      });
    } else if (office_filter) {
      setFilterValue('office_filter', office_filter, { shouldDirty: true });
      setGetAgentsFilter((prev: any) => {
        const {
          filter: { branchId: _, ...rest },
        } = prev;

        return { ...prev, skip: 0, filter: { ...rest, officeId: office_filter } };
      });
    }
  }, [office_filter, filterOfficeValue]);

  useEffect(() => {
    if (branch_filter && !filterWatch('branch_filter') && roleLevel <= IRoleLevel.officeLevel) {
      setFilterValue('branch_filter', branch_filter, { shouldDirty: true });
    }
  }, [branch_filter, filterBranchValue]);

  useEffect(() => {
    if (agent_type_filter && !isSelectedDefaultBranch) {
      setFilterValue('agent_type_filter', agent_type_filter, { shouldDirty: true });
      setGetAgentsFilter(prev => {
        return {
          ...prev,
          filter: {
            ...prev.filter,
            ...(agent_type_filter && agent_type_filter !== '0' ? { type: agent_type_filter } : { type: '' }),
          },
        };
      });
    }
  }, [agent_type_filter, filterAgentType, isSelectedDefaultBranch]);

  useEffect(() => {
    setGetAgentsFilter((prev: any) => {
      const { filter } = prev;

      return { ...prev, skip: 0, filter: { ...filter, branchId: branch_filter } };
    });
  }, [branch_filter]);

  useEffect(() => {
    if (isSelectedDefaultBranch) {
      setFilterValue('agent_type_filter', null);
      setSearchParams({
        ...searchParams,
        agent_type_filter: '',
      });
    }
  }, [isSelectedDefaultBranch, branch_filter, filterBranchValue]);

  useEffect(() => {
    refetchAgentData();
  }, [branch_filter, office_filter, agent_type_filter]);

  useEffect(() => {
    if (AgentType[watch('agentType') as number] === SUBJECT.OWNER) {
      if (String(watch('office'))?.length) {
        setValue('branch', [{ title: 'All', value: '0' }], { shouldValidate: true });
      }
    }
  }, [watch('agentType'), String(watch('office'))?.length]);

  useEffect(() => {
    if (selectedOffice?.result?.name) {
      setOfficesFilter(prev => ({ ...prev }));
    }
  }, [selectedOffice]);

  useEffect(() => {
    if (selectedBranch?.result?.name) {
      setBranchesFilter(prev => ({ ...prev }));
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (watch('office')?.length && mode !== 'edit' && AgentType[watch('agentType') as number] !== SUBJECT.OWNER) {
      setValue('branch', []);
    }
    if (watch('office')?.length && mode !== 'edit' && AgentType[watch('agentType') as number] === SUBJECT.OWNER) {
      setValue('branch', [{ title: 'All', value: '0' }]);
    }
  }, [watch('office')]);

  useEffect(() => {
    if (agentPayType && mode !== 'edit') {
      resetField('ssn_ein_number');
      clearErrors('ssn_ein_number');
    }
    if (agentPayType !== agentById?.result?.agentPayType && mode === 'edit') {
      resetField('ssn_ein_number', { defaultValue: '' });
      clearErrors('ssn_ein_number');
    }
  }, [agentPayType, agentById?.result?.agentPayType, mode]);

  const resetFilter = () => {
    resetFilterForm();
    setSearchParams({
      ...searchParams,
      office_filter: '',
      branch_filter: '',
      agent_type_filter: '',
    });
    setActiveFilter(null);
    setGetAgentsFilter({
      search: '',
      skip: 0,
      limit: 20,
      order: 1,
      orderBy: '',
      filter: { activity: 0 },
    });
    setOfficesFilter({
      search: '',
      skip: 0,
      limit: 10,
    });
    setBranchesFilter({
      search: '',
      skip: 0,
      limit: 10,
      withDefaults: true,
    });
    setAuthoritiesByAgent({
      skip: 0,
      limit: 10,
    });
  };

  const {
    handleSubmit: handleBrackedSubmit,
    control: brackedControl,
    getValues: getBrackedValues,
    watch: watchBrackeds,
    formState: { isValid: isBrackedValid, errors: brackedErrors, isDirty: isBrackedDirty },
    reset: resetBrackeds,
    setValue: setBrackedsValue,
  } = useForm<IBracket>({
    defaultValues: defaultBrackedValues,
    mode: 'onBlur',
    resolver: yupResolver(brackedValidation(brackeds)),
  });

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    setAuthoritiesByOffice({
      search: '',
      skip: 0,
      limit: 10,
      ignoreAuthorityIds: [],
    });
    setAuthoritiesByBranch({
      search: '',
      skip: 0,
      limit: 10,
      ignoreAuthorityIds: [],
    });
    setAuthoritiesByAgent({
      search: '',
      skip: 0,
      limit: 10,
    });
    reset(defaultValues, { keepDirty: false });
  };

  useEffect(() => {
    resetBrackeds(defaultBrackedValues);
  }, [mode, open]);

  useEffect(() => {
    if (mode === 'edit' && agentById) {
      reset({
        address: agentById?.result?.address,
        secondAddress: agentById?.result?.secondAddress || '',
        agent_pay_type: agentById?.result?.agentPayType,
        branch:
          AgentType[agentById?.result?.agentType as AgentType] === SUBJECT.OWNER
            ? [{ title: 'All', value: '0' }]
            : [agentById?.result?.branch],
        city: agentById?.result?.city,
        email: agentById?.result?.email,
        first_name: agentById?.result?.firstName,
        last_name: agentById?.result?.lastName,
        location: agentById?.result?.location,
        office: [agentById?.result?.office],
        pay_type: agentById?.result?.payType,
        paid_as: agentById?.result?.paidAs,
        ssn_ein_number: agentById?.result?.agentPayInput,
        phone_number: formatPhone(agentById?.result?.phone),
        second_number: formatPhone(agentById?.result?.phoneSecond),
        state: agentById?.result?.stateId,
        agentType: agentById?.result?.agentType,
        user_name: agentById?.result?.username,
        zip_code: agentById?.result?.zip,
        authorities: agentById.result.authorities?.map((item: IAuthorityByAgentId) => item?.id),
        brackeds: agentById?.result?.brackets?.map((item: IBracket) => {
          return {
            to: item?.to,
            from: item?.from,
            percent: item?.percent,
          };
        }),
        file: agentById?.result?.files || [],
        emergencyName: agentById?.result?.emergencyName || '',
        emergencyPhone: agentById?.result?.emergencyPhone || null,
        emergencyRelation: agentById?.result?.emergencyRelation || '',
      });
      setBrackeds(
        agentById?.result.brackets.map((item: IBracket) => {
          return {
            to: item.to,
            from: item.from,
            percent: item.percent,
          };
        })
      );
      setAllFiles(savedFiles);
      trigger('file');
    } else {
      reset(defaultValues);
      setBrackeds([]);
      setAllFiles([]);
    }
  }, [mode, open, id, agentById]);

  const onBrackedSubmit = (data: IBracket) => {
    setBrackeds(prev => [...prev, data]);
    setValue('brackeds', [...brackeds, data], { shouldDirty: true });
    clearErrors('brackeds');
  };

  const lastIndex = brackeds?.length - 1;
  const lastItem = brackeds?.[lastIndex];

  const handleDeleteBrackedRow = (index: number) => {
    setDeletedIndex(index);
    setBrackeds(prev => prev.filter((item, currentIndex) => currentIndex !== index));
    dispatch(closeModal());
    setValue(
      'brackeds',
      brackeds.filter((item, currentIndex) => currentIndex !== index),
      { shouldDirty: true }
    );
  };

  const refetch = async () => {
    await refetchAuthoritiesByAgentId();
    refetchShortAuthorities();
  };

  const handleDeleteBracked = (index: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          handleDeleteBrackedRow(index);
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  useEffect(() => {
    if (brackeds?.length) {
      resetBrackeds({
        percent: null,
        from: Number(lastItem!.to!) + 1,
        to: null,
      });
      setBrackeds(
        brackeds?.map((item, index) => {
          if (deletedIndex === brackeds?.length) {
            return {
              ...item,
            };
          }
          if (deletedIndex && deletedIndex <= brackeds?.length && brackeds[index] === brackeds[deletedIndex! - 1]) {
            return {
              ...item,
              to: Number(brackeds[deletedIndex!]!.from!) - 1,
            };
          }
          return item;
        })
      );
    } else {
      resetBrackeds(defaultBrackedValues);
    }
    setBrackeds(prev =>
      prev?.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            from: 0,
          };
        }
        return item;
      })
    );
  }, [brackeds?.length]);

  const onSubmit: SubmitHandler<IAgentFormData> = data => {
    if (mode === 'edit' && !isEditLoading) {
      updateAgent({ id, ...data, brackeds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully edited',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
          refetchAgentData();
          setUsername('');
          setSearchTerm('');
        }
      });
    } else if (mode === 'add' && !isCreateLoading) {
      inviteAgent({ ...data, brackeds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(officeQueryCheck ? { office_filter: filterOfficeValue } : {}),
      ...(branchQueryCheck ? { branch_filter: filterBranchValue } : {}),
      ...(filterAgentType ? { agent_type_filter: filterAgentType } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
    resetBrackeds(defaultBrackedValues);
    resetForm();
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'agents' });

  const handleCancel = () => {
    if (isDirty || isBrackedDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid && brackeds?.length ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk:
            isValid && brackeds?.length
              ? () => {
                  onSubmit(watch());
                  dispatch(closeModal());
                  clearErrors();
                }
              : () => {
                  dispatch(closeModal());
                },
          onCancel: handleCloseModal,
        })
      );
    } else {
      resetForm();
    }
  };

  const handleResetPassword = (email: string) => {
    resetPassword(email).then((recover: any) => {
      if (recover.error) {
        notification.error({
          message: recover?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'Instructions to reset the password were sent to your email',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  const handleStopResize = (columns: IAgentTableColumns[]): void => {
    editColumns({ type: 'agents', columns });
  };

  const handleInviteAgain = (id: number) => {
    inviteAgentAgain(id).then((res: any) => {
      if (res.error) {
        notification.error({
          message: res?.error?.data?.details?.[0] || ServerErrorCodes[0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: 'The agent was successfully invited',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    });
  };

  // agent edit logic
  const hasAccessToEdit = () => {
    if (
      userType === UserTypes.ADMIN ||
      (employeeLevel === EmployeeLevels.CORPORATE && agentPagePermissions.edit) ||
      (agentById && agentType < agentById.result.agentType) ||
      isFetchingById
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (columnsData?.columns?.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  useEffect(() => {
    if (open === 'false') {
      dispatch(authoritiesAPI.util.resetApiState());
    }
  }, [mode, open, id]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getAgentsFilter,
        };
      });
    }
  }, [getAgentsFilter]);

  useEffect(() => {
    if (open === 'false') {
      setSearchParams({
        ...searchParams,
        id: '',
      });
    }
  }, [open]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: agentPagePermissions.create
      ? {
          component: (
            <CreateEdit
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              getValues={getValues}
              errors={errors}
              setValues={setValue}
              watch={watch}
              handleBrackedSubmit={handleBrackedSubmit}
              brackedControl={brackedControl}
              getBrackedValues={getBrackedValues}
              watchBrackeds={watchBrackeds}
              isBrackedValid={isBrackedValid}
              onBrackedSubmit={onBrackedSubmit}
              handleDeleteBracked={handleDeleteBracked}
              brackeds={brackeds}
              brackedErrors={brackedErrors}
              mode={mode}
              setError={setError}
              statesData={statesData}
              isDirty={isDirty}
              trigger={trigger}
              authorities={
                AgentType[watch('agentType') as number] === SUBJECT.OWNER ? authorityByOfficeId : authorityByBranchId
              }
              data={agentById}
              officesData={officesData}
              branchesData={branchesData}
              authoritiesByAgentId={authoritiesByAgentId}
              authoritiesByAgent={authoritiesByAgent}
              setAuthoritiesByAgent={setAuthoritiesByAgent}
              officesByType={officesByType}
              setOfficesByType={setOfficesByType}
              branchesByOffice={branchesByOffice}
              setBranchesByOffice={setBranchesByOffice}
              authoritiesByOffice={authoritiesByOffice}
              setAuthoritiesByOffice={setAuthoritiesByOffice}
              authoritiesByBranch={authoritiesByBranch}
              setAuthoritiesByBranch={setAuthoritiesByBranch}
              beforeUploadForCreate={beforeUploadForCreate}
              handleDeleteFileCreate={handleDeleteFileCreate}
              handleDownloadFileCreate={handleDownloadFileCreate}
              handleViewFileCreate={handleViewFileCreate}
              agentId={id}
              allFiles={allFiles}
              isFetchingById={isFetchingById}
              refetchAuthoritiesByAgentId={refetch}
              setBrackedsValue={setBrackedsValue}
              username={String(agentById?.result?.username)}
              resetField={resetField}
              clearErrors={clearErrors}
              setUsername={setUsername}
              isUserNameExists={isUserNameExists}
              employeeLevel={employeeLevel}
              isEditLoading={isEditLoading}
              isAutocompleteFetching={
                AgentType[watch('agentType') as number] === SUBJECT.OWNER
                  ? isAutocompleteFetchingByOffice
                  : isAutocompleteFetchingByBranch
              }
              isCreateLoading={isCreateLoading}
            />
          ),
          buttonText: SUBJECT.BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit:
      agentPagePermissions.edit && hasAccessToEdit()
        ? {
            component: (
              <CreateEdit
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                getValues={getValues}
                control={control}
                watch={watch}
                handleBrackedSubmit={handleBrackedSubmit}
                brackedControl={brackedControl}
                getBrackedValues={getBrackedValues}
                watchBrackeds={watchBrackeds}
                isBrackedValid={isBrackedValid}
                brackeds={brackeds}
                handleDeleteBracked={handleDeleteBracked}
                onBrackedSubmit={onBrackedSubmit}
                errors={errors}
                brackedErrors={brackedErrors}
                mode={mode}
                setValues={setValue}
                statesData={statesData}
                setError={setError}
                isDirty={isDirty}
                trigger={trigger}
                username={String(agentById?.result?.username)}
                authorities={
                  AgentType[agentById?.result?.agentType as AgentType] === SUBJECT.OWNER
                    ? authorityByOfficeId
                    : authorityByBranchId
                }
                data={agentById}
                officesData={officesData}
                branchesData={branchesData}
                authoritiesByAgentId={authoritiesByAgentId}
                authoritiesByAgent={authoritiesByAgent}
                setAuthoritiesByAgent={setAuthoritiesByAgent}
                officesByType={officesByType}
                setOfficesByType={setOfficesByType}
                branchesByOffice={branchesByOffice}
                setBranchesByOffice={setBranchesByOffice}
                authoritiesByOffice={authoritiesByOffice}
                setAuthoritiesByOffice={setAuthoritiesByOffice}
                authoritiesByBranch={authoritiesByBranch}
                setAuthoritiesByBranch={setAuthoritiesByBranch}
                beforeUploadForEdit={beforeUploadForEdit}
                handleDeleteFileEdit={handleDeleteFileEdit}
                handleDownloadFileEdit={handleDownloadFileEdit}
                handleViewFileEdit={handleViewFileEdit}
                agentId={id}
                allFiles={allFiles}
                isFetchingById={isFetchingById}
                setBrackedsValue={setBrackedsValue}
                refetchAuthoritiesByAgentId={refetch}
                handleNewFileView={handleViewFileCreate}
                handleNewFileDownload={handleDownloadFileCreate}
                clearErrors={clearErrors}
                setUsername={setUsername}
                isUserNameExists={isUserNameExists}
                employeeLevel={employeeLevel}
                isEditLoading={isEditLoading}
                resetField={resetField}
                isAutocompleteFetching={
                  AgentType[watch('agentType') as number] === SUBJECT.OWNER
                    ? isAutocompleteFetchingByOffice
                    : isAutocompleteFetchingByBranch
                }
                isCreateLoading={isCreateLoading}
              />
            ),
            buttonText: SUBJECT.EDIT_BTN_TEXT,
            onCancel: handleCancel,
            onSubmit: handleSubmit(onSubmit),
          }
        : { component: <Navigate to="/not-found" replace={true} /> },
    view: {
      component: (
        <View
          authoritiesByAgentId={authoritiesByAgentId}
          authoritiesByAgent={authoritiesByAgent}
          setAuthoritiesByAgent={setAuthoritiesByAgent}
          data={agentById}
          isFetchingById={isFetchingById}
          canEdit={hasAccessToEdit()}
          pagePermissions={agentPagePermissions}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    watchBrackeds(),
    watch('pay_type'),
    officesData,
    brackeds?.length,
    agentById,
    setDragColumns,
    dragColumns,
    isEditLoading,
    isCreateLoading,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(officeQueryCheck ? { office_filter: filterOfficeValue } : {}),
      ...(branchQueryCheck ? { branch_filter: filterBranchValue } : {}),
      ...(filterAgentType ? { agent_type_filter: filterAgentType } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setGetAgentsFilter(prev => {
      if (sortOrder === 0) {
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      }
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setGetAgentsFilter(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleActiveFilter = (type: number) => {
    setGetAgentsFilter(prev => ({
      ...prev,
      skip: 0,
      limit: prev.limit,
      filter: { ...prev.filter, activity: type - 1 },
    }));
    setActiveFilter(type);
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string, rowData: any) => {
    if (rowData?.verifiedAt) {
      dispatch(
        openModal({
          title: checked ? SUBJECT.CHECKED_ACTIVATE : SUBJECT.CHECKED_DEACTIVATE,
          okText: SUBJECT.YES,
          cancelText: SUBJECT.NO,
          cancelButtonVariant: 'gray',
          okButtonVariant: checked ? 'modalPrimary' : 'danger',
          onOk: () => {
            changeStatus({ id: rowId, status: checked ? 1 : 2 }).then((data: any) => {
              if (data.error) {
                notification.error({
                  message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                  duration: 1,
                  icon: <DangerIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              } else {
                notification.success({
                  message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                  duration: 1,
                  icon: <SuccessIcon />,
                  placement: 'topRight',
                  closeIcon: <CloseIcon />,
                  key: notificationKey,
                  btn: (
                    <button type="button" onClick={() => notification.destroy(notificationKey)}>
                      <CloseIcon />
                    </button>
                  ),
                });
              }
            });
            dispatch(closeModal());
          },
          onCancel: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setGetAgentsFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
    sendAgentsActivityWatchMessage();
  };

  const handleRowEdit = (rowId: string | number) => {
    setSearchParams({
      ...searchParams,
      ...(officeQueryCheck ? { office_filter: filterOfficeValue } : {}),
      ...(branchQueryCheck ? { branch_filter: filterBranchValue } : {}),
      ...(filterAgentType ? { agent_type_filter: filterAgentType } : {}),
      mode: 'edit',
      open: 'true',
      id: String(rowId),
    });
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      agent_type_filter: filterAgentType || agent_type_filter || '',
    });
  }, [filterOfficeValue, filterBranchValue, filterAgentType, isSelectedDefaultBranch]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  const onlineAgents = useSelector(selectOnlineAgents);

  const updatedResult = useMemo(() => {
    const updatedData = data?.result.map((agent: any) => {
      if (agent.activity || onlineAgents?.includes(Number(agent.id))) {
        return {
          ...agent,
          activity: true,
        };
      }
      return agent;
    });
    return updatedData;
  }, [onlineAgents?.length, data?.result]);

  return {
    handleSubmit,
    control,
    onSubmit,
    getValues,
    data,
    columns: columnsData?.columns,
    isFetching,
    handleRowClick,
    currentChildren,
    handleCloseModal,
    handleSortClick,
    handleStopResize,
    handlePaginationClick,
    handleSwitchClick,
    handleColumnSearch,
    handleRowEdit,
    resetFilter,
    getFilterValues,
    filterControl,
    skip: getAgentsFilter.skip,
    limit: getAgentsFilter.limit,
    isFilterDirty,
    officesData,
    branchesData,
    filterOfficeData: finalOfficeOptions,
    setOfficesFilter,
    officesFilter,
    filterBranchesData: finalBranchesOptions,
    office_filter,
    branchesFilter,
    setBranchesFilter,
    handleInviteAgain,
    handleResetPassword,
    handleOfficeFilterSelect,
    setFilterValue,
    handleBranchFilterSelect,
    resetOfficeParams,
    resetBranchParams,
    activeFilter,
    handleActiveFilter,
    filterOfficeValue,
    filterBranchValue,
    filterWatch,
    agentPagePermissions,
    roleLevel,
    onlineAgents,
    updatedResult,
    hasAccessToEdit,
    isSelectedDefaultBranch,
  };
};

export default useAgent;
