import { ICreator } from 'services/auth/interface';
import { IAuthorityByOfficeId, OfficeSetUpTypes } from 'services/profile/offices/interfaces';

export interface IFilter {
  [key: string]: string;
}

export interface IGetLoadsParams {
  search: string;
  skip: number;
  limit: number;
  order: number;
  orderBy: string;
  filter: IFilter;
  ignoreAuthorityIds?: number[];
}

export enum RequestStatus {
  'Pending' = 1,
  'Approve' = 2,
  'Deny' = 3,
  'COD' = 4,
  'Consignment' = 5,
}

export interface IRequestStatusText {
  Pending: string;
  Approve: string;
  Deny: string;
  COD: string;
  Consignment: string;
}

export const RequestStatusText: IRequestStatusText = {
  Pending: 'Pending',
  Approve: 'Approved',
  Deny: 'Denied',
  COD: 'COD',
  Consignment: 'Consignment',
};

export interface ILoadFile {
  id: number;
  fileId: number;
  type: string;
  blurred?: boolean;
  loadId: number;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;
  readable: number;
  mailable?: boolean;
  file: {
    id: number;
    preview: string;
    download: string;
    name: string;
    createdBy: number;
    createdAt: number;
    type: number;
  };
  creator: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    phone: string;
  };
}

export interface ILoadFilesResponse {
  result: Array<ILoadFile>;
  count: number;
}

export interface ICreditRequestForEmployeeByIdResponse {
  commentsCount: number;
  creditCheck: {
    customerFinalAmount: number;
    addedLoadAt: number;
    id: number;
    officeId: number;
    branchId: number;
    customerId: number;
    carrierId: number;
    takenAuthorityId: number;
    paymentTermId: number;
    loadType: number;
    order: number;
    customerAmount: number;
    customer: {
      name: string;
      id: number;
    };
    office: {
      name: string;
      id: number;
      type: OfficeSetUpTypes;
    };
    branch: {
      name: string;
      id: number;
    };
    creator: ICreator;
    customerContact: string;
    claimed: boolean;
    carrierAmount: number;
    paymentTermDay: string | number;
    paymentTermPercent: string | number;
    paymentTermType: string | number;
    requestStatus: number;
    status: number;
    carrierStatus: number;
    customerStatus: number;
    processing: boolean;
    createdAt: number;
    updatedAt: number;
    deletedAt: number;
    createdBy: number;
    updatedBy: number;
    deletedBy: number;
    truckId: number;
    authority: {
      id: number;
      name: string;
      mc: number;
      type: number;
    };
    code: number;
  };
  depth: {
    depth: number;
    count: number;
  };
  paymentLimit: number;
}

export interface ILoadsByIdResponse {
  redConfirmation: any[];
  addedLoadAtL: number;
  uploadedPOD: any[];
  regDateAndTime: number;
  loadId: number;
  code: number;
  loadStatus: number;
  office: {
    name: string;
    id: number;
  };
  branch: {
    name: string;
    id: number;
  };
  agent: {
    name: string;
    id: number;
  };
  customer: {
    name: string;
    id: number;
    mc: string;
  };
  customerRef: string;
  carrier: {
    contactName: string;
    companyName: string;
    factoringType: number;
    id: number;
  };
  loadType: number;
  firstPickupCity: any;
  firstPickupState: any;
  firstPickupDate: any;
  lastDeliveryCity: any;
  lastDeliveryState: any;
  lastDeliveryDate: any;
  customerReceivables: any;
  carrierPayables: any;
  profit: number;
  soldAsMc: any;
  factored: boolean;
  customerDebt: number;
  customerInvoice: any[];
  carrierPayment: any[];
  comments_count: number;
}

export interface ICreditRequestForEmployeeResponse {
  result: Array<ICreditRequestForEmployeeByIdResponse>;
  count: number;
}

export interface ILoadsRequestResponse {
  result: Array<ILoadsByIdResponse>;
  count: number;
  grossProfit: number;
  potentialGrossProfit: number;
  withoutRedConfirmation: number;
  average: number;
}

export interface ILoadFilterRequest {
  branchIds: number[] | [];
  officeIds: number[] | [];
  agentIds: number[] | [];
  loadTypes: string | string[];
  document: string | string[];
  statuses: string | string[];
  loadStartDate: number;
  loadEndDate: number;
  skip: number;
  limit: number;
  orderBy: number;
}

export interface IComment {
  id: number;
  loadId: number;
  createdBy: number;
  creator: ICreator;
  message: string;
  createdAt: number;
}

export interface ICreditRequestById {
  createdAt: number | Date;
  creatorId: number | string;
  addedLoadAt: number;
  id: number | string;
  customerId: number | string;
  customerAmount: number;
  customerName: string;
  officeName: string;
  branchName: string;
  code: number;
  agentName: string;
  amount: string;
  realAmount: string;
  debtNotPaid: string;
  debtQty: number;
  status: number | string;
  requestStatus: number | string;
  carrierStatus: number | string;
  customerStatus: number | string;
  processing: boolean;
  paymentMethod: string;
  comments: number;
  paymentTermPercent: number | string;
  agentPhone: string;
  debtLimit?: string;
  authorityName: string;
  authorityId: any;
  mc: number;
}

export interface IGetCommentsResponse {
  result: Array<IComment>;
  count: number;
}

export interface IGetLoadById {
  canCreateInvoice: boolean;
  hasCarrier: boolean;
  agents: {
    amount: number;
    branch: { id: number; name: string };
    createdAt: number;
    office: { id: number; name: string };
    user: { id: number; firstName: string; lastName: string; email: string; phone: string; username: string };
  }[];
  commentsCount: number;
  finance: {
    agentBalance: number;
    agentFinalProfit: number;
    secondAgentBalance: number;
    secondAgentFinalProfit: number;
    carrierAmountPaid: number;
    carrierBalance: number;
    carrierFinalPayable: number;
    carrierInitialRate: number;
    carrierPaymentTermGeneratedAmount: number;
    customerAmountPaid: number;
    customerBalance: number;
    customerFinalReceivable: number;
    customerPaymentTermPercent: number;
    customerInitialRate: number;
    customerPaymentTermGeneratedAmount: number;
  };
  information: {
    bookedType: number;
    commodity: string;
    feetOfPartial: number;
    givenType: number;
    refrigeratorCelsius: number;
    soldType: number;
    takenType: number;
    transportCapability: number;
    weight: number;
  };
  load: {
    id: number;
    code: number;
    fuelAdvance?: boolean;
    office: {
      id: number;
      name: string;
    };
    branch: {
      id: number;
      name: string;
    };
    rateConfirmationSentAt: number;
    soldAsAuthority: {
      id: number;
      mc: number;
      name: string;
      createdAt: number | string;
      type: number;
      title: string;
      value: number;
    };
    truck: {
      truckNumber: string;
    };
    claimed: boolean;
    carrier?: {
      id: number;
      name: string;
      mc: number;
      phone?: string;
      companyName?: string;
      rating: number;
      ratingCount: number;
      accessRating: number;
    };
    carrierAmount: number;
    carrierFinalAmount: number;
    customer: { id: number; name: string; phone?: string };
    customerAmount: number;
    customerContact: string;
    customerContactName: string;
    customerDeptAmount: number;
    customerFinalAmount: number;
    loadType: number;
    referenceNumber: string;
    requestStatus: number;
    status: number;
    carrierStatus: number;
    customerStatus: number;
    processing: boolean;
    createdAt: number;
    addedLoadAt: number;
    paymentTermType: number;
    takenAsAuthority: { id: number; name: string; mc: string; type: number; createdAt: number };
    customerAccess?: boolean;
    carrierAccess?: boolean;
    retrieved: boolean;
    retrievedAt: number;
    byPaymentTerm: boolean;
  };
  noa: any[];
}
export interface IGetLoadByIdModified {
  canCreateInvoice: boolean;
  hasCarrier: boolean;
  agents: {
    amount: number;
    branch: { id: number; name: string };
    createdAt: number;
    office: { id: number; name: string };
    user: { id: number; firstName: string; lastName: string; email: string; phone: string; username: string };
  }[];
  commentsCount: number;
  finance: {
    agentBalance: string;
    agentFinalProfit: string;
    secondAgentBalance?: string;
    secondAgentFinalProfit?: string;
    carrierAmountPaid: string;
    carrierBalance: string;
    carrierFinalPayable: string;
    carrierInitialRate: string;
    carrierPaymentTermGeneratedAmount: string;
    customerAmountPaid: string;
    customerBalance: string;
    customerFinalReceivable: string;
    customerPaymentTermPercent: number;
    customerInitialRate: string;
    customerPaymentTermGeneratedAmount: string;
  };
  information: {
    bookedType: number;
    commodity: string;
    feetOfPartial: number;
    givenType: number;
    refrigeratorCelsius: number;
    soldType: number;
    takenType: number;
    transportCapability: number;
    weight: number;
  };
  load: {
    id: number;
    fuelAdvance?: boolean;
    office: {
      id: number;
      name: string;
    };
    branch: {
      id: number;
      name: string;
    };
    soldAsAuthority: {
      id: number;
      mc: number;
      name: string;
      createdAt: number | string;
      type: number;
      title: string;
      value: number;
    };
    truck: {
      truckNumber: string;
    };
    requestStatus: number;
    claimed: boolean;
    carrier?: {
      id: number;
      name: string;
      mc: number;
      phone?: string;
      companyName?: string;
      rating: number;
      ratingCount: number;
      accessRating: number;
    };
    carrierAmount: string;
    carrierFinalAmount: string;
    customer: { id: number; name: string; phone?: string };
    customerAmount: number;
    customerContact: string;
    customerContactName: string;
    rateConfirmationSentAt: number;
    customerDeptAmount: string;
    customerFinalAmount: string;
    loadType: number;
    referenceNumber: string;
    status: number;
    carrierStatus: number;
    customerStatus: number;
    processing: boolean;
    paymentTermType: number;
    createdAt: number;
    addedLoadAt: number;
    takenAsAuthority: { id: number; name: string; mc: string; type: number; createdAt: number };
    customerAccess?: boolean;
    carrierAccess?: boolean;
    retrieved: boolean;
    retrievedAt: number;
    code: number;
    byPaymentTerm: boolean;
    noa: any[];
  };
}

export interface IGetLoadByIdResponse {
  result: IGetLoadById;
}

export interface IGetLoadPoint {
  address: string;
  appointmentDate: number;
  city: string;
  comment: string;
  createdAt: number;
  createdBy: number;
  facility: string;
  id: number;
  loadId: number;
  phone: string;
  state: {
    id: number;
    name: string;
    region: number;
  };
  stateId: number;
  type: number;
  updatedAt: number;
  updatedBy: number;
  zip: string;
  creator: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    username: string;
  };
}

export interface IGetLoadPointsResponse {
  result: IGetLoadPoint[];
  count: number;
}

export interface ICreateLoadPoint {
  dropType: number;
  appointmentDate: Date;
  address: string;
  facility: string;
  city: string;
  state: number;
  zip: string;
  comment: string;
  phoneNumber: string;
  appointmentTime: string;
  timePeriod: string;

  id: string;
}

export interface IRequestParams {
  search?: string;
  skip?: number;
  limit?: number;
  order?: number;
  orderBy?: string;
}

export interface IAddFilesToLoadRequest {
  id: number;
  files: Array<{
    fileId: number;
    type: string;
    readable: string;
  }>;
}

export interface IGetLoadFilesRequest extends IRequestParams {
  id: string;
}

export interface IGetCreditRequestForEmployees extends IRequestParams, ILoadFilters {
  field?: string;
  filter?: object;
}

export interface ILoadFilters {
  branchIds?: number[];
  officeIds?: number[];
  agentIds?: any;
  loadTypes?: string | string[];
  document?: string | string[];
  statuses?: {
    status?: number[];
    customerStatus?: number[];
    carrierStatus?: number[];
    claimed?: boolean;
    processing?: boolean;
  };
  dateStart: string;
  dateEnd?: string;
}

export interface IGetCommentRequest {
  skip: number;
  limit: number;
  id?: number | string;
}

export interface ICreateInternalLoadBody {
  referenceNumber: string;
  customerAmount: number;
  id: number;
  customerContact: string;
  customerContactName: string;
  authorities: any;
  documentation: [number];
  transportType: string;
  takenAs: number;
  givenAs: number;
  bookedAs: number;
  soldAs: number;
  celsius: number;
  commodity: string;
  weight: number;
  feetPartial: number;
  pickups: [];
  dropOffs: [];
  loadPoints: [
    {
      type: number;
      phone: string;
      appointmentDate: number;
      facility: string;
      address: string;
      city: string;
      stateId: number;
      zip: string;
      comment: string;
    }
  ];
}

export interface ICreateConnectingLoadBody {
  referenceNumber: string;
  agentAmount: number;
  agent: any;
  id: number;
  customerContact: string;
  customerAmount: string;
  customerContactName: string;
  authorities: number;
  documentation: [number];
  transportType: string;
  takenAs: number;
  givenAs: number;
  bookedAs: number;
  soldAs: number;
  celsius: number;
  commodity: string;
  weight: number;
  refrigeratorCelsius: number;
  feetPartial: number;
  dropOffs: [];
  pickups: [];
  loadPoints: [
    {
      type: number;
      phone: string;
      appointmentDate: number;
      facility: string;
      address: string;
      city: string;
      stateId: number;
      zip: string;
      comment: string;
    }
  ];
}

export interface ICreateRegularLoadBody {
  referenceNumber: string;
  customerAmount: number;
  customerContactName: string;
  carrier: number;
  carrierAmount: number;
  truck: number;
  id: number;
  customerContact: string;
  authorities: any;
  documentation: [number];
  transportCapability: number;
  takenAs: number;
  givenAs: number;
  bookedAs: number;
  soldAs: number;
  celsius: number;
  commodity: string;
  weight: number;
  feetPartial: number;
  transportType?: string;
  dropOffs: [];
  pickups: [];
  soldUsSwitch: boolean;
}

export interface IPaymentTerm {
  authority: IAuthorityByOfficeId;
  authorityId: number;
  createdAt: number;
  customer: Partial<IAuthorityByOfficeId>;
  customerId: number;
  days: number;
  emails: string[];
  id: number;
  percent: number;
  type: number;
  updatedAt: number;
}

export interface IPaymentTermsBody {
  result: IPaymentTerm[];
  count: number;
}

export interface ITransactionItem {
  agentPaid: boolean;
  carrierAmount: number;
  carrierPaid: boolean;
  comment: string;
  createdAt: number;
  createdBy: number;
  creator: ICreator;
  customerAmount: number;
  customerPaid: boolean;
  deletedAt: number;
  firstAgentAmount: number;
  id: number;
  label: number;
  loadId: number;
  secondAgentAmount: number;
  type: number;
  deletor: any;
}

export interface ITransactionBody {
  result: ITransactionItem[];
  count: number;
}
