import React from 'react';
import { Col, Row } from 'antd';
import Button from 'ui/button/Button';

import ResetIcon from 'components/svgs/ResetIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { SUBJECT } from './constants/constants';
import WhiteListDataGrid from './data-grid/WhiteListDataGrid';
import { Input } from './hoc-instance/instance';
import { useWhiteList } from './useWhiteList';

import styles from './WhiteList.module.scss';

const WhiteList = () => {
  const {
    control,
    setValue,
    getValues,
    errors,
    mode,
    clearErrors,
    setEditingRow,
    handleRowEdit,
    handleRowDelete,
    editingId,
    whiteListData,
    whiteListCount,
    whiteListFormRef,
    handleAddIpAddress,
    isLoading,
    isDirty,
    reset,
  } = useWhiteList();

  if (!whiteListData?.length && mode === 'view') {
    return null;
  }

  return (
    <ViewItemWrapper
      title={SUBJECT.WHITE_LIST}
      Component={
        isDirty && (
          <Button
            icon={<ResetIcon />}
            variant="ghost"
            onClick={() => {
              reset();
              clearErrors();
            }}
            children={SUBJECT.RESET}
          />
        )
      }
    >
      <div ref={whiteListFormRef}>
        <Row gutter={10} align="middle" className={styles.antRow}>
          <Col span={10}>
            <Input
              name="ip"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.IP}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) =>
                setValue('ip', e.target.value.replace(/[^0-9.]/g, ''), { shouldValidate: true, shouldDirty: true })
              }
            />
          </Col>
          <Col span={10}>
            <Input
              name="comment"
              type="text"
              control={control}
              rules={{ required: true }}
              placeholder={SUBJECT.COMMENTS}
              getValues={getValues}
              required
              errors={errors}
              onChange={(e: any) =>
                setValue('comment', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
              }
            />
          </Col>
          <Col span={4}>
            <Button children={editingId ? SUBJECT.SAVE : SUBJECT.ADD} variant="comment" onClick={handleAddIpAddress} />
          </Col>
        </Row>
      </div>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          {!!whiteListData?.length && (
            <WhiteListDataGrid
              data={whiteListData}
              count={mode === 'edit' && whiteListCount}
              handleRowDelete={(values: any) => handleRowDelete(values)}
              handleRowEdit={(values: any) => handleRowEdit(values)}
              editingRow={editingId}
              setEditingRow={setEditingRow}
              isLoading={isLoading}
              withoutAction={mode === 'view'}
            />
          )}
        </Col>
      </Row>
    </ViewItemWrapper>
  );
};

export default WhiteList;
