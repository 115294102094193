export interface IPayoutFilter {
  officePayout: any;
  branchPayout: any;
  agentPayout: any;
  monthPickerPayout: { startDate: Date | number | string; endDate: Date | number | string };
}

export interface ISelectedRow {
  id: number;
  agency: string;
  agent: string;
  branch: string;
  paidAs: string;
  paidDate: string;
  gross: string;
  charges: string;
  credit: string;
  difference: string;
  final: number;
  action: string;
  status: number;
}

export interface IPayoutTableColumns {
  title: string;
  id: string;
  dataIndex: string;
  displayType: string;
  isVisible: boolean;
  sortable: boolean;
  width: number;
  isSearchable?: boolean;
}

export enum Cards {
  'Loads' = 1,
  'Potential gross profit' = 2,
  'Gross profit' = 3,
  'Credit' = 4,
  'Charges' = 5,
  'Service Fee' = 6,
  'Other service fee' = 7,
  'Advance' = 8,
  'Collection' = 9,
  'CreditCheck' = 10,
  'SoftwareFee' = 11,
  'LPF' = 12,
}

export enum ActiveTabRoutes {
  Agency = 'payrollPaymentOffice',
  Branch = 'payrollPaymentBranch',
  Agent = 'payrollPaymentAgent',
}

export interface IPaymentRoutes {
  [key: string]: ActiveTabRoutes;
}

export enum PaymentStatusTypes {
  APPROVED = 1,
  PAID = 2,
  PENDING = 3,
}
