import { ITonuLoadFormData } from './interfaces';

export enum SUBJECT {
  ALLOWED_FORMAT_PDF = 'Allowed Format (pdf)',
  ADDITIONAL = 'Additional',
  LOAD_STATUS = 'Load status',
  EDIT = 'Edit',
  TAKEN_AS_MC = 'Taken As MC',
  LOAD_ID = 'Load ID',
  OFFICE = 'Agency',
  OFFICE_ID = 'Agency ID',
  BRANCH = 'Branch',
  BRANCH_ID = 'Branch ID',
  AGENT = 'Agent',
  AGENT_ID = 'Agent ID',
  CUSTOMER_NAME = 'Customer Name',
  CUSTOMER_ID = 'Customer ID',
  CARRIER_NAME = 'Carrier Name',
  CARRIER_ID = 'Carrier ID',
  LOAD_TYPE = 'Load Type',
  QUICK_PAY = 'Quick Pay',
  REFERENCE_NUMBER = 'Reference Number',
  CUSTOMER_AMOUNT = 'Customer Amount',
  CARRIER_PAYMENT_BREAKDOWN = 'Carrier Payment Breakdown',
  CONTACT_NAME = 'Customer Contact Name',
  CUSTOMER_CONTACT = 'Phone Number',
  CARRIER_AMOUNT = 'Carrier Rate',
  TRUCK = 'Truck',
  COMMENTS = 'Comments',
  DOCUMENTATION = 'Documents',
  ADDED_BY = 'Added by',
  DATE = 'Date',
  LOAD_FINANCE = 'Load Finance',
  CUSTOMER_BALANCE = 'Balance',
  INITIAL_RATE_CUSTOMER = 'Initial Rate',
  FINAL_RECEIVABLE_CUSTOMER = 'Final Receivable',
  AMOUNT_PAID_CUSTOMER = 'Amount Received',
  CARRIER_BALANCE = 'Balance',
  INITIAL_RATE_CARRIER = 'Initial Rate',
  FINAL_PAYABLE_CARRIER = 'Final Payable',
  AMOUNT_PAID_CARRIER = 'Amount Paid',
  AGENT_BALANCE = 'Initial Profit',
  FINAL_PROFIT = 'Final Profit',
  TRANSACTION = 'Transaction',
  DATE_AND_TIME = 'Date and time',
  USERNAME = 'Username',
  CUST_AMOUNT = 'Customer Amount',
  BALANCE_AMOUNT = 'Balance Amount',
  COMMENT = 'Comment',
  GENERAL_INFORMATION_ID = 'general-information',
  CARRIER = 'Carrier Detail',
  NOT_SELECTED = 'Not selected',
  TAKEN_AS_AUTHORITY = 'Taken As Authority',
  CARRIER_SWITCH_NAME = 'carrierSwitch',
  CUSTOMER = 'Customer Detail',
  CUSTOMER_SWITCH_NAME = 'customerSwitch',
  SOLD_US_AUTHORITY = 'Sold As Authority',
  DOCUMENTATION_ID = 'documentation',
  UPLOAD_RED_CONFIRMATION = 'Upload Rate Confirmation',
  UPLOAD_TEXT = 'Drag And Drop Or',
  ALLOWED_FORMAT = 'Allowed Format (jpeg, pdf, tiff, png)',
  DOWNLOAD_ALL = 'Download All',
  FILE_NAME = 'filename_red confirmation_45454121M-454.jpg',
  UPLOADER_NAME = 'Added by Miles Frost',
  UPLOADED_DATE = '12-30-2021, 07:45 PM',
  GENERAL_INFO = 'General Information',
  SOLD_US = 'Sold As',
  SOLD_US_SWITCH_NAME = 'soldUsSwitch',
  CREATE_RATE = 'Create New Carrier Rate Confirmation',
  TONU_LOAD = 'Tonu Load',
  TONU_LOAD_CREATE = 'Add New Load Tonu',
  SEND_TO_BILLING = 'Send To Billing',
  SENT_TO_BILLING = 'Sent To Billing',
  LOADS_FINANCE_ID = 'loads-finance',
  TRANSACTION_ID = 'Transaction',
  TRANSACTION_TYPE = 'Transaction Type',
  WRITE_COMMENT = 'Write Comment',
  ADD_TRANSACTION = 'Add Transaction',
  COMMENT_ID = 'Comment',
  ARE_YOU_SURE_CLAIM = 'Are You Sure You Want To Mark As Claim?',
  ARE_YOU_SURE_LOAD = 'Are You Sure You Want To Cancel Load?',
  ARE_YOU_SURE_ACCEPT = 'Do You Want To Send An Invoice To The customer?',
  YES = 'Yes',
  CANCEL = 'Cancel',
  NO = 'No',
  ARE_YOU_SURE = 'Are You Sure Want To Delete Invoice',
  DELETE = 'Delete',
  ACCEPT = 'Accept',
  ARE_YOU_BILLING = 'Are You Sure Want To Send To Billing',
  SEND = 'Send',
  CLOSE = 'Close',
  ACCEPTED_SUCCESSFULLY = 'Load Was Successfully Accepted To Billing',
  REJECT = 'Reject',
  REJECTED_SUCCESSFULLY = 'Load Was Successfully Rejected To Billing',
  CREATE_INVOICE_IN_PROCCESS = 'Invoice Generation In Process',
  INVOICE_SUCCESSFULLY = 'Invoice Successfully Created',
  REQUEST_SENT_SUCCESSFULLY = 'Request Sent Successfully',
  JUST_ARE_YOU_SURE = 'Are You Sure?',
  HISTORY = 'History',
  ALL_AUTHORITIES = 'Sold As Authority',
  LOAD_TRANSACTION = 'Transactions',
  CUSTOMER_IS_FACTORED = 'Customer Is Factored',
  CUSTOMER_IS_NOT_FACTORED = 'Customer Is Not Factored',
  FACTORED_YES_NO = 'Factored Yes/No',
  TONU_BANNER_ID = 'tonu-load',
  VIEW_CUSTOMER_ID = 'customer-id',
  BILLING_ID = 'billing-id',
  VIEW_CARRIER_ID = 'carrier-id',
  RATE_CONFIRMATION_ID = 'confirmation-id',
  LOAD_FINANCE_ID = 'finance-id',
  LOAD_TRANSACTION_ID = 'transaction-id',
  VIEW_BREAKDOWN_ID = 'breakdown-id',
  CARRIER_RATE_CONFIRMATION_ID = 'carrier-rate-confirmation-id',
}

export enum VALIDATION_MESSAGES {
  CUSTOMER_NAME_REQUIRED = 'Customer name is a required field',
  REFERENCE_NUMBER_REQUIRED = 'Reference number is a required field',
  CUSTOMER_AMOUNT_REQUIRED = 'Customer amount is a required field',
  CUSTOMER_CONTACT_REQUIRED = 'Phone Number is a required field',
  CARRIER_REQUIRED = 'Carrier is a required field',
  TRUCK_REQUIRED = 'Truck is a required field',
  AUTHORITY_REQUIRED = 'Authority is a required field',
  RED_CONFIRMATION_REQUIRED = 'Red confirmation is a required field',
  CARRIER_AMOUNT_REQUIRED = 'Carrier Rate is a required field',
  MIN_OPTION = 'At least one option is required',
  POSITIVE = 'Please enter a positive number',
  INTEGER = 'Please enter an integer',
  MIN_NUMBER = 'Number must be greater than or equal to 0',
  VALID_NUMBER = 'Please enter a valid number',
  MAX_NUMBER = 'Carrier Amount must be less than or equal to Customer Amount',
  FILE_REQUIRED = 'File is a required field',
  PHONE = 'Incorrect phone format',
  MAX_DIGIT = 'Maximum of 10 digits allowed',
  CUSTOMER_CONTACT_NAME_REQUIRED = 'Phone Number is a required field',
  NOT_ZERO = 'Should be a positive number',
  LESS_THAN_MILLION = 'Amount must be less than a million',
}

export const defaultValues: ITonuLoadFormData = {
  allAuthorities: [],
  carrier: [],
  carrierAmount: '',
  carrierSwitch: true,
  customerAmount: '',
  customerContact: '',
  customerContactName: '',
  customerName: [],
  customerSwitch: false,
  file: [],
  loadType: 'Tonu',
  referenceNumber: '',
  soldUsSwitch: false,
  takenUsAuthority: [],
  truck: [],
};

export const SEARCH_CARRIER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
  { key: 'value', name: 'ID Number' },
];

export const SEARCH_CUSTOM_CUSTOMER_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'value', name: 'ID number' },
];

export const CUSTOMER_CUSTOM_KEYS = ['title', 'value'];
export const CARRIER_KEYS = ['id', 'name', 'mc', 'value'];

export const SEARCH_CUSTOMER_COLUMNS = [{ key: 'value', name: '' }];

export const CUSTOMER_KEYS = ['title'];

export const SEARCH_TRUCK_COLUMNS = [{ key: 'value', name: '' }];

export const TRUCK_KEYS = ['title'];

export const MY_AUTHORITY_COLUMNS = [
  { key: 'name', name: 'Name' },
  { key: 'mc', name: 'MC Number' },
];
export const AUTHORITY_KEYS = ['id', 'name', 'mc'];

export const TONU_SECTIONS = [
  {
    id: 'tonu-load',
    title: 'Tonu Load ',
  },
  {
    id: 'general-information',
    title: 'General Information',
  },
  {
    id: 'loads-finance',
    title: 'Loads Finance',
  },
  {
    id: 'transaction',
    title: 'Transaction',
  },
  {
    id: 'carrier-rate-confirmation',
    title: 'Carrier Rate Confirmation',
  },
  {
    id: 'carrier-payment-breakdown',
    title: 'Carrier Payment Breakdown',
  },
  {
    id: 'documentation',
    title: 'Documents',
  },
  {
    id: 'billing-block',
    title: 'Billing',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];
export const TONU_SECTIONS_VIEW = [
  {
    id: 'tonu-load',
    title: 'Tonu Load',
  },
  {
    id: 'finance-id',
    title: 'Load Finance',
  },
  {
    id: 'transaction-id',
    title: 'Transactions',
  },
  {
    id: 'customer-id',
    title: 'Customer Detail',
  },
  {
    id: 'confirmation-id',
    title: 'Customer Rate Confirmation',
  },
  {
    id: 'carrier-id',
    title: 'Carrier Detail',
  },
  {
    id: 'carrier-rate-confirmation-id',
    title: 'Carrier Rate Confirmation',
  },
  {
    id: 'breakdown-id',
    title: 'Carrier Payment Breakdown',
  },
  {
    id: 'billing-id',
    title: 'Billing',
  },
  {
    id: 'comment',
    title: 'Comment',
  },
];
