import { notification } from 'antd';
import { v4 as uuid } from 'uuid';

import CloseIcon from 'components/svgs/CloseIcon';

import { AgentHandler } from './handlers/agent';
import { AuthorityHandler } from './handlers/authority';
import { BranchHandler } from './handlers/branch';
import { CarrierHandler } from './handlers/carrier';
import { CustomerHandler } from './handlers/customer';
import { EmployeeHandler } from './handlers/employee';
import { LoadHandler } from './handlers/load';
import { OfficeHandler } from './handlers/office';
import { PayrollChargeHandler } from './handlers/payroll/charge';
import { PayrollCollectionHandler } from './handlers/payroll/collection';
import { TruckHandler } from './handlers/truck';
import { UserHandler } from './handlers/user';
import { transformHandlerName } from './constants';
import { Handler, ISocketMessage } from './interfaces';

notification.config({
  rtl: false,
  maxCount: 10,
  duration: 4,
  placement: 'topRight',
});

class NotificationHandler {
  public readonly handlers: Record<string, Handler> = {};
  constructor() {
    this.handlers = {
      authority: new AuthorityHandler(),
      office: new OfficeHandler(),
      branch: new BranchHandler(),
      agent: new AgentHandler(),
      user: new UserHandler(),
      customer: new CustomerHandler(),
      carrier: new CarrierHandler(),
      truck: new TruckHandler(),
      employee: new EmployeeHandler(),
      load: new LoadHandler(),
      payrollCharge: new PayrollChargeHandler(),
      payrollCollection: new PayrollCollectionHandler(),
    };
  }

  handle(event: ISocketMessage) {
    const eventType = transformHandlerName(event.payload.eventType);
    const [handlerKey, subHandlerKey] = eventType?.split('.') || [];
    const handler = this.handlers[handlerKey];

    if (handler && subHandlerKey && handler.handlers[subHandlerKey]) {
      handler.handlers[subHandlerKey](event);
      const key = uuid();
      if (handler.notification) {
        notification.open({
          message: handler.notification,
          className: 'notficationItemPopup',
          key,
          btn: (
            <button type="button" onClick={() => notification.destroy(key)}>
              <CloseIcon />
            </button>
          ),
        });
      }
    }
  }
}

export const handler = new NotificationHandler();
