const FuelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_2641_3110)">
        <path
          d="M1.5 3C1.5 1.34531 2.84531 0 4.5 0H12C13.6547 0 15 1.34531 15 3V12H15.375C17.6531 12 19.5 13.8469 19.5 16.125V17.625C19.5 18.2484 20.0016 18.75 20.625 18.75C21.2484 18.75 21.75 18.2484 21.75 17.625V10.4062C20.4562 10.0734 19.5 8.89688 19.5 7.5V4.5L18 3C17.5875 2.5875 17.5875 1.9125 18 1.5C18.4125 1.0875 19.0875 1.0875 19.5 1.5L23.1234 5.12344C23.6859 5.68594 24 6.45 24 7.24688V17.6297C24 19.4953 22.4906 21.0047 20.625 21.0047C18.7594 21.0047 17.25 19.4953 17.25 17.6297V16.1297C17.25 15.0938 16.4109 14.2547 15.375 14.2547H15V21.0047C15.8297 21.0047 16.5 21.675 16.5 22.5047C16.5 23.3344 15.8297 24.0047 15 24.0047L1.5 24C0.670312 24 0 23.3297 0 22.5C0 21.6703 0.670312 21 1.5 21V3ZM4.5 3.75V8.25C4.5 8.6625 4.8375 9 5.25 9H11.25C11.6625 9 12 8.6625 12 8.25V3.75C12 3.3375 11.6625 3 11.25 3H5.25C4.8375 3 4.5 3.3375 4.5 3.75Z"
          fill="#438589"
        />
      </g>
      <defs>
        <clipPath id="clip0_2641_3110">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FuelIcon;
