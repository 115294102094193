import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { useGetLoadFilesByIdsMutation, useGetLoadsListQuery } from 'services/loads/loads';
import { useGetAgentQuery, useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetBranchesAutocompleteQuery } from 'services/profile/branches/branches';
import { useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { AgentTypes, EmployeeLevels, notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { multiplyDownloadWithDelay } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { IRoleLevel } from '../../../../services/truck-board/carrier-request/interfaces';
import { selectUserInfo, selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import { IFilterStatusProps, IFilterVariants, LoadViewModes } from '../../components/create-load/constants/types';
import { INewLoadParams } from '../new-load/constants/types';

import { FILTER_DEFAULT_VALUES, SUBJECT } from './constants/constants';
import { IFilterValues, LoadFilterStatuses } from './constants/types';

export const useLoadsTab = (setExcelParams: any) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const { permissionsInfo, employeeLevel, userType, agentType, branchIds } = useSelector(selectUserPermissionsInfo);

  const { roleLevel } = permissionsInfo;

  const {
    formState: { isDirty },
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
  } = useForm<IFilterValues>({
    defaultValues: FILTER_DEFAULT_VALUES,
    mode: 'onBlur',
  });

  const [withRedConf, setWithRedConf] = useState<boolean>(false);
  const { searchParams, setSearchParams } = useDetectedParams();
  const {
    tab,
    mode,
    open,
    date,
    loadsOffice,
    loadsBranch,
    loadsAgent,
    loadsByType,
    document,
    loadStatus,
    loadStartDate,
    loadEndDate,
    loadOption,
    fuelAdvance,
  } = searchParams;
  const { data: columnsData } = useGetColumnsQuery({ type: 'loads' });

  const navigate = useNavigate();

  const [getNewLoadFilter, setNewLoadFilter] = useState<Partial<INewLoadParams>>({
    skip: 0,
    limit: 20,
    order: 1,
    search: '',
    orderBy: 'status',
    field: '',
  });
  const [filters, setFilters] = useState<IFilterStatusProps>({
    statuses: [],
    carrierStatuses: [],
    customerStatuses: [],
  });
  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...getNewLoadFilter,
          branchIds: loadsBranch?.length ? loadsBranch?.split(',')?.map(el => Number(el)) : [],
          officeIds: loadsOffice?.length ? loadsOffice?.split(',')?.map(el => Number(el)) : [],
          agentIds: loadsAgent ? [Number(loadsAgent)] : [],
          loadTypes: filterWatch('loadsByType'),
          document: filterWatch('document'),
          statuses: {
            ...filters,
            ...(filterWatch('loadStatus').includes(LoadFilterStatuses.processing) ? { processing: true } : {}),
            ...(filterWatch('loadStatus').includes(LoadFilterStatuses.claimed) ? { claimed: true } : {}),
          },
          dateEnd: loadEndDate,
          dateStart: loadStartDate,
          isConvertingTimeNeeded: loadOption === '5',
        };
      });
    }
  }, [
    getNewLoadFilter,
    loadsBranch?.length,
    loadsOffice?.length,
    loadsAgent,
    loadEndDate,
    loadStartDate,
    filterWatch('loadsByType'),
    filterWatch('document'),
    filters,
    filterWatch('loadStatus'),
  ]);

  const [getLoadFilesByIds] = useGetLoadFilesByIdsMutation();

  const [branchFilter, setBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    withDefaults: true,
  });

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
  });

  const [selectedOffices, setSelectedOffices] = useState<any>([]);
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  const [selectedBranches, setSelectedBranches] = useState<any>([]);
  const [finalBranchesOptions, setFinalBranchesOptions] = useState<any>({ result: [], count: 0 });

  const { data: officesOptions = { result: [], count: 0 }, isFetching: isOfficesFetching } =
    useGetOfficesAutocompleteQuery(officesFilter);
  const { data: branchesData = { result: [], count: 0 }, isFetching: isBranchFetching } =
    useGetBranchesAutocompleteQuery(
      { officeId: selectedOffices?.[0]?.id, ...branchFilter },
      {
        skip:
          roleLevel === IRoleLevel.corpLevel &&
          (!filterWatch('loadsOffice')?.length || filterWatch('loadsOffice')!.length > 1),
      }
    );

  ////////////// AGENT FILTER
  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
    excludeOwners: true,
  });

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    {
      ...agentsFilter,
      branchId:
        employeeLevel === EmployeeLevels.BRANCH || agentType === AgentTypes.MANAGER
          ? branchIds?.[0]
          : filterWatch('loadsBranch')?.[0],
    },
    {
      skip:
        roleLevel <= IRoleLevel.officeLevel &&
        (typeof filterWatch('loadsBranch') === 'string' || filterWatch('loadsBranch')?.length !== 1),
    }
  );

  const { data: selectedAgent } = useGetAgentQuery(loadsAgent, { skip: !loadsAgent });

  const [selectedAgentOption, setSelectedAgentOption] = useState<any>({});
  const [finalAgentOptions, setFinalAgentOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    if (selectedAgent) {
      setSelectedAgentOption([
        {
          firstName: selectedAgent?.result?.firstName,
          lastName: selectedAgent?.result?.lastName,
          username: selectedAgent?.result?.username,
          id: selectedAgent?.result?.id,
          value: String(selectedAgent?.result?.id),
        },
      ]);
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (agentsOptions && !selectedAgentOption.id) {
      setFinalAgentOptions(agentsOptions);
    } else if (agentsOptions) {
      setFinalAgentOptions({
        result: [selectedAgentOption, ...agentsOptions.result.filter((el: any) => el.id !== selectedAgentOption?.id)],
        count: agentsOptions?.count,
      });
    }
  }, [selectedAgentOption, agentsOptions?.result?.length]);
  ////////////////////

  useEffect(() => {
    if (Array.isArray(filterWatch('loadsBranch'))) {
      setSelectedBranches((prev: any) =>
        [
          ...prev.filter((el: any) => !branchesData?.result?.some((item: any) => item.value == el.value)),
          ...branchesData.result,
        ].filter((el: any) => filterWatch('loadsBranch')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('loadsBranch'), isBranchFetching]);

  useEffect(() => {
    if (Array.isArray(filterWatch('loadsOffice'))) {
      setSelectedOffices((prev: any) =>
        [
          ...prev.filter((el: any) => !officesOptions?.result?.some((item: any) => item.value == el.value)),
          ...officesOptions.result,
        ].filter((el: any) => filterWatch('loadsOffice')?.some((item: any) => item == el.value))
      );
    }
  }, [filterWatch('loadsOffice'), isOfficesFetching]);

  useEffect(() => {
    if (officesOptions?.result) {
      setFinalOfficeOptions({
        result: [
          ...selectedOffices,
          ...officesOptions.result.filter((el: any) => !selectedOffices.some((item: any) => item.value == el.value)),
        ],
        count: officesOptions.count,
      });
    } else {
      setFinalOfficeOptions(selectedOffices);
    }
  }, [selectedOffices, isOfficesFetching]);

  useEffect(() => {
    if (branchesData?.result?.length) {
      setFinalBranchesOptions({
        result: [
          ...selectedBranches,
          ...branchesData.result.filter((el: any) => !selectedBranches.some((item: any) => item.value == el.value)),
        ],
        count: branchesData.count,
      });
    } else {
      setFinalBranchesOptions(selectedBranches);
    }
  }, [selectedBranches?.length, isBranchFetching]);

  const filterVariants: IFilterVariants = {
    1: { status: [3] },
    2: { processing: true },
    3: { carrierStatus: [1] },
    4: { customerStatus: [1] },
    5: { customerStatus: [2] },
    6: { carrierStatus: [2] },
    7: { status: [2] },
    8: { claimed: true },
  };

  const handleLoadFilterStatus = (selectedValues: any) => {
    const updatedFilters: any = {
      statuses: [],
      carrierStatuses: [],
      customerStatuses: [],
    };

    selectedValues.forEach((selectedValue: any) => {
      const filterVariant = filterVariants[selectedValue];

      if (filterVariant) {
        if (filterVariant.status) {
          updatedFilters.statuses = [...(new Set([...updatedFilters.statuses, ...filterVariant.status]) as any)];
        }
        if (filterVariant.carrierStatus) {
          updatedFilters.carrierStatuses = [
            ...(new Set([...updatedFilters.carrierStatuses, ...filterVariant.carrierStatus]) as any),
          ];
        }
        if (filterVariant.customerStatus) {
          updatedFilters.customerStatuses = [
            ...(new Set([...updatedFilters.customerStatuses, ...filterVariant.customerStatus]) as any),
          ];
        }
      }
    });

    setFilters(updatedFilters);
    setFilterValue('loadStatus', selectedValues, { shouldDirty: true, shouldTouch: true });
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  const [editColumns] = useEditColumnsMutation();

  const dateFilter = filterWatch('date');
  const dateQueryCheck = !!(typeof dateFilter === 'string' && dateFilter);

  const loadsOfficeFilter = filterWatch('loadsOffice');

  const loadsBranchFilter = filterWatch('loadsBranch');

  const loadsAgentFilter = filterWatch('loadsAgent')?.[0]?.id
    ? filterWatch('loadsAgent')?.[0]?.id
    : filterWatch('loadsAgent');

  const loadsByTypeFilter = filterWatch('loadsByType')?.toString();
  const loadsByTypeQueryCheck = !!(typeof loadsByTypeFilter === 'string' && loadsByTypeFilter);

  const documentFilter = filterWatch('document')?.toString();
  const documentQueryCheck = !!(typeof documentFilter === 'string' && documentFilter);

  const loadStatusFilter = filterWatch('loadStatus')?.toString();
  const loadStatusQueryCheck = !!(typeof loadStatusFilter === 'string' && loadStatusFilter);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(dateQueryCheck ? { date: dateFilter } : { date: '' }),
      ...(loadsOfficeFilter
        ? { loadsOffice: Array.isArray(loadsOfficeFilter) ? loadsOfficeFilter?.join(',') : '' }
        : { loadsOffice: '' }),
      ...(loadsBranchFilter
        ? { loadsBranch: Array.isArray(loadsBranchFilter) ? loadsBranchFilter?.join(',') : '' }
        : { loadsBranch: '' }),
      ...(loadsAgentFilter ? { loadsAgent: loadsAgentFilter } : { loadsAgent: '' }),
      ...(loadsByTypeQueryCheck ? { loadsByType: loadsByTypeFilter } : { loadsByType: '' }),
      ...(documentQueryCheck ? { document: documentFilter } : { document: '' }),
      ...(loadStatusQueryCheck ? { loadStatus: loadStatusFilter } : { loadStatus: '' }),
      ...(filterWatch('fuelAdvance')?.length
        ? { fuelAdvance: filterWatch('fuelAdvance') as string }
        : { fuelAdvance: '' }),
    });
  }, [
    dateFilter,
    loadsOfficeFilter?.length,
    loadsBranchFilter?.length,
    loadsAgentFilter,
    loadsByTypeFilter,
    documentFilter,
    loadStatusFilter,
    filterWatch('fuelAdvance')?.length,
  ]);

  useEffect(() => {
    if (filterWatch('loadsOffice')!.length !== 1 && roleLevel === IRoleLevel.corpLevel) {
      setSearchParams({
        ...searchParams,
        loadsBranch: '',
        loadsAgent: '',
      });
      setFilterValue('loadsBranch', []);
      setFilterValue('loadsAgent', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('loadsOffice')]);

  useEffect(() => {
    if (filterWatch('loadsBranch')!.length !== 1 && roleLevel <= IRoleLevel.officeLevel) {
      setSearchParams({
        ...searchParams,
        loadsAgent: '',
      });
      setFilterValue('loadsAgent', []);
      setSelectedAgentOption({});
    }
  }, [filterWatch('loadsBranch')]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
  }, []);

  useEffect(() => {
    const filterOptions = [
      { key: 'date', value: date },
      { key: 'loadsOffice', value: loadsOffice ? loadsOffice.split(',') : loadsOffice },
      { key: 'loadsBranch', value: loadsBranch ? loadsBranch.split(',') : loadsBranch },
      {
        key: 'loadsAgent',
        value: loadsAgent,
      },
      { key: 'loadsByType', value: loadsByType ? loadsByType.split(',') : loadsByType },
      { key: 'document', value: document ? document.split(',') : document },
      { key: 'loadStatus', value: loadStatus ? loadStatus.split(',') : loadStatus },
      { key: 'fuelAdvance', value: fuelAdvance },
    ];

    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilterValue(key as keyof IFilterValues, value);
      }
    });
    setNewLoadFilter((prev: any) => {
      return {
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: {
          ...prev.filter,
        },
      };
    });
  }, [
    date,
    loadsOffice,
    loadsBranch,
    loadsAgent,
    loadsByType,
    document,
    loadStatus,
    agentsOptions?.result?.length,
    fuelAdvance,
  ]);

  const handleResetFilters = () => {
    filterReset();
    setSelectedAgentOption({});
    setFilters({
      statuses: [],
      carrierStatuses: [],
      customerStatuses: [],
    });
    setSearchParams({
      tab,
      open,
      mode,
      loadStartDate: '',
      loadEndDate: '',
      loadAllDate: '',
      loadOption: '3',
      fuelAdvance: '',
    });
    setNewLoadFilter((prev: any) => {
      return {
        ...prev,
        skip: 0,
      };
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setNewLoadFilter((prev: any) => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleRowClick = (rowId: string, row: any) => {
    setSearchParams({
      ...searchParams,
      mode: LoadViewModes?.[row.loadType as keyof typeof LoadViewModes],
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string, rowData: any) => {
    setSearchParams({
      ...searchParams,
      mode: rowData?.loadType === 'Tonu' ? 'tonuEdit' : 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setNewLoadFilter((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 1,
          orderBy: 'status',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handleStopResize = (columns: []) => {
    editColumns({ type: 'loads', columns });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setNewLoadFilter(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleDownloadClick = (fileIds: number[], id: number) => {
    if (fileIds.length) {
      getLoadFilesByIds({ id, fileIds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: SUBJECT.DOWNLOADING_IN_PROCCESS,
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          const files = data?.data?.result.map((file: any) => {
            return {
              preview: file?.file?.preview,
              download: file?.file?.download,
              id: file?.file?.id,
            };
          });
          multiplyDownloadWithDelay(files);
        }
      });
    }
  };

  const handleFilterWithRedConfirmation = () => {
    if (!filterWatch('document').includes('2')) {
      setFilterValue('document', [...filterWatch('document'), '2']);
    } else {
      const filtered = filterWatch('document').filter(item => item !== '2');
      setFilterValue('document', filtered);
    }
    setWithRedConf(prev => !prev);
  };

  useEffect(() => {
    setWithRedConf(!!filterWatch('document').includes('2'));
  }, [filterWatch('document')]);

  const handleCommentClick = (id: string) => {
    setSearchParams({
      ...searchParams,
      mode: 'comments',
      open: 'true',
      id: id,
    });
  };

  useEffect(() => {
    if (userInfo.officeIds && userType !== 1) {
      if (
        (loadsOffice && !loadsOffice.split(',')?.every(el => userInfo?.officeIds?.some(id => id === Number(el)))) ||
        (loadsBranch && !loadsBranch.split(',')?.every(el => userInfo?.branchIds?.some(id => id === Number(el))))
      ) {
        setTimeout(() => {
          navigate({ pathname: '/not-found' });
        }, 0);
      }
    }
  }, [userInfo]);

  const { data, isFetching: isLoading } = useGetLoadsListQuery(
    {
      ...getNewLoadFilter,
      branchIds: loadsBranch?.length ? loadsBranch?.split(',')?.map(el => Number(el)) : [],
      officeIds: loadsOffice?.length ? loadsOffice?.split(',')?.map(el => Number(el)) : [],
      agentIds: loadsAgent ? [Number(loadsAgent)] : [],
      loadTypes: filterWatch('loadsByType'),
      document: filterWatch('document'),
      fuelAdvance: filterWatch('fuelAdvance'),
      statuses: {
        ...filters,
        ...(filterWatch('loadStatus').includes(LoadFilterStatuses.processing) ? { processing: true } : {}),
        ...(filterWatch('loadStatus').includes(LoadFilterStatuses.claimed) ? { claimed: true } : {}),
      },
      dateEnd: loadEndDate,
      dateStart: loadStartDate,
      isConvertingTimeNeeded: loadOption === '5',
    },
    { skip: !loadStartDate || !loadEndDate }
  );

  useEffect(() => {
    if (data?.result) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result]);

  useEffect(() => {
    handleLoadFilterStatus(filterWatch('loadStatus'));
  }, []);

  return {
    data,
    columns: columnsData?.columns,
    isLoading,
    withRedConf,
    filterControl,
    handleRowEdit,
    handleFilterWithRedConfirmation,
    handleRowClick,
    handleSortClick,
    handleColumnSearch,
    handlePaginationClick,
    handleStopResize,
    handleDownloadClick,
    handleResetFilters,
    handleCommentClick,
    setFilterValue,
    filterWatch,
    agentsFilter,
    setAgentsFilter,
    agentsOptions,
    officesOptions: finalOfficeOptions,
    setOfficesFilter,
    setBranchFilter,
    branchesData: finalBranchesOptions,
    skip: getNewLoadFilter.skip,
    isDirty: isDirty || loadOption !== '3',
    loadOption,
    limit: getNewLoadFilter.limit,
    selectedOffices,
    selectedBranches,
    loadsAgent,
    handleLoadFilterStatus,
    roleLevel,
    finalAgentOptions,
    selectedAgentOption,
  };
};
