import { getWhiteListColumns } from './constants/helpers';

const useWhiteListDataGrid = ({ handleRowDelete, handleRowEdit, setEditingRow, withoutAction }: any) => {
  const CURRENT_COLUMNS = getWhiteListColumns({
    handleRowDelete,
    handleRowEdit,
    setEditingRow,
    withoutAction,
  });

  return { CURRENT_COLUMNS };
};

export default useWhiteListDataGrid;
