import { ISummariesCards } from 'services/payroll/information/types';

import {
  CardNames,
  cardsNames,
  FACTORED_CARDS,
  LPF_SOFTWARE_FEE_CARDS,
  NOT_FACTORED_CARDS,
  PAYROLL_CARDS_ID,
} from './constants';
import {
  CollectionCards,
  CreditCheckCard,
  DistributeCards,
  FactoredCards,
  LPF_SOFTWARE_FEE_CARDS_ENUM,
  NotFactoredCards,
  SUMMARIES_FACTORED_CARDS,
} from './types';

export const getInformationOrderBy = (card: string) => {
  if (
    FACTORED_CARDS.includes(card as FactoredCards) ||
    DistributeCards.CHARGES === card ||
    DistributeCards.CREDITS === card ||
    CreditCheckCard.CREDIT_CHECK === card ||
    LPF_SOFTWARE_FEE_CARDS.includes(card as LPF_SOFTWARE_FEE_CARDS_ENUM)
  )
    return 'acceptBillingTimeAt';
  if (NOT_FACTORED_CARDS.includes(card as NotFactoredCards)) return 'addedLoadAt';
  if (CollectionCards.COLLECTION === card) return 'createdFor';
  return 'createdAt';
};

export const payrollCards = (cards: ISummariesCards, selectedCardData: { [key: string]: any } = {}, card?: number) => {
  if (cards) {
    const filteredSelectedCardData = {
      ...cards,
      ...(SUMMARIES_FACTORED_CARDS[card as number]
        ? {
            [SUMMARIES_FACTORED_CARDS[card as number]]: selectedCardData[SUMMARIES_FACTORED_CARDS[card as number]],
          }
        : {}),
    };
    const { finalPay: _, ...rest } = { ...cards, ...filteredSelectedCardData };

    return Object.entries(rest).map(([name, price]) => {
      return {
        price,
        name: cardsNames[name as keyof typeof cardsNames],
        id: PAYROLL_CARDS_ID[CardNames[name as keyof typeof CardNames]]?.toString(),
        type: name,
      };
    });
  }
};
