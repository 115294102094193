import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { LoadTypes } from 'pages/loads/constants/constants';
import { IGetLoadsParams } from 'services/loads/interface';
import { useGetCarrierInsuranceByIdQuery, useGetTrucksAutocompleteQuery } from 'services/loads/loads';
import { useGetAgentsAutocompleteQuery } from 'services/profile/agents/agents';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import { useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { useGetCustomersAutocompleteQuery } from 'services/profile/customers/customers';
import { useGetStatesQuery } from 'services/states/states';

import { selectUserPermissionsInfo } from '../../../../store/user-slice/selector';
import { EmployeeLevels, UserTypes } from '../../../../utils/constants';

import { defaultValues, pickupsDropoffsDefaultValues } from './constants/constants';
import { ICreateLoad, IPickupsDropoffs } from './constants/types';
import { pickupsDropoffsValidation, validation } from './constants/validation';

export const useCreateLoadOutside = () => {
  const { searchParams } = useDetectedParams();
  const { mode, open } = searchParams;
  const { employeeLevel, userType } = useSelector(selectUserPermissionsInfo);

  const [timePeriod, setTimePeriod] = useState<'AM' | 'PM'>('AM');

  const [isEditCreating, setIsEditCreating] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<number | undefined>();

  const [carrierId, setCarrierId] = useState('');

  const { data: insuranceDate } = useGetCarrierInsuranceByIdQuery({ id: carrierId }, { skip: !carrierId });

  const {
    formState: { isDirty, isValid, errors, touchedFields, dirtyFields, isSubmitting, isSubmitted },
    control,
    watch,
    setValue,
    handleSubmit,
    trigger,
    reset,
    clearErrors,
    resetField,
    setFocus,
  } = useForm<ICreateLoad>({
    defaultValues,
    mode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(validation(true)),
  });

  useEffect(() => {
    if (watch('truck')?.length && watch('carrier')?.length) {
      resetField('truck');
      trigger('truck');
    }
    if (watch('carrier')?.[0]?.value) {
      setCarrierId(watch('carrier')?.[0]?.value);
    }
  }, [watch('carrier')]);

  const {
    formState: { isValid: isPickupDropOffsValid, errors: pickupDropOffsErrors, dirtyFields: pickupDirtyFields },
    control: pickupDropOffsControl,
    watch: pickupDropOffsWatch,
    setValue: setPickupDropOffsValue,
    reset: pickupsDropoffsReset,
    handleSubmit: handleSubmitPickupDropOffs,
    trigger: pickupsDropoffsTrigger,
    setFocus: setPickupDropOffsFocus,
    clearErrors: clearPickupDropoffsError,
    setError: setPickUpDropOffError,
  } = useForm<IPickupsDropoffs>({
    defaultValues: pickupsDropoffsDefaultValues,
    mode: 'all',
    resolver: yupResolver(
      pickupsDropoffsValidation({
        loadType: watch('load_type'),
        pickups: watch('pickups'),
        dropOffs: watch('dropOffs'),
        timePeriod: timePeriod,
        isEditCreating,
        editedId,
        editMode: false,
      })
    ),
  });

  useEffect(() => {
    if (open === 'false') {
      pickupsDropoffsReset({});
      reset(defaultValues);
    }
  }, [open]);

  useEffect(() => {
    // todo appointmentTime errormessage
    if (pickupDropOffsWatch('appointmentTime') && pickupDropOffsWatch('appointmentTime') !== 'null:null') {
      pickupsDropoffsTrigger('appointmentTime');
    }
    if (pickupDropOffsWatch('appointmentDate')) {
      pickupsDropoffsTrigger('appointmentDate');
    }
  }, [pickupDropOffsWatch('appointmentDate'), timePeriod, pickupDropOffsWatch('appointmentTime')]);

  // useEffect(() => {
  //   if (data?.customerName) {
  //     setValue('customerName', data?.customerName, { shouldDirty: false, shouldTouch: false });
  //   }
  // }, [data?.customerName, open]);

  // useEffect(() => {
  //   if (data?.customerAmount) {
  //     setValue('customerAmount', Number(data?.customerAmount), { shouldDirty: false, shouldTouch: false });
  //   }
  // }, [data?.customerAmount, open]);

  const [carriersState, setCarriersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [customersState, setCustomersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const [agentsFilter, setAgentsFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const { data: agentsOptions = { result: [], count: 0 } } = useGetAgentsAutocompleteQuery(
    { ...agentsFilter },
    {
      skip: mode !== 'outsideCreate' || open === 'false' || LoadTypes.CONNECTING_LOAD !== watch('load_type'),
    }
  );

  const [trucksState, setTrucksState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    search: '',
    limit: 10,
  });

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    search: '',
    skip: 0,
    limit: 10,
    ignoreAuthorityIds: [],
  });

  const [serializedStates, setSerializedState] = useState({});

  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: mode !== 'outsideCreate' || open === 'false',
    }
  );

  const { data: carriers = [] as any } = useGetCarriersAutocompleteQuery(carriersState, {});

  const { data: customers = [] as any } = useGetCustomersAutocompleteQuery(customersState, {});

  const { data: trucks } = useGetTrucksAutocompleteQuery(
    { carrierId: watch('carrier')?.[0]?.value, ...trucksState },
    { skip: !watch('carrier')?.length || mode !== 'outsideCreate' || open === 'false' }
  );

  const { data: statesData } = useGetStatesQuery(
    {},
    { skip: mode === 'viewNewLoad' || mode === 'view' || mode === 'customize' || open === 'false' }
  );
  const statesSerializer = (statesData: any) => {
    const serializedStates: any = {};
    statesData.forEach((el: any) => el.children.forEach((el: any) => (serializedStates[el.value] = el.title)));
    setSerializedState(serializedStates);
  };
  useEffect(() => {
    if (statesData?.length) {
      statesSerializer(statesData);
    }
  }, [statesData]);

  const handleClearDate = () => {
    setPickupDropOffsValue('appointmentDate', null, { shouldDirty: true, shouldValidate: true });
  };

  const handleSwitchSoldUs = () => {
    setValue('soldUsSwitch', !watch('soldUsSwitch'), {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const pickups = watch('pickups') || [];
  const dropOffs = watch('dropOffs') || [];
  const dropType = pickupDropOffsWatch('dropType') || '';

  const pickupEditedIndex = pickups?.findIndex((el: any) => el.id === editedId);
  const dropOffEditedIndex = dropOffs?.findIndex((el: any) => el.id === editedId);

  const prevPickUpIndex =
    pickups.length && pickups.length > 1 && pickupEditedIndex !== 0 ? pickupEditedIndex - 1 : pickupEditedIndex;

  const prevDropOffIndex =
    dropOffs.length && dropOffs.length > 1 && dropOffEditedIndex !== 0 ? dropOffEditedIndex - 1 : dropOffEditedIndex;

  const nextDropOffIndex =
    dropOffs.length > 1 && dropOffEditedIndex + 1 <= dropOffs.length - 1 ? dropOffEditedIndex + 1 : dropOffEditedIndex;

  const nextPickUpIndex =
    pickups.length && pickups.length > 1 && pickupEditedIndex + 1 <= pickups.length - 1
      ? pickupEditedIndex + 1
      : pickupEditedIndex;
  const handleMinCalendarDisable = () => {
    if (dropType == '1' && pickupEditedIndex > prevPickUpIndex) {
      return pickups[prevPickUpIndex]?.appointmentDate;
    } else if (dropType == '1' && pickupEditedIndex === prevPickUpIndex) {
      return;
    } else if (dropType == '2' && dropOffEditedIndex > prevDropOffIndex) {
      return dropOffs[prevDropOffIndex]?.appointmentDate;
    } else if (dropType == '2' && dropOffEditedIndex === prevDropOffIndex) {
      return pickups[0]?.appointmentDate;
    }
  };

  const handleMaxCalendarDisableEdit = () => {
    if (dropType == '1' && watch('pickups').length === 1 && watch('dropOffs').length) {
      return dropOffs[0]?.appointmentDate;
    } else if (dropType == '1' && watch('pickups').length > 1 && pickupEditedIndex < nextPickUpIndex) {
      if (pickups[pickupEditedIndex]?.appointmentDate < pickups[nextPickUpIndex]?.appointmentDate) {
        return pickups[nextPickUpIndex]?.appointmentDate;
      }
    } else if (dropType == '2' && watch('dropOffs').length > 1 && dropOffEditedIndex < nextDropOffIndex) {
      return dropOffs[nextDropOffIndex]?.appointmentDate;
    } else if (dropType == '2' && watch('dropOffs').length > 1 && dropOffEditedIndex === nextDropOffIndex) {
      return;
    }
  };

  const handleEditMinCalendarDisableEdit = () => {
    if (watch('dropOffs')?.length && pickupDropOffsWatch('dropType') == '2') {
      return watch('dropOffs')?.[watch('dropOffs').length - 1]?.appointmentDate;
    } else if (watch('pickups')?.length && pickupDropOffsWatch('dropType') == '1') {
      return watch('pickups')?.[watch('pickups').length - 1]?.appointmentDate;
    } else if (watch('pickups')?.length === 1 && pickupDropOffsWatch('dropType') == '1') {
      return watch('pickups')?.[0]?.appointmentDate;
    } else if (!watch('dropOffs')?.length && pickupDropOffsWatch('dropType') == '2') {
      return watch('pickups')?.[0]?.appointmentDate;
    }
  };

  useEffect(() => {
    setAuthoritiesState((prev: any) => {
      return {
        ...prev,
        ...(watch('takenUsAuthority').length
          ? { ignoreAuthorityIds: [watch('takenUsAuthority')?.[0]?.id] }
          : { ignoreAuthorityIds: [] }),
      };
    });
    if (!watch('takenUsAuthority').length) {
      resetField('authorities');
    }
  }, [watch('takenUsAuthority')]);

  useEffect(() => {
    if (watch('load_type')) {
      clearErrors();
    }
  }, [watch('load_type')]);

  return {
    loadType: watch('load_type'),
    handleSubmit,
    handleSubmitPickupDropOffs,
    watch,
    pickupDropOffsWatch,
    control,
    pickupDropOffsControl,
    errors,
    pickupDropOffsErrors,
    authorities,
    setAuthoritiesState,
    carriers,
    setCarriersState,
    setCustomersState,
    agents: agentsOptions,
    setAgentsState: setAgentsFilter,
    trucks,
    setTrucksState,
    setValue,
    setPickupDropOffsValue,
    handleClearDate,
    statesData,
    serializedStates,
    isValid,
    isPickupDropOffsValid,
    pickupsDropoffsTrigger,
    trigger,
    isDirty,
    handleSwitchSoldUs,
    reset,
    clearErrors,
    pickupsDropoffsReset,
    carrierInsurance: insuranceDate?.result || 0,
    minDate: !isEditCreating ? handleEditMinCalendarDisableEdit() : handleMinCalendarDisable(),

    maxDate: isEditCreating ? handleMaxCalendarDisableEdit() : undefined,
    setTimePeriod,
    timePeriod,
    disabled: !pickupDropOffsWatch('dropType'),
    timeDisabled: !pickupDropOffsWatch('appointmentDate'),
    isEditCreating,
    setIsEditCreating,
    editedId,
    setEditedId,
    touchedFields,
    resetField,
    dirtyFields,
    isSubmitting,
    setFocus,
    isSubmitted,
    setPickupDropOffsFocus,
    pickupDirtyFields,
    clearPickupDropoffsError,
    setPickUpDropOffError,
    customers,
  };
};
