import { createApi } from '@reduxjs/toolkit/query/react';
import { ICarrierRatingParams } from 'services/profile/carriers/interfaces';
import { baseQuery } from 'services/service';

import { handleShowFloatingPercent } from '../../utils/helpers';
import { IDepthByCustomerAndAuthority } from '../profile/offices/interfaces';
import { getDepthByCustomerAndAuthoritySerializer } from '../profile/offices/serializers';

import {
  createConnectingLoad,
  createConnectingLoadOutside,
  createInternalLoad,
  createInternalLoadOutside,
  createLoadPoint,
  createRegularLoad,
  createRegularLoadOutside,
  createTonuLoad,
  editInternalInfoLoad,
  editLoad,
  editLoadPoint,
} from './deserializers';
import {
  IAddFilesToLoadRequest,
  ICreditRequestForEmployeeByIdResponse,
  ICreditRequestForEmployeeResponse,
  IGetCommentRequest,
  IGetCommentsResponse,
  IGetCreditRequestForEmployees,
  IGetLoadByIdModified,
  IGetLoadByIdResponse,
  IGetLoadFilesRequest,
  IGetLoadPointsResponse,
  ILoadFilesResponse,
  ILoadsRequestResponse,
  IPaymentTermsBody,
  ITransactionBody,
} from './interface';
import {
  getCreditRequestForEmployeesByIdSerializer,
  getCreditRequestForEmployeesSerializer,
  getLoadById,
  getLoadComments,
  getLoadFilesSerializer,
  getLoadPointById,
  getLoadsParamsSerializer,
  getLoadsSerializer,
  getNewLoadForAgentSerializer,
  getPaymentTermsSerializer,
  getTransactionstSerializer,
} from './serializers';

export const loadsAPI = createApi({
  reducerPath: 'loadsAPI',
  baseQuery,
  tagTypes: ['Loads', 'loadsFiles', 'unspecified', 'Notes', 'Billing', 'newLoadForAgent', 'newLoadForEmployee'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    addFileToLoad: builder.mutation({
      query: ({ id, files }: IAddFilesToLoadRequest) => ({
        url: `loads/${id}/files/`,
        method: 'POST',
        body: { files },
      }),
      invalidatesTags: ['Loads'],
    }),
    addRedConfirmationFileToLoad: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `loads/${id}/file/${fileId}/red-confirmation`,
        method: 'POST',
      }),
      invalidatesTags: ['loadsFiles', 'Loads'],
    }),
    addUnspecifiedFileToLoad: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `loads/${id}/file/${fileId}/unspecified`,
        method: 'POST',
      }),
      invalidatesTags: ['unspecified', 'Loads'],
    }),
    getLoadFiles: builder.query({
      query: ({ id, search, skip }: IGetLoadFilesRequest) => ({
        url: `loads/${id}/files`,
        params: { search, skip, limit: 50, order: 2, orderBy: 'createdAt' },
      }),
      transformResponse: (body: ILoadFilesResponse) => getLoadFilesSerializer(body),
      providesTags: ['loadsFiles', 'unspecified'],
    }),
    deleteFileFromLoad: builder.mutation({
      query: ({ fileId }: { fileId: number }) => ({
        url: `loads/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['loadsFiles', 'Loads'],
    }),
    createCheckRequest: builder.mutation({
      query: body => ({
        url: 'loads/credits',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Loads'],
    }),
    getCreditRequestForEmployees: builder.query({
      query: ({ field, search, skip, limit, order, orderBy }: Partial<IGetCreditRequestForEmployees>) => ({
        url: `loads/credits/employees`,
        params: { search, field, skip, limit, order, orderBy },
      }),
      transformResponse: (body: ICreditRequestForEmployeeResponse) => getCreditRequestForEmployeesSerializer(body),
      providesTags: ['newLoadForEmployee', 'Loads'],
    }),
    getCreditRequestForAgents: builder.query({
      query: ({ field, search, skip, limit, order, orderBy }: Partial<IGetCreditRequestForEmployees>) => {
        return {
          url: `loads/credits/agents`,
          params: { search, field, skip, limit, order, orderBy },
        };
      },
      providesTags: ['Loads', 'newLoadForAgent'],
      transformResponse: (body: ICreditRequestForEmployeeResponse) => getNewLoadForAgentSerializer(body),
    }),
    getLoadsList: builder.query({
      query: (filters: any) => {
        const queryParams = getLoadsParamsSerializer(filters);
        return {
          url: 'loads',
          params: queryParams,
        };
      },
      transformResponse: (body: ILoadsRequestResponse) => getLoadsSerializer(body),
      providesTags: ['Loads'],
    }),
    getCreditRequestForEmployeesById: builder.query({
      query: ({ id }: { id: any }) => ({
        url: `loads/credits/${id}/employees`,
      }),
      transformResponse: (body: { result: ICreditRequestForEmployeeByIdResponse }) =>
        getCreditRequestForEmployeesByIdSerializer(body?.result),
    }),
    getCreditRequestForAgentsById: builder.query({
      query: ({ id }: { id: any }) => ({
        url: `loads/credits/${id}/agents`,
      }),
      transformResponse: (body: { result: ICreditRequestForEmployeeByIdResponse }) =>
        getCreditRequestForEmployeesByIdSerializer(body?.result),
    }),
    createComment: builder.mutation({
      query: ({ id, message }: { id: number; message: string }) => ({
        url: `loads/${id}/comments`,
        method: 'POST',
        body: { message },
      }),
      invalidatesTags: ['Loads'],
    }),
    getComments: builder.query({
      query: ({ id, skip, limit }: IGetCommentRequest) => ({
        url: `loads/${id}/comments`,
        params: { skip, limit },
      }),
      transformResponse: (body: IGetCommentsResponse) => getLoadComments(body),
      providesTags: ['Loads'],
    }),
    deleteComment: builder.mutation({
      query: ({ id, commentId }: { id: number; commentId: number }) => ({
        url: `loads/${id}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loads'],
    }),
    updateCreditStatus: builder.mutation({
      query: ({ id, status }: { id: number; status: string }) => ({
        url: `loads/credits/${id}`,
        method: 'PUT',
        body: { status },
      }),
      invalidatesTags: ['Loads'],
    }),
    getDepthByCustomerAndAuthority: builder.query({
      query: ({ customerId, authorityId }: { customerId: number; authorityId: number }) => ({
        url: `loads/depth/${customerId}/${authorityId}`,
      }),
      transformResponse: (body: IDepthByCustomerAndAuthority) => getDepthByCustomerAndAuthoritySerializer(body),
      providesTags: ['Loads'],
    }),
    getPaymentTermsByCustomerIdAndAuthorityId: builder.query({
      query: ({ customerId, authorityId }: { customerId: number; authorityId: number }) => ({
        url: `customers/${customerId}/payment-terms/${authorityId}`,
      }),
      transformResponse: (body: any) => {
        if (Object.keys(body).length) {
          return {
            result: {
              ...body.result,
              percent: handleShowFloatingPercent(body?.result?.percent),
              ...(body?.result?.paymentLimit
                ? { paymentLimit: handleShowFloatingPercent(body?.result?.paymentLimit) }
                : {}),
            },
          };
        }
      },
      providesTags: ['Loads'],
    }),
    getPaymentTerms: builder.query({
      query: ({ field, search, skip, limit, order, orderBy }: Partial<IGetCreditRequestForEmployees>) => ({
        url: `customers/payment-terms`,
        params: { search, field, skip, limit, order, orderBy },
      }),
      transformResponse: (body: IPaymentTermsBody) => getPaymentTermsSerializer(body),
      providesTags: ['Loads'],
    }),
    createTonuLoad: builder.mutation({
      query: body => ({
        url: 'loads/tonu',
        method: 'POST',
        body: createTonuLoad(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createInternalLoad: builder.mutation({
      query: body => ({
        url: 'loads/internal',
        method: 'POST',
        body: createInternalLoad(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createConnectinglLoad: builder.mutation({
      query: body => ({
        url: 'loads/connecting',
        method: 'POST',
        body: createConnectingLoad(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createRegularLoad: builder.mutation({
      query: body => ({
        url: 'loads/regular',
        method: 'POST',
        body: createRegularLoad(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    editTonuLoad: builder.mutation({
      query: ({ referenceNumber }: { referenceNumber: number }) => ({
        url: 'loads/regular',
        method: 'PUT',
        body: { referenceNumber },
      }),
      invalidatesTags: ['Loads'],
    }),
    getLoadById: builder.query({
      query: ({ id }: { id: any }) => ({
        url: `loads/${id}`,
      }),
      transformResponse: (body: IGetLoadByIdResponse): IGetLoadByIdModified => getLoadById(body),
      providesTags: ['loadsFiles'],
    }),
    editLoad: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `loads/${id}`,
        method: 'PUT',
        body: editLoad(body),
      }),
      invalidatesTags: ['Loads', 'loadsFiles'],
    }),
    editInternalInfoLoad: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `loads/${id}/internal/information`,
        method: 'PUT',
        body: editInternalInfoLoad(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createLoadPoint: builder.mutation({
      query: ({ id, body }: { id: any; body: any }) => ({
        url: `loads/${id}/load-point`,
        method: 'POST',
        body: createLoadPoint(body),
      }),
    }),
    getLoadPointById: builder.query({
      query: ({ id, pointsState }: any) => ({
        url: `loads/${id}/load-point`,
        params: { ...pointsState },
      }),
      transformResponse: (body: IGetLoadPointsResponse) => getLoadPointById(body),
    }),
    getLoadPointByIdAutocomplete: builder.query({
      query: ({ id, pointsState }: any) => ({
        url: `loads/${id}/load-point`,
        params: { ...pointsState },
      }),
      transformResponse: (body: IGetLoadPointsResponse) => getLoadPointById(body),
    }),
    getTransactionstById: builder.query({
      query: ({ id }: { id: any }) => ({
        url: `loads/${id}/transactions`,
        params: { order: 2, orderBy: 'createdAt' },
      }),
      transformResponse: (body: ITransactionBody) => getTransactionstSerializer(body),
    }),
    sendToBilling: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `loads/${id}/send-to-billing`,
        method: 'PUT',
      }),
      invalidatesTags: ['Loads', 'loadsFiles'],
    }),
    rejectLoadBilling: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `loads/${id}/reject-billing`,
        method: 'DELETE',
      }),
    }),
    rejectLoadRetrieved: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `loads/${id}/reject-retrieved`,
        method: 'PUT',
      }),
    }),
    acceptBilling: builder.mutation({
      query: ({ id, sendEmail }: { id: number; sendEmail: boolean }) => ({
        url: `loads/${id}/accept-billing`,
        method: 'POST',
        body: { sendEmail },
      }),
      invalidatesTags: ['Loads'],
    }),
    claimLoad: builder.mutation({
      query: (id: any) => ({
        url: `loads/${id}/claim`,
        method: 'POST',
      }),
      invalidatesTags: ['Loads'],
    }),
    unclaimLoad: builder.mutation({
      query: (id: any) => ({
        url: `loads/${id}/unclaim`,
        method: 'POST',
      }),
      invalidatesTags: ['Loads'],
    }),
    cancelLoad: builder.mutation({
      query: (id: any) => ({
        url: `loads/${id}/cancel`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loads'],
    }),
    updateLoadPoint: builder.mutation({
      query: ({ body }: { body: any }) => {
        return {
          url: `loads/load-point/${body.id}`,
          method: 'PUT',
          body: editLoadPoint(body),
        };
      },
    }),
    deleteLoadPoint: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `loads/load-point/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loads'],
    }),
    createLoadInvoice: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `loads/${id}/invoice`,
        method: 'POST',
      }),
      invalidatesTags: ['loadsFiles'],
    }),
    editLoadFile: builder.mutation({
      query: ({ id, readable, mailable, type }) => ({
        url: `loads/files/${id}`,
        method: 'PUT',
        body: { readable, mailable, type },
      }),
      invalidatesTags: ['Loads'],
    }),
    getLoadFilesByIds: builder.mutation({
      query: ({ id, fileIds }: { id: number; fileIds: number[] }) => ({
        url: `loads/${id}/files/by/ids`,
        method: 'POST',
        body: { fileIds: fileIds?.map((el: any) => Number(el)) },
      }),
      invalidatesTags: ['Loads'],
    }),
    getLoadHistory: builder.query({
      query: ({ id, skip, limit }: any) => ({
        url: `loads/${id}/history`,
        params: { skip, limit },
      }),
      providesTags: ['Notes'],
    }),
    getCarrierInsuranceById: builder.query({
      query: ({ id }: any) => ({
        url: `carriers/${id}/insurance/expiration`,
      }),
    }),
    getLoadsAutocomplete: builder.query({
      query: ({ search, skip, limit }: any) => ({
        url: 'loads/autocomplete',
        params: { search, skip, limit },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            code: el.code,
            type: el.type,
            createdAt: el.createdAt,
            status: el.status,
            referenceNumber: el.referenceNumber,
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Loads'],
    }),

    createEditCarrierRating: builder.mutation({
      query: ({ rating, carrierId }: Partial<ICarrierRatingParams>) => ({
        url: `carriers/ratings`,
        method: 'POST',
        body: { carrierId: Number(carrierId), rating },
      }),
      invalidatesTags: ['loadsFiles'],
    }),
    deleteCarrierRating: builder.mutation({
      query: ({ ratingId }: Partial<ICarrierRatingParams>) => ({
        url: `carriers/ratings/${ratingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['loadsFiles'],
    }),

    getRateConfirmations: builder.query({
      query: ({ id, limit = '50' }: any) => {
        return { url: `loads/${id}/file/rate-confirmations`, params: { order: 2, orderBy: 'createdAt', limit } };
      },
      providesTags: ['loadsFiles'],
    }),

    createRateConfirmation: builder.mutation({
      query: ({ id }: any) => ({
        url: `loads/${id}/rate-confirmation`,
        method: 'POST',
      }),
      invalidatesTags: ['loadsFiles'],
    }),

    sendRateConfirmation: builder.mutation({
      query: ({ id }: any) => ({
        url: `loads/${id}/send-rate-confirmation`,
        method: 'POST',
      }),
      invalidatesTags: ['loadsFiles'],
    }),

    getCarrierBreakdownsInLoad: builder.query({
      query: ({ skip, limit, order, orderBy, id }: any) => ({
        url: `loads/${id}/files/breakdowns`,
        params: { skip, limit, order, orderBy },
      }),
      providesTags: ['loadsFiles'],
    }),

    getTrucksAutocomplete: builder.query({
      query: ({ search, skip, limit, carrierId }: any) => ({
        url: 'carriers/truck/autocomplete',
        params: { search, skip, limit, carrierId },
      }),
    }),
    createRegularLoadOutside: builder.mutation({
      query: body => ({
        url: 'loads/outside/regular',
        method: 'POST',
        body: createRegularLoadOutside(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createConnectingOutside: builder.mutation({
      query: body => ({
        url: 'loads/internal/connecting',
        method: 'POST',
        body: createConnectingLoadOutside(body),
      }),
      invalidatesTags: ['Loads'],
    }),
    createInternalOutside: builder.mutation({
      query: body => ({
        url: 'loads/internal/internal',
        method: 'POST',
        body: createInternalLoadOutside(body),
      }),
      invalidatesTags: ['Loads'],
    }),
  }),
});
export const {
  useGetCommentsQuery,
  useGetLoadByIdQuery,
  useEditLoadMutation,
  useEditInternalInfoLoadMutation,
  useClaimLoadMutation,
  useGetLoadFilesQuery,
  useCancelLoadMutation,
  useUnclaimLoadMutation,
  useEditTonuLoadMutation,
  useGetLoadPointByIdQuery,
  useCreateCommentMutation,
  useAddFileToLoadMutation,
  useDeleteCommentMutation,
  useSendToBillingMutation,
  useAcceptBillingMutation,
  useCreateTonuLoadMutation,
  useCreateLoadPointMutation,
  useUpdateLoadPointMutation,
  useDeleteLoadPointMutation,
  useCreateRegularLoadMutation,
  useGetTransactionstByIdQuery,
  useCreateLoadInvoiceMutation,
  useRejectLoadBillingMutation,
  useCreateInternalLoadMutation,
  useDeleteFileFromLoadMutation,
  useUpdateCreditStatusMutation,
  useCreateCheckRequestMutation,
  useCreateConnectinglLoadMutation,
  useGetCreditRequestForAgentsQuery,
  useLazyGetCreditRequestForAgentsQuery,
  useGetLoadsListQuery,
  useGetCreditRequestForEmployeesQuery,
  useLazyGetCreditRequestForEmployeesQuery,
  useGetCreditRequestForAgentsByIdQuery,
  useLazyGetCreditRequestForAgentsByIdQuery,
  useGetDepthByCustomerAndAuthorityQuery,
  useGetCreditRequestForEmployeesByIdQuery,
  useLazyGetCreditRequestForEmployeesByIdQuery,
  useGetPaymentTermsByCustomerIdAndAuthorityIdQuery,
  useLazyGetPaymentTermsByCustomerIdAndAuthorityIdQuery,
  useGetPaymentTermsQuery,
  useAddRedConfirmationFileToLoadMutation,
  useAddUnspecifiedFileToLoadMutation,
  useEditLoadFileMutation,
  useGetLoadPointByIdAutocompleteQuery,
  useGetLoadFilesByIdsMutation,
  useGetLoadHistoryQuery,
  useGetCarrierInsuranceByIdQuery,
  useGetLoadsAutocompleteQuery,
  useRejectLoadRetrievedMutation,
  useCreateEditCarrierRatingMutation,
  useDeleteCarrierRatingMutation,
  useGetRateConfirmationsQuery,
  useGetCarrierBreakdownsInLoadQuery,
  useCreateRateConfirmationMutation,
  useSendRateConfirmationMutation,
  useGetTrucksAutocompleteQuery,
  useCreateConnectingOutsideMutation,
  useCreateInternalOutsideMutation,
  useCreateRegularLoadOutsideMutation,
} = loadsAPI;
