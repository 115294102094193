import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import useFileUpload from 'hooks/useFileUpload';
import { SUBJECT } from 'pages/loads/constants/constants';
import { handleBalanceAmount } from 'pages/loads/constants/helpers';
import { billingAPI } from 'services/factoring/billing/billing';
import { IGetLoadsParams } from 'services/loads/interface';
import {
  useAcceptBillingMutation,
  useAddRedConfirmationFileToLoadMutation,
  useAddUnspecifiedFileToLoadMutation,
  useCancelLoadMutation,
  useClaimLoadMutation,
  useCreateLoadInvoiceMutation,
  useDeleteFileFromLoadMutation,
  useEditLoadFileMutation,
  useEditLoadMutation,
  useGetCarrierBreakdownsInLoadQuery,
  useGetTrucksAutocompleteQuery,
  useRejectLoadBillingMutation,
  useRejectLoadRetrievedMutation,
  useSendToBillingMutation,
  useUnclaimLoadMutation,
} from 'services/loads/loads';
import { useGetAuthoritiesShortQuery } from 'services/profile/authorities/authorities';
import { useGetCarriersAutocompleteQuery } from 'services/profile/carriers/carriers';
import { ICarrierType } from 'services/profile/carriers/interfaces';
import { useCreateTransactionMutation, useDeleteTransactionMutation } from 'services/transactions/transactions';
import { selectActiveSection } from 'store/active-section-slice/selector';
import { changeLoading, closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { CarrierStatus, CustomerStatus, LoadStatus } from 'ui/load-status/constants/types';
import { FileTypes } from 'ui/upload-row/constants/types';
import { AgentTypes, EmployeeLevels, notificationKey, UserTypes } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatNumberWithThousandsSeparatorAndDecimal, scrollToTarget } from 'utils/helpers';

import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { transactionDefalultValue } from '../../constants/constants';
import { ITransactionFormData } from '../../constants/types';
import { transactionValidation } from '../../constants/validation';

export const useConnectingEdit = ({
  data,
  createLoadForm,
  loadFiles,
  loadPoints,
  refetchTransaction,
  refetchLoadById,
  refetchFilesByLoadId,
  refetchRateConfirmations,
  isFetching,
  isFilesFetching,
  loadsPagePermissions,
}: any) => {
  const { searchParams, setSearchParams } = useDetectedParams();
  const { open, mode, id } = searchParams;

  const drawerRef: { current: any } = useRef(null);

  const [isDefaultHistory, setIsDefaultHistory] = useState(false);
  const handleScrollToHistory = (mode: string) => {
    scrollToTarget(drawerRef);
    setIsDefaultHistory(mode === 'history');
  };
  const { employeeLevel, userType, branchIds, officeIds, agentType, permissionsInfo } =
    useSelector(selectUserPermissionsInfo);
  const [breakdownParams, setBreakdownParams] = useState({
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
  });

  const { data: breakdowns, isFetching: isBreakdownLoading } = useGetCarrierBreakdownsInLoadQuery(
    { id: id, ...breakdownParams },
    { skip: !id }
  );

  const handleCarrierBreakdownPaginationClick = (skip: number, limit: number) => {
    setBreakdownParams((prev: any) => ({
      ...prev,
      limit,
      skip,
    }));
  };

  const handleBreakdownSortClick = (sortOrder: number, dataIndex: string) => {
    setBreakdownParams((prev: any) => {
      if (sortOrder === 0)
        return {
          ...prev,
          skip: 0,
          limit: prev.limit,
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const isDisableSoldUs = () => {
    if (
      (userType == 3 ||
        (userType == 2 && (employeeLevel == 2 || employeeLevel == 3) && loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING &&
      !data?.load?.rateConfirmationSentAt
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === 1 && loadsPagePermissions.editSoldAsMc) || userType === 1) &&
        data?.load?.carrierStatus !== CarrierStatus.CARRIER_STATUS_PAID &&
        !data?.load?.rateConfirmationSentAt
      );
  };
  const isDisableReference = () => {
    if (
      (userType == 3 ||
        (userType == 2 && (employeeLevel == 2 || employeeLevel == 3) && loadsPagePermissions.editSoldAsMc)) &&
      data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING
    ) {
      return false;
    } else
      return !(
        ((employeeLevel === 1 && loadsPagePermissions.editReferenceNumber) || userType === 1) &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_PAID &&
        data?.load?.customerStatus !== CustomerStatus.CUSTOMER_STATUS_DEBT
      );
  };

  const dispatch = useDispatch();
  const activeSection = useSelector(selectActiveSection);
  const { id: userId } = useSelector(selectUserInfo);
  const [editLoad] = useEditLoadMutation();
  const [addRedConfirmationFileToLoad] = useAddRedConfirmationFileToLoadMutation();
  const [addUnspecifiedFileToLoad] = useAddUnspecifiedFileToLoadMutation();
  const [deleteFileFromLoad] = useDeleteFileFromLoadMutation();
  const [createTransaction, { isLoading: isCreateTransactionLoading }] = useCreateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [editLoadFile] = useEditLoadFileMutation();
  const [sendToBilling] = useSendToBillingMutation();
  const [createInvoice, { isLoading: isInvoiceLoading }] = useCreateLoadInvoiceMutation();
  const [acceptBilling] = useAcceptBillingMutation();
  const [rejectBilling] = useRejectLoadBillingMutation();
  const [rejectLoadRetrieved] = useRejectLoadRetrievedMutation();
  const [cancelLoad] = useCancelLoadMutation();
  const [claimLoad] = useClaimLoadMutation();
  const [unclaimLoad] = useUnclaimLoadMutation();

  const {
    formState: { errors: transactioErrors, dirtyFields: transactionDirtyFields },
    control: transactioControl,
    reset: resetTransaction,
    watch: transactionWatch,
    setValue: transactionSetValue,
    trigger: transactionTrigger,
  } = useForm<ITransactionFormData>({
    defaultValues: transactionDefalultValue,
    mode: 'all',
    resolver: yupResolver(transactionValidation()),
  });

  const customer = transactionWatch('customer');
  const carrier = transactionWatch('carrierTransaction');
  const secondAgent = transactionWatch('secondAgent');

  const amount = handleBalanceAmount(Number(customer), Number(carrier));

  const isTransactionValidBtn =
    !!(customer || carrier || secondAgent) &&
    !!transactionWatch('transactionType') &&
    !Object.keys(transactioErrors).length;

  useEffect(() => {
    if (amount) {
      transactionSetValue('balanceAmount', formatNumberWithThousandsSeparatorAndDecimal(amount));
    } else {
      transactionSetValue('balanceAmount', 0);
    }
  }, [customer, carrier]);

  const handleResetTransactionForm = () => {
    resetTransaction();
  };

  const {
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
  } = useFileUpload({
    data: {
      result: { files: loadFiles?.result?.RED_CONFIRMATION },
    },
    setValue: createLoadForm.setValue,
    getValues: createLoadForm.watch,
    id: data?.load?.id,
    addNewFile: addRedConfirmationFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger: createLoadForm.trigger,
    autoUpdate: true,
  });

  const [isSendedToBilling, setIsSendedToBilling] = useState(false);
  const [carriersState, setCarriersState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });
  const [trucksState, setTrucksState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    search: '',
    limit: 10,
  });

  const [authoritiesState, setAuthoritiesState] = useState<Partial<IGetLoadsParams>>({
    skip: 0,
    limit: 10,
    search: '',
  });

  const authoritiesRequestType = () => {
    return !(employeeLevel === EmployeeLevels.CORPORATE || userType === UserTypes.ADMIN);
  };

  const { data: authorities = [] } = useGetAuthoritiesShortQuery(
    { ...authoritiesState, onlyAssigned: authoritiesRequestType() },
    {
      skip: mode !== 'edit' || open === 'false',
    }
  );

  const { data: carriers = [] } = useGetCarriersAutocompleteQuery(carriersState, {
    skip: mode !== 'edit' || open === 'false',
  });

  const { data: trucks } = useGetTrucksAutocompleteQuery(
    { carrierId: createLoadForm.watch('carrier')?.[0]?.id, ...trucksState },
    {
      skip:
        !!createLoadForm.watch('truck')?.length ||
        !createLoadForm.watch('carrier')?.[0]?.id ||
        mode !== 'edit' ||
        open === 'false',
    }
  );

  const podFiles = useMemo(() => {
    const resultArray = [];
    for (const key in loadFiles?.result) {
      if (key !== 'RED_CONFIRMATION' && key !== 'INVOICE') {
        resultArray.push(...loadFiles.result[key]);
      }
    }
    return resultArray;
  }, [loadFiles]);

  const mailableFiles = podFiles.filter(item => item.mailable);

  useEffect(() => {
    if (data?.load?.status === LoadStatus.LOAD_STATUS_FACTORED) {
      setIsSendedToBilling(true);
    }
    if (data?.load?.status === LoadStatus.LOAD_STATUS_NOT_PROCESSING) {
      setIsSendedToBilling(false);
    }
  }, [data?.load?.status]);

  const {
    handleViewFileEdit: handleViewFileEditPOD,
    handleDeleteFileEdit: handleDeleteFileEditPOD,
    handleDownloadFileEdit: handleDownloadFileEditPOD,
    beforeUploadForEdit: beforeUploadForEditPOD,
    allFiles: allFilesPOD,
    setAllFiles: setAllFilesPOD,
    savedFiles: savedFilesPOD,
  } = useFileUpload({
    data: {
      result: { files: podFiles },
    },
    setValue: createLoadForm.setValue,
    getValues: createLoadForm.watch,
    id: data?.load?.id,
    addNewFile: addUnspecifiedFileToLoad,
    deleteFile: deleteFileFromLoad,
    trigger: createLoadForm.trigger,
    autoUpdate: true,
  });

  useEffect(() => {
    setAllFiles(savedFiles);
  }, [savedFiles]);

  useEffect(() => {
    setAllFilesPOD(savedFilesPOD);
  }, [savedFilesPOD]);

  useEffect(() => {
    createLoadForm.reset(
      {
        load_type: data?.load?.loadType ? data?.load?.loadType : '',
        customerName: data?.load?.customer?.name || '',
        referenceNumber: data?.load?.referenceNumber,
        customerAmount: data?.load?.customerAmount || '',
        customerContact: data?.load?.customerContact || '',
        customerContactName: data?.load?.customerContactName || '',
        carrier: data?.load?.carrier?.id
          ? [{ ...data?.load?.carrier, type: ICarrierType[data?.load?.carrier?.type] }]
          : [],
        carrierAmount: Number(data?.load?.carrierAmount) ? data?.load?.carrierAmount : '',
        truck: data?.load?.truck ? [data?.load?.truck] : [],
        agent: [],
        agentAmount: '',
        authorities: data?.load?.soldAsAuthority?.id ? [data?.load?.soldAsAuthority] : [],
        file: [],
        soldUsSwitch: Boolean(data?.load?.soldAsAuthority?.id),
        markAsSwitch: data?.load?.claimed,
        transportType: data?.information?.transportCapability || null,
        celsius: data?.information?.refrigeratorCelsius || '',
        takenAs: data?.information?.takenType || null,
        givenAs: data?.information?.givenType || null,
        commodity: data?.information?.commodity || '',
        bookedAs: data?.information?.bookedType || null,
        soldAs: data?.information?.soldType || null,
        feetPartial: '',
        weight: data?.information?.weight || '',
        pickups: loadPoints?.result?.pickup || [],
        dropOffs: loadPoints?.result?.dropoffs || [],
        comment: '',
      },
      { keepDirty: false }
    );
  }, [data, data?.load?.claimed]);

  useEffect(() => {
    createLoadForm.setValue('pickups', loadPoints?.result?.pickup || [], { keepDirty: false, keepTouched: false });
    createLoadForm.setValue('dropOffs', loadPoints?.result?.dropoffs || [], { keepDirty: false, keepTouched: false });
  }, [loadPoints?.result?.pickup?.length, loadPoints?.result?.dropoffs?.length]);

  const handleSwitchSoldUs = () => {
    createLoadForm.setValue('soldUsSwitch', !createLoadForm.watch('soldUsSwitch'), {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    if (!createLoadForm.watch('soldUsSwitch')) {
      createLoadForm.setValue('authorities', []);
    }
  };

  const handleSwitchMarkAsClaim = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_CLAIM,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: data?.load?.claimed ? 'modalPrimary' : 'danger',
        onOk: data?.load?.claimed
          ? () => {
              dispatch(changeLoading(true));
              unclaimLoad(String(data?.load?.id)).then((args: any) => {
                if (args.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(args?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                } else {
                  notification.success({
                    message: 'Successfully marked as claim',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                  dispatch(closeModal());
                  refetchLoadById({ id: data?.load?.id });
                }
              });
            }
          : () => {
              dispatch(changeLoading(true));
              claimLoad(String(data?.load?.id)).then((args: any) => {
                if (args.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(args?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                } else {
                  notification.success({
                    message: 'Successfully marked as claim',
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  dispatch(changeLoading(false));
                  dispatch(closeModal());
                  refetchLoadById({ id: data?.load?.id });
                }
              });
            },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };
  const handleSaveModal = () => {
    dispatch(
      openModal({
        title: SUBJECT.JUST_ARE_YOU_SURE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        onOk: () => {
          dispatch(changeLoading(true));
          editLoad({
            id: Number(data.load.id!),
            referenceNumber: String(createLoadForm.watch('referenceNumber')),
            soldAuthority: createLoadForm.watch('authorities'),
            ...(!data?.load?.carrier?.id
              ? {
                  ...(createLoadForm.watch('carrier')?.length ? { carrier: createLoadForm.watch('carrier') } : {}),
                  ...(createLoadForm.watch('carrierAmount')
                    ? { carrierAmount: createLoadForm.watch('carrierAmount') }
                    : {}),
                  ...(createLoadForm.watch('truck')?.length ? { truck: createLoadForm.watch('truck') } : {}),
                }
              : {}),
          }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully edited',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              createLoadForm.resetField('carrier', { defaultValue: createLoadForm.watch('carrier') });
              createLoadForm.resetField('carrierAmount', { defaultValue: createLoadForm.watch('carrierAmount') });
              createLoadForm.resetField('truck', { defaultValue: createLoadForm.watch('truck') });
              createLoadForm.resetField('authorities', { defaultValue: createLoadForm.watch('authorities') });
              createLoadForm.resetField('soldUsSwitch', { defaultValue: createLoadForm.watch('soldUsSwitch') });
              createLoadForm.resetField('referenceNumber', {
                defaultValue: String(createLoadForm.watch('referenceNumber')),
              });
              refetchLoadById();
              createLoadForm.clearErrors([
                'referenceNumber',
                'soldUsSwitch',
                'authorities',
                'truck',
                'carrierAmount',
                'carrier',
              ]);
              setSearchParams({
                ...searchParams,
                open: 'false',
              });
            }
            dispatch(changeLoading(false));
            dispatch(closeModal());
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };
  const generalInformationRef = useRef(null);

  const handleSave = (triggerVariant: string) => {
    if (triggerVariant === 'customerCarrierAccess') {
      if (!createLoadForm.watch('referenceNumber')) {
        createLoadForm.trigger('referenceNumber');
      } else if (
        (createLoadForm.watch('soldUsSwitch') && !createLoadForm.watch('authorities')?.length) ||
        !createLoadForm.watch('carrier')?.length ||
        !createLoadForm.watch('carrierAmount') ||
        (createLoadForm.watch('carrier')?.[0]?.type === 'Preferred Carrier' && !createLoadForm.watch('truck')?.length)
      ) {
        scrollToTarget(generalInformationRef);
        createLoadForm.trigger(['carrier', 'carrierAmount', 'authorities']);
        createLoadForm.watch('carrier')?.[0]?.type === 'Preferred Carrier' && createLoadForm.trigger('truck');
      } else {
        handleSaveModal();
      }
    } else if (triggerVariant === 'carrierAccess') {
      if (
        (createLoadForm.watch('soldUsSwitch') && !createLoadForm.watch('authorities')?.length) ||
        !createLoadForm.watch('carrier')?.length ||
        !createLoadForm.watch('carrierAmount') ||
        (createLoadForm.watch('carrier')?.[0]?.type === 'Preferred Carrier' && !createLoadForm.watch('truck')?.length)
      ) {
        scrollToTarget(generalInformationRef);

        createLoadForm.trigger(['carrier', 'carrierAmount', 'authorities']);
        createLoadForm.watch('carrier')?.[0]?.type === 'Preferred Carrier' && createLoadForm.trigger('truck');
      } else {
        handleSaveModal();
      }
    } else if (triggerVariant === 'customerAccess') {
      if (!createLoadForm.watch('referenceNumber')) {
        scrollToTarget(generalInformationRef);
        createLoadForm.trigger('referenceNumber');
      } else {
        handleSaveModal();
      }
    }
  };

  const handleCancel = () => {
    createLoadForm.reset({
      ...createLoadForm.watch(),
      authorities: data?.load?.soldAsAuthority.id ? [data?.load?.soldAsAuthority] : [],
      soldUsSwitch:
        createLoadForm.watch('carrier')[0]?.type === 'Outside' ? true : Boolean(data?.load?.soldAsAuthority?.id),
      referenceNumber: data?.load?.referenceNumber,
      carrier: [],
      carrierAmount: '',
      truck: [],
    });
    createLoadForm.trigger(['authorities']);
  };

  const handleCreateTransaction = () => {
    dispatch(changeLoading(true));
    createTransaction({
      loadId: data?.load?.id,
      body: transactionWatch(),
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        dispatch(changeLoading(false));
      } else {
        notification.success({
          message: 'Successfully created',
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        resetTransaction();
        refetchTransaction();
        refetchLoadById();
        dispatch(changeLoading(false));
        refetchRateConfirmations();
        dispatch(billingAPI.util.invalidateTags(['Billing']));
      }
    });
  };

  const handleDeleteTransaction = (id: number | string) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CANCEL,
        okButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          deleteTransaction({ id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
              refetchTransaction();
              refetchLoadById();
              refetchRateConfirmations();
            }
            dispatch(closeModal());
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleEditFile = ({ id, type, readable, mailable }: any) => {
    const typeData = FileTypes[type as keyof typeof FileTypes] ? FileTypes[type as keyof typeof FileTypes] : type;

    editLoadFile({
      id,
      ...(readable && {
        readable: Number(readable),
      }),
      mailable:
        (FileTypes[type as keyof typeof FileTypes] as string) === SUBJECT.CARRIER_INVOICE ? false : Boolean(+mailable),
      type: typeData,
    }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.SUCCESSFULLY_UPDATED,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      }
      refetchFilesByLoadId();
    });
  };
  const handleSendToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_BILLING,
        okText: SUBJECT.SEND,
        cancelText: SUBJECT.CLOSE,
        onOk: () => {
          sendToBilling({ id: data?.load?.id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: SUBJECT.REQUEST_SENT_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              setIsSendedToBilling(true);
              dispatch(closeModal());
            }
          });
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const checkIsSendToBillingButtonDisabled = (files: any) => {
    const hasPodFile = files?.POD && files.POD.length > 0;
    const hasMailablePod = hasPodFile && files?.POD.some((file: any) => file.mailable === true);

    const hasRedConfirmationFile = files?.RED_CONFIRMATION && files.RED_CONFIRMATION.length > 0;
    const hasMailableRedConfirmation =
      hasRedConfirmationFile && files?.RED_CONFIRMATION.some((file: any) => file.mailable === true);

    return !(hasMailablePod && hasMailableRedConfirmation && data?.hasCarrier);
  };
  const isSendToBillingDisabled = checkIsSendToBillingButtonDisabled(loadFiles?.result);

  const handleAcceptToBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_ACCEPT,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'danger',
        onOk: () => {
          dispatch(changeLoading(true));
          acceptBilling({ id: data?.load?.id, sendEmail: true }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
              refetchFilesByLoadId();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
        onCancel: () => {
          dispatch(changeLoading(true));
          acceptBilling({ id: data?.load?.id, sendEmail: false }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(changeLoading(false));
            } else {
              notification.success({
                message: SUBJECT.ACCEPTED_SUCCESSFULLY,
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              refetchLoadById();
              refetchFilesByLoadId();
              if (!isFetching && !isFilesFetching) {
                dispatch(changeLoading(false));
                dispatch(closeModal());
              }
            }
          });
        },
      })
    );
  };

  const handleRejectBilling = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.REJECT,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          data?.load?.retrieved
            ? rejectLoadRetrieved({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchLoadById();
                  refetchFilesByLoadId();
                  refetchTransaction();
                }
              })
            : rejectBilling({ id: data?.load?.id }).then((data: any) => {
                if (data.error) {
                  notification.error({
                    message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                    duration: 3,
                    icon: <DangerIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                } else {
                  notification.success({
                    message: SUBJECT.REJECTED_SUCCESSFULLY,
                    duration: 1.5,
                    icon: <SuccessIcon />,
                    placement: 'topRight',
                    closeIcon: <CloseIcon />,
                    key: notificationKey,
                    btn: (
                      <button type="button" onClick={() => notification.destroy(notificationKey)}>
                        <CloseIcon />
                      </button>
                    ),
                  });
                  refetchLoadById();
                  refetchTransaction();
                  refetchFilesByLoadId();
                }
              });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const handleCancelLoad = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE_LOAD,
        okText: SUBJECT.CANCEL,
        cancelText: SUBJECT.NO,
        okButtonVariant: 'danger',
        cancelButtonVariant: 'gray',
        onOk: () => {
          cancelLoad(data?.load?.id).then((args: any) => {
            if (args.error) {
              dispatch(closeModal());
              notification.error({
                message: ServerErrorCodes[Number(args?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully canceled',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
              dispatch(closeModal());
              refetchLoadById({ id: data?.load?.id });
            }
          });
        },
        onCancel: () => dispatch(closeModal()),
      })
    );
  };

  const handleCreateInvoice = () => {
    notification.info({
      message: SUBJECT.CREATE_INVOICE_IN_PROCCESS,
      duration: 3,
      placement: 'topRight',
      closeIcon: <CloseIcon />,
    });
    createInvoice({ id: data?.load?.id }).then((data: any) => {
      if (data.error) {
        notification.error({
          message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
          duration: 3,
          icon: <DangerIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
      } else {
        notification.success({
          message: SUBJECT.INVOICE_SUCCESSFULLY,
          duration: 1.5,
          icon: <SuccessIcon />,
          placement: 'topRight',
          closeIcon: <CloseIcon />,
          key: notificationKey,
          btn: (
            <button type="button" onClick={() => notification.destroy(notificationKey)}>
              <CloseIcon />
            </button>
          ),
        });
        refetchFilesByLoadId();
      }
    });
  };

  const handleDeleteInvoice = () => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          deleteFileFromLoad({ fileId: loadFiles?.result?.INVOICE[0].id }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 3,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: 'Successfully deleted',
                duration: 1.5,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const isSaveButtonVisible = useMemo(() => {
    return !!(
      createLoadForm.dirtyFields.carrierAmount ||
      createLoadForm.dirtyFields.carrier ||
      createLoadForm.dirtyFields.truck ||
      createLoadForm.dirtyFields.authorities ||
      createLoadForm.dirtyFields.referenceNumber ||
      createLoadForm.dirtyFields.soldUsSwitch
    );
  }, [Object.keys(createLoadForm.dirtyFields)?.length]);

  const isUnderSameBranchOrOffice = useMemo(() => {
    if (
      employeeLevel === EmployeeLevels.OFFICE ||
      employeeLevel === EmployeeLevels.BRANCH ||
      agentType === AgentTypes.MANAGER ||
      agentType === AgentTypes.OWNER
    ) {
      return (
        officeIds[0] === data?.agents?.[0]?.office?.id ||
        officeIds[0] === data?.agents?.[1]?.office?.id ||
        branchIds[0] === data?.agents?.[0]?.branch?.id ||
        branchIds[0] === data?.agents?.[1]?.branch?.id
      );
    }
    return false;
  }, [data, employeeLevel]);

  const isSecondAgent = data?.agents?.[1]?.user?.id === userId;
  const isFirstAgent = data?.agents?.[0]?.user?.id === userId;

  const hasAccessToRateConf = useMemo(() => {
    if (
      branchIds?.includes(data?.agents?.[0]?.branch?.id) &&
      branchIds?.includes(data?.agents?.[1]?.branch?.id) &&
      officeIds?.includes(data?.agents?.[0]?.office?.id) &&
      officeIds?.includes(data?.agents?.[1]?.office?.id)
    ) {
      return true;
    }
    if (
      !branchIds?.includes(data?.agents?.[0]?.branch?.id) &&
      branchIds?.includes(data?.agents?.[1]?.branch?.id) &&
      !officeIds?.includes(data?.agents?.[0]?.office?.id) &&
      officeIds?.includes(data?.agents?.[1]?.office?.id)
    ) {
      return false;
    }
  }, [data?.agents?.[0]?.branch?.id, data?.agents?.[0]?.office?.id, branchIds, officeIds]);

  const addTransactionCheck = () => {
    if (
      userType === UserTypes.ADMIN ||
      (employeeLevel === EmployeeLevels.CORPORATE && loadsPagePermissions.transaction)
    ) {
      return false;
    } else {
      if (
        (data?.load?.customerStatus === 2 && !data?.load?.processing) ||
        (data?.load?.carrierStatus === 2 && !data?.load?.processing)
      ) {
        return true;
      }
    }
  };

  useEffect(() => {
    setAuthoritiesState((prev: any) => {
      return {
        ...prev,
        ...(data?.load?.takenAsAuthority?.id
          ? { ignoreAuthorityIds: [data?.load?.takenAsAuthority?.id] }
          : { ignoreAuthorityIds: [] }),
      };
    });
  }, []);

  useEffect(() => {
    if (isSaveButtonVisible) {
      if (!createLoadForm.watch('carrier')?.length) {
        createLoadForm.setValue('truck', []);
        createLoadForm.trigger('truck');
      }
    }
  }, [createLoadForm.watch('carrier')?.length]);

  return {
    handleSwitchSoldUs,
    handleSwitchMarkAsClaim,
    control: createLoadForm.control,
    watch: createLoadForm.watch,
    setValue: createLoadForm.setValue,
    errors: createLoadForm.errors,
    touchedFields: createLoadForm.touchedFields,
    dirtyFields: createLoadForm.dirtyFields,
    activeSection,
    handleCancel,
    handleSave,
    handleViewFileEdit,
    handleDeleteFileEdit,
    handleDownloadFileEdit,
    beforeUploadForEdit,
    allFiles,
    setAllFiles,
    savedFiles,
    transactionWatch,
    transactioControl,
    transactioErrors,
    isTransactionValidBtn,
    transactionTrigger,
    transactionSetValue,
    handleCreateTransaction,
    handleDeleteTransaction,
    handleViewFileEditPOD,
    handleDeleteFileEditPOD,
    handleDownloadFileEditPOD,
    beforeUploadForEditPOD,
    allFilesPOD,
    isInvoiceLoading,
    setAllFilesPOD,
    savedFilesPOD,
    handleEditFile,
    userId,
    handleSendToBilling,
    handleAcceptToBilling,
    handleRejectBilling,
    handleCreateInvoice,
    handleCancelLoad,
    handleDeleteInvoice,
    carriers,
    isSaveButtonVisible,
    setCarriersState,
    authorities,
    setAuthoritiesState,
    trucks,
    addTransactionCheck,
    setTrucksState,
    isSendToBillingDisabled,
    connectingTrigger: createLoadForm.trigger,
    isSendedToBilling,
    transactionDirtyFields,
    handleResetTransactionForm,
    mailableFiles,
    employeeLevel,
    userType,
    agentType,
    hasAccessToRateConf,
    isUnderSameBranchOrOffice,
    isCreateTransactionLoading,
    isSecondAgent,
    isDisableSoldUs,
    isDisableReference,
    breakdowns,
    isBreakdownLoading,
    handleCarrierBreakdownPaginationClick,
    handleBreakdownSortClick,
    breakdownParams,
    isFirstAgent,
    generalInformationRef,
    handleScrollToHistory,
    isDefaultHistory,
    setIsDefaultHistory,
    drawerRef,
    permissionsInfo,
  };
};
