import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/service';

import { IGetOfficesParams, OfficeCreditCheckChargeTypes, OfficeSetUpTypes } from './interfaces';
import { getAuthoritiesByOfficeId, getOfficeByIdSerializer, getOfficesSerializer } from './serializers';

export const officesAPI = createApi({
  reducerPath: 'officesAPI',
  baseQuery,
  tagTypes: ['Offices'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  endpoints: builder => ({
    getOffices: builder.query({
      query: ({ search, field, skip, limit, order, orderBy, filter }: Partial<IGetOfficesParams>) => ({
        url: 'offices',
        params: { search, field, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => getOfficesSerializer(body),
      providesTags: ['Offices'],
    }),
    getOfficesShortData: builder.query({
      query: ({ search, skip, limit, order, orderBy, filter }: Partial<IGetOfficesParams>) => ({
        url: 'offices/short-data',
        params: { search, skip, limit, order, orderBy, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Offices'],
    }),
    getOfficesAutocomplete: builder.query({
      query: ({ search, skip, limit, filter }: Partial<IGetOfficesParams>) => ({
        url: 'offices/autocomplete',
        params: { search, skip, limit, ...filter },
      }),
      transformResponse: (body: any) => {
        const result = body?.result?.map((el: any) => {
          return {
            id: el.id,
            title: el.name,
            value: String(el.id),
            default: Boolean(el.default),
          };
        });
        return {
          result,
          count: body.count,
        };
      },
      providesTags: ['Offices'],
    }),
    getOffice: builder.query({
      query: id => `offices/${id}`,
      transformResponse: (body: any) => getOfficeByIdSerializer(body),
      providesTags: ['Offices'],
    }),
    createOffice: builder.mutation({
      query: body => ({
        url: 'offices',
        method: 'POST',
        body: {
          address: body.address,
          ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
          authorityIds: body.authorities?.map((el: any) => Number(el)),
          city: body.city,
          name: body.name,
          location: Number(body.officeLocation),
          payoutPercent: Number(body.payout),
          phone: body.phoneNumber,
          stateId: Number(body.stateId),
          zip: body.zipcode,
          fax: body?.fax ? String(body?.fax) : '',
          bankName: body.bankName || '',
          accountingNumber: body.accounting_number || '',
          achRoutingNumber: body.ach_routing_number || '',
          type: body.type,
          feeType: body.feeType,
          fee: +body.fee,
          ...(+body.creditCheck === 1
            ? { creditCheckChargeType: OfficeCreditCheckChargeTypes.OFFICE_CREDIT_CHECK_CHARGE_TYPES_MONTH }
            : {}),
          ...(+body.creditCheck === 1 ? { creditCheckChargeAmount: Number(body.creditCheckPayAmount) } : {}),
          ...(body.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE)
            ? {
                permissions: {
                  billingPermission: {
                    view: Number(body.billing) !== 1,
                    download: false,
                    actions: {
                      acceptToBilling: Number(body.billing) === 3 || Number(body.billing) === 4,
                      reject: Number(body.billing) === 3 || Number(body.billing) === 4,
                    },
                    bothActions: Number(body.billing) === 4,
                  },
                  payablePermission: {
                    view: Number(body.payables) !== 1,
                    download: false,
                    actions: {
                      pay: Number(body.payables) === 3 || Number(body.payables) === 4,
                      claimLoadPay: Number(body.payables) === 3 || Number(body.payables) === 4,
                      receiveInvoice: Number(body.payables) === 3 || Number(body.payables) === 4,
                    },
                    bothActions: Number(body.payables) === 4,
                  },
                  receivablePermission: {
                    view: Number(body.receivables) !== 1,
                    download: false,
                    actions: {
                      receive: Number(body.receivables) === 3 || Number(body.receivables) === 4,
                      claimLoadReceive: Number(body.receivables) === 3 || Number(body.receivables) === 4,
                    },
                    bothActions: Number(body.receivables) === 4,
                  },
                  payrollCreditCheck: {
                    view: +body.creditCheck === 1,
                    download: true,
                    actions: {
                      noAction: true,
                    },
                  },
                },
              }
            : {}),
        },
      }),
      invalidatesTags: ['Offices'],
    }),
    updateOffice: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `offices/${id}`,
        method: 'PUT',
        body: {
          address: body.address,
          ...(body?.secondAddress ? { secondAddress: body.secondAddress } : {}),
          authorityIds: body.authorities?.map((el: any) => Number(el)),
          city: body.city,
          fax: body?.fax ? String(body?.fax) : '',
          name: body.name,
          location: Number(body.officeLocation),
          payoutPercent: Number(body.payout),
          phone: body.phoneNumber,
          stateId: Number(body.stateId),
          zip: body.zipcode,
          bankName: body.bankName || '',
          accountingNumber: body.accounting_number || '',
          achRoutingNumber: body.ach_routing_number || '',
          feeType: body.feeType,
          fee: +body.fee,
          type: +body.type,
          creditCheckChargeType: Number(body.creditCheck) === 1 ? +body.creditCheck : 0,
          ...(+body.creditCheck === 1 ? { creditCheckChargeAmount: Number(body.creditCheckPayAmount) } : {}),
          ...(body.type === String(OfficeSetUpTypes.OFFICE_SET_UP_TYPES_OUTSIDE)
            ? {
                permissions: {
                  billingPermission: {
                    view: Number(body.billing) !== 1,
                    download: false,
                    actions: {
                      acceptToBilling: Number(body.billing) === 3 || Number(body.billing) === 4,
                      reject: Number(body.billing) === 3 || Number(body.billing) === 4,
                    },
                    bothActions: Number(body.billing) === 4,
                  },
                  payablePermission: {
                    view: Number(body.payables) !== 1,
                    download: false,
                    actions: {
                      pay: Number(body.payables) === 3 || Number(body.payables) === 4,
                      claimLoadPay: Number(body.payables) === 3 || Number(body.payables) === 4,
                      receiveInvoice: Number(body.payables) === 3 || Number(body.payables) === 4,
                    },
                    bothActions: Number(body.payables) === 4,
                  },
                  receivablePermission: {
                    view: Number(body.receivables) !== 1,
                    download: false,
                    actions: {
                      receive: Number(body.receivables) === 3 || Number(body.receivables) === 4,
                      claimLoadReceive: Number(body.receivables) === 3 || Number(body.receivables) === 4,
                    },
                    bothActions: Number(body.receivables) === 4,
                  },
                  payrollCreditCheck: {
                    view: +body.creditCheck === 1,
                    download: true,
                    actions: {
                      noAction: true,
                    },
                  },
                },
              }
            : {}),
        },
      }),
    }),
    changeStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `offices/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      invalidatesTags: ['Offices'],
    }),
    getOfficesAuthorities: builder.query({
      query: id => `offices/${id}/authorities`,
      transformResponse: (body: any) => getAuthoritiesByOfficeId(body),
      providesTags: ['Offices'],
    }),

    addAuthorityToOffice: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `offices/${id}/authority/${authorityId}`,
        method: 'POST',
      }),
    }),

    removeAuthorityFromOffice: builder.mutation({
      query: ({ id, authorityId }) => ({
        url: `offices/${id}/authority/${authorityId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetOfficesQuery,
  useGetOfficeQuery,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  useChangeStatusMutation,
  useGetOfficesShortDataQuery,
  useGetOfficesAuthoritiesQuery,
  useAddAuthorityToOfficeMutation,
  useRemoveAuthorityFromOfficeMutation,
  useGetOfficesAutocompleteQuery,
} = officesAPI;
