const LPFIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
      <path
        d="M29.5665 16.8773L29.1766 15.7029C28.9741 15.6703 28.7696 15.6176 28.5631 15.5449C28.3565 15.4721 28.1694 15.3802 28.0019 15.2692L26.988 15.6983L26.2733 14.8322L27.0266 13.8905C26.9805 13.7605 26.943 13.6033 26.9141 13.4191C26.8853 13.2349 26.8729 13.0738 26.877 12.9359L25.8718 12.2697L26.2875 11.2265L27.384 11.325C27.5095 11.168 27.6595 11.0234 27.834 10.8909C28.0084 10.7585 28.187 10.6458 28.3698 10.5528L28.3819 9.31545L29.5626 9.13048L29.9525 10.3049C30.1549 10.3375 30.3596 10.3912 30.5665 10.4661C30.7734 10.5409 30.961 10.6359 31.1292 10.7511L32.141 10.3095L32.8578 11.1882L32.1045 12.1298C32.1493 12.2515 32.1864 12.4066 32.216 12.595C32.2455 12.7834 32.2575 12.9424 32.2521 13.0719L33.2573 13.7381L32.8416 14.7813L31.7451 14.6828C31.6196 14.8397 31.4696 14.9844 31.2951 15.1169C31.1207 15.2493 30.9421 15.362 30.7593 15.455L30.7472 16.6923L29.5665 16.8773ZM29.8007 14.5112C30.2403 14.4424 30.5793 14.2445 30.8177 13.9176C31.056 13.5907 31.1408 13.2074 31.0719 12.7677C31.003 12.3281 30.8051 11.9891 30.4782 11.7508C30.1513 11.5124 29.7681 11.4277 29.3284 11.4965C28.8888 11.5654 28.5498 11.7633 28.3114 12.0902C28.0731 12.4171 27.9883 12.8004 28.0572 13.24C28.1261 13.6797 28.324 14.0187 28.6509 14.257C28.9778 14.4954 29.3611 14.5801 29.8007 14.5112Z"
        fill="#252733"
      />
      <path
        d="M32.0407 22.4328L33.4346 20.7911C33.3001 20.4605 33.1935 20.1087 33.1149 19.7357C33.0362 19.3627 33.0037 19.0014 33.0173 18.6519L31.4625 17.5322L32.1041 15.686L34.1857 15.9548C34.3374 15.7687 34.5372 15.5708 34.7851 15.3613C35.033 15.1518 35.2613 14.9878 35.4702 14.8692L35.5519 12.7719L37.4792 12.4468L38.3242 14.1664C38.6711 14.2112 39.0219 14.3035 39.3766 14.4431C39.7313 14.5828 40.0604 14.7465 40.3639 14.9342L42.215 13.8333L43.5578 15.422L42.1638 17.0637C42.2984 17.3943 42.4035 17.7473 42.4794 18.1226C42.5552 18.498 42.5834 18.8629 42.5642 19.2172L44.136 20.3226L43.4774 22.1831L41.3958 21.9142C41.2554 22.0909 41.0584 22.2863 40.8049 22.5006C40.5514 22.7149 40.3258 22.8766 40.1282 22.9856L40.0466 25.0829L38.1193 25.408L37.2742 23.6884C36.9273 23.6435 36.5765 23.5513 36.2218 23.4117C35.8671 23.272 35.538 23.1083 35.2345 22.9206L33.3835 24.0215L32.0407 22.4328ZM35.7711 20.6416C36.2711 21.2331 36.8719 21.5583 37.5736 21.6172C38.2753 21.676 38.9219 21.4555 39.5134 20.9555C40.105 20.4555 40.4302 19.8547 40.489 19.153C40.5479 18.4513 40.3273 17.8047 39.8273 17.2132C39.3274 16.6217 38.7265 16.2965 38.0249 16.2376C37.3232 16.1788 36.6766 16.3993 36.085 16.8993C35.4935 17.3993 35.1683 18.0001 35.1095 18.7018C35.0506 19.4035 35.2712 20.0501 35.7711 20.6416Z"
        fill="#252733"
      />
      <path
        d="M8.16357 22.394C8.16357 21.7107 8.7175 21.1567 9.40081 21.1567H27.2209C27.9042 21.1567 28.4582 21.7107 28.4582 22.394V26.9479H8.16357V22.394Z"
        fill="#F0F2F5"
        stroke="#252733"
      />
      <path
        d="M8.16357 14.393C8.16357 13.7097 8.7175 13.1558 9.40081 13.1558H27.2209C27.9042 13.1558 28.4582 13.7097 28.4582 14.393V18.9469H8.16357V14.393Z"
        fill="#F0F2F5"
        stroke="#252733"
      />
      <rect x="8.16357" y="29.543" width="20.2946" height="6.97186" rx="1.23724" fill="#F0F2F5" stroke="#252733" />
      <mask
        id="path-6-outside-1_2_3718"
        maskUnits="userSpaceOnUse"
        x="19.4049"
        y="23.7139"
        width="19"
        height="22"
        fill="black"
      >
        <rect fill="white" x="19.4049" y="23.7139" width="19" height="22" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.1486 26.9295C33.803 26.1562 32.2424 25.7139 30.5783 25.7139C25.512 25.7139 21.4049 29.8139 21.4049 34.8716C21.4049 38.3999 23.4036 41.4622 26.3324 42.9916L26.9723 41.8259C24.4554 40.5226 22.7358 37.8976 22.7358 34.8716C22.7358 30.5477 26.247 27.0425 30.5783 27.0425C32.0106 27.0425 33.3532 27.4258 34.5088 28.0952L35.1486 26.9295Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.1486 26.9295C33.803 26.1562 32.2424 25.7139 30.5783 25.7139C25.512 25.7139 21.4049 29.8139 21.4049 34.8716C21.4049 38.3999 23.4036 41.4622 26.3324 42.9916L26.9723 41.8259C24.4554 40.5226 22.7358 37.8976 22.7358 34.8716C22.7358 30.5477 26.247 27.0425 30.5783 27.0425C32.0106 27.0425 33.3532 27.4258 34.5088 28.0952L35.1486 26.9295Z"
        fill="#252733"
      />
      <path
        d="M35.1486 26.9295L36.6715 27.7655L37.4911 26.2722L36.0142 25.4233L35.1486 26.9295ZM26.3324 42.9916L25.5283 44.5315L27.0366 45.3191L27.8553 43.8275L26.3324 42.9916ZM26.9723 41.8259L28.4952 42.6618L29.3515 41.1016L27.7711 40.2832L26.9723 41.8259ZM34.5088 28.0952L33.638 29.5985L35.1763 30.4896L36.0317 28.9311L34.5088 28.0952ZM36.0142 25.4233C34.4122 24.5026 32.5541 23.9766 30.5783 23.9766V27.4511C31.9308 27.4511 33.1938 27.8098 34.2829 28.4357L36.0142 25.4233ZM30.5783 23.9766C24.5553 23.9766 19.6677 28.8517 19.6677 34.8716H23.1421C23.1421 30.7762 26.4686 27.4511 30.5783 27.4511V23.9766ZM19.6677 34.8716C19.6677 39.0732 22.0497 42.715 25.5283 44.5315L27.1366 41.4516C24.7576 40.2094 23.1421 37.7266 23.1421 34.8716H19.6677ZM27.8553 43.8275L28.4952 42.6618L25.4493 40.99L24.8095 42.1557L27.8553 43.8275ZM20.9986 34.8716C20.9986 38.573 23.1038 41.779 26.1734 43.3686L27.7711 40.2832C25.807 39.2661 24.4731 37.2221 24.4731 34.8716H20.9986ZM30.5783 25.3053C25.2904 25.3053 20.9986 29.5855 20.9986 34.8716H24.4731C24.4731 31.51 27.2037 28.7797 30.5783 28.7797V25.3053ZM35.3796 26.592C33.9664 25.7733 32.3244 25.3053 30.5783 25.3053V28.7797C31.6968 28.7797 32.74 29.0783 33.638 29.5985L35.3796 26.592ZM36.0317 28.9311L36.6715 27.7655L33.6257 26.0936L32.9859 27.2593L36.0317 28.9311Z"
        fill="#F0F2F5"
        mask="url(#path-6-outside-1_2_3718)"
      />
      <path
        d="M36.4842 28.016C36.6645 28.2986 36.4695 28.6702 36.1341 28.6832L34.2397 28.7563C33.906 28.7692 33.6833 28.4169 33.8384 28.1214L34.7144 26.4518C34.8695 26.1563 35.2864 26.1384 35.4658 26.4196L36.4842 28.016Z"
        fill="#252733"
      />
      <path
        d="M28.613 42.8133C28.7932 43.0959 28.5982 43.4676 28.2629 43.4805L26.3685 43.5537C26.0347 43.5666 25.8121 43.2143 25.9672 42.9187L26.8432 41.2492C26.9983 40.9536 27.4152 40.9358 27.5946 41.217L28.613 42.8133Z"
        fill="#252733"
      />
    </svg>
  );
};

export default LPFIcon;
