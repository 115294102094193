import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Skeleton } from 'antd';
import clsx from 'clsx';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { selectUserPermissionsInfo } from 'store/user-slice/selector';
import Typography from 'ui/typography/Typography';
import { formatNumberWithThousandsSeparatorAndDecimal } from 'utils/helpers';

import AdvanceIcon from 'components/svgs/cards-icons/AdvanceIcon';
import CardsLoadIcon from 'components/svgs/cards-icons/CardsLoadIcon';
import ChargesIcon from 'components/svgs/cards-icons/ChargesIcon';
import CollectionIcon from 'components/svgs/cards-icons/CollectionIcon';
import CreditIcon from 'components/svgs/cards-icons/CreditIcon';
import GrossProfitIcon from 'components/svgs/cards-icons/GrossProfitIcon';
import OtherServiceFeeIcon from 'components/svgs/cards-icons/OtherServiceFeeIcon';
import PotentialGrossProfitIcon from 'components/svgs/cards-icons/PotentialGrossProfitIcon';
import ServiceFeeIcon from 'components/svgs/cards-icons/ServiceFeeIcon';
import CreditCheckIcon from 'components/svgs/CreditCheckIcon';
import LPFIcon from 'components/svgs/LPFIcon';
import SoftwareFeeIcon from 'components/svgs/SoftwareFeeIcon';

import { IRoleLevel } from '../../services/truck-board/carrier-request/interfaces';

import { ICardIcon, ICardItem, IPayrollCardsProps } from './constants/types';

import styles from './PayrollCards.module.scss';

const PayrollCards = ({
  cards,
  withColoredWrapper = false,
  handleSelectCard,
  isSummariesFetching,
}: IPayrollCardsProps) => {
  const { searchParams } = useDetectedParams();
  const { mine, card } = searchParams;
  const { permissionsInfo } = useSelector(selectUserPermissionsInfo);
  const { roleLevel } = permissionsInfo;

  const reFormatPrice = (price: number | string, withDollar = true) => {
    return formatNumberWithThousandsSeparatorAndDecimal(price, withDollar);
  };

  const isAllPriceNotUndefined = useMemo(() => {
    return cards?.some(el => el.price === undefined);
  }, [cards]);

  const cardIcons: ICardIcon = useMemo(
    () => ({
      1: <CardsLoadIcon />,
      2: <PotentialGrossProfitIcon />,
      3: <GrossProfitIcon />,
      4: <CreditIcon />,
      5: <ChargesIcon />,
      6: <ServiceFeeIcon />,
      7: <OtherServiceFeeIcon />,
      8: <AdvanceIcon />,
      9: <CollectionIcon />,
      10: <CreditCheckIcon />,
      11: <SoftwareFeeIcon />,
      12: <LPFIcon />,
    }),
    []
  );
  // todo add logic
  const cardPrice = (price: number | string, name: string) => {
    const isOtherServiceFeeOrAdvance = name === 'Other Service Fee' || name === 'Advance';
    const isLPF = name === 'LPF';

    let adjustedPrice = Number(price) || 0;

    if (
      (isOtherServiceFeeOrAdvance && (roleLevel === IRoleLevel.corpLevel || roleLevel === IRoleLevel.branchLevel)) ||
      (isOtherServiceFeeOrAdvance && roleLevel === IRoleLevel.officeLevel && mine === 'true') ||
      (isLPF && roleLevel !== IRoleLevel.corpLevel)
    ) {
      adjustedPrice *= -1;
    }

    return adjustedPrice;
  };

  if (isAllPriceNotUndefined) {
    return null;
  }

  return (
    <div
      className={clsx(styles.payrollWrapper, {
        [styles.payrollWrapperColored]: withColoredWrapper,
        [styles.wrapperLoading]: isSummariesFetching,
      })}
    >
      <Row gutter={[10, 10]}>
        {cards?.map(({ id, name, price }: ICardItem) => {
          return (
            <Col
              key={id}
              lg={withColoredWrapper ? 4 : 6}
              xl={withColoredWrapper ? 4 : 6}
              xxl={withColoredWrapper ? 3 : 4}
            >
              {isSummariesFetching ? (
                <Skeleton
                  active
                  paragraph={{ rows: 1, width: ['100%'], className: styles.skeletonRow }}
                  title={false}
                  avatar={false}
                />
              ) : (
                <div
                  onClick={handleSelectCard ? () => handleSelectCard(id) : undefined}
                  className={clsx(styles.payrollCard, {
                    [styles.payRollCardActive]: card === id && !withColoredWrapper,
                    [styles.defaultCursor]: withColoredWrapper,
                  })}
                >
                  <div>
                    <Typography variant="heading" level={5} className={styles.cardsTitle}>
                      {name}
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className={clsx(styles.price, styles.positivePrice, {
                        [styles.negativePrice]: cardPrice(price, name) < 0,
                        [styles.zeroPrice]: !cardPrice(price, name),
                      })}
                    >
                      {reFormatPrice(cardPrice(price, name), name !== 'Loads')}
                    </Typography>
                  </div>
                  {!withColoredWrapper && cardIcons[id]}
                </div>
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
export default PayrollCards;
