import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Popover, Row } from 'antd';
import clsx from 'clsx';
import { SUBJECT } from 'pages/profile/tabs/branch/constants/constants';
import { Location } from 'services/profile/agents/interfaces';
import Button from 'ui/button/Button';
import DataGrid from 'ui/data-grid/DataGrid';
import Switch from 'ui/inputs/switch/Switch';
import { MainLoader } from 'ui/main-loader/MainLoader';
import Typography from 'ui/typography/Typography';
import { formatDate } from 'utils/dates';
import { formatNumberWithThousandsSeparatorAndDecimal, formatPhone } from 'utils/helpers';

import TooltipText from 'components/tooltip-text/TooltipText';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import { useView } from './useView';

import styles from './View.module.scss';

const View = ({
  data,
  isFetchingById,
  pagePermissions: { edit, terminateOn, terminateOff },
  authoritiesByBranchId,
  setGetAuthoritiesByBranchFilter,
}: any) => {
  const { handleChecked, handleChangeMode, copiedText, handleCopy, viewData, handlePaginationClick, mode } = useView({
    data,
    setGetAuthoritiesByBranchFilter,
  });
  const checkedValue = !data?.result?.terminatedAt;

  return (
    <div className={styles.viewMain}>
      <MainLoader isFetching={isFetchingById} />
      <ViewItemWrapper
        title={SUBJECT.BRANCH_INFO}
        Component={edit && <Button className="viewDrawerEditBtn" children={SUBJECT.EDIT} onClick={handleChangeMode} />}
      >
        <Row className={styles.agentInfo} gutter={[0, 16]}>
          <Col span={8}>
            <div className={styles.topbannerPairs}>
              <span className="top-banner-title">{SUBJECT.BRANCH_NAME}: </span>{' '}
              <TooltipText
                prefixClass="top-banner-value"
                data={data?.result?.name}
                subject={`${SUBJECT.BRANCH_INFO}${SUBJECT.BRANCH_NAME}`}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.topbannerPairs}>
              <span className="top-banner-title">{SUBJECT.OFFICE}: </span>{' '}
              <span className="top-banner-value">{data?.result?.office?.name}</span>
            </div>
          </Col>

          {!!data?.result?.location && (
            <Col span={8}>
              <div className={styles.topbannerPairs}>
                <span className="top-banner-title">{SUBJECT.LOCATION}: </span>
                <span className="top-banner-value">{Location[data?.result?.location]}</span>
              </div>
            </Col>
          )}
          {!!data?.result?.branchConsignmentPercent && (
            <Col span={8}>
              <div className={styles.topbannerPairs}>
                <span className="top-banner-title">{SUBJECT.CONSIGMENT_PERCENT}: </span>
                <span className="top-banner-value">{data?.result?.branchConsignmentPercent}</span>
              </div>
            </Col>
          )}
          {data?.result?.managers?.length && (
            <Col span={8}>
              <Row>
                <Col>
                  <span className="top-banner-title">
                    {data?.result?.managers?.length === 1 ? SUBJECT.MANAGER : SUBJECT.MANAGERS}:{' '}
                  </span>
                </Col>
                <Col span={18}>
                  <div className={styles.textWithLinks}>
                    {data?.result?.managers.length === 1 && (
                      <TooltipText
                        phoneLink={formatPhone(viewData?.managers?.[0]?.phone)}
                        prefixClass={styles.infoParagraph}
                        data={`${viewData?.managers?.[0]?.firstName} ${viewData?.managers?.[0]?.lastName}`}
                        subject={`${SUBJECT.BRANCH_INFO}${SUBJECT.MANAGERS}`}
                      />
                    )}
                    {data?.result?.managers?.length > 1 && (
                      <Popover
                        placement="top"
                        overlayClassName={styles.popoverOverlay}
                        content={data?.result?.managers?.map((el: any, index: any) => (
                          <Col key={index}>
                            <Row>
                              <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                                {formatPhone(el?.phone)}
                              </a>
                              <Typography
                                variant="paragraph"
                                className={clsx(styles.phoneNameInArray, 'top-banner-value')}
                              >
                                &nbsp;{`${el?.firstName} ${el?.lastName}`}
                              </Typography>
                            </Row>
                          </Col>
                        ))}
                      >
                        <a className={styles.infoParagraph}>{`+${data?.result?.managers?.length}`}</a>
                      </Popover>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      <ViewItemWrapper title={SUBJECT.FULL_INFO}>
        <Row justify="space-between" align="top" gutter={[10, 30]}>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.DATE} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography
                  variant="paragraph"
                  children={formatDate(viewData?.createdAt)}
                  className={styles.infoParagraph}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.OFFICE_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={viewData?.office?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.OFFICE_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BRANCH_NAME} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <TooltipText
                  prefixClass={styles.infoParagraph}
                  data={viewData?.name}
                  subject={`${SUBJECT.FULL_INFO}${SUBJECT.BRANCH_NAME}`}
                />
              </Col>
            </Row>
          </Col>
          {!!data?.result?.location && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.LOCATION} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={viewData?.locationTypeName}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {viewData?.managers?.length && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography
                    variant="heading"
                    children={viewData?.managers?.length === 1 ? SUBJECT.MANAGER : SUBJECT.MANAGERS}
                    className={styles.infoHeading}
                  />
                </Col>
                <Col span={13}>
                  <div className={styles.textWithLinks}>
                    {viewData?.managers.length === 1 && (
                      <>
                        <TooltipText
                          phoneLink={formatPhone(viewData?.managers?.[0]?.phone)}
                          prefixClass={styles.infoParagraph}
                          data={`${viewData?.managers?.[0]?.firstName} ${viewData?.managers?.[0]?.lastName}`}
                          subject={`${SUBJECT.FULL_INFO}${SUBJECT.MANAGERS}`}
                        />
                      </>
                    )}
                    {viewData?.managers?.length && viewData?.managers?.length > 1 && (
                      <Popover
                        placement="top"
                        overlayClassName={styles.popoverOverlay}
                        content={viewData?.managers?.map((el: any, index: any) => (
                          <Col key={index}>
                            <Row>
                              <a className={styles.phoneLinkInArray} href={`tel:${el?.phone}`}>
                                {formatPhone(el?.phone)}
                              </a>
                              <Typography variant="paragraph" className={styles.phoneNameInArray}>
                                {`${el?.firstName} ${el?.lastName}`}
                              </Typography>
                            </Row>
                          </Col>
                        ))}
                      >
                        <a>{`+${viewData?.managers?.length}`}</a>
                      </Popover>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.AGENT} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Typography variant="paragraph" children={viewData?.agentCount} className={styles.infoParagraph} />
              </Col>
            </Row>
          </Col>
          {!!viewData?.address && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={viewData?.address}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!viewData?.secondAddress && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.SECOND_ADDRESS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={viewData?.secondAddress}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.SECOND_ADDRESS}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!viewData?.city && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CITY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={viewData?.city}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.CITY}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {viewData?.state && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.STATE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={viewData?.state?.name} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          {!!viewData?.zip && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ZIPCODE} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography variant="paragraph" children={viewData?.zip} className={styles.infoParagraph} />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.PHONE_NUMBER} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Link className="top-banner-value" to={`tel:${data?.result?.phone}`}>
                  {formatPhone(viewData?.phone)}
                </Link>
              </Col>
            </Row>
          </Col>
          {viewData?.fax && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.FAX} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <TooltipText
                    prefixClass={styles.infoParagraph}
                    data={viewData?.fax}
                    subject={`${SUBJECT.FULL_INFO}${SUBJECT.FAX}`}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!viewData?.branchConsignmentPercent && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.CONSIGMENT_PERCENT} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={viewData?.branchConsignmentPercent}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!!viewData?.branchConsignmentPercent && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.GROSS} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={viewData?.branchConsignmentAmount}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.STATUS} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                <Switch
                  checked={checkedValue || false}
                  onChange={handleChecked}
                  disabled={(checkedValue && !terminateOff) || (!checkedValue && !terminateOn)}
                />
              </Col>
            </Row>
          </Col>
          {!!viewData?.authorityCount && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.AUTHORITIES} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={viewData?.authorityCount}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={10}>
            <Row gutter={10}>
              <Col span={11}>
                <Typography variant="heading" children={SUBJECT.BRACKED} className={styles.infoHeading} />
              </Col>
              <Col span={13}>
                {viewData?.brackets?.map((item: any) => {
                  return (
                    <Typography
                      variant="paragraph"
                      children={`${item?.percent}% (${formatNumberWithThousandsSeparatorAndDecimal(
                        item?.from,
                        true
                      )} - ${formatNumberWithThousandsSeparatorAndDecimal(item?.to, true)}) `}
                      className={styles.infoParagraphBracket}
                    />
                  );
                })}
              </Col>
            </Row>
          </Col>
          {data?.result?.creator.username && (
            <Col span={10}>
              <Row gutter={10}>
                <Col span={11}>
                  <Typography variant="heading" children={SUBJECT.ADDED_BY} className={styles.infoHeading} />
                </Col>
                <Col span={13}>
                  <Typography
                    variant="paragraph"
                    children={`${data?.result?.creator.username}`}
                    className={styles.infoParagraph}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ViewItemWrapper>
      {!!authoritiesByBranchId?.result?.length && (
        <ViewItemWrapper title={`${SUBJECT.ADDED} Authority (${authoritiesByBranchId?.count})`}>
          <Row>
            <Col span={24}>
              <DataGrid
                data={authoritiesByBranchId?.result}
                count={authoritiesByBranchId?.count}
                type="authoritiesView"
                handlePaginationClick={handlePaginationClick}
                copiedText={copiedText}
                handleCopy={handleCopy}
                mode={mode}
              />
            </Col>
          </Row>
        </ViewItemWrapper>
      )}
    </div>
  );
};

export default View;
