import { phoneRegExp } from 'utils/regexp';
import * as yup from 'yup';

import { VALIDATION_MESSAGES } from './constants';
import { IAddBrackedFormData } from './types';

const {
  NAME_REQUIRED,
  OFFICE_REQUIRED,
  LOCATION_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  PHONE,
  INCORRECT_FAX,
  MAX_LENGTH,
  AUTHORITIES_REQUIRED,
  ADDRESS_MAX_LENGTH,
  MAX_MIN_ZIP_LENGTH,
  BRACKET_REQUIRED,
} = VALIDATION_MESSAGES;

export const validation = yup.object().shape({
  name: yup.string().required(NAME_REQUIRED),
  office: yup.array().min(1, OFFICE_REQUIRED).required(OFFICE_REQUIRED),
  location: yup.string(),
  phoneNumber: yup.string().max(12, MAX_LENGTH).required(PHONE_NUMBER_REQUIRED).matches(phoneRegExp, PHONE),
  fax: yup
    .string()
    .nullable()
    .test('isCorrectFax', 'isCorrectFax', function checkIsFaxCorrect(value) {
      if ((value && value.length === 12) || !value) {
        return true;
      } else if (!value?.match(phoneRegExp)) {
        return this.createError({
          message: INCORRECT_FAX,
          path: 'fax',
        });
      } else {
        return this.createError({
          message: MAX_LENGTH,
          path: 'fax',
        });
      }
    }),
  zipcode: yup.string().test('isZipLength', function (value: any) {
    if (value) {
      if (value.length === 5) {
        return true;
      } else {
        return this.createError({
          message: MAX_MIN_ZIP_LENGTH,
          path: 'zipcode',
        });
      }
    }

    return true;
  }),
  address: yup.string().notRequired().max(300, ADDRESS_MAX_LENGTH),
  authorities: yup.array().test('length', AUTHORITIES_REQUIRED, function checkLength(value: any) {
    return value?.length > 0;
  }),
  brackeds: yup.array().test('length', BRACKET_REQUIRED, function checkLength(arr = []) {
    return arr.length > 0;
  }),
  percent: yup
    .number()
    .nullable()
    .typeError('Please enter a valid number')
    .transform(value => (isNaN(value) ? undefined : value))
    .max(100, 'Percent must be less than 100')
    .test('is-decimal', 'Up to 2 decimal places allowed', value => {
      if (!value) {
        return true;
      } else {
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      }
    }),
  amount: yup
    .mixed()
    .nullable()
    .test('is-decimal', 'Up to 2 decimal places allowed', value => {
      if (!value) return true;
      const decimalPart = value.toString().split('.')[1];
      return !decimalPart || decimalPart.length <= 2;
    })
    .test('is-positive, Value must be positive', value => {
      return value ? Number(value) >= 0 : true;
    }),
});

export const brackedValidation = (values: IAddBrackedFormData[]) => {
  const percents = values?.map(item => item?.percent) || [];
  return yup.object().shape({
    percent: yup
      .number()
      .nullable()
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .max(100, 'Percent must be less than 100')
      .positive('Value must be positive')
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      })
      .moreThan(Math.max(...(percents as number[])), 'Value should be more than existing percents'),
    from: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .test('is-decimal', 'Up to 2 decimal places allowed', value => {
        if (!value) return true;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || decimalPart.length <= 2;
      }),
    to: yup
      .number()
      .nullable()
      .typeError('Please enter a valid number')
      .transform(value => (isNaN(value) ? undefined : value))
      .test('is-decimal', 'Up to 2 decimal places allowed', function checkRequire(value) {
        if (Number(value) <= Number(this?.parent?.from) && Number(this.parent.percent !== 0)) {
          return this.createError({
            message: 'Should be more or equal from',
            path: 'to',
          });
        } else if (Number(value) === 0 && Number(this.parent.percent === 0)) {
          return true;
        } else if (!Number(value)) {
          return true;
        } else {
          return true;
        }
      }),
  });
};
