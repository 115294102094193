import { Table } from 'antd';
import clsx from 'clsx';
import { IDataSource } from 'ui/data-grid/constants/types';

import useWhiteListDataGrid from './useWhiteListDataGrid';

import styles from './WhiteListDataGrid.module.scss';

const WhiteListDataGrid = ({
  data,
  handleRowEdit,
  handleRowDelete,
  editingRow,
  setEditingRow,
  isLoading = false,
  withoutAction,
}: any) => {
  const { CURRENT_COLUMNS } = useWhiteListDataGrid({
    handleRowDelete,
    handleRowEdit,
    setEditingRow,
    data,
    withoutAction,
  });

  return (
    <div className={styles.tableWrapper}>
      <Table
        tableLayout="fixed"
        rowClassName={({ id }: IDataSource) =>
          clsx({
            ['activeRow']: String(id) === editingRow,
          })
        }
        columns={CURRENT_COLUMNS}
        dataSource={data}
        rowKey={record => record.key}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
};

export default WhiteListDataGrid;
