import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { IState } from 'services/states/interfaces';

export interface IFilterStatusProps {
  statuses: number[];
  carrierStatuses: number[];
  customerStatuses: number[];
}
export interface IFilterVariants {
  [key: string]: IFilterVariantElem;
}

export interface IPickUp {
  address: string;
  appointmentDate: Date;
  appointmentTime: string;
  city: string;
  comment: string;
  creator: {
    firstName: string;
    lastName: string;
  };
  dropType: string;
  facility: { [key: string]: string } | null;
  phoneNumber: string;
  state: string;
  zip: 3;
  timePeriod: string;
}
export interface IFilterVariantElem {
  status?: number[];
  carrierStatus?: number[];
  customerStatus?: number[];
  claimed?: boolean;
  processing?: boolean;
}

export interface ICreateLoad {
  load_type: string | number;
  customerName: any;
  referenceNumber: string;
  customerAmount: number | '';
  customerContact: string;
  customerContactName: string;
  soldUsSwitch: boolean;
  markAsSwitch: boolean;
  authorities: [];

  carrier?: null | string | any;
  carrierAmount?: string;
  truck?: any;
  agent?: any;
  agentAmount?: string;
  connectingLoadsSwitch?: boolean;
  takenUsAuthority: any;

  documentation?: any[];

  transportType?: null | string;
  celsius: string;
  takenAs: null | string;
  givenAs: null | string;
  commodity: string;
  bookedAs: null | string;
  soldAs: null | string;
  weight: string;
  feetPartial: string;

  pickups: IPickUp[];
  dropOffs: IPickUp[];
  file: [];
  comment: string;
}

export interface IPickupsDropoffs {
  dropType: null | string;
  phoneNumber: string | null;
  appointmentDate: Date | null;
  appointmentTime: string | null;
  facility: any;
  address: string;
  city: string;
  state: null | string;
  zip: string;
  comment: string;
}

export enum LoadTypes {
  'Regular' = 1,
  'Tonu' = 2,
  'Connecting' = 3,
  'Internal' = 4,
}

export enum loadTypesView {
  'regularLoad' = 1,
  'tonuLoad' = 2,
  'connectingLoad' = 3,
  'internalBoardLoad' = 4,
}

export enum LoadViewModes {
  'Regular' = 'regularLoad',
  'Tonu' = 'tonuLoad',
  'Connecting' = 'connectingLoad',
  'Internal' = 'internalBoardLoad',
}

export enum TransportTypes {
  'Dry' = 1,
  'Refrigerated' = 2,
  'Power only' = 3,
  'Flat bed' = 4,
}

export enum ResourceTypes {
  'Solo' = 1,
  'Team' = 2,
}

export enum BookedTypes {
  'Full' = 1,
  'Partial' = 2,
}

export interface ICreateLoadProps {
  createLoadForm: {
    agents: {
      count: number;
      result: [];
    };
    authorities: {
      count: number;
      result: [];
    };
    carriers: {
      count: number;
      result: [];
    };
    control: Control<ICreateLoad>;
    data: [] | undefined;
    errors: FieldErrors;
    handleClearDate: () => void;
    handleSubmit: UseFormHandleSubmit<ICreateLoad>;
    handleSubmitPickupDropOffs: SubmitHandler<IPickupsDropoffs>;
    isPickupDropOffsValid: boolean;
    isValid: boolean;
    pickupDropOffsControl: Control<IPickupsDropoffs>;
    pickupDropOffsErrors: FieldErrors;
    pickupDropOffsWatch: UseFormWatch<IPickupsDropoffs>;
    setAgentsState: () => void;
    setAuthoritiesState: () => void;
    setCarriersState: () => void;
    setPickupDropOffsValue: (name: string, value: unknown) => void;
    setTrucksState: () => void;
    setValue: UseFormSetValue<ICreateLoad>;
    statesData: [IState];
    trigger: UseFormTrigger<ICreateLoad>;
    trucks: [] | undefined;
    watch: UseFormWatch<ICreateLoad>;
  };
  onSubmit: SubmitHandler<ICreateLoad>;
}

export interface IAddLoad {
  watch: UseFormWatch<ICreateLoad>;
  control: Control<ICreateLoad | IPickupsDropoffs>;
  errors: FieldErrors;
  authorities: {
    count: number;
    result: [];
  };
  setAuthoritiesState: () => void;
  carriers: {
    count: number;
    result: [];
  };
  setCarriersState: () => void;
  agents: {
    count: number;
    result: [];
  };
  setAgentsState: () => void;
  trucks: {
    count: number;
    result: [];
  };
  setTrucksState: () => void;
  setValue: UseFormSetValue<ICreateLoad>;
}
