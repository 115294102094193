import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import {
  AGENT_COLUMNS_LOAD,
  AGENT_KEYS_LOAD,
} from 'pages/profile/tabs/carriers/components/blacklist/constants/constants';
import { selectUserInfo } from 'store/user-slice/selector';
import ErrorMessage from 'ui/error-message/ErrorMessage';
import SimpleSelect from 'ui/inputs/simpleSelect/SimpleSelect';
import Typography from 'ui/typography/Typography';
import {
  floatNumberFormatter,
  formatNumberWithThousandsSeparatorAndDecimal,
  formatPhone,
  unicodeFormatter,
} from 'utils/helpers';

import DollarIcon from 'components/svgs/DollarIcon';
import ViewItemWrapper from 'components/view-item-wrapper/ViewItemWrapper';

import {
  AUTHORITIES_CUSTOM_COLUMNS,
  AUTHORITIES_CUSTOM_KEYS,
  AUTHORITY_KEYS,
  CARRIER_CUSTOM_KEYS,
  LOAD_TYPES_OPTIONS,
  MY_AUTHORITY_COLUMNS,
  SEARCH_CARRIER_CUSTOM_COLUMNS,
  SEARCH_TRUCK_COLUMNS,
  SUBJECT,
  TRUCK_KEYS,
} from '../../constants/constants';
import { LoadTypes } from '../../constants/types';
import { Input, SearchableDropdown, Switch } from '../../hoc/instance';

import styles from '../../CreateLoad.module.scss';

export const AddLoadOutside = ({
  watch,
  control,
  errors,
  authorities,
  setAuthoritiesState,
  carriers,
  setCarriersState,
  agents,
  setAgentsState,
  trucks,
  setTrucksState,
  setValue,
  handleSwitchSoldUs,
  trigger,
  touchedFields,
  customers,
  setCustomersState,
}: any) => {
  const { id: userId } = useSelector(selectUserInfo);

  useEffect(() => {
    if (watch('load_type') === '1' && watch('carrier')[0]?.type === 'Outside') {
      setValue('soldUsSwitch', true, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('soldUsSwitch', false, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [watch('load_type'), watch('carrier')]);

  return (
    <ViewItemWrapper id="general-information" title={SUBJECT.ADD_LOAD}>
      <Row align="middle" className={styles.topBannerBlock}>
        <Col span={12}>
          <Typography variant="heading" children={SUBJECT.GENERAL_INFO} className={styles.generalInfoHeading} />
        </Col>
      </Row>
      <Row gutter={[18, 40]}>
        <Col span={8}>
          <Controller
            name="load_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <SimpleSelect
                  options={LOAD_TYPES_OPTIONS}
                  placeholder={SUBJECT.LOAD_TYPE}
                  name={field.name}
                  onBlur={() => trigger('load_type')}
                  onChange={(value: string | string[]) => {
                    field.onChange(value);
                  }}
                  errors={errors}
                  value={field.value}
                  required
                />
              );
            }}
          />
          <ErrorMessage fieldError={errors?.load_type} />
        </Col>
        <Col span={8}>
          <SearchableDropdown
            options={customers?.result || []}
            columns={SEARCH_CARRIER_CUSTOM_COLUMNS}
            placeholder={SUBJECT.CUSTOMER_NAME}
            offset={customers?.count}
            setOffset={setCustomersState}
            values={watch('customerName')}
            keys={CARRIER_CUSTOM_KEYS}
            control={control}
            name="customerName"
            errors={errors}
            showKey="title"
            setValue={setValue}
            withoutBorder={true}
            required
            rules={{ required: true }}
            width={620}
          />
        </Col>
        <Col span={8}>
          <SearchableDropdown
            options={authorities?.result}
            columns={MY_AUTHORITY_COLUMNS}
            keys={AUTHORITY_KEYS}
            placeholder={SUBJECT.TAKEN_AS_AUTHORITY}
            name="takenUsAuthority"
            control={control}
            setValue={setValue}
            values={watch('takenUsAuthority')}
            offset={authorities?.count}
            setOffset={setAuthoritiesState}
            showKey="name"
            required
            errors={errors}
            rules={{ required: true }}
            width={500}
            onBlur={() => {
              if (Object.keys(touchedFields).includes('customerName')) {
                setTimeout(() => {
                  trigger(['takenUsAuthority', 'customerName']);
                }, 300);
              } else {
                setTimeout(() => {
                  trigger(['takenUsAuthority']);
                }, 300);
              }
            }}
          />
        </Col>
        <Col span={8}>
          <Input
            name="customerAmount"
            control={control}
            placeholder={SUBJECT.CUSTOMER_AMOUNT}
            rules={{ required: true }}
            errors={errors}
            value={watch('customerAmount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('customerAmount')) : ''}
            suffix={<DollarIcon />}
            required
            disabled={!watch('customerName')}
            onChange={(e: any) => {
              setValue('customerAmount', floatNumberFormatter(e.target.value), {
                shouldDirty: true,
                shouldValidate: true,
              });
              if (watch('agentAmount')) {
                trigger('agentAmount');
              }
            }}
          />
        </Col>
        <Col span={8}>
          <Input
            name="referenceNumber"
            control={control}
            placeholder={SUBJECT.REFERENCE_NUMBER}
            rules={{ required: true }}
            errors={errors}
            getValues={watch}
            type="text"
            onChange={(e: any) =>
              setValue('referenceNumber', e.target.value.trimStart(), { shouldValidate: true, shouldDirty: true })
            }
            required
          />
        </Col>

        {LoadTypes[watch('load_type')] !== 'Internal' && (
          <>
            <Col span={8}>
              <Input
                name="customerContactName"
                type="text"
                control={control}
                rules={{ required: true }}
                placeholder={SUBJECT.CONTACT_NAME}
                getValues={watch}
                errors={errors}
                required
                onChange={(e: any) => {
                  setValue('customerContactName', unicodeFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
            <Col span={8}>
              <Input
                name="customerContact"
                control={control}
                placeholder={SUBJECT.CUSTOMER_CONTACT}
                rules={{ required: true }}
                errors={errors}
                getValues={watch}
                required
                onChange={(e: any) => {
                  setValue('customerContact', formatPhone(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            </Col>
          </>
        )}

        {LoadTypes[watch('load_type')] === 'Regular' && (
          <>
            <Col span={8}>
              <SearchableDropdown
                options={carriers?.result}
                columns={SEARCH_CARRIER_CUSTOM_COLUMNS}
                placeholder={SUBJECT.CARRIER}
                offset={carriers?.count}
                setOffset={setCarriersState}
                values={watch('carrier')}
                keys={CARRIER_CUSTOM_KEYS}
                control={control}
                name="carrier"
                errors={errors}
                showKey="title"
                setValue={setValue}
                withoutBorder={true}
                required
                rules={{ required: true }}
                width={620}
              />
            </Col>
            {watch('carrier')[0]?.type === 'Preferred Carrier' && (
              <Col span={8}>
                <SearchableDropdown
                  options={trucks?.result?.map((truck: any) => ({
                    title: truck?.truckNumber,
                    value: String(truck?.id),
                  }))}
                  placeholder={SUBJECT.TRUCK}
                  offset={trucks?.count}
                  setOffset={setTrucksState}
                  values={watch('truck')}
                  columns={SEARCH_TRUCK_COLUMNS}
                  keys={TRUCK_KEYS}
                  control={control}
                  onBlur={() => trigger('truck')}
                  name="truck"
                  showKey="title"
                  errors={errors}
                  setValue={setValue}
                  disabled={!watch('carrier')?.length}
                  withoutBorder={true}
                  required
                  rules={{ required: true }}
                />
              </Col>
            )}
            <Col span={8}>
              <Input
                name="carrierAmount"
                control={control}
                placeholder={SUBJECT.CARRIER_AMOUNT}
                rules={{ required: true }}
                errors={errors}
                value={
                  watch('carrierAmount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('carrierAmount')) : ''
                }
                suffix={<DollarIcon />}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValue('carrierAmount', floatNumberFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Col>
          </>
        )}
        {LoadTypes[watch('load_type')] === 'Connecting' && (
          <>
            <Col span={8}>
              <SearchableDropdown
                options={agents?.result?.filter((el: any) => el.id !== userId)}
                columns={AGENT_COLUMNS_LOAD}
                keys={AGENT_KEYS_LOAD}
                placeholder={SUBJECT.AGENT}
                name="agent"
                control={control}
                setValue={setValue}
                values={watch('agent')}
                errors={errors}
                offset={agents?.count}
                setOffset={setAgentsState}
                showKey="username"
                showId
                rules={{ required: true }}
                required
                width={620}
              />
            </Col>
            <Col span={8}>
              <Input
                name="agentAmount"
                control={control}
                placeholder={SUBJECT.AGENT_AMOUNT}
                rules={{ required: true }}
                errors={errors}
                value={watch('agentAmount') ? formatNumberWithThousandsSeparatorAndDecimal(watch('agentAmount')) : ''}
                onChange={(e: any) => {
                  setValue('agentAmount', floatNumberFormatter(e.target.value), {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                }}
                suffix={<DollarIcon />}
                required
              />
            </Col>
          </>
        )}
      </Row>
      {LoadTypes[watch('load_type')] !== 'Connecting' && LoadTypes[watch('load_type')] !== 'Internal' && (
        <Row align="middle" className={styles.switchSection}>
          <Col span={2}>
            <Typography variant="heading" children={SUBJECT.SOLD_US_SWITCH} className={styles.switchHeading} />
          </Col>
          <Col>
            <Switch
              name="soldUsSwitch"
              control={control}
              disabled={watch('carrier')[0]?.type === 'Outside'}
              checked={watch('soldUsSwitch')}
              onChange={handleSwitchSoldUs}
            />
          </Col>
        </Row>
      )}

      {watch('soldUsSwitch') &&
        LoadTypes[watch('load_type')] !== 'Connecting' &&
        LoadTypes[watch('load_type')] !== 'Internal' && (
          <Row align="middle" gutter={18} className={styles.switchSection}>
            <Col span={8}>
              <SearchableDropdown
                options={authorities?.result?.map((item: any) => ({
                  title: item?.name,
                  value: String(item?.id),
                  id: String(item?.id),
                }))}
                placeholder={SUBJECT.ALL_AUTHORITIES}
                offset={authorities?.count}
                setOffset={setAuthoritiesState}
                values={watch('authorities')}
                columns={AUTHORITIES_CUSTOM_COLUMNS}
                keys={AUTHORITIES_CUSTOM_KEYS}
                control={control}
                name="authorities"
                showKey="title"
                setValue={setValue}
                onBlur={() => {
                  setTimeout(() => {
                    trigger('authorities');
                  }, 700);
                }}
                withoutBorder={true}
                required
                rules={{ required: true }}
                errors={errors}
                width={500}
                disabled={!watch('takenUsAuthority')?.length}
              />
            </Col>
          </Row>
        )}
    </ViewItemWrapper>
  );
};
