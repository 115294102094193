import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useDetectedParams } from 'hooks/useDetectedParams';
import { usePermittedActions } from 'hooks/usePermittedActions';
import useResetColumns from 'hooks/useResetColumns';
import {
  authoritiesAPI,
  useGetAuthoritiesByOfficeIdQuery,
  useGetBranchAuthoritiesQuery,
} from 'services/profile/authorities/authorities';
import { IGetAuthoritiesParams } from 'services/profile/authorities/interfaces';
import {
  useChangeStatusMutation,
  useCreateBranchMutation,
  useGetBranchesQuery,
  useGetBranchQuery,
  useUpdateBranchMutation,
  useUpdateDefaultBranchMutation,
} from 'services/profile/branches/branches';
import { IGetBranchesParams } from 'services/profile/branches/interfaces';
import { useGetOfficeQuery, useGetOfficesAutocompleteQuery } from 'services/profile/offices/offices';
import { useGetStatesQuery } from 'services/states/states';
import { useDeleteColumnsMutation, useEditColumnsMutation, useGetColumnsQuery } from 'services/user/user';
import { setButtonVisibility } from 'store/add-button-slice/addButton';
import { closeModal, openModal } from 'store/modal-slice/modals';
import { selectUserInfo, selectUserPermissionsInfo } from 'store/user-slice/selector';
import { notificationKey } from 'utils/constants';
import { ServerErrorCodes } from 'utils/errors';
import { formatPhone } from 'utils/helpers';
import { IChildren } from 'utils/types';

import CustomColumn from 'components/custom-column/CustomColumn';
import CloseIcon from 'components/svgs/CloseIcon';
import DangerIcon from 'components/svgs/DangerIcon';
import SuccessIcon from 'components/svgs/SuccessIcon';

import { IOfficeParams } from '../office/constants/types';

import CreateEdit from './components/create-edit/CreateEdit';
import View from './components/view/View';
import { defaultBrackedValues, defaultValues, filterDefaultValues, SUBJECT } from './constants/constants';
import { IAddBrackedFormData, IBranchFilter, IBranchFormData, IBranchTableColumns } from './constants/types';
import { brackedValidation, validation } from './constants/validation';

const useBranch = ({ setExcelParams }: IOfficeParams) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    permissionsInfo: { roleLevel },
    userType,
  } = useSelector(selectUserPermissionsInfo);
  const userInfo = useSelector(selectUserInfo);

  const { searchParams, setSearchParams } = useDetectedParams();
  const { mode, location, open, id, office_filter } = searchParams;

  const [getAuthoritiesFilter, setGetAuthoritiesFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
    ignoreAuthorityIds: [],
  });

  const [getAuthoritiesByBranchFilter, setGetAuthoritiesByBranchFilter] = useState<Partial<IGetAuthoritiesParams>>({
    skip: 0,
    limit: 10,
  });
  const {
    data: authoritiesByBranchId,
    refetch: authoritiesRefetch,
    isFetching: isAutocompleteFetching,
  } = useGetBranchAuthoritiesQuery({ id, ...getAuthoritiesByBranchFilter }, { skip: !id });
  const [branchFilters, setBranchFilters] = useState<Partial<IGetBranchesParams>>({
    search: '',
    field: '',
    skip: 0,
    limit: 20,
    order: 2,
    orderBy: 'createdAt',
    filter: {},
  });

  const { data = [], isFetching, refetch: refetchBranchesList } = useGetBranchesQuery(branchFilters);

  const { currentData: branchById, isFetching: isFetchingById } = useGetBranchQuery(id, { skip: !id });

  const [createBranch, { isLoading: isCreateLoading }] = useCreateBranchMutation();
  const [changeStatus] = useChangeStatusMutation();
  const [updateBranch, { isLoading: isEditLoading }] = useUpdateBranchMutation();
  const [updateDefaultBranch, { isLoading: isEditDefaultBranchLoading }] = useUpdateDefaultBranchMutation();

  const { data: statesData } = useGetStatesQuery({}, { skip: mode === 'view' });

  const { data: columnsData } = useGetColumnsQuery({ type: 'branches' });
  const [editColumns, { isLoading: isLoadingColumnsEdit }] = useEditColumnsMutation();
  const [deleteColumns, { isLoading: isLoadingColumnsDelete }] = useDeleteColumnsMutation();

  const [brackeds, setBrackeds] = useState<IAddBrackedFormData[]>([]);
  const [deletedIndex, setDeletedIndex] = useState<number | null>(null);

  const [officesFilter, setOfficesFilter] = useState<Partial<IGetBranchesParams>>({
    search: '',
    skip: 0,
    limit: 10,
  });

  const { data: filterOfficeData } = useGetOfficesAutocompleteQuery(officesFilter);
  const { data: selectedOffice } = useGetOfficeQuery(office_filter, { skip: !office_filter });

  const [selectedOfficeOption, setSelectedOfficeOption] = useState<any>({});
  const [finalOfficeOptions, setFinalOfficeOptions] = useState<any>({ result: [], count: 0 });

  useEffect(() => {
    setSelectedOfficeOption({
      title: selectedOffice?.result.name,
      id: selectedOffice?.result.id,
      value: String(selectedOffice?.result.id),
    });
  }, [selectedOffice]);

  useEffect(() => {
    if (filterOfficeData && !selectedOfficeOption.id) {
      setFinalOfficeOptions(filterOfficeData);
    } else if (filterOfficeData) {
      setFinalOfficeOptions({
        result: [
          selectedOfficeOption,
          ...filterOfficeData.result.filter((el: any) => el.id !== selectedOfficeOption.id),
        ],
        count: filterOfficeData?.count,
      });
    }
  }, [selectedOfficeOption, filterOfficeData]);

  const refetch = async () => {
    await authoritiesRefetch();
    authorityShortRefetch();
  };

  const handleOfficeFilterSelect = (item: any) => {
    if (item.id != office_filter) {
      setSearchParams({
        ...searchParams,
        office_filter: item.id,
      });
      setBranchFilters(prev => ({
        ...prev,
        skip: 0,
        limit: prev.limit,
        filter: {
          ...prev.filter,
          officeId: office_filter,
        },
      }));
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    getValues,
    reset,
    setValue,
    watch,
    clearErrors,
    trigger: formTrigger,
    resetField,
  } = useForm<IBranchFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (mode === 'add') {
      dispatch(authoritiesAPI.util.resetApiState());
      setGetAuthoritiesFilter({
        skip: 0,
        limit: 10,
        ignoreAuthorityIds: [],
      });
    }
  }, [watch('office')]);

  const { data: authoritiesByOfficeId = [], refetch: authorityShortRefetch } = useGetAuthoritiesByOfficeIdQuery(
    { officeId: watch('office')?.[0]?.value, ...getAuthoritiesFilter, ...(mode === 'edit' && { ignoreBranchId: id }) },
    { skip: mode === 'view' || !watch('office').length }
  );

  const {
    handleSubmit: handleBrackedSubmit,
    control: brackedControl,
    getValues: getBrackedValues,
    watch: watchBrackeds,
    formState: { isValid: isBrackedValid, errors: brackedErrors, isDirty: isBrackedDirty },
    trigger,
    reset: resetBrackeds,
    setValue: setBrackedsValue,
  } = useForm<IAddBrackedFormData>({
    defaultValues: defaultBrackedValues,
    mode: 'onBlur',
    resolver: yupResolver(brackedValidation(brackeds)),
  });

  const {
    control: filterControl,
    reset: filterReset,
    watch: filterWatch,
    setValue: setFilterValue,
    formState: { isDirty: isFilterDirty },
  } = useForm<IBranchFilter>({
    defaultValues: filterDefaultValues,
    mode: 'onChange',
  });

  const filterValue = filterWatch('location_filter');
  const filterOfficeValue = filterWatch('office_filter');
  const filterQueryCheck = typeof filterValue === 'string';

  useEffect(() => {
    if (location) {
      setFilterValue('location_filter', location, { shouldDirty: true });
      setBranchFilters(prev => ({ ...prev, skip: 0, limit: prev.limit, filter: { ...prev.filter, location } }));
    }
  }, [location]);

  useEffect(() => {
    if (office_filter && !filterWatch('office_filter')) {
      setFilterValue('office_filter', office_filter, { shouldDirty: true });
      setBranchFilters((prev: any) => {
        const { filter } = prev;

        return { ...prev, skip: 0, filter: { ...filter, officeId: office_filter } };
      });
    } else if (office_filter) {
      setFilterValue('office_filter', office_filter, { shouldDirty: true });
      setBranchFilters((prev: any) => {
        const {
          filter: { branchId: _, ...rest },
        } = prev;

        return { ...prev, skip: 0, filter: { ...rest, officeId: office_filter } };
      });
    }
  }, [office_filter]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { location: filterValue } : {}),
    });
  }, [filterValue, filterOfficeValue]);

  useEffect(() => {
    resetBrackeds(defaultBrackedValues);
  }, [mode, open]);

  useEffect(() => {
    if (mode === 'edit') {
      reset({
        name: branchById?.result?.name,
        office: [{ name: branchById?.result?.office?.name, value: branchById?.result?.office?.id }],
        location: branchById?.result?.location,
        phoneNumber: formatPhone(branchById?.result?.phone),
        fax: branchById?.result?.fax,
        address: branchById?.result?.address,
        secondAddress: branchById?.result?.secondAddress || '',
        city: branchById?.result?.city,
        state: branchById?.result?.stateId,
        zipcode: branchById?.result?.zip || '',
        percent: branchById?.result?.branchConsignmentPercent,
        amount: branchById?.result?.branchConsignmentAmount,
        gross: branchById?.result?.gross || 0,
        authorities: authoritiesByBranchId?.result?.map((item: any) => item?.id),
        brackeds: branchById?.result?.brackets?.map((item: any) => {
          return {
            to: item?.to,
            from: item?.from,
            percent: item?.percent,
          };
        }),
      });
      setBrackeds(
        branchById?.result?.brackets?.map((item: any) => {
          return {
            to: item?.to,
            from: item?.from,
            percent: item?.percent,
          };
        })
      );
    } else {
      reset(defaultValues);
      setBrackeds([]);
    }
  }, [mode, open, id, branchById]);

  useEffect(() => {
    if (watchBrackeds('to') && watchBrackeds('from')) {
      const timeout = setTimeout(() => {
        trigger(['from', 'to']);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [watchBrackeds('to'), watchBrackeds('from')]);

  const onBrackedSubmit = (data: IAddBrackedFormData) => {
    setBrackeds(prev => [...prev, data]);
    setValue('brackeds', [...brackeds, data], { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const lastIndex = brackeds?.length - 1;
  const lastItem = brackeds?.[lastIndex];

  const branchPagePermission = usePermittedActions('profiles.branches');

  useEffect(() => {
    if (brackeds?.length) {
      resetBrackeds({
        percent: null,
        from: Number(lastItem!.to!) + 1,
        to: null,
      });
      setBrackeds(
        brackeds?.map((item, index) => {
          if (deletedIndex === brackeds!.length) {
            return {
              ...item,
            };
          }
          if (deletedIndex && deletedIndex <= brackeds?.length && brackeds[index] === brackeds[deletedIndex! - 1]) {
            return {
              ...item,
              to: Number(brackeds[deletedIndex!]!.from!) - 1,
            };
          }
          return item;
        })
      );
    } else {
      resetBrackeds(defaultBrackedValues);
    }
    setBrackeds(prev =>
      prev?.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            from: 0,
          };
        }
        return item;
      })
    );
  }, [brackeds?.length]);

  const handleDeleteBrackedRow = (index: number) => {
    setDeletedIndex(index);
    setBrackeds(prev => prev.filter((item, currentIndex) => currentIndex !== index));
    dispatch(closeModal());
    setValue(
      'brackeds',
      brackeds.filter((item, currentIndex) => currentIndex !== index),
      { shouldDirty: true, shouldTouch: true, shouldValidate: true }
    );
  };

  const handleDeleteBracked = (index: number) => {
    dispatch(
      openModal({
        title: SUBJECT.ARE_YOU_SURE,
        okText: SUBJECT.DELETE,
        cancelText: SUBJECT.CLOSE,
        okButtonVariant: 'danger',
        onOk: () => {
          handleDeleteBrackedRow(index);
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const resetFilter = () => {
    filterReset();
    setSearchParams({
      ...searchParams,
      location: '',
      office_filter: '',
    });
    setFilterValue('office_filter', '');
    setBranchFilters(prev => ({ ...prev, filter: {} }));
  };

  const resetForm = () => {
    setSearchParams({
      ...searchParams,
      open: 'false',
      id: '',
    });
    setGetAuthoritiesFilter({
      skip: 0,
      limit: 10,
      ignoreAuthorityIds: [],
    });
    setGetAuthoritiesByBranchFilter({
      skip: 0,
      limit: 10,
    });
    reset(defaultValues, { keepDirty: false });
    clearErrors();
  };

  const handleSwitchClick = (checked: boolean, rowId: number | string) => {
    dispatch(
      openModal({
        title: !checked ? SUBJECT.CHECKED_DEACTIVATE : SUBJECT.CHECKED_ACTIVATE,
        okText: SUBJECT.YES,
        cancelText: SUBJECT.NO,
        cancelButtonVariant: 'gray',
        okButtonVariant: checked ? 'modalPrimary' : 'danger',
        onOk: () => {
          changeStatus({ id: rowId, status: checked ? 1 : 2 }).then((data: any) => {
            if (data.error) {
              notification.error({
                message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
                duration: 1,
                icon: <DangerIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            } else {
              notification.success({
                message: checked ? SUBJECT.SUCCESSFULLY_ACTIVATED : SUBJECT.SUCCESSFULLY_DEACTIVATED,
                duration: 1,
                icon: <SuccessIcon />,
                placement: 'topRight',
                closeIcon: <CloseIcon />,
                key: notificationKey,
                btn: (
                  <button type="button" onClick={() => notification.destroy(notificationKey)}>
                    <CloseIcon />
                  </button>
                ),
              });
            }
          });
          dispatch(closeModal());
        },
        onCancel: () => {
          dispatch(closeModal());
        },
      })
    );
  };

  const onSubmit = (values: IBranchFormData) => {
    if (mode === 'edit' && !(isEditLoading || isEditDefaultBranchLoading)) {
      if (branchById?.result?.default) {
        updateDefaultBranch({ id, ...values }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully edited',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
            refetchBranchesList();
          }
        });
      } else {
        updateBranch({ id, ...values, brackeds }).then((data: any) => {
          if (data.error) {
            notification.error({
              message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
              duration: 3,
              icon: <DangerIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
          } else {
            notification.success({
              message: 'Successfully edited',
              duration: 1.5,
              icon: <SuccessIcon />,
              placement: 'topRight',
              closeIcon: <CloseIcon />,
              key: notificationKey,
              btn: (
                <button type="button" onClick={() => notification.destroy(notificationKey)}>
                  <CloseIcon />
                </button>
              ),
            });
            resetForm();
            refetchBranchesList();
          }
        });
      }
    } else if (mode === 'add' && !isCreateLoading) {
      createBranch({ ...values, brackeds }).then((data: any) => {
        if (data.error) {
          notification.error({
            message: ServerErrorCodes[Number(data?.error?.data?.code) || 0],
            duration: 3,
            icon: <DangerIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
        } else {
          notification.success({
            message: 'Successfully created',
            duration: 1.5,
            icon: <SuccessIcon />,
            placement: 'topRight',
            closeIcon: <CloseIcon />,
            key: notificationKey,
            btn: (
              <button type="button" onClick={() => notification.destroy(notificationKey)}>
                <CloseIcon />
              </button>
            ),
          });
          resetForm();
        }
      });
    }
  };

  const handleStopResize = (columns: IBranchTableColumns[]): void => {
    editColumns({ type: 'branches', columns });
  };

  const [dragColumns, setDragColumns] = useState<any>([]);

  useEffect(() => {
    if (columnsData?.columns.length) {
      setDragColumns(columnsData?.columns);
    }
  }, [columnsData?.columns]);

  const handleCloseModal = () => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { location: filterValue } : {}),
      ...(filterOfficeValue ? { office_filter: filterOfficeValue } : {}),
      open: 'false',
      id: '',
    });
    dispatch(closeModal());
    reset(defaultValues);
    resetBrackeds(defaultBrackedValues);
    resetForm();
  };

  const { isSuccess, setIsSuccess, defaultColumnsData, handleResetToDefault, onCustomizeSubmit, setIsDragged } =
    useResetColumns({ setDragColumns, deleteColumns, editColumns, handleCloseModal, dragColumns, type: 'branches' });

  const handleCancel = () => {
    if (isDirty || isBrackedDirty) {
      dispatch(
        openModal({
          title: SUBJECT.UNSAVED_CHANGES,
          okText: isValid && brackeds?.length ? SUBJECT.SAVE : SUBJECT.STAY_HERE,
          cancelText: SUBJECT.CLOSE,
          onOk:
            isValid && brackeds?.length
              ? () => {
                  onSubmit(watch());
                  dispatch(closeModal());
                  clearErrors();
                }
              : () => {
                  dispatch(closeModal());
                },
          onCancel: handleCloseModal,
        })
      );
    } else {
      setSearchParams({
        ...searchParams,
        ...(filterValue && filterQueryCheck ? { location: filterValue } : {}),
        ...(filterOfficeValue ? { office_filter: filterOfficeValue } : {}),
        open: 'false',
      });
      if (mode === 'edit') {
        resetForm();
      }
      setGetAuthoritiesFilter({
        skip: 0,
        limit: 10,
        ignoreAuthorityIds: [],
      });
      setGetAuthoritiesByBranchFilter({
        skip: 0,
        limit: 10,
      });
    }
  };

  const handleRowClick = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { location: filterValue } : {}),
      ...(filterOfficeValue ? { office_filter: filterOfficeValue } : {}),
      mode: 'view',
      open: 'true',
      id: rowId,
    });
  };

  const handleRowEdit = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      ...(filterValue && filterQueryCheck ? { location: filterValue } : {}),
      mode: 'edit',
      open: 'true',
      id: rowId,
    });
  };

  const handleColumnSearch = (value: string, column: string) => {
    setBranchFilters(prev => {
      return {
        ...prev,
        search: String(value),
        field: column,
        skip: 0,
        limit: prev.limit,
      };
    });
  };

  const handleSortClick = (sortOrder: number, dataIndex: string) => {
    setBranchFilters(prev => {
      if (sortOrder === 0)
        return {
          ...prev,
          order: 2,
          orderBy: 'createdAt',
        };
      return {
        ...prev,
        order: sortOrder,
        orderBy: dataIndex,
      };
    });
  };

  const handlePaginationClick = (skip: number, limit: number) => {
    setBranchFilters(prev => ({
      ...prev,
      limit,
      skip,
    }));
  };

  useEffect(() => {
    if (open === 'false') {
      dispatch(authoritiesAPI.util.resetApiState());
    }
  }, [mode, open, id]);

  useEffect(() => {
    if (setExcelParams) {
      setExcelParams((prev: any) => {
        return {
          ...prev,
          ...branchFilters,
        };
      });
    }
  }, [branchFilters]);

  useEffect(() => {
    if (userInfo.branchIds && userType !== 1) {
      if (office_filter && !userInfo?.officeIds?.some(el => el === Number(office_filter))) {
        setTimeout(() => {
          navigate({ pathname: '/not-found' });
        }, 0);
      }
    }
  }, [userInfo]);

  const drawerChildren: IChildren = {
    customize: {
      component: (
        <CustomColumn
          columns={columnsData?.columns}
          isDefault={columnsData?.isDefault}
          dragColumns={dragColumns}
          setDragColumns={setDragColumns}
          handleResetToDefault={handleResetToDefault}
          isSuccess={isSuccess}
          setIsSuccess={setIsSuccess}
          defaultColumnsData={defaultColumnsData}
          setIsDragged={setIsDragged}
          onCustomizeSubmit={onCustomizeSubmit}
          isLoading={isLoadingColumnsEdit || isLoadingColumnsDelete}
        />
      ),
      buttonText: SUBJECT.SAVE,
      onSubmit: onCustomizeSubmit,
    },
    add: branchPagePermission.create
      ? {
          component: (
            <CreateEdit
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              watch={watch}
              handleBrackedSubmit={handleBrackedSubmit}
              brackedControl={brackedControl}
              getBrackedValues={getBrackedValues}
              watchBrackeds={watchBrackeds}
              isBrackedValid={isBrackedValid}
              onBrackedSubmit={onBrackedSubmit}
              handleDeleteBracked={handleDeleteBracked}
              brackeds={brackeds}
              brackedErrors={brackedErrors}
              mode={mode}
              statesData={statesData}
              authoritiesByOfficeId={authoritiesByOfficeId}
              authoritiesByBranchId={authoritiesByBranchId}
              data={branchById}
              refetch={refetch}
              getAuthoritiesFilter={getAuthoritiesFilter}
              setGetAuthoritiesFilter={setGetAuthoritiesFilter}
              getAuthoritiesByBranchFilter={getAuthoritiesByBranchFilter}
              setGetAuthoritiesByBranchFilter={setGetAuthoritiesByBranchFilter}
              isFetchingById={isFetchingById}
              setBrackedsValue={setBrackedsValue}
              trigger={formTrigger}
              brackedTrigger={trigger}
              isEditLoading={isEditLoading}
              isEditDefaultBranchLoading={isEditDefaultBranchLoading}
              isCreateLoading={isCreateLoading}
              isAutocompleteFetching={isAutocompleteFetching}
              resetField={resetField}
            />
          ),
          buttonText: SUBJECT.ADD_BRANCH,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    edit: branchPagePermission.edit
      ? {
          component: (
            <CreateEdit
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              watch={watch}
              handleBrackedSubmit={handleBrackedSubmit}
              brackedControl={brackedControl}
              getBrackedValues={getBrackedValues}
              watchBrackeds={watchBrackeds}
              isBrackedValid={isBrackedValid}
              onBrackedSubmit={onBrackedSubmit}
              handleDeleteBracked={handleDeleteBracked}
              brackeds={brackeds}
              brackedErrors={brackedErrors}
              mode={mode}
              statesData={statesData}
              authoritiesByOfficeId={authoritiesByOfficeId}
              authoritiesByBranchId={authoritiesByBranchId}
              data={branchById}
              refetch={refetch}
              getAuthoritiesFilter={getAuthoritiesFilter}
              setGetAuthoritiesFilter={setGetAuthoritiesFilter}
              getAuthoritiesByBranchFilter={getAuthoritiesByBranchFilter}
              setGetAuthoritiesByBranchFilter={setGetAuthoritiesByBranchFilter}
              isFetchingById={isFetchingById}
              setBrackedsValue={setBrackedsValue}
              trigger={formTrigger}
              brackedTrigger={trigger}
              isEditLoading={isEditLoading}
              isEditDefaultBranchLoading={isEditDefaultBranchLoading}
              isCreateLoading={isCreateLoading}
              isAutocompleteFetching={isAutocompleteFetching}
              resetField={resetField}
            />
          ),
          buttonText: SUBJECT.EDIT_BTN_TEXT,
          onCancel: handleCancel,
          onSubmit: handleSubmit(onSubmit),
        }
      : {
          component: <Navigate to="/not-found" replace={true} />,
        },
    view: {
      component: (
        <View
          authoritiesByBranchId={authoritiesByBranchId}
          data={branchById}
          isFetchingById={isFetchingById}
          pagePermissions={branchPagePermission}
          setGetAuthoritiesByBranchFilter={setGetAuthoritiesByBranchFilter}
        />
      ),
      onCancel: handleCancel,
    },
  };

  const currentChildren = useMemo(() => {
    return {
      component: drawerChildren[mode]?.component,
      buttonText: drawerChildren[mode]?.buttonText,
      onCancel: drawerChildren[mode]?.onCancel,
      onSubmit: drawerChildren[mode]?.onSubmit,
      withFooter: mode !== 'view',
    };
  }, [
    mode,
    isDirty,
    isBrackedDirty,
    isBrackedValid,
    watch('authorities')?.length,
    watchBrackeds(),
    watch(),
    branchById,
    brackeds?.length,
    isEditLoading,
    isCreateLoading,
    isEditDefaultBranchLoading,
    isLoadingColumnsEdit,
    isLoadingColumnsDelete,
  ]);

  useEffect(() => {
    if (data?.result?.length) {
      dispatch(setButtonVisibility({ visible: true }));
    } else {
      dispatch(setButtonVisibility({ visible: false }));
    }
  }, [data?.result?.length]);

  return {
    currentChildren,
    isFetching,
    columns: columnsData?.columns,
    data,
    filterControl,
    handleRowEdit,
    handleRowClick,
    handleSortClick,
    handleSwitchClick,
    handlePaginationClick,
    handleStopResize,
    handleColumnSearch,
    skip: branchFilters.skip,
    limit: branchFilters.limit,
    isFilterDirty,
    filterOfficeData: finalOfficeOptions,
    resetFilter,
    setOfficesFilter,
    handleOfficeFilterSelect,
    setFilterValue,
    filterOfficeValue,
    branchPagePermission,
    roleLevel,
  };
};

export default useBranch;
